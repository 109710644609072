const initState = {};

export const csv_search = (state = initState, action) => {
  switch (action.type) {
    case "GET_BULKS":
      state = {
        ...state,
        ...action.bulks,
      };
  }
  return state;
};
