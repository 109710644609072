import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IS_PROD } from "../../constants";
import { FormattedMessage } from "react-intl";

export default function ModalMessage(props) {
  useEffect(() => {
    if (IS_PROD) {
      props.googleTagManagerEvent && props.googleTagManagerEvent();
    }
  }, []);
  return (
    <Modal
      show={props.toggle}
      onHide={() => props.setToggle(false)}
      className=" fade  delete-member-wrapper"
      id="delete-member"
    >
      <Modal.Header
        closeButton={!props.forbidCancel}
        className="modal-header border-0"
      >
        <div className="modal-inner-header">
          <h4 className="title">{props.title}</h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-0">
        <div className="unsubscribe-type-list p-24">{props.textElement}</div>
      </div>
      {props.error && (
        <div
          className="btn-grp text-center  error  kaspr-form-text-error"
          style={{ top: 0 }}
        >
          {props.error + " "}
        </div>
      )}
      {!props.forbidAction && (
        <div className="modal-footer border-0">
          {!props.forbidCancel && (
            <a
              href={props.cancelLink || "#"}
              className="btn3 btn-md"
              onClick={() => props.setToggle(false)}
              data-tracking-id={props.tracking && props.tracking.cancel}
            >
              {props.cancel || <FormattedMessage id={"cancel"} />}
            </a>
          )}
          {props.disabled && props.disabled.yes ? (
            <a
              href="#"
              className="btn1 btn-md ml-20"
              style={{ background: "#ccc", textShadow: "none" }}
              data-tracking-id={props.tracking && props.tracking.confirm}
            >
              {props.action}
            </a>
          ) : (
            <a
              href="#"
              className="btn1 btn-md ml-20"
              onClick={props.handleAction}
              data-tracking-id={props.tracking && props.tracking.confirm}
            >
              {props.action}
            </a>
          )}
        </div>
      )}
    </Modal>
  );
}
