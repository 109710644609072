import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import ConnectionModalHeader from "./DefaultConnectionModal/ConnectionModalHeader/ConnectionModalHeader";
import PopUp from "../../../../../components/PopUp/PopUp";
import ConnectionModalLeft from "./DefaultConnectionModal/ConnectionModalLeft/ConnectionModalLeft";
import ConnectionModalRight from "./DefaultConnectionModal/ConnectionModalRight/ConnectionModalRight";
import "./ConnectionModal.css";
import { SettingsContext, actions } from "../../../store/Settings.store";
import ZapierModal from "./ZapierComponent/ZapierModal/ZapierModal";

function ConnectionModal(props) {
  const { state, dispatch } = useContext(SettingsContext);
  useEffect(() => {
    dispatch({
      type: actions.SET_INTEGRATION_TYPE,
      payload: props.integration,
    });
  }, [props.integration]);

  if (
    (state.integrationType === "zapier" && state.isZapierModalOpen) ||
    (state.integrationType === "zapier" &&
      !state.isZapierModalOpen &&
      props.account)
  )
    return <ZapierModal props={props} />;

  return (
    <PopUp
      show={props.show}
      id="connection-modal"
      onClose={props.onHide}
      style={{
        maxHeight: "90vh",
        width: "75vw",
        maxWidth: "980px",
        padding: 0,
      }}
      styleClose={{ top: "20px", right: "20px" }}
    >
      <ConnectionModalHeader props={props} />
      <div id="connection-modal-right" className="connection-modal-body">
        <ConnectionModalLeft integration={props.integration} />
        <ConnectionModalRight props={props} />
      </div>
    </PopUp>
  );
}

export default injectIntl(ConnectionModal);
