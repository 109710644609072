import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import "./AddTaskModal.css";
import { useIntl } from "react-intl";
import AddTaskSection from "../AddTaskSection/AddTaskSection";
function AddTaskModal({ show, setToggle, onCreate, organization, lead, task }) {
  const intl = useIntl();

  return (
    <Modal
      show={show}
      onHide={() => setToggle(false)}
      className=" fade  add_task_modal"
      id="add-task-modal"
    >
      <AddTaskSection
        onCreate={onCreate}
        setToggle={setToggle}
        isModal
        organization={organization}
        lead={lead}
        task={task}
      />
    </Modal>
  );
}

export default AddTaskModal;
