import React, { useReducer, useMemo } from "react";
import { searchReducer, searchInitialState } from "./store/Search.store";
import SearchProvider from "./store/Search.provider";
import { Header, ContentWrapperUi, SideNav } from "../../components";
import SearchPeople from "./SearchPeople/SearchPeople";
import SearchPattern from "./SearchPattern/SearchPattern";

function SearchTabs() {
  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li>
            <a data-toggle="tab" href="#people_tab" className="active">
              People
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#pattern_tab">
              Pattern
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function Search(props) {
  const [state, dispatch] = useReducer(searchReducer, searchInitialState);

  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <SearchProvider store={store}>
      <Header
        setNavToggle={() =>
          props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)
        }
        navToggle={props.redux.globalReducer.navToggle}
        currentPage={"Search"}
        profile={props.redux.profile}
      />
      <ContentWrapperUi>
        <SideNav navToggle={props.redux.globalReducer.navToggle} />
        <SearchWrapper>
          <SearchTabs />
          <div className="tab-content">
            <SearchPeople />
            <SearchPattern />
          </div>
        </SearchWrapper>
      </ContentWrapperUi>
    </SearchProvider>
  );
}

function SearchWrapper({ children }) {
  return (
    <div className="col-md-10 content-wrap">
      <div className="content-block">
        <div className="inner-content-wrap">
          <div className="wd-wrapper">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Search;
