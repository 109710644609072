import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { priceCalc } from "../../../services/utils";
import OrderSummary from "./OrderSummary/OrderSummary";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import "./Checkout.css";
import {
  Elements,
  useElements,
  useStripe,
  CardElement,
  IbanElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../../constants";
import { BillingContext } from "../store/billing.store";
import {
  createOrUpdateCustomer,
  subscribe,
  getSubscriptionInfos,
  getCredits,
  payNow,
} from "../../../services/api/credits.api";
import actions from "../store/billing.actions";
import { connect } from "react-redux";
import { ModalMessage, PreLoader } from "../../../components";
import reduxActions from "../../../redux/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { SuccessModal } from "./common/common.tools";
function Checkout({
  plan,
  organizationCredits,
  updatePaymentToggle,
  resetCredit,
  user,
}) {
  const { state, dispatch } = useContext(BillingContext);
  const [changeCardToggle, setChangeCardToggle] = useState(false);
  const [blockProceed, setBlockProceed] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("CARD");
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl();

  const checkPlanId = () => {
    if (plan.planId === "plan_1") return false;
    if (plan.planId === "plan_2") return false;
    if (plan.planId === "plan_3") return false;
    return true;
  };
  const googleTagManagerEvent = () => {
    if (organizationCredits && organizationCredits.plan.planId !== "0") return;

    window.googleTagManager(
      "purchase",
      plan.unitPrice
        ? priceCalc({
            price: plan.unitPrice,
            yearly: plan.yearly,
            seats: plan.qt,
            withoutDiscount: checkPlanId(),
          })
        : priceCalc({
            price: plan.pricePerSeat,
            yearly: plan.yearly,
            perYear: true,
            seats: plan.seats,
            withoutDiscount: checkPlanId(),
          })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (blockProceed) return;
    setBlockProceed(true);
    let source = null,
      error = null;
    if (
      !organizationCredits.customer ||
      (organizationCredits.customer &&
        !organizationCredits.customer.cardInfo) ||
      changeCardToggle
    ) {
      const cardElement = elements.getElement(CardElement);
      ({ error, source } = await stripe.createSource(cardElement));

      if (error) {
        setBlockProceed(false);
        return setPaymentError(error.message);
      }
    }
    if (!plan) {
      const { data, statusCode } = await createOrUpdateCustomer({
        paymentInfos: { ...state.checkoutForm, source: source && source.id },
      });
      if (statusCode !== 200) {
        setBlockProceed(false);
        return setPaymentError(data.messageTranslated || data.message);
      }
      return (window.location = "/billing");
    }
    let extraCredits = plan.extraCreditsToUpgrade.map((credit) => ({
      amount: credit.addedCredits,
      creditType: credit.creditType,
    }));

    let currentPlan = {
      //  ...plan,
      numberOfSeats: plan.numberOfSeats,
      numberOfMonths: plan.numberOfMonths,
      planId: plan.planId,
      isAnnual: plan.yearly,
      country: state.checkoutForm.country,
      coupon: plan.coupon,
      deleteCoupon: !!!plan.coupon,
      admins: plan.admins,
      extraCredits,
    };
    if (plan.invitees) {
      currentPlan.invitees = plan.invitees;
    }

    if (plan.isPrivate || plan.previousSeats !== plan.seats) {
      currentPlan.numberOfMonths = plan.numberOfMonths;
    }
    let sb_data, sb_status;
    if (plan.isPlanSuspended) {
      ({ data: sb_data, statusCode: sb_status } = await payNow({
        paymentInfos: { ...state.checkoutForm, source: source && source.id },
        admins: plan.admins,
      }));
    } else {
      ({ data: sb_data, statusCode: sb_status } = await subscribe({
        paymentInfos: { ...state.checkoutForm, source: source && source.id },
        ...currentPlan,
      }));
    }

    if (sb_status === 200 && !sb_data.ok) {
      let responseStripe = await stripe.handleCardPayment(sb_data.clientSecret);
      if (responseStripe.error) {
        setBlockProceed(false);
        if (
          responseStripe.error.code === "payment_intent_authentication_failure"
        )
          return setPaymentError(
            intl.formatMessage({ id: "billing.3DSError" })
          );
        return setPaymentError(responseStripe.error.message);
      } else {
        const { data, statusCode } = await createOrUpdateCustomer({
          paymentInfos: { ...state.checkoutForm, source: source && source.id },
        });
        return setSuccessModal(true);
      }
    } else if (sb_status === 200) {
      setSuccessModal(true);
    } else {
      setPaymentError(sb_data.messageTranslated || sb_data.message);
      setBlockProceed(false);
    }
  };
  const handleRedirectAndRefreshCredits = () => {
    resetCredit();
    window.location.href = "/workspace";
  };
  if (loading) return <PreLoader />;
  // if (plan && !plan.subscriptionInfo) return <PreLoader />;
  /*  return <div> <CheckoutHeader /></div>;*/
  return (
    <div className={"main-wraper checkout-main-wrapper"}>
      <CheckoutHeader />
      <section className="checkout-wrapper">
        <form onSubmit={handleSubmit} className="row">
          <CheckoutForm
            organizationCredits={organizationCredits}
            setChangeCardToggle={setChangeCardToggle}
            changeCardToggle={changeCardToggle}
            updatePaymentToggle={updatePaymentToggle}
            error={paymentError}
          />
          {plan && !updatePaymentToggle && (
            <OrderSummary
              plan={plan}
              organizationCredits={organizationCredits}
              blockProceed={blockProceed}
              error={paymentError}
              user={user}
            />
          )}
        </form>
      </section>
      {successModal && (
        <>
          {organizationCredits &&
          organizationCredits.plan &&
          organizationCredits.plan.planId == "0" ? (
            <SuccessModal
              successModal={successModal}
              handleRedirectAndRefreshCredits={handleRedirectAndRefreshCredits}
              googleTagManagerEvent={googleTagManagerEvent}
            />
          ) : (
            <ModalMessage
              toggle={successModal}
              setToggle={() => {
                handleRedirectAndRefreshCredits(false);
              }}
              title={<FormattedMessage id={"billing.paymentPending"} />}
              textElement={
                <>
                  <FormattedMessage
                    id={"billing.yourPaymentIsBeingProcessed"}
                  />
                </>
              }
              action={"Ok"}
              handleAction={handleRedirectAndRefreshCredits}
              forbidCancel
              googleTagManagerEvent={googleTagManagerEvent}
            />
          )}
        </>
      )}
      {/*
      {successModal && (
        <ModalMessage
          toggle={successModal}
          setToggle={() => {
            handleRedirectAndRefreshCredits(false);
          }}
          title={<FormattedMessage id={"billing.paymentPending"} />}
          textElement={
            <>
              <FormattedMessage id={"billing.yourPaymentIsBeingProcessed"} />
            </>
          }
          action={"Ok"}
          handleAction={handleRedirectAndRefreshCredits}
          forbidCancel
          googleTagManagerEvent={googleTagManagerEvent}
        />
      )}*/}
    </div>
  );
}
function CheckoutHeader() {
  return (
    <header className="signup-header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-4 back-ui">
            <div className="back-home-ui">
              <a href="/billing/plans" className="back-home-btn">
                <FormattedMessage id={"billing.backToHome"} />
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="logo text-center">
              <NavLink className="" to="/billing">
                <img
                  className="img-fluid"
                  src="/assets/images/logo_on_white.png"
                  width="125"
                  alt="img"
                />
              </NavLink>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </header>
  );
}
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
function CheckoutWithStripe({
  plan,
  organizationCredits,
  updatePaymentToggle,
  resetCredit,
  user,
}) {
  return (
    <Elements stripe={stripePromise}>
      <Checkout
        plan={plan}
        organizationCredits={organizationCredits}
        updatePaymentToggle={updatePaymentToggle}
        resetCredit={resetCredit}
        user={user}
      />
    </Elements>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCredit: async () => {
      const { data } = await getCredits();
      dispatch({ type: reduxActions.GET_CREDITS, payload: data });
    },
  };
};
export default connect(null, mapDispatchToProps)(CheckoutWithStripe);
