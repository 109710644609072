import React, { useContext, useState } from "react";
import DataNotFound from "../../../../components/DataNotFound/DataNotFound";
import { Pagination, Tooltip } from "../../../../components";
import {
  deleteWaitingList,
  getWaitingList,
} from "../../../../services/api/leads.api";
import { OverlayTrigger } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { LeadsContext, actions } from "../../store/leads.store";
import { checkPermission } from "../../../../services/utils";
import { toast } from "react-toastify";

export default function PreLeads(props) {
  const { state, dispatch } = useContext(LeadsContext);
  const [currentPage, setPage] = useState(1);
  const memberPermission = checkPermission({
    permissions: props.redux.profile.permissions,
    name: "Leads",
    action: "Edit",
  });

  const planPermission = checkPermission({
    permissions: props.redux.credits.organizationCredits.plan.permissions,
    name: "Leads",
    action: "Edit",
  });
  const handleGoToPage = (page) => {
    window.scrollTo({
      behavior: "smooth",
      block: "start",
    });
    getWaitingList({ page }).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: {
            waitingListPreLeads: data.preLeads,
            waitingListPreLeadsPages: data.pages,
            waitingListPreLeadsPage: data.pages.page,
          },
        });
        setPage(page);
      } else {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: { waitingListPreLeads: "not_allowed" },
        });
      }
    });
  };

  const handleDeleteWaitingList = async (id, page) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      dispatch({
        type: actions.GET_WAITING_LIST_NOTIFICATIONS,
        payload: { waitingListNotifications: "not_allowed" },
      });
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    await deleteWaitingList({ id });
    getWaitingList({ page }).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: {
            waitingListPreLeads: data.preLeads,
            waitingListPreLeadsPages: data.pages,
            waitingListPreLeadsPage: data.pages.page,
          },
        });
      } else {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: { activity: "not_allowed" },
        });
      }
    });
  };

  if (!state.waitingListPreLeads) {
    return <div />;
  }

  return (
    <WaitingListWrapper
      state={state}
      page={currentPage}
      pages={state.waitingListPreLeadsPages}
      handleGoToPage={handleGoToPage}
    >
      {state.waitingListPreLeads.length == 0 ? (
        <div className="no-data" style={{ boxShadow: "none", borderRadius: 0 }}>
          <FormattedMessage id={"dataNotFound"} />
        </div>
      ) : (
        <table width="100%" className="table-fixed">
          <thead>
            <tr className="sticky-header">
              <th>
                <FormattedMessage id={"info.name"} />
              </th>
              <th>
                <FormattedMessage id={"info.company"} />
              </th>
              <th>
                <FormattedMessage id={"info.job"} />
              </th>
              <th>
                <FormattedMessage id={"info.location"} />
              </th>
              <th>
                <FormattedMessage id={"leads.addedBy"} />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.waitingListPreLeads.map((item, i) => (
              <WaitingListItem
                key={i}
                item={item}
                handleDeleteWaitingList={handleDeleteWaitingList}
                currentPage={currentPage}
              />
            ))}
          </tbody>
        </table>
      )}
    </WaitingListWrapper>
  );
}

function WaitingListItem({ item, handleDeleteWaitingList, currentPage }) {
  return (
    <tr>
      <td>
        <div className="username view-profile">
          <a
            style={{ width: "250px" }}
            onClick={(e) => e.stopPropagation()}
            href={"http://linkedin.com/in/" + item.linkedin}
            target={"_blank"}
            rel="noopener noreferrer nofollow"
          >
            <p className="user-name capital" style={{ marginRight: "5px" }}>
              {item.firstName}
            </p>
            <p className="user-name capital">{item.lastName}</p>{" "}
            <img src="/assets/images/link_icon.svg" alt="" />
          </a>
          {item.job && item.job.length > 25 ? (
            <OverlayTrigger
              key={"job-" + item._id}
              placement={"top"}
              overlay={(props) => Tooltip({ ...props, data: item.job })}
            >
              <span>{item.job.substring(0, 25) + "..."}</span>
            </OverlayTrigger>
          ) : (
            <span> {item.job} </span>
          )}
        </div>
      </td>
      <td>{item.company}</td>
      <td>{item.job}</td>
      <td>{item.location}</td>
      <td>{item.addedBy.email}</td>
      <td>
        <div className="button-ui delete-account">
          <button
            onClick={() => handleDeleteWaitingList(item._id, currentPage)}
            className="delete-account-btn"
            style={{ width: 100 }}
          >
            <FormattedMessage id={"delete"} />
          </button>
        </div>
      </td>
    </tr>
  );
}

function WaitingListWrapper({ children, page, handleGoToPage, pages }) {
  return (
    <div className="table-wrap">
      <div className="head_block">
        <div className="left_block">
          <div className="title-box">
            <p>
              <FormattedMessage id={"organization.waitingList"} />
            </p>
          </div>
        </div>
        <Pagination
          pages={pages.totalPages}
          currentPage={page}
          goToPage={handleGoToPage}
          top
        />
      </div>
      <div className="table-content sticky-table">{children}</div>
    </div>
  );
}
