import React, { Component } from "react";
import qs from "querystring";
import "./style.css";
import { integrationAuthConnect } from "../../../services/api/integrations.api";
import { messages_en } from "../../../services/translations";
import { CLIENT_ID_ZOHO } from "../../../constants";

class AuthComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      login: "",
      isCrmVerified: "",
    };
  }

  async componentWillMount() {
    const code = qs.parse(this.props.location.search)["?code"];
    this.setState({ loading: true });
    if (code && code !== "undefined") {
      try {
        let email = "";
        let result = null;
        if (this.props.location.pathname.indexOf("hubspot") > -1) {
          result = await integrationAuthConnect({
            code,
            integrationName: "hubspot",
          });
          email = result && result.data && result.data["hubspotEmail"];
          if (result && result.statusCode >= 400)
            this.setState({
              error: result.data.messageTranslated || result.data.message,
            });
        } else if (this.props.location.pathname.indexOf("zoho") > -1) {
          const domain = qs
            .parse(this.props.location.search)
            ["location"].toUpperCase();
          result = await integrationAuthConnect({
            code,
            integrationName: "zohocrm",
            domain,
          });
          email = result && result.data && result.data["zohocrmEmail"];
          if (result && result.statusCode >= 400)
            this.setState({
              error: result.data.messageTranslated || result.data.message,
            });
        } else if (this.props.location.pathname.indexOf("salesforce") > -1) {
          result = await integrationAuthConnect({
            code,
            integrationName: "salesforce",
          });
          if (result && result.statusCode >= 400)
            this.setState({
              error: result.data.messageTranslated || result.data.message,
            });
          email = result && result.data && result.data["salesforceEmail"];
        } else {
          result = await integrationAuthConnect({
            code,
            integrationName: "gmail",
          });
          if (result && result.statusCode >= 400)
            this.setState({
              error: result.data.messageTranslated || result.data.message,
            });
          email = result && result.data && result.data["gmailEmail"];
        }
        this.setState({
          login: email,
          isCrmVerified: result && result.data && result.data.isCrmVerified,
        });
      } catch (err) {
        this.setState({ loading: false, error: err, login: "err" });
      } finally {
        localStorage.setItem("login", this.state.login);
        localStorage.setItem("isCrmVerified", this.state.isCrmVerified);
        localStorage.setItem("loginError", this.state.error);
        this.setState({ loading: false });
        window.open("", "_parent", "");
        window.close();
      }
    }
  }

  render() {
    return (
      <div className="AuthComplete">
        {this.state.loading && (
          <img
            src={"/assets/images/loading.gif"}
            alt={"loading"}
            style={{ height: "110px" }}
          />
        )}
        {this.state.error && <div>Error</div>}
      </div>
    );
  }
}

export default AuthComplete;
