export function legacyPermissions(currentPlan, intl) {
  let current = [];
  let newPricing = [];
  // STARTUP MONTHLY
  if (currentPlan.numberOfMonths == 1) {
    current = [
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage(
        { id: "billing.phoneCreditsPerMonth" },
        {
          value: 100,
        }
      ),
      intl.formatMessage(
        { id: "billing.exportCreditsPerMonth" },
        {
          value: 250,
        }
      ),
      intl.formatMessage({ id: "legacy.permissions.linkedinOutreach" }),
      intl.formatMessage({ id: "legacy.permissions.crmIntegrationApi" }),
      intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
    ];
    newPricing = [
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage(
        { id: "billing.phoneCreditsPerYear" },
        {
          value: 1200,
        }
      ),
      intl.formatMessage(
        { id: "billing.exportCreditsPerYear" },
        {
          value: 3000,
        }
      ),
      /*  intl.formatMessage({ id: "legacy.permissions.linkedinOutreach" }),*/
      intl.formatMessage({ id: "legacy.permissions.enrichmentWorkflow" }),
      intl.formatMessage({ id: "legacy.permissions.crmIntegrationApi" }),
      intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
    ];
  }
  // STARTUP YEARLY
  if (currentPlan.numberOfMonths == 12) {
    current = [
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage(
        { id: "legacy.permissions.phoneCreditsPerMonth" },
        {
          value: 100,
        }
      ),
      intl.formatMessage(
        { id: "billing.exportCreditsPerMonth" },
        {
          value: 250,
        }
      ),
      intl.formatMessage({ id: "legacy.permissions.enrichmentWorkflow" }),
      intl.formatMessage({ id: "legacy.permissions.crmIntegrationApi" }),
      intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
    ];
    newPricing = [
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage(
        { id: "billing.phoneCreditsPerYear" },
        {
          value: 1200,
        }
      ),
      intl.formatMessage(
        { id: "billing.exportCreditsPerYear" },
        {
          value: 3000,
        }
      ),
      /* intl.formatMessage({ id: "legacy.permissions.linkedinOutreach" }),*/
      intl.formatMessage({ id: "legacy.permissions.enrichmentWorkflow" }),
      intl.formatMessage({ id: "legacy.permissions.crmIntegrationApi" }),
      intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
    ];
  }
  /*  // ENTERPRISE MONTHLY
  if (currentPlan.planId == "plan_12" && currentPlan.numberOfMonths == 1) {
  }
  // ENTERPRISE YEARLY
  if (currentPlan.planId == "plan_12" && currentPlan.numberOfMonths == 12) {
  }
  if (currentPlan.planId == "plan_11") {
    current = [
      intl.formatMessage({ id: "legacy.permissions.leadManagement" }),
      intl.formatMessage({ id: "legacy.permissions.exportCsv" }),
      intl.formatMessage({ id: "legacy.permissions.creditsMonth" }),
    ];
    newPricing = [
      intl.formatMessage({ id: "legacy.permissions.allCurrentFeatures" }),
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage({ id: "legacy.permissions.phoneCreditsPerMonth" }),
      intl.formatMessage({ id: "legacy.permissions.linkedinOutreach" }),
      intl.formatMessage({ id: "legacy.permissions.enrichmentWorkflow" }),
      intl.formatMessage({ id: "legacy.permissions.crmIntegrationApi" }),
      intl.formatMessage({ id: "legacy.permissions.apiAccess" }),
    ];
  } else {
    // ENTERPRISE
    current = [
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage({ id: "legacy.permissions.apiAccess" }),
      intl.formatMessage({ id: "legacy.permissions.addOnCredits" }),
      intl.formatMessage({ id: "legacy.permissions.newIntegrations" }),
    ];
    newPricing = [
      intl.formatMessage({ id: "legacy.permissions.unlimitedB2B" }),
      intl.formatMessage({ id: "legacy.permissions.apiAccess" }),
      intl.formatMessage({ id: "legacy.permissions.addOnCredits" }),
      intl.formatMessage({ id: "legacy.permissions.newIntegrations" }),
      intl.formatMessage({ id: "legacy.permissions.phoneCreditsPerMonth" }),
    ];
  }*/

  return { current, newPricing };
}
