import React from "react";

export default function InitialAvatar({ firstName, lastName, className }) {
  let initials = "";
  if (firstName && lastName) {
    initials = firstName[0] + lastName[0];
  } else if (firstName) {
    initials = firstName[0] + firstName[1];
  } else {
    initials = lastName[0] + lastName[1];
  }

  return <div className={className}>{initials}</div>;
}
