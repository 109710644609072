import React from "react";
import "./CreditsSection.css";
import { FormattedMessage } from "react-intl";
export default function CreditsSection({
  organizationCredits,
  remaining,
  type,
}) {
  return (
    <div id={"credits-section"}>
      <div className={"credit-earned"} style={{ color: "#4D58F3" }}>
        <img src="/assets/images/work-email-credits.svg" alt="" />{" "}
        <FormattedMessage
          id={"b2bCredits"}
          values={{
            value:
              organizationCredits && organizationCredits.subscription ? (
                <FormattedMessage id={"unlimited"} />
              ) : remaining.isUnlimited ? (
                <FormattedMessage id={"unlimited"} />
              ) : (
                remaining.workEmailCredits
              ),
          }}
        />
      </div>
      <div className={"credit-earned"} style={{ color: "#0EB763" }}>
        <img src="/assets/images/phone-credits.svg" alt="" />{" "}
        <FormattedMessage
          id={"phoneCredits"}
          values={{
            value: remaining.phoneCredits,
          }}
        />
      </div>

      <div className={"credit-earned"} style={{ color: "#FF206E" }}>
        <img src="/assets/images/direct-email-credits.svg" alt="" />
        <FormattedMessage
          id={"personalEmailCredits"}
          values={{
            value: remaining.personalEmailCredits,
          }}
        />
      </div>
      {remaining.type == "organizationRemaining" && (
        <div className={"credit-earned"} style={{ color: "#B149FF" }}>
          <img src="/assets/images/export-credits.svg" alt="" />
          <FormattedMessage
            id={"exportCredits"}
            values={{
              value: remaining.exportCredits,
            }}
          />
        </div>
      )}
    </div>
  );
}
