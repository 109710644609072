import React, { useEffect, useState } from "react";
import PopUp from "../../../../../components/PopUp/PopUp";
import { FormattedMessage, useIntl } from "react-intl/lib";
import "./FeedbackModal.css";
import FeedbackModalList from "./FeedbackModalList/FeedbackModalList";
import { options } from "../../../constants";

function FeedbackModal({
  toggle,
  optionSelected,
  setOptionSelected,
  handleNextModal,
  handleCloseModal,
}) {
  const intl = useIntl();
  const [feedback, setFeedback] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleNextButton = () => {
    if (feedback) {
      setOptionSelected((prevOption) => ({
        ...prevOption,
        reason: optionSelected.reason + " - " + feedback,
      }));
    }

    if (optionSelected.reason === "Not satisfied with the product") {
      handleNextModal("lets-us-help-modal");
    } else if (optionSelected.reason === "Too difficult to figure out") {
      handleNextModal("chat-with-expert-modal");
    } else {
      handleNextModal("final-confirmation-modal");
    }
  };

  useEffect(() => {
    if (optionSelected === "") {
      setDisabled(true);
    } else if (
      (feedback.length > 0 && optionSelected.openTextArea) ||
      !optionSelected.openTextArea
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [optionSelected, feedback]);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  return (
    <PopUp
      toggle={toggle}
      onClose={handleCloseModal}
      style={{
        width: "80vw",
        maxWidth: "500px",
        height: "auto",
        position: "absolute",
        top: "7vh",
      }}
    >
      <div id="feedback-modal">
        <div className="feedback-modal-title">
          <img src="/assets/images/heart.svg" alt="heart" />
          <h2>
            <FormattedMessage id="cancellationflow.yourFeedback" />
          </h2>
        </div>
        <h1>
          <FormattedMessage id="cancellationflow.yourfeedback.WhyCancelling" />
        </h1>
        <h3>
          <FormattedMessage id="cancellationflow.whyYouAreLeaving" />
        </h3>

        <div className="checkbox-wrap custom-radio-list">
          {options.map((option, index) => {
            return (
              <FeedbackModalList
                option={option}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
              />
            );
          })}
          {optionSelected.openTextArea && (
            <div className="feedback-text-area">
              <h3>
                <FormattedMessage id={optionSelected.textAreaQuestionId} />
                <span style={{ color: "red" }}> *</span>
              </h3>
              <textarea
                placeholder={intl.formatMessage({
                  id: "cancellationflow.yourfeedback.AlternativeSolutionAnswer",
                })}
                style={{ width: "100%" }}
                value={feedback}
                onChange={handleFeedbackChange}
              />
            </div>
          )}
        </div>

        <div className="buttons-container">
          <button className=" btn2 ripple-btn" onClick={handleCloseModal}>
            <FormattedMessage id={"cancellationflow.Back"} />
          </button>
          <button
            className={"btn btn1"}
            style={{
              opacity: disabled ? 0.4 : 1,
              cursor: disabled ? "not-allowed" : "pointer",
            }}
            onClick={handleNextButton}
            disabled={disabled}
          >
            <FormattedMessage id="cancellationflow.yourfeedback.Next" />
          </button>
        </div>
      </div>
    </PopUp>
  );
}

export default FeedbackModal;
