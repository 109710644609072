import React, { useEffect, useReducer, useState } from "react";
import PopUp from "../PopUp/PopUp";
import "./LegacyPopup.css";
import { legacyPermissions } from "./LeagacyPopup.tools";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import {
  getLegacyPopupInfos,
  getSubscriptionInfos,
  legacyTracking,
  subscribe,
} from "../../services/api/credits.api";
import { toast, ToastContainer } from "react-toastify";
import {
  getOrgAdmins,
  updateMemberInfos,
} from "../../services/api/organization.api";
import { formatSubscribeInfo } from "../../services/utils/tools";
import ChooseAdmins from "../../container/Billing/common/ChooseAdmins/ChooseAdmins";

export function Choice({ currentPlan, organizationCredits }) {
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("yearly");
  const [confirmRenew, setConfirmRenew] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);
  const [newPlan, setNewPlan] = useState(null);
  const [allowRenew, setAllowRenew] = useState(null);
  const [loading, setLoading] = useState(null);
  const [orgAdmins, setOrgAdmins] = useState(null);
  const [selectAdminsToggle, setSelectAdminsToggle] = useState(null);
  const [selectedAdmins, setSelectedAdmins] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let today = new Date().getTime();
    let endDate = null;
    if (organizationCredits.subscription) {
      endDate = new Date(
        organizationCredits.subscription.nonRenewalNoticeDeadline ||
          organizationCredits.subscription.endDate
      ).getTime();
    }

    let leftDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
    leftDays = currentPlan.numberOfMonths == 12 ? leftDays - 15 : leftDays - 1;
    leftDays = leftDays < 0 ? 0 : leftDays;
    setDaysLeft(leftDays);
    getLegacyPopupInfos().then(
      ({ data: legacyInfoData, statusCode: legacyInfoStatus }) => {
        if (legacyInfoStatus == 200) {
          setAllowRenew(legacyInfoData.allowRenew);

          getSubscriptionInfos({
            planId: legacyInfoData.nextPlan,
            isAnnual: currentPlan.numberOfMonths == 12,
            numberOfSeats: currentPlan.numberOfSeats,
          }).then(({ data, statusCode }) => {
            setNewPlan({ ...data.plan, ...formatSubscribeInfo(data) });
            /*setSubscriptionPeriod(
              data.plan.numberOfMonths == 1 ? "monthly" : "yearly"
            );*/
          });
        }
        getOrgAdmins().then(({ data, statusCode }) => {
          if (statusCode == 200) {
            setOrgAdmins(
              data.admins.map((admin) => ({
                email: admin.email,
                checked: false,
              }))
            );
          }
        });
      }
    );
  }, []);
  const intl = useIntl();
  if (!organizationCredits) return <div />;
  let permissions = legacyPermissions(
    organizationCredits && organizationCredits.plan && organizationCredits.plan,

    intl
  );
  const planMatch = {
    plan_11: { name: "Starter" },
    plan_12: { name: "Starter" },
  };
  const handleRenew = async () => {
    setLoading(true);
    const { data, statusCode } = await subscribe({
      planId: currentPlan.planId,
    });
    await legacyTracking("renew");
    setLoading(false);
    if (statusCode == 200) {
      toast.success("Successfully updated");
      const {
        data: memberData,
        statusCode: memberStatusCode,
      } = await updateMemberInfos({
        infos: { showPopup: false },
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else toast.error(data.messageTranslated || data.message);
  };
  const handleUpgrade = async () => {
    //await legacyTracking("upgrade");
    if (newPlan) {
      window.location.href =
        "/billing/plans/" + newPlan.planId + "/" + subscriptionPeriod;
    }
  };

  const closePopup = async () => {
    if (window.location.href.indexOf("legacy") > -1) {
      window.history.pushState(null, null, "/billing/info");
      return window.location.reload();
    }
    const { data, statusCode } = await updateMemberInfos({
      infos: { showPopup: false },
    });
    if (statusCode == 200) {
      try {
        await legacyTracking("view");
      } catch (err) {}
    }
    window.location.reload();
  };

  const handleSwitchNow = async () => {
    if (currentPlan.planId == "plan_11" && currentPlan.numberOfMonths == 1) {
      handleUpgrade();
    }
    if (orgAdmins.length > newPlan.maxAdmins && !selectedAdmins) {
      return setSelectAdminsToggle(newPlan);
    }

    const { data, statusCode } = await subscribe({
      planId: "plan_1",
      numberOfMonths: currentPlan.numberOfMonths,
      isAnnual: currentPlan.numberOfMonths == 12,
      country: organizationCredits.customer.country,
      admins: selectedAdmins || undefined,
    });
    if (statusCode == 200) {
      window.location.reload();
    } else {
      toast.error(data.message);
    }
  };

  if (selectAdminsToggle) {
    return (
      <ChooseAdmins
        setToggle={() => {
          setSelectAdminsToggle(null);
          setSelectedAdmins(null);
        }}
        onChange={setSelectedAdmins}
        onSubmit={() => {
          handleSwitchNow(selectAdminsToggle);
        }}
        selectedAdmins={selectedAdmins}
        selectedPlan={selectAdminsToggle}
        error={error}
        setOrgAdmins={setOrgAdmins}
        orgAdmins={orgAdmins}
      />
    );
  }

  if (confirmRenew) {
    return (
      <Confirm
        setToggle={() => {
          setConfirmRenew(false);
          closePopup();
        }}
        toggle={confirmRenew}
        handleUpgrade={handleUpgrade}
        handleRenew={handleRenew}
        newPlan={newPlan}
        loading={loading}
      />
    );
  }
  /*  if (allowRenew) {
    return (
      <PopupWithRenew
        setConfirmRenew={setConfirmRenew}
        organizationCredits={organizationCredits}
        daysLeft={daysLeft}
        closePopup={closePopup}
        currentPlan={currentPlan}
        planMatch={planMatch}
        newPlan={newPlan}
        permissions={permissions}
        handleUpgrade={handleUpgrade}
      />
    );
  }*/
  if (currentPlan.numberOfMonths === 1) {
    return (
      <PopupYearlyPlan
        setConfirmRenew={setConfirmRenew}
        organizationCredits={organizationCredits}
        daysLeft={daysLeft}
        closePopup={closePopup}
        currentPlan={currentPlan}
        planMatch={planMatch}
        newPlan={newPlan}
        permissions={permissions}
        handleUpgrade={handleUpgrade}
        handleSwitchNow={handleSwitchNow}
      />
    );
  }
  return (
    <OneChoice
      permissions={permissions}
      closePopup={closePopup}
      handleUpgrade={handleUpgrade}
      organizationCredits={organizationCredits}
      daysLeft={daysLeft}
      newPlan={newPlan}
      currentPlan={currentPlan}
      handleSwitchNow={handleSwitchNow}
    />
  );

  /*  return (
    <PopUp
      withoutClose
      style={{
        background: "linear-gradient(180deg, #FFF7F3 0%, #FFFFFF 100%)",
      }}
    >
      <div id={"legacy-choice"}>
        <span className="close" onClick={closePopup}>
          <FormattedMessage id={"legacy.iWillChooseLater"} />
        </span>
        <h1>
          <FormattedMessage id={"legacy.makeYourChoice"} />
        </h1>
        <p>
          <FormattedMessage id={"legacy.legacyText"} />
        </p>
        {/!* {currentPlan.numberOfMonths !== 12 ? (
          <div className="radio-group">
            <label
              className={subscriptionPeriod === "yearly" ? "active" : ""}
              onClick={() => setSubscriptionPeriod("yearly")}
            >
              <input
                type="radio"
                name={"rd"}
                checked={subscriptionPeriod === "yearly"}
              />
              <FormattedMessage id={"legacy.annually"} />
            </label>
            <label
              className={subscriptionPeriod === "monthly" ? "active" : ""}
              onClick={() => setSubscriptionPeriod("monthly")}
            >
              <input
                type="radio"
                name={"rd"}
                checked={subscriptionPeriod === "monthly"}
              />
              <FormattedMessage id={"legacy.monthly"} />
            </label>
          </div>
        ) : (
          <div className="radio-group"></div>
        )}*!/}
        <div className="radio-group"></div>
        <div className={"plans-wp"}>
          <div className={"legacy-section-container"}>
            <div>
              <h3>
                <FormattedMessage id={"legacy.yourCurrentPlan"} />{" "}
                {/!*  <small> ( {currentPlan.name} )</small>*!/}
              </h3>
              <div>
                {" "}
                <FormattedMessage
                  id={
                    currentPlan.planId == "plan_11"
                      ? "legacy.legacyPlanIncludes"
                      : "legacy.legacyPlanDoesNotIncludes"
                  }
                  values={{ value: "Legacy" }}
                />
              </div>
              <div
                className={
                  "permissions " +
                  (currentPlan.planId == "plan_12" ? "inactive" : "")
                }
              >
                {permissions.current.map((permission, i) => {
                  return (
                    <div key={i}>
                      {currentPlan.planId == "plan_12" ? (
                        <img src="/assets/images/ic-cross-ui.svg" alt="" />
                      ) : (
                        <img src="/assets/images/round-check.png" alt="" />
                      )}{" "}
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              className={"btn btn2"}
              onClick={() => setConfirmRenew(true)}
            >
              <FormattedMessage id={"legacy.renewForOneYear"} />
            </button>
          </div>
          <div className={"legacy-section-container"}>
            <div>
              <h3>
                {planMatch[currentPlan.planId]
                  ? planMatch[currentPlan.planId].name
                  : newPlan && newPlan.name}
              </h3>
              <div>
                <FormattedMessage
                  id={"legacy.legacyPlanIncludes"}
                  values={{
                    value: planMatch[currentPlan.planId]
                      ? planMatch[currentPlan.planId].name
                      : newPlan && newPlan.name,
                  }}
                />
              </div>
              <div className={"permissions"}>
                {permissions.newPricing.map((permission, i) => {
                  return (
                    <div key={i}>
                      <img src="/assets/images/round-check.png" alt="" />{" "}
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <button className={"btn btn1"} onClick={handleUpgrade}>
              <img
                src="/assets/images/crown.png"
                style={{ width: 20, marginTop: -5, marginRight: 10 }}
              />
              <FormattedMessage
                id={"legacy.upgradePlanFor"}
                values={{
                  value:
                    newPlan &&
                    (newPlan.numberOfMonths == 12
                      ? (newPlan.subscriptionTotal * 0.01) / 12
                      : newPlan.subscriptionTotal * 0.01
                    ).toFixed(2),
                }}
              />
            </button>
          </div>
        </div>
        <div>
          <a href="/billing/plans/compare">
            <FormattedMessage id={"legacy.comparePlans"} />
          </a>
          <p>
            <img src="/assets/images/info-red.svg" alt="" />{" "}
            <FormattedMessage
              id={"legacy.youHaveXDaysToMageYourChoice"}
              values={{
                days: <strong> {daysLeft}</strong>,
                date: (
                  <strong>
                    {" "}
                    <FormattedDate
                      value={new Date(organizationCredits.subscription.endDate)}
                      year="numeric"
                      // month="long"
                      month={"numeric"}
                      day="numeric"
                    />{" "}
                  </strong>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </PopUp>
  );*/
}
export function OneChoice({
  permissions,
  handleUpgrade,
  closePopup,
  organizationCredits,
  daysLeft,
  newPlan,
  currentPlan,
  handleSwitchNow,
}) {
  return (
    <PopUp
      withoutClose
      style={{
        background: "linear-gradient(180deg, #FFF7F3 0%, #FFFFFF 100%)",
      }}
    >
      <div id={"legacy-choice"}>
        <span className="close" onClick={closePopup}>
          <FormattedMessage id={"legacy.iWillChooseLater"} />
        </span>
        <h1>
          <FormattedMessage id={"legacy.moveToOurNewPlans"} />
        </h1>
        <p>
          <FormattedMessage id={"legacy.yourSubscriptionIsOneOfOur"} />
        </p>
        <div className={"plans-wp no-choice "}>
          <div className={"legacy-section-container"}>
            <div>
              <h3 className={"legacy-price-container"}>
                <span>Starter plan</span>
                <span className={"legacy-Price-line"}>
                  <span>
                    <strong>
                      {currentPlan.planId == "plan_11" ? "+15€" : "+0€"}{" "}
                    </strong>
                    /
                    <FormattedMessage id={"billing.userMonth"} />
                    <div className={"billed-type"}>
                      <FormattedMessage id={"billing.billedAnnually"} />
                    </div>
                  </span>
                </span>
              </h3>

              <div className={"permissions "}>
                {permissions.newPricing.map((permission, i) => {
                  return (
                    <div key={i}>
                      <img src="/assets/images/round-check.png" alt="" />{" "}
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            {currentPlan.planId == "plan_11" ? (
              <button className={"btn btn1"} onClick={handleUpgrade}>
                <img
                  src="/assets/images/crown.png"
                  style={{ width: 20, marginTop: -5, marginRight: 10 }}
                />
                <FormattedMessage
                  id={"legacy.upgradePlanFor"}
                  values={{
                    value:
                      newPlan &&
                      (newPlan.numberOfMonths == 12
                        ? (newPlan.subscriptionTotal * 0.01) / 12
                        : newPlan.subscriptionTotal * 0.01
                      ).toFixed(2),
                  }}
                />
              </button>
            ) : (
              <button className={"btn btn2"} onClick={handleSwitchNow}>
                <FormattedMessage id={"legacy.switchNow"} />
              </button>
            )}
          </div>
        </div>
        <div>
          <a href="/billing/plans/compare">
            <FormattedMessage id={"legacy.comparePlans"} />
          </a>
        </div>
      </div>
    </PopUp>
  );
}
export function Confirm({
  handleRenew,
  handleUpgrade,
  setToggle,
  newPlan,
  loading,
}) {
  return (
    <PopUp
      withoutClose
      style={{
        background: "linear-gradient(180deg, #FFF7F3 0%, #FFFFFF 100%)",
      }}
    >
      <div id={"legacy-choice"} className={"legacy-confirm"}>
        <span className="close" onClick={() => setToggle(false)}>
          <FormattedMessage id={"legacy.iWillChooseLater"} />
        </span>
        <h1>
          <FormattedMessage id={"legacy.areYouSure"} />
        </h1>
        <p>
          <FormattedMessage id={"legacy.youHaveDecidedToRenew"} />
        </p>
        <div className={"plans-wp"}>
          <button className={"btn btn2"} onClick={handleRenew}>
            {loading ? (
              <img
                src="/assets/images/loading.gif"
                style={{ width: 20 }}
                alt=""
              />
            ) : (
              <FormattedMessage id={"legacy.renewForOneYear"} />
            )}
          </button>
          <button className={"btn btn1"} onClick={handleUpgrade}>
            <img
              src="/assets/images/crown.png"
              style={{ width: 20, marginTop: -5, marginRight: 10 }}
            />
            <FormattedMessage
              id={"legacy.upgradePlanFor"}
              values={{
                value:
                  newPlan &&
                  (newPlan.numberOfMonths == 12
                    ? (newPlan.subscriptionTotal * 0.01) / 12
                    : newPlan.subscriptionTotal * 0.01
                  ).toFixed(2),
              }}
            />
          </button>
        </div>
      </div>
    </PopUp>
  );
}
function PopupWithRenew({
  closePopup,
  currentPlan,
  setConfirmRenew,
  planMatch,
  newPlan,
  daysLeft,
  organizationCredits,
  permissions,
  handleUpgrade,
}) {
  return (
    <PopUp
      withoutClose
      style={{
        background: "linear-gradient(180deg, #FFF7F3 0%, #FFFFFF 100%)",
      }}
    >
      <div id={"legacy-choice"}>
        <span className="close" onClick={closePopup}>
          <FormattedMessage id={"legacy.iWillChooseLater"} />
        </span>
        <h1>
          <FormattedMessage id={"legacy.makeYourChoice"} />
        </h1>
        <p>
          <FormattedMessage id={"legacy.legacyText"} />
        </p>
        {/* {currentPlan.numberOfMonths !== 12 ? (
          <div className="radio-group">
            <label
              className={subscriptionPeriod === "yearly" ? "active" : ""}
              onClick={() => setSubscriptionPeriod("yearly")}
            >
              <input
                type="radio"
                name={"rd"}
                checked={subscriptionPeriod === "yearly"}
              />
              <FormattedMessage id={"legacy.annually"} />
            </label>
            <label
              className={subscriptionPeriod === "monthly" ? "active" : ""}
              onClick={() => setSubscriptionPeriod("monthly")}
            >
              <input
                type="radio"
                name={"rd"}
                checked={subscriptionPeriod === "monthly"}
              />
              <FormattedMessage id={"legacy.monthly"} />
            </label>
          </div>
        ) : (
          <div className="radio-group"></div>
        )}*/}
        <div className="radio-group"></div>
        <div className={"plans-wp"}>
          <div className={"legacy-section-container"}>
            <div>
              <h3>
                <FormattedMessage id={"legacy.yourCurrentPlan"} />{" "}
                {/*  <small> ( {currentPlan.name} )</small>*/}
              </h3>
              <div>
                {" "}
                <FormattedMessage
                  id={
                    currentPlan.planId == "plan_11"
                      ? "legacy.legacyPlanIncludes"
                      : "legacy.legacyPlanDoesNotIncludes"
                  }
                  values={{ value: "Legacy" }}
                />
              </div>
              <div
                className={
                  "permissions " +
                  (currentPlan.planId == "plan_12" ? "inactive" : "")
                }
              >
                {permissions.current.map((permission, i) => {
                  return (
                    <div key={i}>
                      {currentPlan.planId == "plan_12" ? (
                        <img src="/assets/images/ic-cross-ui.svg" alt="" />
                      ) : (
                        <img src="/assets/images/round-check.png" alt="" />
                      )}{" "}
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              className={"btn btn2"}
              onClick={() => setConfirmRenew(true)}
            >
              <FormattedMessage id={"legacy.renewForOneYear"} />
            </button>
          </div>
          <div className={"legacy-section-container"}>
            <div>
              <h3>
                {planMatch[currentPlan.planId]
                  ? planMatch[currentPlan.planId].name
                  : newPlan && newPlan.name}
              </h3>
              <div>
                <FormattedMessage
                  id={"legacy.legacyPlanIncludes"}
                  values={{
                    value: planMatch[currentPlan.planId]
                      ? planMatch[currentPlan.planId].name
                      : newPlan && newPlan.name,
                  }}
                />
              </div>
              <div className={"permissions"}>
                {permissions.newPricing.map((permission, i) => {
                  return (
                    <div key={i}>
                      <img src="/assets/images/round-check.png" alt="" />{" "}
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <button className={"btn btn1"} onClick={handleUpgrade}>
              <img
                src="/assets/images/crown.png"
                style={{ width: 20, marginTop: -5, marginRight: 10 }}
              />
              <FormattedMessage
                id={"legacy.upgradePlanFor"}
                values={{
                  value:
                    newPlan &&
                    (newPlan.numberOfMonths == 12
                      ? (newPlan.subscriptionTotal * 0.01) / 12
                      : newPlan.subscriptionTotal * 0.01
                    ).toFixed(2),
                }}
              />
            </button>
          </div>
        </div>
        <div>
          <a href="/billing/plans/compare">
            <FormattedMessage id={"legacy.comparePlans"} />
          </a>
          <p>
            <img src="/assets/images/info-red.svg" alt="" />{" "}
            <FormattedMessage
              id={"legacy.youHaveXDaysToMageYourChoice"}
              values={{
                days: (
                  <strong style={daysLeft === 0 ? { color: "red" } : {}}>
                    {daysLeft}
                  </strong>
                ),
                date: (
                  <strong>
                    {" "}
                    <FormattedDate
                      value={new Date(organizationCredits.subscription.endDate)}
                      year="numeric"
                      // month="long"
                      month={"numeric"}
                      day="numeric"
                    />{" "}
                  </strong>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </PopUp>
  );
}
function PopupYearlyPlan({
  closePopup,
  currentPlan,
  setConfirmRenew,
  planMatch,
  newPlan,
  daysLeft,
  organizationCredits,
  permissions,
  handleUpgrade,
  handleSwitchNow,
}) {
  return (
    <PopUp
      withoutClose
      style={{
        background: "linear-gradient(180deg, #FFF7F3 0%, #FFFFFF 100%)",
      }}
    >
      <div id={"legacy-choice"}>
        <span className="close" onClick={closePopup}>
          <FormattedMessage id={"legacy.iWillChooseLater"} />
        </span>
        <h1>
          <FormattedMessage id={"legacy.makeYourChoice"} />
        </h1>
        <p>
          <FormattedMessage id={"legacy.yourSubscriptionIsOneOfOur"} />
        </p>
        {/* {currentPlan.numberOfMonths !== 12 ? (
          <div className="radio-group">
            <label
              className={subscriptionPeriod === "yearly" ? "active" : ""}
              onClick={() => setSubscriptionPeriod("yearly")}
            >
              <input
                type="radio"
                name={"rd"}
                checked={subscriptionPeriod === "yearly"}
              />
              <FormattedMessage id={"legacy.annually"} />
            </label>
            <label
              className={subscriptionPeriod === "monthly" ? "active" : ""}
              onClick={() => setSubscriptionPeriod("monthly")}
            >
              <input
                type="radio"
                name={"rd"}
                checked={subscriptionPeriod === "monthly"}
              />
              <FormattedMessage id={"legacy.monthly"} />
            </label>
          </div>
        ) : (
          <div className="radio-group"></div>
        )}*/}
        <div className="radio-group"></div>
        <div className={"plans-wp"}>
          <div className={"legacy-section-container"}>
            <div>
              <h3 className={"legacy-price-container"}>
                <span>Starter plan</span>
                <span className={"legacy-Price-line"}>
                  <span>
                    <strong>
                      {currentPlan.planId == "plan_11" ? "+19€" : "+0€"}{" "}
                    </strong>
                    / <FormattedMessage id={"billing.userMonth"} />
                    <div className={"billed-type"}></div>
                  </span>
                </span>
              </h3>
              {/* <div>
                {" "}
                <FormattedMessage
                  id={
                    currentPlan.planId == "plan_11"
                      ? "legacy.legacyPlanIncludes"
                      : "legacy.legacyPlanDoesNotIncludes"
                  }
                  values={{ value: "Legacy" }}
                />
              </div>*/}
              <div
                /* className={
                  "permissions " +
                  (currentPlan.planId == "plan_12" ? "inactive" : "")
                }*/
                className={"permissions "}
              >
                {permissions.current.map((permission, i) => {
                  return (
                    <div key={i}>
                      {/* {currentPlan.planId == "plan_12" ? (
                        <img src="/assets/images/ic-cross-ui.svg" alt="" />
                      ) : (
                        <img src="/assets/images/round-check.png" alt="" />
                      )}{" "}*/}
                      <img src="/assets/images/round-check.png" alt="" />
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <button className={"btn btn2"} onClick={handleSwitchNow}>
              <FormattedMessage id={"legacy.switchNow"} />
            </button>
          </div>
          <div className={"legacy-section-container"}>
            <div>
              <h3 className={"legacy-price-container"}>
                <span>Starter plan</span>
                <span className={"legacy-Price-line"}>
                  <span>
                    <strong>
                      {currentPlan.planId == "plan_11" ? "+5€" : "-15€"}
                    </strong>{" "}
                    /
                    <FormattedMessage id={"billing.userMonth"} />
                    <div className={"billed-type"}>
                      <FormattedMessage id={"billing.billedAnnually"} />
                    </div>
                  </span>
                </span>
              </h3>
              <div className={"permissions"}>
                {permissions.newPricing.map((permission, i) => {
                  return (
                    <div key={i}>
                      <img src="/assets/images/round-check.png" alt="" />{" "}
                      <span>{permission}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <button className={"btn btn1"} onClick={handleUpgrade}>
              <img
                src="/assets/images/crown.png"
                style={{ width: 20, marginTop: -5, marginRight: 10 }}
              />
              <FormattedMessage
                id={"legacy.upgradePlanFor"}
                values={{
                  value:
                    newPlan &&
                    (newPlan.numberOfMonths == 12
                      ? (newPlan.subscriptionTotal * 0.01) / 12
                      : newPlan.subscriptionTotal * 0.01
                    ).toFixed(2),
                }}
              />
            </button>
          </div>
        </div>
        <div>
          <a href="/billing/plans/compare">
            <FormattedMessage id={"legacy.comparePlans"} />
          </a>
          <p>
            <img src="/assets/images/info-red.svg" alt="" />{" "}
            <FormattedMessage
              id={"legacy.youHaveXDaysToMageYourChoice"}
              values={{
                days: (
                  <strong style={daysLeft === 0 ? { color: "red" } : {}}>
                    {daysLeft}
                  </strong>
                ),
                date: (
                  <strong>
                    <FormattedDate
                      value={new Date(organizationCredits.subscription.endDate)}
                      year="numeric"
                      // month="long"
                      month={"long"}
                      day="numeric"
                    />
                  </strong>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </PopUp>
  );
}
export default { Choice };
