import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const useFullPageLoader = () => {
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => setCounter(Math.max(counter - 1, 0)), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return [
    loading ? (
      <div>
        <div className="fp-container">
          <img
            src="/assets/images/logo_round.png"
            className="fp-loader"
            alt="loading"
          />
          <p className="info-text">
            <a>
              <FormattedMessage id={"downloadingFile"} />
            </a>
            {typeof counter === "number" && (
              <a>
                <FormattedMessage
                  id={"secondsToGo"}
                  values={{ value: Math.max(2, counter) }}
                />
              </a>
            )}
          </p>
        </div>
      </div>
    ) : null,
    (state) => setLoading(state),
    (time) => setCounter(time),
  ];
};

export default useFullPageLoader;
