import React from "react";
import PopUp from "../../../../../components/PopUp/PopUp";
import { FormattedMessage, useIntl } from "react-intl/lib";
import "./LetUsHelpModal.css";

const LetUsHelpModal = ({
  toggle,
  setError,
  handlePreviousModal,
  handleNextModal,
  handleCloseModal,
}) => {
  const handleCheckItOut = () => {
    window.open(
      "https://www.kaspr.io/use-cases/how-i-use-kaspr-to-prospect",
      "_blank"
    );
    handleCloseModal();
  };

  const handleDeclineButton = () => {
    handleNextModal("final-confirmation-modal");
  };

  return (
    <PopUp
      toggle={toggle}
      onClose={handleCloseModal}
      style={{
        width: "80vw",
        maxWidth: "500px",
        height: "auto",
        position: "absolute",
        top: "7vh",
      }}
    >
      <div id="let-us-help-modal">
        <div className="let-us-help-modal-title">
          <img src="/assets/images/chat.svg" alt="chat icon" />
          <h2>
            <FormattedMessage id="cancellationflow.letUsHelp" />
          </h2>
        </div>
        <h1>
          <FormattedMessage id="cancellationflow.prospectingOnDemand" />
        </h1>
        <p>
          <FormattedMessage
            id="cancellationflow.prospectingOnDemand.text"
            values={{
              br: <br />,
              strong: (chunk) => <strong> {chunk}</strong>,
            }}
          />
        </p>
        <div id="check-it-out-btn">
          <button
            className="btn btn1 btn-ripple"
            style={{ width: "100%" }}
            onClick={handleCheckItOut}
          >
            <FormattedMessage id="cancellationflow.prospectingOnDemand.checkItOut" />
          </button>
        </div>
        <div className="buttons-container">
          <button className=" btn2 ripple-btn" onClick={handlePreviousModal}>
            <FormattedMessage id={"cancellationflow.Back"} />
          </button>
          <button
            className={"btn btn1 ripple-btn btn-outline-primary"}
            onClick={handleDeclineButton}
          >
            <FormattedMessage id="decline" />
          </button>
        </div>
      </div>
    </PopUp>
  );
};

export default LetUsHelpModal;
