import React, { useEffect } from "react";
import * as messages from "../translations";
import { useIntl } from "react-intl";

import {
  checkDataSet,
  getAllParents,
  getAllChildren,
  getTrackingId,
  checkClickable,
} from "./TrackingEvents.tools";

function TrackingEvents({ children, eventTypes = ["click"], onTrack }) {
  const intl = useIntl();
  useEffect(() => {
    eventTypes.forEach((eventType) => {
      document.addEventListener(eventType, onEventTrack);
    });
    return () => {
      eventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, onEventTrack);
      }, true);
    };
  }, []);

  const handleTracking = (trackingId) => {
    // SEND EVENT ...
    // console.log({ trackingId });
  };

  const onEventTrack = (event) => {
    try {
      let dataObject = Object.assign({}, event.target.dataset);
      if (!checkDataSet(dataObject)) {
        let nodes = getAllParents(event.target);
        dataObject = nodes.find(
          (node) => node.dataset && checkDataSet(node.dataset)
        );
        dataObject = dataObject && dataObject.datasetrackingIdt;
      }

      if (checkDataSet(dataObject)) {
        //SEND EVENT ...
        handleTracking(dataObject.trackingId);
      } else {
        let allMessages = messages["messages_" + intl.locale];
        let messagesToMap = {};
        for (let key in allMessages) {
          messagesToMap[allMessages[key]] = key.trim();
        }
        const children = getAllChildren(event.target.childNodes);
        let TrackingIdFromHtml = null;
        if (event.target && getTrackingId(event.target, messagesToMap)) {
          TrackingIdFromHtml = getTrackingId(event.target, messagesToMap);
        } else {
          children.forEach((child) => {
            if (
              getTrackingId(child, messagesToMap) &&
              checkClickable(child.parentNode)
            ) {
              TrackingIdFromHtml = getTrackingId(child, messagesToMap);
            }
          });
        }
        if (TrackingIdFromHtml) handleTracking(TrackingIdFromHtml);
      }
    } catch (err) {}
  };
  return children;
}
export default TrackingEvents;
