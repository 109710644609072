import React, { useContext, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { pricePlanCalc } from "../../../../services/utils";
import RightModal from "../../../../components/RightModal/RightModal";
import BillingDetailsModal from "../BillingDetailsModal/BillingDetailsModal";
import { formatSubscribeInfo } from "../../../../services/utils/tools";
import CurrencyDisplay from "../../../../components/CurrencyDisplay/CurrencyDisplay";
import { getSubscriptionInfos } from "../../../../services/api/credits.api";
import { toast } from "react-toastify";
import actions from "../../store/billing.actions";
import ApiPlans from "../../Plans/ApiPlans";
import { BillingContext } from "../../store/billing.store";

export default function PaymentInfo({
  plan,
  customer,
  subscription,
  updatePaymentInfos,
  user,
  organizationCredits,
}) {
  const [billingDetailsToggle, setBillingDetailsToggle] = useState(false);
  const [loadingSwitchAnnual, setLoadingSwitchAnnual] = useState(false);
  const { dispatch } = useContext(BillingContext);

  const handleSwitchToYearly = async () => {
    setLoadingSwitchAnnual(true);
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: plan.numberOfSeats,
      numberOfMonths: 12,
      planId: plan.planId,
      extraCredits: plan.extraCredits,
      country:
        (organizationCredits.customer &&
          organizationCredits.customer.country) ||
        "France",
    });
    if (statusCode != 200) {
      toast.error(data.messageTranslated || data.message);
      return dispatch({
        type: actions.GET_SUBSCRIPTION_INFOS,
        payload: null,
      });
    }
    setLoadingSwitchAnnual(false);
    dispatch({
      type: actions.GET_SUBSCRIPTION_INFOS,
      payload: {
        numberOfSeats: plan.numberOfSeats,
        numberOfMonths: 12,
        planId: plan.planId,
        country: plan.country,
        extraCredits: plan.extraCredits,
        ...formatSubscribeInfo(data),
      },
    });
  };

  if (!subscription) {
    return (
      <div className="info-card billing-info-card">
        <div className={"payment-info-head"}>
          <h2>{plan.name}</h2>
        </div>
        {customer && (
          <div className="billing-line">
            <div className={"billing-line-method"}>
              {/*<p>
            <FormattedMessage id={"billing.paymentMethod"} />
          </p>*/}
              <div> ***{customer.cardInfo && customer.cardInfo.last4}</div>
              <button onClick={updatePaymentInfos}>
                <FormattedMessage id={"billing.updatePaymentInfo"} />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="info-card billing-info-card">
      {billingDetailsToggle && (
        <BillingDetailsModal
          setToggle={setBillingDetailsToggle}
          toggle={billingDetailsToggle}
          subscription={subscription}
          plan={plan}
          user={user}
          organizationCredits={organizationCredits}
        />
      )}
      <div className={"payment-info-head"}>
        <h2>
          <FormattedMessage id={"billing.yourKasprPlan"} />
        </h2>
        {plan.version == 2 && (
          <button onClick={setBillingDetailsToggle}>
            <FormattedMessage id={"billing.billingDetails"} />
          </button>
        )}
      </div>
      <div className="current-subscription">
        <div>
          <span>
            <FormattedMessage id={"billing.currentSubscription"} />{" "}
          </span>
          <span>
            <FormattedMessage id={"billing.subscriptionCost"} />
          </span>
        </div>
        <div>
          <div>
            <strong>
              {" "}
              {plan.numberOfMonths == 12
                ? "1 year subscription"
                : "Monthly subscription"}{" "}
            </strong>{" "}
            {subscription && (
              <span className={"date"}>
                <FormattedDate
                  value={new Date(subscription.lastRenewDate)}
                  year="numeric"
                  month="long"
                  day="numeric"
                  // weekday="long"
                />{" "}
                -{" "}
                <FormattedDate
                  value={new Date(subscription.endDate)}
                  year="numeric"
                  month="long"
                  day="numeric"
                  //  weekday="long"
                />
              </span>
            )}
          </div>
          <div>
            <strong>
              <CurrencyDisplay
                user={user}
                organizationCredits={organizationCredits}
                amount={plan.totalPrice}
              />
              <small style={{ fontSize: "13px", color: "#666" }}>
                {" "}
                (<FormattedMessage id={"billing.excludingTax"} /> ){" "}
              </small>{" "}
            </strong>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="billing-line">
          <div>
            <p>
              <FormattedMessage id={"billing.billingCycle"} />
            </p>
            <div>
              {plan.numberOfMonths == 12 ? (
                <FormattedMessage id="billing.yearly" />
              ) : (
                <FormattedMessage id="billing.monthly" />
              )}{" "}
            </div>
          </div>
          {subscription &&
          ((organizationCredits.pending &&
            organizationCredits.pending.plan &&
            organizationCredits.pending.plan.planId !== "0") ||
            (organizationCredits.pending &&
              !organizationCredits.pending.plan)) ? (
            <div>
              <p>
                <FormattedMessage id={"billing.nextBillingDate"} />
              </p>
              <div>
                <FormattedDate
                  value={new Date(subscription.endDate)}
                  year="numeric"
                  month="long"
                  day="numeric"
                />{" "}
              </div>
            </div>
          ) : (
            <div>
              <p style={{ color: "rgb(255, 32, 110)" }}>
                <FormattedMessage id={"billing.subscriptionEnd"} />
              </p>
              <div>
                <FormattedDate
                  value={new Date(subscription.endDate)}
                  year="numeric"
                  month="long"
                  day="numeric"
                />{" "}
              </div>
            </div>
          )}
          {customer && (
            <div>
              <p>
                <FormattedMessage id={"billing.paymentMethod"} />
              </p>
              <div> ***{customer.cardInfo && customer.cardInfo.last4}</div>
              <button onClick={updatePaymentInfos}>
                <FormattedMessage id={"billing.updatePaymentInfo"} />
              </button>
            </div>
          )}
        </div>
        {plan.numberOfMonths == 1 && (
          <div
            className={"switch-to-yearly-subscription"}
            onClick={handleSwitchToYearly}
          >
            <small>
              <FormattedMessage id={"billing.changeToYearlySubscription"} />
            </small>
            {loadingSwitchAnnual ? (
              <span>
                <img
                  src={"/assets/images/loading.gif"}
                  style={{ width: 20, margin: "0px 49px" }}
                />
              </span>
            ) : (
              <span>
                <FormattedMessage id={"billing.switchToAnnual"} />
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
