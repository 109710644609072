const template = {
  leadExported: {
    name: {
      en: "Lead exported",
      fr: "lead exporté",
    },
    value: {
      en: "{{member}} exported {{lead}} to {{integration}}",
      fr: "{{member}} a exporté {{lead}} vers {{integration}}",
    },
    fields: ["member", "lead", "integration"],
  },
  leadExportedExcel: {
    name: {
      en: "Lead exported",
      fr: "lead exporté",
    },
    value: {
      en: "{{member}} exported {{lead}} to an Excel file",
      fr: "{{member}} a exporté {{lead}} dans un fichier Excel",
    },
    fields: ["member", "lead"],
  },
  leadExportedCSV: {
    name: {
      en: "Lead exported",
      fr: "lead exporté",
    },
    value: {
      en: "{{member}} exported {{lead}} to a CSV file",
      fr: "{{member}} a exporté {{lead}} dans un fichier CSV",
    },
    fields: ["member", "lead"],
  },
  leadAddedFromPlugin: {
    name: {
      en: "Lead added",
      fr: "Contact enrichi",
    },
    value: {
      en: "{{member}} added {{lead}}",
      fr: "{{member}} a ajouté {{lead}}",
    },
    fields: ["member", "lead"],
  },
  leadAddedFromWorkflow: {
    name: {
      en: "Lead added",
      fr: "Contact enrichi",
    },
    value: {
      en: "{{lead}} was enriched from the workflow {{workflowName}}",
      fr:
        "{{lead}} a été enrichi à partir de l'automatisation {{workflowName}}",
    },
    fields: ["lead", "workflowName"],
  },
  leadUpdated: {
    name: {
      en: "Lead information updated: ",
      fr: "Informations du contact mises à jour: ",
    },
    value: {
      en: "{{member}} updated some information of {{lead}} :",
      fr: "{{member}} a modifié quelques informations de {{lead}} :",
    },
    fields: ["member", "lead"],
  },
  leadLabelChange: {
    name: {
      en: "List change",
      fr: "Changement de liste",
    },
    value: {
      en: "{{member}} transferred {{lead}} to the list {{listEn}}",
      fr: "{{member}} a transféré {{lead}} vers la liste {{listFr}}",
    },
    fields: ["member", "lead", "listFr", "listEn"],
  },
  leadTagged: {
    name: {
      en: "Lead tagged",
      fr: "Ajout de tag",
    },
    value: {
      en: "{{member}} tagged {{lead}}: {{tag}}",
      fr: '{{member}} a ajouté le tag "{{tag}}" pour {{lead}}',
    },
    fields: ["member", "lead", "tag"],
  },
  leadUntagged: {
    name: {
      en: "Lead untagged",
      fr: "Suppression de tag",
    },
    value: {
      en: "{{member}} untagged {{lead}}: {{tag}}",
      fr: '{{member}} a supprimé le tag "{{tag}}" pour {{lead}}',
    },
    fields: ["member", "lead", "tag"],
  },
  leadAddedFromBulkEnrichment: {
    name: {
      en: "Lead added",
      fr: "Contact enrichi",
    },
    value: {
      en: "{{lead}} was enriched from bulk Enrichment",
      fr:
          "{{lead}} a été enrichi à partir de l'enrichissement CSV",
    },
    fields: ["lead", "workflowName"],
  },
  leadEmailed: {
    name: {
      en: "Email sent",
      fr: "Mail envoyé",
    },
    value: {
      en: "{{member}} sent an email to {{lead}}",
      fr: "{{member}} a envoyé un mail à {{lead}}",
    },
    fields: ["member", "lead"],
  },
  leadCalled: {
    name: {
      en: "Call",
      fr: "Appel",
    },
    value: {
      en: "{{member}} failed to reach {{lead}}",
      fr: "{{member}} n'a pas réussi a joindre {{lead}}",
    },
    fields: ["member", "lead"],
  },
  leadAnswered: {
    name: {
      en: "Call",
      fr: "Appel",
    },
    value: {
      en: "{{lead}} Answered {{lead}}'s call",
      fr: "{{lead}} a répondu à {{member}}",
    },
    fields: ["member", "lead"],
  },
  leadCallEnded: {
    name: {
      en: "Call",
      fr: "Appel",
    },
    value: {
      en: "{{member}} had a call with {{lead}} for a duration of {{duration}}",
      fr:
        "{{member}} a passé un appel avec {{lead}} pour une durée de {{duration}}",
    },
    fields: ["member", "lead", "duration"],
  },
};

export default template;
