import React from "react";
import RightModal from "../../../../components/RightModal/RightModal";
import { FormattedDate, FormattedMessage } from "react-intl";
import { calculateExtraCreditsPrice } from "../../../../services/utils/calculatePrice";
import CurrencyDisplay from "../../../../components/CurrencyDisplay/CurrencyDisplay";

export default function BillingDetailsModal({
  setToggle,
  toggle,
  plan,
  subscription,
  user,
  organizationCredits,
}) {
  return (
    <RightModal toggle={toggle} onClose={setToggle}>
      <div className={"billing-details-modal"}>
        <h2>
          <FormattedMessage id={"billing.billingDetails"} />{" "}
        </h2>
        <div>
          <div className="auto-renewal-terms">
            <div>
              <h3>
                <FormattedMessage id={"billing.autoRenewalTerms"} />
              </h3>
              <p>
                {plan.numberOfMonths == 12 ? (
                  <FormattedMessage id={"billing.yearSubscriptionTerms"} />
                ) : (
                  <FormattedMessage id={"billing.MonthSubscriptionTerms"} />
                )}{" "}
                -{" "}
                <FormattedDate
                  value={new Date(subscription.endDate)}
                  year="numeric"
                  month="long"
                  day="numeric"
                />
              </p>
            </div>
            <div>
              <h3>
                <FormattedMessage id="billing.plan.nonRenewalNoticeDeadline" />
              </h3>
              <p>
                {plan.numberOfMonths === 12 ? (
                  <FormattedDate
                    value={
                      new Date(
                        new Date(subscription.endDate).setDate(
                          new Date(subscription.endDate).getDate() - 30
                        )
                      )
                    }
                    year="numeric"
                    month="long"
                    day="numeric"
                    hour="numeric"
                    minute="numeric"
                  />
                ) : (
                  <FormattedDate
                    value={
                      new Date(
                        new Date(subscription.endDate).setDate(
                          new Date(subscription.endDate).getDate() - 2
                        )
                      )
                    }
                    year="numeric"
                    month="long"
                    day="numeric"
                    hour="numeric"
                    minute="numeric"
                  />
                )}
              </p>
            </div>
          </div>

          <div className="billing-details-section">
            <CreditItem
              plan={plan}
              subscription
              organizationCredits={organizationCredits}
              user={user}
            />
            <CreditItem
              plan={plan}
              creditType={"phoneCredits"}
              title={
                <FormattedMessage
                  id={"billing.phoneCredits"}
                  values={{ value: "" }}
                />
              }
              credits={plan.phoneCredits.extra || 0}
              organizationCredits={organizationCredits}
              user={user}
            />
            <CreditItem
              plan={plan}
              creditType={"personalEmailCredits"}
              title={
                <FormattedMessage
                  id={"billing.personalEmailCredits"}
                  values={{ value: "" }}
                />
              }
              credits={plan.personalEmailCredits.extra || 0}
              organizationCredits={organizationCredits}
              user={user}
            />
            <CreditItem
              plan={plan}
              creditType={"exportCredits"}
              title={
                <FormattedMessage
                  id={"billing.exportCredits"}
                  values={{ value: "" }}
                />
              }
              credits={plan.exportCredits.extra || 0}
              organizationCredits={organizationCredits}
              user={user}
            />
          </div>
          <div className={"total-amount-section"}>
            <p>
              <FormattedMessage id={"billing.totalAmount"} />
            </p>
            <div>
              <strong>
                <CurrencyDisplay
                  user={user}
                  organizationCredits={organizationCredits}
                  amount={plan.totalPrice}
                />
                <small style={{ fontSize: "13px", color: "#666" }}>
                  {" "}
                  (<FormattedMessage id={"billing.excludingTax"} /> ){" "}
                </small>
              </strong>
            </div>
          </div>
          <p className={"billed-info"}>
            {plan.numberOfMonths == 12 ? (
              <FormattedMessage id={"billing.billedEveryYear"} />
            ) : (
              <FormattedMessage id={"billing.billedEveryMonth"} />
            )}
          </p>
          <button className="btn btn1" onClick={() => setToggle(false)}>
            <FormattedMessage id={"close"} />
          </button>
        </div>
      </div>
    </RightModal>
  );
}

function CreditItem({
  plan,
  subscription,
  credits,
  creditType,
  user,
  organizationCredits,
  title,
}) {
  let creditAmount = null;
  if (!subscription) {
    creditAmount = calculateExtraCreditsPrice({
      creditType: creditType,
      isAnnual: plan.numberOfMonths == 12,
      amount: credits,
    }).amount;
  }
  return (
    <div className="details-line">
      {subscription ? (
        <div>
          <p>
            {plan.name} {plan.numberOfSeats} <FormattedMessage id={"users"} />
          </p>
          <div>
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={
                plan.numberOfMonths == 12
                  ? plan.pricePerSeat * 12 * plan.numberOfSeats
                  : plan.pricePerSeat * plan.numberOfSeats
              }
            />
          </div>
        </div>
      ) : (
        <div>
          <p>
            <FormattedMessage id={"billing.addOn"} /> {title} ({credits} /{" "}
            {plan.numberOfMonths == 12 ? (
              <FormattedMessage id={"billing.year"} />
            ) : (
              <FormattedMessage id={"billing.month"} />
            )}
            )
          </p>
          <div>
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={creditAmount}
            />
          </div>
        </div>
      )}
      <p>
        {plan.numberOfMonths == 12 ? (
          <FormattedMessage id={"billing.billedYearly"} />
        ) : (
          <FormattedMessage id={"billing.billedMonthly"} />
        )}{" "}
      </p>
    </div>
  );
}
