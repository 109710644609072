import React, { useState } from "react";
import "./AddNoteSection.css";
import { FormattedMessage, useIntl } from "react-intl";
export default function AddNoteSection({
  goToNotes,
  addNote,
  onCancel,
  containerStyle,
}) {
  const [value, setValue] = useState("");
  const intl = useIntl();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addNote(value);
      }}
      className={"add-note-section-container"}
      style={containerStyle}
    >
      <div className={"add-note-title-container"}>
        <h2>
          <FormattedMessage id={"leads.createNewNote"} />
        </h2>
        <p onClick={goToNotes}>
          <FormattedMessage id={"leads.viewNotes"} />{" "}
          <img src="/assets/images/ic-right-arrow.svg" alt="" />{" "}
        </p>
      </div>
      <textarea
        placeholder={intl.formatMessage({ id: "leads.writeYourNoteHere" })}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        autoFocus={true}
        required={true}
      />
      <div className={"add-note-buttons"}>
        <button
          type={"button"}
          className={"btn btn2"}
          onClick={() => {
            setValue("");
            onCancel();
          }}
        >
          <FormattedMessage id={"cancel"} />
        </button>
        <button type={"submit"} className={"btn btn1"}>
          <FormattedMessage id={"add"} />
        </button>
      </div>
    </form>
  );
}
