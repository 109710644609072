import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import CurrencyDisplay from "../../../../../components/CurrencyDisplay/CurrencyDisplay";

export default function BillingInfoSection({
  plan,
  user,
  organizationCredits,
}) {
  return (
    <>
      {plan.vat && (
        <BillingInfoItem
          //   title={"Vat (" + plan.vat.percentage + "%)"}
          title={
            <FormattedMessage
              id={"billing.vat"}
              values={{
                value: plan.vat.percentage,
              }}
            />
          }
          value={
            plan.vat.unitPrice && (
              <CurrencyDisplay
                user={user}
                organizationCredits={organizationCredits}
                amount={(plan.vat.unitPrice * 0.01).toFixed(2)}
              />
            )
          }
        />
      )}
      <BillingInfoItem
        title={<FormattedMessage id={"billing.autoRenewal"} />}
        value={plan.automaticRenewal ? "Yes" : "No"}
      />
      <BillingInfoItem
        title={<FormattedMessage id={"billing.billingCycle"} />}
        value={
          plan.numberOfMonths == 12 ? (
            <FormattedMessage id={"billing.yearly"} />
          ) : (
            <FormattedMessage id={"billing.monthly"} />
          )
        }
      />
      <BillingInfoItem
        title={<FormattedMessage id={"billing.nextBillingDate"} />}
        value={
          <FormattedDate
            value={plan.renewDate}
            month={"long"}
            day={"2-digit"}
            year={"numeric"}
          />
        }
      />
      {/* <BillingInfoItem title={"Non renewal notice deadline"} value={"--"} />*/}
      <BillingInfoItem
        title={<FormattedMessage id={"billing.nextBillingAmount"} />}
        value={
          <CurrencyDisplay
            user={user}
            organizationCredits={organizationCredits}
            amount={(plan.totalRenew * 0.01).toFixed(2)}
          />
        }
      />
    </>
  );
}

function BillingInfoItem({ title, value }) {
  return (
    <div className="inline-info">
      <span> {title}</span>
      <span>
        <strong>{value} </strong>
      </span>
    </div>
  );
}
