import React, { useEffect, useState, useRef } from "react";
import "./SideNav.css";
import { Col, Navbar, Row, Container, Form } from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
function SideNav({ navToggle, redux, isOnboarding }) {
  const [isPaidCustomer, setIsPaidCustomer] = useState(false);

  useEffect(() => {
    setIsPaidCustomer(
      // Legacy browsers supported
      redux.credits &&
        redux.credits.organizationCredits &&
        redux.credits.organizationCredits.plan &&
        redux.credits.organizationCredits.plan.planId !== "0"
    );
  }, []);

  const checkPermission = () => {
    const member = redux.organization.member;
    if (!member) return true;
    if (member && member.type !== "member") return true;
  };

  return (
    <SideNavWrapper navToggle={navToggle}>
      <NavItem
        to={"/home"}
        icon={"dashboard-icon"}
        page={<FormattedMessage id={"sidenav.home"} />}
        tracking={{ "data-tracking-id": "sidenav.home" }}
        isOnboarding={isOnboarding}
      />
      <NavItem
        to={"/leads"}
        icon={"lead-icon"}
        page={<FormattedMessage id={"sidenav.leads"} />}
        tracking={{ "data-tracking-id": "sidenav.leads" }}
      />
      <NavItem
        to={"/tasks"}
        icon={"ic-tasks"}
        page={<FormattedMessage id={"leads.tasks"} />}
        tracking={{ "data-tracking-id": "sidenav.tasks" }}
      />
      <NavItem
        to={"/bulk-enrichment"}
        icon={"bulk-icon"}
        page={<FormattedMessage id={"sidenav.bulkEnrichment"} />}
        tracking={{ "data-tracking-id": "sidenav.bulkEnrichment" }}
      />
      <NavItem
        to={"/workflow"}
        icon={"workflow-icon"}
        page={<FormattedMessage id={"sidenav.workflow"} />}
        tracking={{ "data-tracking-id": "sidenav.workflow" }}
      />
      <NavItem
        to={"/workspace"}
        icon={"team-icon"}
        page={<FormattedMessage id={"sidenav.organization"} />}
        organization={redux.organization.organization}
        collapseId={"#organiztaion-collapse"}
        tracking={{ "data-tracking-id": "sidenav.organization" }}
        subOrganizations={
          redux.organization.subOrganizations &&
          redux.organization.subOrganizations.organizations
        }
      />

      {checkPermission() && (
        <>
          <NavItem
            to={isPaidCustomer ? "/billing/info" : "/billing/plans"} // Navigate to appropriate billing page based on customer status
            icon={"billing-icon"}
            page={<FormattedMessage id={"sidenav.billing"} />}
            collapseId={"#billing-collapse"}
            tracking={{ "data-tracking-id": "sidenav.billing" }}
          />
        </>
      )}
      <NavItem
        to={"/settings"}
        icon={"setting-icon"}
        page={<FormattedMessage id={"sidenav.settings"} />}
        collapseId={"#setting-collapse"}
        tracking={{ "data-tracking-id": "sidenav.settings" }}
      />
    </SideNavWrapper>
  );
}

// =========   side nav components  =========
function NavItem(props) {
  const [redirect, setRedirect] = useState(false);

  return (
    <li>
      <NavLink
        to={props.to}
        id={"onboarding-icon"}
        activeClassName={
          props.isOnboarding
            ? "active onBoarding-home-icon-change-style"
            : "active"
        }
        data-toggle="collapse"
        data-target={props.collapseId}
        {...props.tracking}
        onClick={() => {
          if (
            props.to == "/leads" &&
            window.location.pathname.indexOf("/lead/") > -1
          )
            window.location = "/leads";
          setRedirect(true);
        }}
      >
        <span className={"icon " + props.icon} />
        <span className="nav-txt">{props.page}</span>
        {!props.organization && props.to === "/workspace" && (
          <div className={"red-point"}>
            <i className="fas fa-exclamation"></i>
          </div>
        )}
      </NavLink>
      <div className="tooltip-box">
        <p>{props.page}</p>
      </div>
      {redirect && <Redirect to={props.to} />}
    </li>
  );
}
function Collapse({ id, pages }) {
  return (
    <div id={id} className={"collapse "}>
      <ul className="dropdown-list nav nav-tabs">
        {pages.map((page, key) => {
          return (
            <li key={key}>
              <a
                data-toggle="tab"
                href={page.to}
                style={{ background: "none", boxShadow: "none" }}
              >
                {page.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
function SideNavWrapper({ children, navToggle }) {
  return (
    <Col
      md={2}
      lg={2}
      className={
        "sticky side-nav-container" /*+ (navToggle ? "small-navbar" : "")*/
      }
    >
      <div id="sideNav">
        <Navbar expand={"md"} className="navbar  navbar-light">
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav custScroll ">{children}</ul>
          </div>
        </Navbar>
      </div>
    </Col>
  );
}
const mapStateToProps = (state) => ({ redux: state });
export default connect(mapStateToProps)(SideNav);
