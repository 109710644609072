import { actions } from "../../../../store/Settings.store";

const handleSetKasprProperties = (properties, dispatch) => {
  dispatch({ type: actions.SET_KASPR_PROPERTIES, payload: properties });
};
const handleSetIntegrationProperties = (properties, dispatch) => {
  dispatch({ type: actions.SET_INTEGRATION_PROPERTIES, payload: properties });
};
const handleSetMappedProperties = (properties, dispatch) => {
  dispatch({ type: actions.SET_MAPPED_PROPERTIES, payload: properties });
};
const handleSetMappedValues = (values, dispatch) => {
  dispatch({ type: actions.SET_MAPPED_VALUES, payload: values });
};
const handleSetIsCustomValue = (values, dispatch) => {
  dispatch({ type: actions.SET_IS_CUSTOM_VALUE, payload: values });
};
const handleSetPropertiesAutoComplete = (properties, dispatch) => {
  dispatch({
    type: actions.SET_PROPERTIES_AUTO_COMPLETE,
    payload: properties,
  });
};
const handleSetShowAutoComplete = (boolean, dispatch) => {
  dispatch({ type: actions.SET_SHOW_AUTO_COMPLETE, payload: boolean });
};
const handleSetShowKasprPropertiesAutoComplete = (properties, dispatch) => {
  dispatch({
    type: actions.SET_SHOW_KASPR_PROPERTIES_AUTO_COMPLETE,
    payload: properties,
  });
};
const handleSetPropertyIndex = (nb, dispatch) => {
  dispatch({ type: actions.SET_PROPERTY_INDEX, payload: nb });
};
const handleSetError = (err, dispatch) => {
  dispatch({ type: actions.SET_ERROR, payload: err });
};

export {
  handleSetMappedValues,
  handleSetKasprProperties,
  handleSetMappedProperties,
  handleSetIntegrationProperties,
  handleSetShowKasprPropertiesAutoComplete,
  handleSetIsCustomValue,
  handleSetError,
  handleSetPropertiesAutoComplete,
  handleSetPropertyIndex,
  handleSetShowAutoComplete,
};
