import React, { useState, useEffect } from "react";
import GetOauthUrl from "../common/GetOauthUrl/GetOauthUrl";
import PopUp from "../common/PopUp/PopUp";
import RenderButton from "../common/RenderButton/RenderButton";

const AuthServ = ({
  clientId,
  redirectUri,
  scopes,
  responseType,
  oauthUrl,
  onLogin,
  text,
  type,
  dim,
  inModal,
  onHide,
}) => {
  const [login, setLogin] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  let timer;

  useEffect(() => {
    if (!clientId || !redirectUri) {
      setError(true);
    }
    return () => clearInterval(timer);
  }, [clientId, redirectUri]);

  const startOAuth = (e) => {
    if (e) e.stopPropagation();
    setLoading(true);

    let url = GetOauthUrl(
      clientId,
      redirectUri,
      scopes,
      responseType,
      oauthUrl,
      onLogin,
      text,
      type
    );

    let popup = PopUp(url, `${text} Sign In`, dim.width, dim.height);

    timer = setInterval(function () {
      if (popup.closed) {
        clearInterval(timer);
        const login = localStorage.getItem("login");
        const error = localStorage.getItem("loginError");

        setLogin(login);
        setLoading(false);
        setError(!!error);
        setSuccess(login !== "err");

        if (onLogin) {
          const isLoginError = login === "err";
          const isCrmVerified = localStorage.getItem("isCrmVerified");
          onLogin(
            isLoginError ? false : login,
            error,
            isLoginError ? null : isCrmVerified
          );
        }

        // Clear local storage
        localStorage.setItem("login", null);
        localStorage.setItem("loginError", null);
      }
    }, 500);
  };

  if (error) {
    return <div>Error occurred</div>;
  }

  return (
    <RenderButton
      type={type}
      startOAuth={startOAuth}
      inModal={inModal}
      onHide={onHide}
    />
  );
};

export default AuthServ;
