const actions = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  PHONE_VALIDATION: "PHONE_VALIDATION",
  PHONE_VALIDATED: "PHONE_VALIDATED",
  INVITE_FRIEND: "INVITE_FRIEND",
  FRIEND_INVITED: "FRIEND_INVITED",
  ACCOUNT_VALIDATION: "ACCOUNT_VALIDATION",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export default actions;
