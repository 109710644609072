import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { leaveOrganization } from "../../../../../services/api/organization.api";
import {
  actions,
  OrganizationContext,
} from "../../../store/Organization.store";

export default function MoveToAnotherWorkspaceModal({
  hasOrganization,
  handleAcceptInvitation,
}) {
  const [error, setError] = useState(false);
  const { state, dispatch } = useContext(OrganizationContext);

  const handleSubmit = async () => {
    if (hasOrganization && !state.isAloneInWorkspace && !state.isAdmin) {
      const { data, statusCode } = await leaveOrganization();
      if (statusCode === 200) {
        handleAcceptInvitation(state.selectedInvitation);
        dispatch({
          type: actions.SET_MOVE_WORKSPACE,
          payload: { moveWorkspace: false },
        });
        window.location.href = "/workspace";
      }
    } else if (
      hasOrganization &&
      !state.isAloneInWorkspace &&
      state.isAdmin &&
      state.hasOtherOwnerOrAdmin
    ) {
      const { data, statusCode } = await leaveOrganization();
      if (statusCode === 200) {
        handleAcceptInvitation(state.selectedInvitation);
        dispatch({
          type: actions.SET_MOVE_WORKSPACE,
          payload: { moveWorkspace: false },
        });
        window.location.href = "/workspace";
      }
    } else if (hasOrganization && state.isAloneInWorkspace) {
      dispatch({
        type: actions.SET_IS_MOVING,
        payload: { isMoving: true },
      });
      dispatch({
        type: actions.SET_MOVE_WORKSPACE,
        payload: { moveWorkspace: false },
      });
      dispatch({
        type: actions.SET_DELETE_TEAM_MODAL,
        payload: { deleteTeamModal: true },
      });
    } else if (
      hasOrganization &&
      !state.isAloneInWorkspace &&
      state.isAdmin &&
      !state.hasOtherOwnerOrAdmin
    ) {
      dispatch({
        type: actions.SET_MOVE_WORKSPACE,
        payload: { moveWorkspace: false },
      });
      dispatch({
        type: actions.SET_SELECT_ADMINS_TOGGLE,
        payload: { selectAdminsToggle: true },
      });
    }
  };

  return (
    <Modal
      show={true}
      onHide={() =>
        dispatch({
          type: actions.SET_MOVE_WORKSPACE,
          payload: { moveWorkspace: false },
        })
      }
      className="fade"
      id="move-wk-modal"
      dialogClassName="modal-dialog-centered"
      centered
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id="organization.createJoin.moveToAnotherWorkspace" />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <FormattedMessage id="organization.createJoin.areYouSureYouWantToMoveToAnotherWk" />
      </div>
      {error && (
        <div className="btn-grp text-center error kaspr-form-text-error">
          {error}
        </div>
      )}
      <div className="move-wk-footer">
        <a
          href="#"
          className="btn2"
          onClick={() =>
            dispatch({
              type: actions.SET_MOVE_WORKSPACE,
              payload: { moveWorkspace: false },
            })
          }
        >
          <FormattedMessage id="bulkEnrichment.enrichmentOptions.no" />
        </a>
        <a href="#" className="btn1" onClick={handleSubmit}>
          <FormattedMessage id="bulkEnrichment.enrichmentOptions.yes" />
        </a>
      </div>
    </Modal>
  );
}
