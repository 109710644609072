import React from "react";
import LinkedinIntegrationCard from "./LinkedinIntegrationCard/LinkedinIntegrationCard";
import OauthIntegrationCard from "./OauthIntegrationCard/OauthIntegrationCard";
import ApiKeyIntegrationCard from "./ApiKeyIntegrationCard/ApiKeyIntegrationCard";
import ZapierIntegrationCard from "./ZapierIntegrationCard/ZapierIntegrationCard";
import { services } from "../constants";

function IntegrationCard(props) {
  const { name } = props;

  const cardTypes = {
    linkedin: LinkedinIntegrationCard,
    oauth: OauthIntegrationCard,
    apikey: ApiKeyIntegrationCard,
    zapier: ZapierIntegrationCard,
  };

  const IntegrationComponent = cardTypes[services[name]] || null;

  return IntegrationComponent ? <IntegrationComponent {...props} /> : null;
}

export default IntegrationCard;
