import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../../../../components/Carousel/Carousel";

const IntegrationCarousel = () => {
  const carouselItems = [
    {
      href: {
        en:
          "https://help.kaspr.io/en/articles/8800425-how-to-get-free-credits-by-referring-colleagues",
        fr:
          "https://help.kaspr.io/fr/articles/8800425-comment-obtenir-des-credits-gratuits-en-parrainant-des-collegues",
        es:
          "https://help.kaspr.io/es/articles/8800425-como-conseguir-creditos-gratis-recomendando-a-colegas",
        de:
          "https://help.kaspr.io/de/articles/8800425-so-konnen-sie-sich-durch-das-anwerben-von-kollegen-kostenlose-credits-sichern",
      },

      imageSrc: "/assets/images/carousel-1.png",
      title: "home.slider.howToGetUnlimitedB2b",
    },
    {
      href: {
        en: "https://help.kaspr.io/en/collections/8028464-sales-automation",
        fr: "https://help.kaspr.io/fr/collections/8028464-sales-automation",
        es: "https://help.kaspr.io/es/collections/8028464-sales-automation",
        de: "https://help.kaspr.io/de/collections/8028464-sales-automation",
      },

      imageSrc: "/assets/images/carousel-2.png",
      title: "home.slider.howToLaunchAnAutomation",
    },
    {
      href: {
        en: "https://help.kaspr.io/en/collections/7317002-getting-started",
        fr: "https://help.kaspr.io/fr/collections/7317002-demarrer-avec-kaspr",
        es: "https://help.kaspr.io/es/collections/7317002-para-comenzar",
        de: "https://help.kaspr.io/de/collections/7317002-einfuhrung",
      },

      imageSrc: "/assets/images/carousel-3.png",
      title: "home.slider.howToUseKasprTheBestWay",
    },
    {
      href: {
        en: "https://help.kaspr.io/en/collections/7427405-integrations-api",
        fr: "https://help.kaspr.io/fr/collections/7427405-integrations-api",
        es:
          "https://help.kaspr.io/es/collections/7427405-conectar-tus-herramientas",
        de:
          "https://help.kaspr.io/de/collections/7427405-synchronisieren-sie-ihre-tools",
      },

      imageSrc: "/assets/images/carousel-4.png",
      title: "home.slider.howToConnectKasprWithCrm",
    },
  ];

  return <Carousel carouselItems={carouselItems} />;
};

export default IntegrationCarousel;
