/* global chrome */
import React, { useEffect, useState } from "react";
import { Row, Col, OverlayTrigger } from "react-bootstrap";
import {
  ConfirmPhonePopUp,
  PhoneValidation,
} from "./PhoneValidation/PhoneValidation";
import InviteFriend from "./InviteFriend/InviteFriend";
import {
  changePhone,
  isPhoneTokenSent,
  phoneVerificationRequest,
  skipSteps,
  validatePluginInstallation,
} from "../../../services/api/auth.api";
import { CHROME_STORE, EXTENSION_ID, IS_PROD } from "../../../constants";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "../../../components";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

function OnBoardingSteps(props) {
  const [phoneValidationModalToggle, setPhoneValidationModalToggle] = useState(
    false
  );
  const [confirmPhonePopupToggle, setConfirmPhonePopupToggle] = useState(false);
  const [inviteModalToggle, setInviteToggle] = useState(false);
  const [linkedinModalToggle, setLinkedinToggle] = useState(false);
  const [steps, setSteps] = useState([]);
  const [phone, setPhone] = useState(false);
  let [phoneSmsError, setPhoneSmsError] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    if (props.profile && props.profile.steps) {
      setSteps(props.profile.steps);
    }
    if (window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          action: "isKasprExtensionInstalled",
        },
        async (response, err) => {
          if (response === undefined) {
            //    this.setState({showAlertDownloadPlugin: response === undefined});
          } else {
            const allSteps = props.profile.steps.map((step) => {
              if (step.id === "pluginInstallation") {
                if (step.done) {
                  return step;
                }
                step.done = true;
                validatePluginInstallation();
              }
              return step;
            });
            setSteps(allSteps);
          }
        }
      );
    }
  }, [props.profile]);
  const handleOnClick = async (type) => {
    switch (type) {
      case "phoneValidation":
        let body = await isPhoneTokenSent();
        if (body.data.success) {
          setPhoneValidationModalToggle(true);
          setConfirmPhonePopupToggle(false);
          break;
        }
        setPhoneValidationModalToggle(false);
        setConfirmPhonePopupToggle(true);
        break;
      case "confirm_phone":
        if (phone) {
          await changePhone(phone);
        }
        let { data, statusCode } = await phoneVerificationRequest();
        if (statusCode >= 400 && data && data.message) {
          setPhoneSmsError(data.message.replace(" 'To'", ""));
          break;
        }
        setPhoneSmsError(null);
        setConfirmPhonePopupToggle(false);
        setPhoneValidationModalToggle(true);
        break;
      case "edit_phone":
        setConfirmPhonePopupToggle(true);
        setPhoneValidationModalToggle(false);
        break;
      case "invite":
        setInviteToggle(true);
        break;
      case "linkedin_connect":
        steps.find((stp) => stp.id === "pluginInstallation").done === true
          ? window.open(
              "https://www.linkedin.com/feed/?synchro=kaspr&isFirstKasprSynchro=true"
            )
          : window.open(CHROME_STORE);
        break;
      case "plugin":
        window.open(CHROME_STORE);
        break;
    }
  };
  /* const handlePhoneConfirm = (phone) => {
    setPhone(phone);
  };*/
  const handleSkipSteps = async () => {
    // const results = await skipSteps();
    window.location = "/home";
  };
  const handleProceed = async () => {
    window.location = "/home";
  };
  const checkNextStep = () => {
    if (props.profile && props.profile.nextStep) {
      if (props.profile.nextStep === 1) return true;
      return props.profile.nextStep === 2;
    }
  };
  return (
    <>
      {" "}
      <OnBoardWrapper
        handleSkipSteps={handleSkipSteps}
        handleProceed={handleProceed}
        nextStep={props.profile && props.profile.nextStep}
        intl={props.intl}
      >
        {props.profile.phone && (
          <ConfirmPhonePopUp
            setConfirmPopupToggle={setConfirmPhonePopupToggle}
            toggle={confirmPhonePopupToggle}
            onPhoneChange={setPhone}
            phone={phone ? phone : props.profile.phone}
            onClick={handleOnClick}
            error={phoneSmsError}
          />
        )}
        {props.profile.phone && (
          <PhoneValidation
            handleCloseModal={() => setPhoneValidationModalToggle(false)}
            toggle={phoneValidationModalToggle}
            phone={phone ? phone : props.profile.phone}
            resetCredits={props.resetCredits}
            showToastMessage={(response) => {
              response === "success"
                ? toast.success(
                    props.intl.formatMessage({
                      id: "auth.codeSuccessfullyResent",
                    })
                  )
                : toast.error(response);
            }}
            onClick={handleOnClick}
          />
        )}
        <InviteFriend
          handleCloseModal={() => setInviteToggle(false)}
          toggle={inviteModalToggle}
          showToastMessage={(response) => {
            response === "success"
              ? toast.success(
                  props.intl.formatMessage({
                    id: "auth.invitationsSuccessfullySent",
                  })
                )
              : toast.error(response);
          }}
        />
        <Row>
          <OnBoardingLeft profile={props.profile} intl={props.intl} />
          <Col md={12} lg={9} className={"onboard-list-container"}>
            <div className="recomended-block">
              <div className="onboard-content p-0">
                <h3
                  style={{
                    textAlign: "center",
                    margin: 0,
                    fontSize: 30,
                    marginTop: -30,
                  }}
                  className={"title"}
                >
                  <span style={{ marginBottom: 10, fontWeight: 500 }}>
                    <FormattedMessage id={"auth.oneStepLeftToComplete"} />
                  </span>
                </h3>
              </div>

              <div
                className="top-heading"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <p>
                  <FormattedMessage id={"auth.yourNearlyReadyToGo"} />
                </p>
              </div>
              <div className="recomended-tag-block">
                <span className="tag-ui">
                  <FormattedMessage id={"required"} />
                </span>
              </div>
              <div className="onboard-step-list">
                <table>
                  <tbody>
                    <OnBoardItem
                      icon={"ic-downlaod.png"}
                      title={
                        <FormattedMessage
                          id={"auth.getTheKasprLinkedinChrome"}
                        />
                      }
                      text={
                        <FormattedMessage
                          id={"auth.extensionToQuicklyFindPersonal"}
                        />
                      }
                      action={<FormattedMessage id={"auth.getNow"} />}
                      credits={0}
                      type={"plugin"}
                      onClick={handleOnClick}
                      step={steps.find(
                        (stp) => stp.id === "pluginInstallation"
                      )}
                      active
                    />
                    <OnBoardItem
                      title={
                        <FormattedMessage id={"auth.connectWithLinkedin"} />
                      }
                      text={
                        <FormattedMessage id={"auth.connectWithLinkedinText"} />
                      }
                      action={
                        <FormattedMessage id={"settings.profile.connect"} />
                      }
                      credits={0}
                      type={"linkedin_connect"}
                      onClick={handleOnClick}
                      step={steps.find(
                        (stp) => stp.id === "linkedinValidation"
                      )}
                      noBorder
                      pluginStep={steps.find(
                        (stp) => stp.id === "pluginInstallation"
                      )}
                    />
                    <tr>
                      <td colSpan={100} style={{ textAlign: "right" }}>
                        <div style={{ display: "inline-block" }}>
                          {checkNextStep() ? (
                            <OverlayTrigger
                              key={"proceed"}
                              placement={"top"}
                              overlay={(props) =>
                                Tooltip({
                                  ...props,
                                  data: intl.formatMessage({
                                    id: "auth.youHaveToInstallThePlugin",
                                  }),
                                })
                              }
                            >
                              <p className="contact-more tip">
                                <button
                                  className="btn1"
                                  style={{ background: "#ccc", color: "#fff" }}
                                >
                                  <FormattedMessage id={"auth.letsGo"} />
                                </button>
                              </p>
                            </OverlayTrigger>
                          ) : (
                            <a
                              href="#"
                              className="btn1"
                              onClick={handleSkipSteps}
                            >
                              <FormattedMessage id={"auth.letsGo"} />
                            </a>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </OnBoardWrapper>
      <div
        style={{
          position: "fixed",
          bottom: 10,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {" "}
        <p className="copyright">
          ©2023 Kaspr ·{" "}
          <a
            className="copyright"
            target="_blank"
            href="https://kaspr.io/privacy-policy/"
          >
            <FormattedMessage id={"auth.privacy"} />
          </a>{" "}
          &amp;{" "}
          <a
            className="copyright"
            target="_blank"
            href="https://kaspr.io/terms/"
          >
            <FormattedMessage id={"auth.terms"} />
          </a>
        </p>
      </div>
    </>
  );
}

function OnBoardItem({
  type,
  icon,
  credits,
  title,
  text,
  action,
  active,
  onClick,
  noBorder,
  dashedBorder,
  step,
  pluginStep,
}) {
  return (
    <tr
      className={noBorder ? "border-0" : ""}
      style={{
        borderTop: dashedBorder ? "2px dashed lightpink" : "none",
        ...(pluginStep && !pluginStep.done && type !== "plugin"
          ? { opacity: 0.5, filter: "blur(1px)", pointerEvents: "none" }
          : {}),
      }}
    >
      {type === "plugin" ? (
        <td rowSpan="2">
          <span className="step-icon-ui">
            <img
              src="/assets/images/chrome_extension.svg"
              width="36px"
              alt=""
            />
          </span>
          <span className="arrow-icon">
            <img src="/assets/images/connect-arrow.png" alt="" />
          </span>
        </td>
      ) : icon ? (
        <td>
          <span className="step-icon-ui">
            <img src={"assets/images/" + icon} width="40px" alt="" />
          </span>
        </td>
      ) : null}
      <td colSpan={"12"}>
        <h5>{title}</h5>
        <p>{text}</p>
      </td>
      <td style={{ textAlign: "right", padding: 0 }}>
        <a
          href="#"
          className={
            type === "plugin" && step && !step.done
              ? "btn btn1"
              : pluginStep && pluginStep.done
              ? "btn btn1"
              : "text-btn"
          }
          style={{
            width: 150,
            textAlign: "center",
            display: "inline-block",
            borderRadius: 5,
          }}
          onClick={() => onClick(type)}
        >
          {action}{" "}
          <i className="fas fa-external-link-alt" style={{ marginLeft: 5 }} />
        </a>
      </td>
      <td colSpan={2}>
        <div className={"status " + (step && step.done ? "active" : "")}>
          {/* {type === "plugin" ? (
            ""
          ) : (
            <>
              {" "}
              {credits} <FormattedMessage id={"credits"} />
            </>
          )}*/}
          <span className="status-icon"></span>
        </div>
      </td>
    </tr>
  );
}

function OnBoardWrapper({ children }) {
  return (
    <div className="main-wraper onboard-white-bg">
      <div className="onboard-content-ui">
        <div className="container-fluid">
          <Row>
            <Col lg={12} xl={10}>
              {children}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

function OnBoardingLeft({ profile, intl }) {
  return (
    <Col md={12} lg={3}>
      <div className="onboard-content p-0">
        <h3 className="title">
          <span>
            <FormattedMessage
              id={"auth.hey"}
              values={{
                value: (
                  <span style={{ textTransform: "capitalize" }}>
                    {profile.firstName}
                  </span>
                ),
              }}
            />{" "}
          </span>
          <br />
          <FormattedMessage id={"auth.WelcomeOnBoard"} />
        </h3>
      </div>
      <div className="video-wrapper">
        <span>
          <FormattedMessage id={"auth.howItWorks"} />
        </span>
        <div
          className="video-ui"
          data-toggle="modal"
          data-target="#video-modal"
        >
          <video
            style={{ width: "100%", height: "240px", outline: "none" }}
            controls
          >
            <source src="/assets/videos/kaspr_video.mp4" type="video/mp4" />
            <source src="/assets/videos/kaspr_video.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Col>
  );
}
OnBoardingSteps = injectIntl(OnBoardingSteps);
export { OnBoardingSteps };
