import React from "react";
import actions from "./auth.actions";

const AuthContext = React.createContext();
let language = navigator.language.split(/[-_]/)[0];
language =
  language !== "fr" && language !== "en" && language !== "de" ? "en" : language;

const authInitialState = {
  loginForm: {
    email: "",
    password: "",
  },
  signupForm: {
    email: "",
    firstName: "",
    lastName: "",
    phone: { phoneNumber: "", countryCode: "" },
    password: "",
    acceptedTerms: false,
    receiveNewsletter: true,
    language,
  },
  resetForm: {
    email: "",
    token: "",
    password: "",
    confirmPassword: "",
  },
  ValidationCode: null,
  phoneValidated: false,
  inviteFriend: null,
  friendInvited: false,
  codeConfirmAccount: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        loginForm: { ...state.loginForm, ...action.payload },
      };
    case actions.SIGNUP:
      return {
        ...state,
        signupForm: { ...state.signupForm, ...action.payload },
      };
    case actions.PHONE_VALIDATION:
      return {
        ...state,
        phoneValidationCode: action.payload,
      };
    case actions.PHONE_VALIDATED:
      return {
        ...state,
        phoneValidated: true,
      };
    case actions.INVITE_FRIEND:
      return {
        ...state,
        inviteFriend: action.payload,
      };
    case actions.FRIEND_INVITED:
      return {
        ...state,
        friendInvited: true,
      };
    case actions.ACCOUNT_VALIDATION:
      return {
        ...state,
        codeConfirmAccount: action.payload,
      };
    case actions.RESET_PASSWORD:
      return {
        ...state,
        resetForm: { ...state.resetForm, ...action.payload },
      };
    default:
      return state;
  }
};

export { AuthContext, authInitialState, authReducer, actions };
