import React from "react";
import { PreLoader } from "../../../../../components";
import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";

export default function Performance({ performance, goToProfile, wkType }) {
  if (!performance) {
    return <PreLoader />;
  }
  return (
    <div id="performance_tab" className="tab-pane fade ">
      {/*<div className="perfomance-detail">
        <HeaderInfos infos={performance.infos} wkType={wkType} />
        {performance.steps.length > 0 && (
          <Steps steps={performance.steps} goToProfile={goToProfile} />
        )}
      </div>*/}
      <div
        id="workflow_tab"
        className="perfomance-detail"
        style={{ minHeight: "auto" }}
      >
        <HeaderInfos infos={performance.infos} wkType={wkType} />
        <div className="no-data" style={{ boxShadow: "none" }}>
          <FormattedMessage id={"workflow.comingSoon"} />
        </div>
      </div>
    </div>
  );
}
function HeaderInfos({ infos, wkType }) {
  const getInfos = (infoName) => {
    const currentInfos = infos.find((info) => info.name === infoName);
    return currentInfos.value;
  };
  switch (wkType) {
    case "linkedin":
      return (
        <div className="top-header mini-stats-wrap">
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_contacts.png" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("totalContacts")}</p>
              <span>
                <FormattedMessage id={"workflow.totalContacts"} />
              </span>
            </div>
          </div>
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_invitations.png" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("acceptedInvitations")}</p>
              <span>
                <FormattedMessage id={"workflow.invitationAccepted"} />
              </span>
            </div>
          </div>
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_replies.png" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("respondedMessages")}</p>
              <span>
                <FormattedMessage id={"workflow.replyMessages"} />
              </span>
            </div>
          </div>
        </div>
      );
      break;
    case "enrichment":
      return (
        <div className="top-header mini-stats-wrap">
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_contacts.png" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("totalContacts")}</p>
              <span>
                <FormattedMessage id={"workflow.totalContacts"} />
              </span>
            </div>
          </div>
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_treated.svg" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("profilesTreated")}</p>
              <span>
                <FormattedMessage id={"workflow.treated"} />
              </span>
            </div>
          </div>
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_phone.svg" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("profilesWithPhones")}</p>
              <span>
                <FormattedMessage id={"leads.properties.phones"} />
              </span>
            </div>
          </div>
          <div className="mini-stats">
            <div className="mini-stats-icon">
              <img src="/assets/images/ic_f_email.svg" alt="" />
            </div>
            <div className="mini-stats-content">
              <p>{getInfos("profilesWithEmails")}</p>
              <span>Emails</span>
            </div>
          </div>
        </div>
      );
  }
}

function Steps({ steps, goToProfile }) {
  return (
    <div className="table-content table-width-equal">
      <table width="100%">
        <thead>
          <tr>
            <th>
              <FormattedMessage id={"workflow.step"} />s
            </th>
            <th>Action</th>
            <th>
              <FormattedMessage id={"workflow.inProgress"} />
            </th>
            <th>
              <FormattedMessage id={"workflow.success"} />
            </th>
            <th>
              <FormattedMessage id={"workflow.failures"} />
            </th>
          </tr>
        </thead>
        <tbody>
          {steps.map((step, i) => (
            <tr key={i} onClick={() => goToProfile(step)}>
              <td>
                <FormattedMessage id={"workflow.step"} /> {step.step}
              </td>
              <td>{step.name}</td>
              <td>{step.pending}</td>
              <td>{step.succeeded}</td>
              <td>{step.failed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
