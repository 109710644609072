const actions = {
  TOGGLE: "TOGGLE",
  GET_LEADS: "GET_LEADS",
  SELECT_LEAD: "SELECT_LEAD",
  GET_LABELS: "GET_LABELS",
  SELECT_LABEL: "SELECT_LABEL",
  GET_ORGANIZATION: "GET_ORGANIZATION",
  GET_MEMBERS: "GET_MEMBERS",
  LEADS_FILTER: "LEADS_FILTER",
  GET_CREDITS: "GET_CREDITS",
  CHANGE_PROPERTIES: "CHANGE_PROPERTIES",
  RESET_PROPERTIES: "RESET_PROPERTIES",
  INIT_STATE: "INIT_STATE",
  EDIT_LEAD_FIELDS: "EDIT_LEAD_FIELDS",
  LEADS_LOADING: "LEADS_LOADING",
  GET_WAITING_LIST_NOTIFICATIONS: "GET_WAITING_LIST_NOTIFICATIONS",
  GET_REMAINING: "GET_REMAINING",
  START_WAITING_LIST_NOTIFICATIONS: "START_WAITING_LIST_NOTIFICATIONS",
  GET_WAITING_LIST: "GET_WAITING_LIST",
  LEAD_DETAILS: "LEAD_DETAILS",
  GET_ALL_TAGS: "GET_ALL_TAGS",
  GET_TASKS: "GET_TASKS",
};

export default actions;
