import React, { useContext, useEffect, useState } from "react";
import Activity from "../Activity/Activity";
import Notes from "../Notes/Notes";
import Tasks from "../Tasks/Tasks";
import {
  addLeadNote,
  getLeadFeatures,
  leadNoteDelete,
  leadNoteUpdate,
} from "../../../../../services/api/leads.api";
import { FormattedMessage, useIntl } from "react-intl";
import { actions, LeadsContext } from "../../../store/leads.store";
import { toast, ToastContainer } from "react-toastify";
import { Permissions } from "../../../../../components";
export default function RightFeatures({
  profile,
  user,
  organization,
  addNoteMessage,
  memberPermission,
  planPermission,
  onAddTask,
}) {
  const { state, dispatch } = useContext(LeadsContext);
  const intl = useIntl();
  const handleAddNote = async (noteValue) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    const { statusCode, data } = await addLeadNote({
      text: noteValue,
      leadId: profile._id,
      clientDate: new Date(),
    });
    if (statusCode == 200) {
      const { data } = await getLeadFeatures({ leadId: profile._id });
      dispatch({ type: actions.LEAD_DETAILS, payload: data });
    } else {
      toast.error(data.message);
    }
  };
  const handleUpdateNote = async ({ noteValue, noteId }) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    if (!noteValue) {
      return toast.error(
        intl.formatMessage({ id: "workflow.pleaseEnterTheValidText" })
      );
    }
    const { statusCode, data } = await leadNoteUpdate({
      text: noteValue,
      _id: noteId,
      clientDate: new Date(),
    });
    if (statusCode == 200) {
      const { data } = await getLeadFeatures({ leadId: profile._id });
      dispatch({ type: actions.LEAD_DETAILS, payload: data });
    } else {
      toast.error(data.message);
    }
  };
  const handleDeleteNote = async (note) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    const { statusCode, data } = await leadNoteDelete({
      _id: note._id,
    });
    if (statusCode == 200) {
      const { data } = await getLeadFeatures({ leadId: profile._id });
      dispatch({ type: actions.LEAD_DETAILS, payload: data });
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    if (profile.isViewTasks) {
      let tasksButton = document.querySelector("#showTasks");
      setTimeout(() => {
        if (tasksButton) tasksButton.click();
      }, 0);
    }
  }, []);

  return (
    <div className="col-lg-7">
      <div className="workflow-detail-header">
        <div className="cstm-tabs-ui cs-tabs-slider">
          <ul className="nav nav-tabs">
            <li>
              <a
                data-toggle="tab"
                href="#activity_tab"
                className={!profile.isViewNotes ? "active" : ""}
                onClick={() => {
                  profile.isViewNotes = false;
                  dispatch({ type: actions.SELECT_LEAD, payload: profile });
                }}
              >
                <FormattedMessage id={"leads.activities"} />
              </a>
            </li>
            <li>
              {!planPermission && !memberPermission ? (
                <Permissions.CallToAction
                  withoutIcon={true}
                  isPlan={!planPermission}
                >
                  <a
                    data-toggle="tab"
                    href="#notes_tab"
                    className={profile.isViewNotes ? "active" : ""}
                  >
                    Notes
                  </a>
                </Permissions.CallToAction>
              ) : (
                <a
                  data-toggle="tab"
                  href="#notes_tab"
                  className={profile.isViewNotes ? "active" : ""}
                >
                  <FormattedMessage id={"leads.notes"} />
                </a>
              )}
            </li>
            <li>
              {!planPermission && !memberPermission ? (
                <Permissions.CallToAction
                  withoutIcon={true}
                  isPlan={!planPermission}
                >
                  <a data-toggle="tab" href="#task_tab" id={"showTasks"}>
                    <FormattedMessage id={"leads.tasks"} />
                  </a>
                </Permissions.CallToAction>
              ) : (
                <a data-toggle="tab" href="#task_tab" id={"showTasks"}>
                  <FormattedMessage id={"leads.tasks"} />
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
      {state.leadDetails && (
        <div className="tab-content">
          <Activity
            profile={profile}
            activities={state.leadDetails.activities}
          />
          <Notes
            profile={profile}
            notes={state.leadDetails.notes}
            handleAddNote={handleAddNote}
            handleUpdateNote={handleUpdateNote}
            handleDeleteNote={handleDeleteNote}
            user={user}
            organization={organization}
          />
          <Tasks
            profile={profile}
            onAddTask={onAddTask}
            tasks={state.leadDetails.tasks}
            organization={organization}
            memberPermission={memberPermission}
            planPermission={planPermission}
          />
        </div>
      )}
    </div>
  );
}
