import React from "react";
import { BulkEnrichmentContext } from "./bulkEnrichment.store";
import { PropTypes } from "prop-types";

function BulkEnrichmentProvider({ children, store }) {
  return (
    <BulkEnrichmentContext.Provider value={store}>
      <main id="bulk-enrichment">{children}</main>
    </BulkEnrichmentContext.Provider>
  );
}

BulkEnrichmentProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BulkEnrichmentProvider;
