import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import RingoverIframe from "./RingoverIframe";

export default function RingoverModal({
  toggle,
  handleToggle,
  phones,
  leadId,
}) {
  return (
    <Modal
      show={toggle}
      onHide={() => handleToggle(false)}
      className=" fade  add_task_modal"
    >
      <Modal.Header closeButton className="modal-header border-0">
        <div className="modal-inner-header">
          <h4 className="title" style={{ fontSize: "20px" }}>
            <FormattedMessage id={"leads.callUsingRingover"} />
          </h4>
        </div>
      </Modal.Header>
      <div
        className="modal-body p-0"
        style={{ width: "350px", marginLeft: "60px" }}
      >
        <RingoverIframe phones={phones} leadId={leadId} />
      </div>

      <div className="modal-footer border-0">
        <a href="#" className="btn3 btn-md" onClick={() => handleToggle(false)}>
          <FormattedMessage id={"cancel"} />
        </a>
      </div>
    </Modal>
  );
}
