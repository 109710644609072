import React from "react";
import { PropTypes } from "prop-types";
import { Row } from "react-bootstrap";

function ContentWrapperUi({ children }) {
  return (
    <div className="content-wrapper-ui" style={{ position: "relative" }}>
      <div className="container-fluid">
        <div className="wd-wrapper">
          <Row> {children}</Row>
        </div>
      </div>
    </div>
  );
}

export default ContentWrapperUi;
