import React from "react";
import { CREDIT_COLORS } from "../../../../constants";
import "./CoinUsage.css";
import CustomToolTip from "../../../../components/CustomToolTip/CustomToolTip";
import { FormattedMessage } from "react-intl";
export default function CoinUsage({ creditUsage, type }) {
  return (
    <CustomToolTip element={<FormattedMessage id={"billing." + type} />}>
      <span
        id={"coin-usage"}
        style={{
          color: CREDIT_COLORS[type].color,
          background: CREDIT_COLORS[type].color + "12",
        }}
      >
        <img src={CREDIT_COLORS[type].icon} alt="" />
        <span
          style={{
            color: CREDIT_COLORS[type].color,
          }}
        >
          {creditUsage}
        </span>
      </span>
    </CustomToolTip>
  );
}
