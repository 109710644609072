import React, { useReducer, useMemo, useEffect, useState } from "react";
import { leadsReducer, leadsInitialState, actions } from "./store/leads.store";
import LeadsProvider from "./store/leads.provider";
import { NavLink, useParams } from "react-router-dom";
import "./Leads.css";
import { ContentWrapperUi, Header, SideNav, PreLoader } from "../../components";
import {
  getLeadFeatures,
  getWaitingList,
  leadsFilter,
} from "../../services/api/leads.api";
import LeadsContentWrapper from "./common/LeadsContentWrapper/LeadsContentWrapper";
import { getMembers } from "../../services/api/organization.api";
import { getLabels } from "../../services/api/labels.api";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import { FormattedMessage } from "react-intl";
import LeadsPanel from "./LeadsPanel/LeadsPanel";
import WaitingList from "./waitingList/waitingList";
import queryString from "query-string";
import LeadsDetails from "./LeadsInfo/LeadDetails/LeadDetails";
import { getAccounts } from "../../services/api/integrations.api";
import * as Permissions from "../../components/Permissions/Permissions";
import { usePermissions } from "../../components/Permissions/permissions.hooks";

function Leads(props) {
  const [state, dispatch] = useReducer(leadsReducer, leadsInitialState);
  const [organization, setOrganization] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [account, setAccount] = useState(false);
  const [hidePersonalEmails, setHidePersonalEmails] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const { leadId } = useParams();
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan.permissions,
    props.redux.profile.permissions,
    "Leads"
  );

  useEffect(() => {
    const { waitingList } = queryString.parse(window.location.search);
    if (waitingList == "true") {
      const waitingListElement = document.querySelector("#waitingList_tab");
      const waitingListNavTabElement = document.querySelector("#waitingList");
      const leadsElement = document.querySelector("#leads_panel");
      const leadsTabEelement = document.querySelector("#leads-a");
      leadsElement && leadsElement.classList.remove("show");
      leadsTabEelement && leadsTabEelement.classList.remove("active");
      waitingListElement && waitingListElement.classList.add("show");
      waitingListNavTabElement &&
        waitingListNavTabElement.classList.add("active");
    }
  }, [queryString.parse(window.location.search)]);

  useEffect(() => {
    const org =
      props.redux.organization && props.redux.organization.organization;
    const user = props.redux.profile && props.redux.profile;
    const organizationCredits = props.redux.credits.organizationCredits;
    if (org) {
      setHidePersonalEmails(org.hidePersonalEmail);
    } else {
      setHidePersonalEmails(user.hidePersonalEmail);
    }
  }, [props.redux]);

  useEffect(() => {
    if (!checkPlanPermissions("View")) {
      return setPermissionError("member_permissions");
    }
    if (!checkPlanPermissions("View")) {
      return setPermissionError("plan_permissions");
    }
    getWaitingList().then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: {
            waitingListPreLeads: data.preLeads,
            waitingListPreLeadsPages: data.pages,
            waitingListPreLeadsPage: data.pages.page,
          },
        });
      } else {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: { activity: "not_allowed" },
        });
      }
    });
    getAccounts().then(({ data }) => {
      if (
        data &&
        data.accounts &&
        data.accounts.gmail &&
        data.accounts.gmail.gmailEmail
      )
        setAccount(true);
    });
    if (leadId) {
      getLeadFeatures({ leadId }).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          if (!data.lead) window.location = "/leads";
          const { notes, tasks } = queryString.parse(window.location.search);
          data.lead.isViewDetails = true;
          if (notes === "true") data.lead.isViewNotes = true;
          else if (tasks === "true") data.lead.isViewTasks = true;
          dispatch({ type: actions.SELECT_LEAD, payload: data.lead });
          dispatch({ type: actions.LEAD_DETAILS, payload: data });
        } else {
          window.location = "/leads";
        }
      });
    }
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });

    leadsFilter({ data: state.dataForm }).then((results) => {
      dispatch({ type: actions.LOADING, payload: false });
      dispatch({
        type: actions.LEADS_LOADING,
        payload: false,
      });
      if (results.statusCode == 200) {
        dispatch({
          type: actions.GET_LEADS,
          payload: results.data.leads,
          pages: results.data.pages,
        });
      } else {
        setErrorMessage(results.data.messageTranslated || results.data.message);
      }
    });
    getLabels().then((data) => {
      dispatch({ type: actions.GET_LABELS, payload: data.labels });
    });

    const org = props.redux.organization.organization;
    if (org) {
      setOrganization(org);
      if (org.settings.leadsColumns && org.settings.leadsColumns.length > 0) {
        /*if (columns.some((column) => column.property !== "tags")) {
          columns.push({ name: " Tags", property: "tags", isChecked: false });
        }*/
        let columns = org.settings.leadsColumns.map((orgProperty) => {
          const propertyFound = state.properties.find(
            (prop) => prop.property === orgProperty.property
          );
          if (propertyFound) {
            orgProperty.isFixed = !!propertyFound.isFixed;
          }
          return orgProperty;
        });
        dispatch({
          type: actions.CHANGE_PROPERTIES,
          payload: columns,
        });
      }
      getMembers({ search: "", page: 1 }).then(({ data, statusCode }) => {
        dispatch({
          type: actions.GET_MEMBERS,
          payload: data.members,
        });
      });

      dispatch({ type: actions.GET_CREDITS, payload: props.redux.credits });
    } else {
      setOrganization(false);
    }
    dispatch({
      type: actions.LEADS_LOADING,
      payload: false,
    });
    if (props.redux.organization.tags) {
      dispatch({
        type: actions.GET_ALL_TAGS,
        payload: props.redux.organization.tags,
      });
    }
    return () =>
      dispatch({ type: actions.INIT_STATE, payload: leadsInitialState });
  }, []);
  if (!checkPlanPermissions("View"))
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />
            <LeadsContentWrapper
              navToggle={props.redux.globalReducer.navToggle}
            >
              <Permissions.View
                title={"Hit your next target"}
                description={
                  " All of Kaspr's features help make your prospecting easier. Upgrade for more automation and insights."
                }
                img={"leads-bg.png"}
              />
            </LeadsContentWrapper>
          </ContentWrapperUi>
        </div>
      </LeadsProvider>
    );
  if (!checkMemberPermissions("View"))
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />
            <LeadsContentWrapper
              navToggle={props.redux.globalReducer.navToggle}
            >
              <Permissions.View
                title={"Hit your next target"}
                description={<FormattedMessage id={"permissions.noAccess"} />}
                img={"leads-bg.png"}
              />
            </LeadsContentWrapper>
          </ContentWrapperUi>
        </div>
      </LeadsProvider>
    );
  if (organization == null) {
    return <PreLoader />;
  }
  if (leadId && !state.selectedProfile) {
    return <PreLoader />;
  }
  if (!organization) {
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />
            <LeadsContentWrapper
              navToggle={props.redux.globalReducer.navToggle}
            >
              <DataNotFound
                image={"/assets/images/img_empty_no_leads.png"}
                elements={
                  <div
                    style={{ marginTop: "0" }}
                    className="join-organization-inner join-organization-page"
                  >
                    <h4>
                      <FormattedMessage
                        id={"organization.youHavetoCreateAnOrganization"}
                      />
                    </h4>
                    <p className="desc">
                      <FormattedMessage
                        id={"organization.youHavetoCreateAnOrganization.text"}
                      />
                    </p>
                    <button className={"btn1"}>
                      <NavLink
                        to={"/workspace"}
                        style={{ color: "white" }}
                        data-tracking-id={"leads.createOrganization"}
                      >
                        <FormattedMessage
                          id={"organization.createOrganization"}
                        />
                      </NavLink>
                    </button>
                  </div>
                }
              />
            </LeadsContentWrapper>
          </ContentWrapperUi>
        </div>
      </LeadsProvider>
    );
  }

  return (
    <LeadsProvider store={store}>
      <div className="main-wraper">
        <Header
          currentPage={<FormattedMessage id={"sidenav.leads"} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          isLeadPage={
            state.selectedProfile && state.selectedProfile.isViewDetails
          }
          backToLeads={() => {
            dispatch({ type: actions.SELECT_LEAD, payload: null });
          }}
          organization={props.redux.organization}
        />
        <ContentWrapperUi>
          <SideNav navToggle={props.redux.globalReducer.navToggle} />
          <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
            {(state.selectedProfile && state.selectedProfile.isViewDetails) ||
            leadId ? (
              <LeadsDetails
                {...props}
                leadId={leadId}
                profile={state.selectedProfile}
                user={props.redux.profile}
                organization={props.redux.organization}
                account={account}
                checkPlanPermissions={checkPlanPermissions}
                checkMemberPermissions={checkMemberPermissions}
                isVersion2={
                  props.redux.credits && props.redux.credits.isVersion(2)
                }
              />
            ) : (
              <>
                <NavTab
                  organization={props.redux.organization}
                  profile={props.redux.profile}
                  urlSegment={props.urlSegment}
                />
                <LeadsPanel
                  {...props}
                  setOrganization={setOrganization}
                  errorMessage={errorMessage}
                  profile={props.redux.profile}
                  checkPlanPermissions={checkPlanPermissions}
                  checkMemberPermissions={checkMemberPermissions}
                  isVersion2={
                    props.redux.credits && props.redux.credits.isVersion(2)
                  }
                  remainingCredits={
                    props.redux.credits && props.redux.credits.remaining
                  }
                  organization={props.redux.organization}
                  hidePersonalEmails={hidePersonalEmails}
                />
                <WaitingList {...props} />
              </>
            )}
          </LeadsContentWrapper>
        </ContentWrapperUi>
      </div>
    </LeadsProvider>
  );
}
function NavTab({ urlSegment }) {
  useEffect(() => {
    if (urlSegment) {
      let tab = document.querySelector(`#waitingList`);
      if (tab) tab.click();
    }
  }, []);
  const onTabCLick = (tab) => {
    window.history.pushState(null, null, "/leads/" + tab);
  };
  return (
    <div className="workflow-detail-header" style={{ marginBottom: 35 }}>
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li onClick={() => onTabCLick("")}>
            <a
              id={"leads-a"}
              data-toggle="tab"
              href="#leads_panel"
              className="active"
              data-tracking-id={"leads.leadsTab"}
            >
              <FormattedMessage id={"sidenav.leads"} />
            </a>
          </li>
          <li onClick={() => onTabCLick("waitinglist")}>
            <a
              id={"waitingList"}
              data-toggle="tab"
              href="#waitingList_tab"
              data-tracking-id={"leads.waitingListTab"}
            >
              <FormattedMessage id={"organization.waitingList"} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Leads;
