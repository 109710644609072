import React from "react";
import "./HomeSectionTable.css";
import { FormattedDate, FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
export default function HomeSectionTable({ tHeadData, tBodyData, type }) {
  if (!(tHeadData && tBodyData)) return null;
  let data = tBodyData.slice(0, 4);
  data = [...data];
  return (
    <div className={"home-section-table"}>
      <table>
        <thead>
          <tr>
            {tHeadData.map((th, i) => {
              if (th == "_id" && type == "workflows") return null;
              if (th == "leadId" && type == "tasks") {
                return (
                  <th key={i}>
                    {" "}
                    <div className={"capital"}>
                      {" "}
                      <FormattedMessage
                        id={"settings.integration.salesforce.lead"}
                      />{" "}
                    </div>
                  </th>
                );
              }
              return (
                <th key={i}>
                  {" "}
                  <div className={"capital"}>
                    <FormattedMessage id={"home.list." + th} />{" "}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((tr, i) => (
            <tr key={i}>
              {tHeadData.map((th, i2) => {
                if (th == "name" && type == "workflows")
                  return (
                    <td key={i2}>
                      <NavLink to={"/workflow/" + tr._id}>
                        {typeof tr[th] == "string" && tr[th].length > 15
                          ? tr[th].slice(0, 15) + "..."
                          : tr[th]}{" "}
                      </NavLink>
                    </td>
                  );
                if (th == "_id" && type == "workflows") return null;
                if (th == "active")
                  return (
                    <td key={i2}>
                      {tr[th] ? <span>active</span> : <span>disabled</span>}
                    </td>
                  );
                if (th == "assignee" && type == "tasks")
                  return (
                    <td key={i2}>
                      {tr[th].email && tr[th].email.length > 15
                        ? tr[th].email.slice(0, 15) + "..."
                        : tr[th].email}{" "}
                    </td>
                  );
                if (th == "due" && type == "tasks")
                  return (
                    <td key={i2}>
                      <FormattedDate
                        value={tr[th]}
                        year={"numeric"}
                        month={"short"}
                        day={"numeric"}
                      />
                    </td>
                  );
                if (th == "leadId" && type == "tasks") {
                  return (
                    <td key={i2}>
                      <div style={{ marginBottom: -12 }}>
                        <a
                          href={"https://linkedin.com/in/" + tr[th].linkedin}
                          target={"_blank"}
                          style={{ color: "#3b53d1" }}
                          rel="noopener noreferrer nofollow"
                        >
                          {tr[th].name}{" "}
                          <img src="/assets/images/link_icon.svg" alt="" />
                        </a>{" "}
                        <div style={{ marginTop: -6, color: "#666" }}>
                          {" "}
                          <small>{tr[th].email}</small>
                        </div>
                      </div>
                    </td>
                  );
                }
                return (
                  <td key={i2}>
                    {typeof tr[th] == "string" && tr[th].length > 15
                      ? tr[th].slice(0, 15) + "..."
                      : tr[th]}{" "}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
