import React, { useState } from "react";
import "./AddNoteSection.css";
import { FormattedMessage } from "react-intl";
export default function NoteAddedMessage({
  goToNotes,
  noteAdded,
  onCancel,
  createNewNote,
}) {
  return (
    <div className={"add-note-section-container"}>
      <div className={"add-note-title-container"}>
        <h2>
          <FormattedMessage id={"leads.noteAdded"} />
        </h2>
        <p onClick={goToNotes}>
          <FormattedMessage id={"leads.viewNotes"} />{" "}
          <img src="/assets/images/ic-right-arrow.svg" alt="" />{" "}
        </p>
      </div>
      <div className={"note-error-message"}>
        <i className="fas fa-check-circle" />{" "}
        <FormattedMessage id={"leads.noteSuccessfullyAdded"} />
      </div>
      <div className={"add-note-buttons"}>
        <button
          className={"btn btn1"}
          style={{ width: "max-content" }}
          onClick={createNewNote}
        >
          <FormattedMessage id={"leads.createNewNote"} />
        </button>
        <button className={"btn btn2"} onClick={onCancel}>
          <FormattedMessage id={"cancel"} />
        </button>
      </div>
    </div>
  );
}
