import React from 'react';
import {FormattedMessage} from "react-intl/lib";
import "./FeedbackModalList.css"
function FeedbackModalList({option, optionSelected, setOptionSelected}) {

    const handleOnChange = () => {
        setOptionSelected(option)
    }

    const isSelected = optionSelected.reason === option.reason ? true : false

    return (
        <>
            <label id="feedback-list-container" className={isSelected ? "is-selected" : ""} >
                <input
                    type="checkbox"
                    onChange={handleOnChange}
                    checked={isSelected}
                />
                <span className="checkbox-icon" />
                <span  className="checkbox-text reason">
                    <FormattedMessage id={option.id} />
                </span>
            </label>
        </>
    );
}

export default FeedbackModalList;
