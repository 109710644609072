import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Animation, Language } from "../";
import { NavLink } from "react-router-dom";
import "./Header.css";
import cookie from "react-cookies";
import { BASE_URL, EXTENSION_ID } from "../../constants";
import {
  clickNotification,
  getNotifications,
  seeNotifications,
} from "../../services/api/notifications.api";
import { formatNotificationsDate } from "../../services/utils/formatDate";
import { FormattedMessage, useIntl } from "react-intl";
import {
  changePhone,
  deleteAccount,
  getKasprInvitations,
  isPhoneTokenSent,
  phoneVerificationRequest,
  updateUser,
} from "../../services/api/auth.api";
import InviteFriend from "../../container/Auth/Onboarding/InviteFriend/InviteFriend";
import {
  ConfirmPhonePopUp,
  PhoneValidation,
} from "../../container/Auth/Onboarding/PhoneValidation/PhoneValidation";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Upgrade from "../Permissions/Upgrade/Upgrade";
import { calculateRemaining } from "../../services/utils/tools";
import UpgradeButton from "./common/UpgradeButton/UpgradeButton";

function Header({
  navToggle,
  currentPage,
  isOnBoardingPage,
  profile,
  isWkCreation,
  backToWorkflows,
  remaining,
  credits,
  backToLeads,
  isLeadPage,
  organization,
}) {
  const [notifications, setNotifications] = useState([]);
  const [earnCredits, setEarnCredits] = useState({
    invitations: false,
    phoneValidation: false,
    save25: false,
  });
  const [selectToggle, setSelectToggle] = useState(null);
  const isAdmin = () => {
    return (
      organization &&
      ((organization.member && organization.member.type === "admin") ||
        (organization.member && organization.member.type === "owner"))
    );
  };
  const handleSelectLanguage = async (e) => {
    const profileFields = Object.assign({}, profile);
    profileFields.language = e;
    await updateUser(profileFields);
    window.location = "/";
  };
  const handleExpand = () => {
    if (window.location.href.indexOf("onboarding") > -1) return;
    const sideNavElement = document.querySelector(".side-nav-container");
    const logoElement = document.querySelector(".site-logo");
    const expandElement = document.querySelector(".content-wrap");

    if (sideNavElement) sideNavElement.classList.toggle("small-navbar");
    if (logoElement) logoElement.classList.toggle("s-logo");
    if (expandElement) expandElement.classList.toggle("expand");
  };

  useEffect(() => {
    getNotifications().then(({ data }) => {
      setNotifications(data.notifications);
    });
    getKasprInvitations()
      .then(({ data }) => {
        const phoneValidationStep = profile.steps.find(
          (step) => step.id === "phoneValidation"
        );
        setEarnCredits({
          invitations:
            data.acceptedInvitations && data.acceptedInvitations.length < 5,
          phoneValidation: !phoneValidationStep.done,
          save25:
            credits &&
            credits.organizationCredits &&
            credits.organizationCredits.plan.numberOfMonths !== 12,
        });
      })
      .catch(console.log);
  }, []);

  return (
    <HeaderWrapper isOnBoardingPage={isOnBoardingPage}>
      <div
        className={"site-logo  " + (navToggle ? "s-logo" : "")}
        onClick={handleExpand}
      >
        <a data-tracking-id={"header.kaspr-logo"}>
          <img src="/assets/images/logo_on_white.png" alt="logo" />
        </a>
      </div>
      <div className="page-title">
        {isWkCreation ? (
          <div className="back_btn" onClick={backToWorkflows}>
            <NavLink
              to={"/workflow"}
              data-tracking-id={"header.back-to-workflow"}
            >
              <span className="icon-ui">
                <img
                  src="/assets/images/ic_arrow_left.png"
                  alt=""
                  style={{ width: 10 }}
                />
              </span>
              <FormattedMessage id={"workflow.backToWorflows"} />
            </NavLink>
          </div>
        ) : isLeadPage ? (
          <div className="back_btn" onClick={backToLeads}>
            <NavLink to={"/leads"} data-tracking-id={"header.back-to-leads"}>
              <span className="icon-ui">
                <img
                  src="/assets/images/ic_arrow_left.png"
                  alt=""
                  style={{ width: 10 }}
                />
              </span>
              <FormattedMessage id={"leads.backToLeads"} />
            </NavLink>
          </div>
        ) : (
          <p>{currentPage}</p>
        )}
      </div>
      <div className="right-block">
        {!isOnBoardingPage &&
          isAdmin() &&
          credits &&
          credits.organizationCredits && (
            <UpgradeButton
              hasSubscription={!credits.organizationCredits.subscription}
              organizationCredits={credits.organizationCredits}
              isBillingPage={window.location.href.indexOf("billing") > -1}
              remaining={remaining}
              organization={organization.organization}
            />
          )}

        {remaining && (
          <CreditButton
            credits={credits}
            profile={profile}
            organization={organization}
            organizationCredits={credits.organizationCredits}
            remaining={calculateRemaining(
              remaining,
              credits,
              credits &&
                credits.organizationCredits &&
                credits.organizationCredits.plan.planId !== "0" &&
                (credits.organizationCredits.subscription
                  ? "organizationRemaining"
                  : "personalCredits")
            )}
          />
        )}
        {!isOnBoardingPage && <NotificationUi notifications={notifications} />}
        <ProfileDropDown
          profile={profile}
          isOnBoardingPage={isOnBoardingPage}
          organizationCredits={credits.organizationCredits}
          isAdmin={isAdmin}
        />
      </div>
      {isOnBoardingPage && (
        <div className="button-ui language-dropdown">
          <Language
            setSelectToggle={setSelectToggle}
            selectToggle={selectToggle}
            profile={profile}
            handleSelectLanguage={handleSelectLanguage}
            isOnBoardingPage={isOnBoardingPage}
          />
        </div>
      )}
    </HeaderWrapper>
  );
}

function CreditButton({
  organizationCredits,
  organization,
  remaining,
  credits,
}) {
  const [creditsToggle, setCreditsToggle] = useState(false);
  if (
    organizationCredits &&
    organizationCredits.plan &&
    organizationCredits.plan.version == 1
  ) {
    return (
      <div className="credit-point" style={{ position: "relative" }}>
        <a
          onClick={() => setCreditsToggle(!creditsToggle)}
          className="cp-btn"
          data-tracking-id={"header.remaining-credits"}
          style={{ minWidth: "max-content", cursor: "default" }}
        >
          <img
            src="/assets/images/legacy-credits.svg"
            alt=""
            style={{ width: 35, verticalAlign: "bottom" }}
          />
          {/* <FormattedMessage id={"totalCredits"} />*/}{" "}
          {remaining.legacyCredits}
        </a>
      </div>
    );
  }

  return (
    <div id="total-credits" className="credit-point">
      <a
        onClick={() => setCreditsToggle(!creditsToggle)}
        className="cp-btn"
        data-tracking-id={"header.remaining-credits"}
        style={{ minWidth: "max-content", padding: "0 20px" }}
      >
        <span className="icon credit-icon">
          <img src="/assets/images/ic_credits.png" alt="" />
        </span>
        <FormattedMessage id={"totalCredits"} />
      </a>

      <Animation.SlideDown
        className={"profile-dropdown"}
        toggle={creditsToggle}
        setToggle={setCreditsToggle}
        withoutScroll={true}
      >
        <div className={"credits-drop-down"}>
          <section>
            <div className={"title"}>
              <FormattedMessage id={"totalCredits"} />
            </div>
            <div className={"credit-earned"} style={{ color: "#4D58F3" }}>
              <img src="/assets/images/work-email-credits.svg" alt="" />{" "}
              <FormattedMessage
                id={"b2bCredits"}
                values={{
                  value:
                    organizationCredits && organizationCredits.subscription ? (
                      <FormattedMessage id={"unlimited"} />
                    ) : /*<i className="fas fa-infinity"></i>*/
                    remaining.isUnlimited ? (
                      <FormattedMessage id={"unlimited"} />
                    ) : (
                      remaining.workEmailCredits
                    ),
                }}
              />
            </div>
            <div className={"credit-earned"} style={{ color: "#0EB763" }}>
              <img src="/assets/images/phone-credits.svg" alt="" />{" "}
              <FormattedMessage
                id={"phoneCredits"}
                values={{
                  value: remaining.phoneCredits,
                }}
              />
            </div>

            <div className={"credit-earned"} style={{ color: "#FF206E" }}>
              <img src="/assets/images/direct-email-credits.svg" alt="" />
              <FormattedMessage
                id={"personalEmailCredits"}
                values={{
                  value: remaining.personalEmailCredits,
                }}
              />
            </div>
            <div className={"credit-earned"} style={{ color: "#B149FF" }}>
              <img src="/assets/images/export-credits.svg" alt="" />
              <FormattedMessage
                id={"exportCredits"}
                values={{
                  value: remaining.exportCredits,
                }}
              />
            </div>
          </section>

          {window.location.href.indexOf("billing") === -1 && (
            <div style={{ textAlign: "center" }}>
              {organizationCredits &&
              organizationCredits.plan.planId === "0" ? (
                <NavLink to={"/billing/plans"} className={"btn btn1 cta-btn"}>
                  <FormattedMessage id={"organization.upgradePan"} />
                </NavLink>
              ) : organization &&
                organization.member &&
                organization.member.type !== "member" ? (
                <NavLink
                  to={"/billing/plans/credits/phone"}
                  className={"btn btn1 cta-btn"}
                >
                  <FormattedMessage id={"billing.moreCredits"} />
                </NavLink>
              ) : null}
            </div>
          )}
        </div>
      </Animation.SlideDown>
    </div>
  );
}
function NotificationUi({ notifications }) {
  const [toggle, setToggle] = useState(false);
  const [notificationsElements, setNotificationsElement] = useState([]);
  useEffect(() => {
    let regex = /{{(.*?)}}/g;
    if (!notifications) return setNotificationsElement([]);
    let notifs;
    try {
      notifs = notifications.map((notif) => {
        const variables = notif.template.message.match(regex);
        if (variables && variables.length > 0) {
          variables.forEach((variable, i) => {
            let currentVar = variables[i];
            currentVar = currentVar.replace("{{", "");
            currentVar = currentVar.replace("}}", "");
            let dataVar = notif.data[currentVar];

            if (dataVar) {
              let htmTagStart = dataVar.link
                ? "<a class='username' href=" + dataVar.link + " >"
                : "<strong>";
              let htmlEndTag = dataVar.link ? "</a>" : "</strong>";
              notif.template.message = notif.template.message.replace(
                variables[i],
                htmTagStart +
                  (dataVar.value &&
                    dataVar.value.length &&
                    dataVar.value[0].toUpperCase() + dataVar.value.slice(1)) +
                  htmlEndTag
              );
            }
          });
        }
        return notif;
      });
    } catch (err) {
      notifs = [];
    }

    setNotificationsElement(notifs);
  }, [notifications]);
  const getIcon = (type) => {
    switch (type) {
      case "KasprInvitation":
        return "ic-message.png";
      case "NewMemberInvitation":
        return "ic-message.png";
      case "Invitation":
        return "ic-message.png";
      case "NewMemberJoinReq":
        return "ic-message.png";
      case "GmailReply":
        return "ic-message.png";
      case "JoinReq":
        return "ic-message.png";
      case "SuccessfulPayment":
        return "ic-user-check.png";
      case "Admin":
        return "ic-gift.png";
      case "preLeadToExplore":
        return "Marketing.svg";
      default:
        return "ic-box.png";
    }
  };
  const handleNotificationClick = async (notif, i) => {
    if (!notif.id) return;
    let { data, statusCode } = await clickNotification(notif.id);
    if (statusCode === 200) {
      let notifs = notificationsElements;
      notifs[i].isClicked = true;
      setNotificationsElement(notifs);
    }
  };
  const handleCollapseToggle = async (collapseToggle) => {
    setToggle(collapseToggle);
    if (collapseToggle) await seeNotifications();
    let notifs = notificationsElements.map((notif) => {
      notif.isSeen = true;
      return notif;
    });
    setNotificationsElement(notifs);
  };
  const numberOfNotifs = notificationsElements.filter((notif) => !notif.isSeen)
    .length;
  return (
    <div className="notification-ui">
      <a
        className="ntfc-btn"
        onClick={() => handleCollapseToggle(!toggle)}
        data-tracking-id={"header.notifications"}
      >
        <span
          className="notification-icon"
          data-tracking-id={"header.notifications"}
        >
          <img src="/assets/images/ic_notification.png" alt="" />
        </span>
        {numberOfNotifs > 0 && (
          <span className="counter-icon">{numberOfNotifs}</span>
        )}
      </a>
      <Animation.SlideDown
        className={"profile-dropdown"}
        toggle={toggle}
        setToggle={setToggle}
        style={{ left: 0, width: "320px" }}
        heightSize={300}
      >
        {notificationsElements.length > 0 ? (
          <div style={{ padding: 10 }}>
            <ul className="notification-list">
              <h1>
                <FormattedMessage id={"home.notifications"} />
              </h1>

              {notificationsElements.map((notif, i) => (
                <li key={i}>
                  <div
                    className={"notification-msg-container"}
                    onClick={() => handleNotificationClick(notif, i)}
                  >
                    <span className={"image-container "}>
                      <img
                        src={"/assets/images/" + getIcon(notif.template.name)}
                        alt=""
                      />
                    </span>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: notif.template.message,
                      }}
                    />
                  </div>
                  <small>{formatNotificationsDate(notif.dateAdded)} </small>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            <ul className="notification-list">
              <h1>
                <FormattedMessage id={"noNotificationToShow"} />
              </h1>
            </ul>
          </div>
        )}
      </Animation.SlideDown>
    </div>
  );
}

function ProfileDropDown({
  profile,
  isOnBoardingPage,
  organizationCredits,
  isAdmin,
}) {
  const [toggle, setToggle] = useState(false);
  let [error, setError] = useState(null);
  let [text, setText] = useState(null);
  const intl = useIntl();
  let [modalToggle, setModalToggle] = useState(false);

  const handleRemoveCookie = () => {
    cookie.remove("access_token", { path: "/", domain: BASE_URL });
    if (
      window.chrome &&
      window.chrome.runtime &&
      window.chrome.runtime.sendMessage
    ) {
      window.chrome.runtime.sendMessage(EXTENSION_ID, {
        action: "REFRESH_WHEN_CONNECTED",
        input: { isConnected: false },
      });
    }
    window.location = "/";
  };
  const handleModal = () => {
    setModalToggle(!modalToggle);
    setError(null);
  };
  const handleDeleteAccount = async () => {
    if (
      text !== intl.formatMessage({ id: "settings.profile.deleteMyAccount" })
    ) {
      setError("Please enter the valid phrase");
      return;
    }
    let { data, statusCode } = await deleteAccount();
    if (statusCode === 200) {
      cookie.remove("access_token", { path: "/", domain: BASE_URL });
      window.location = "/";
    } else setError(data.messageTranslated || data.message);
  };
  return (
    <div className="profile-ui">
      <button className="profile-btn" onClick={() => setToggle(true)}>
        <div className="user-icon" data-tracking-id={"header.profileDropDown"}>
          <span className="icon-proifle-bg">
            <img
              src={profile.image || "/assets/images/ic_profile.png"}
              alt=""
            />
          </span>
        </div>
        <div data-tracking-id={"header.profileDropDown"}>
          <p className="user-name capital">
            {profile && profile.firstName + " "}
          </p>
          <p className="user-name capital">{profile && profile.lastName}</p>
        </div>
      </button>
      <Animation.Appear
        className={"profile-dropdown"}
        toggle={toggle}
        setToggle={setToggle}
        style={{ right: -20, left: "none", width: "max-content" }}
        listStyle={{ overflow: "none", maxHeight: "none" }}
      >
        <div
          className={"profile-dropdown-container"}
          style={isOnBoardingPage ? { padding: 0 } : {}}
        >
          <>
            <section className={"section-header"}>
              <img src="/assets/images/profile-placeholder.png" alt="" />
              <h2>{profile && profile.firstName + " " + profile.lastName}</h2>
              <p>{profile.title} </p>
              <div style={{ margin: "10px 0" }}>
                {!isOnBoardingPage && (
                  <a href={"/settings"} className={"btn btn1"}>
                    <FormattedMessage id={"workflow.viewProfile"} />{" "}
                  </a>
                )}
              </div>
            </section>
            <section className={"section-links"}>
              {!isOnBoardingPage && (
                <>
                  {/*  {window.location.href.indexOf("settings") === -1 && (
                    <NavLink to="/settings">
                      <img src="/assets/images/user.svg" alt="" />{" "}
                      <FormattedMessage id={"account&preferences"} />
                    </NavLink>
                  )}*/}
                  <div>
                    <NavLink to={"/billing"}>
                      <img src="/assets/images/plans.svg" alt="" />{" "}
                      <FormattedMessage id={"plans"} />
                    </NavLink>
                    <div className={"item plans-container"}>
                      <span className={"currentPlan"}>
                        <FormattedMessage id={"billing.yourCurrentPlan"} />{" "}
                      </span>
                      <div>
                        <strong>
                          {/*<FormattedMessage id={"organization.filters.recurrentCredits"} />*/}
                          {organizationCredits && organizationCredits.plan ? (
                            organizationCredits.plan.name
                          ) : (
                            <FormattedMessage
                              id={"organization.filters.recurrentCredits"}
                            />
                          )}
                        </strong>{" "}
                        {isAdmin() &&
                          organizationCredits &&
                          !organizationCredits.subscription && (
                            <NavLink to={"/billing"}>
                              <FormattedMessage id={"upgrade"} />{" "}
                            </NavLink>
                          )}{" "}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <a
                href={
                  "https://chrome.google.com/webstore/detail/kaspr-get-anyones-phone-n/kkfgenjfpmoegefcckjklfjieepogfhg"
                }
                target={"_blank"}
              >
                <img src="/assets/images/chrome.svg" alt="" />
                <FormattedMessage id={"installChrome"} />
              </a>
              <a href={"https://help.kaspr.io/"} target={"_blank"}>
                <img src="/assets/images/info.svg" alt="" />{" "}
                <FormattedMessage id={"helpCenter"} />
              </a>
              <a href={"https://www.kaspr.io/privacy-policy"} target={"_blank"}>
                <img src="/assets/images/privacy.svg" alt="" />
                <FormattedMessage id={"privacyCenter"} />
              </a>
            </section>
          </>
          <section className={"section-links last"}>
            <a
              href="#"
              data-tracking-id={"header.profileDropDown-signout"}
              onClick={handleRemoveCookie}
            >
              <FormattedMessage id={"signout"} />
            </a>
          </section>
        </div>
      </Animation.Appear>
      {modalToggle && (
        <Modal
          show={modalToggle}
          onHide={handleModal}
          className="fade  delete-organization-wrapper"
          id="delete-organization"
        >
          <Modal.Header closeButton className="border-0">
            <button type="button" className="close" data-dismiss="modal">
              <img src="/assets/images/ic-cross.png" alt="" />
            </button>
            <div className="modal-inner-header">
              <h4 className="title">
                {" "}
                <FormattedMessage id={"settings.profile.deleteAccount"} />
              </h4>
            </div>
          </Modal.Header>
          <div className="modal-body p-24">
            <div className="delete-inner-ui">
              <div className="alert-wrap">
                <span className="ic-alert">
                  <img src="/assets/images/ic-alert.svg" alt="" />
                </span>
                <label>
                  <FormattedMessage
                    id={"settings.profile.yourAboutToDeleteYourAccount"}
                  />
                </label>
                <p>
                  <FormattedMessage
                    id={"settings.profile.onceAnAccountIsDeleted"}
                    values={{
                      span: (chunk) => <span> {chunk} </span>,
                      br: <br />,
                    }}
                  />
                </p>
              </div>

              <p>
                <FormattedMessage
                  id={"settings.profile.thisActionCannotBeUndone"}
                  values={{
                    br: <br />,
                    span: (chunk) => <span>{chunk} </span>,
                  }}
                />
              </p>
              <div className="form-fields">
                <a href="#" className="delete-tag">
                  “<FormattedMessage id={"settings.profile.deleteMyAccount"} />”
                </a>
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    placeholder={intl.formatMessage({
                      id: "settings.profile.typeHere",
                    })}
                    onChange={(e) => setText(e.target.value)}
                    style={
                      error
                        ? { border: "1px solid red", color: "#981830" }
                        : { color: "#981830" }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {error && (
            <div className="btn-grp text-center  error  kaspr-form-text-error">
              {error + " "}
            </div>
          )}
          <div className="modal-footer border-0">
            <a
              href="#"
              className="btn6"
              onClick={handleModal}
              data-tracking-id={"header.cancel-delete-account"}
            >
              <FormattedMessage id={"settings.profile.cancelKeepIt"} />
            </a>
            <a
              href="#"
              className="btn5 ml-20"
              onClick={handleDeleteAccount}
              data-tracking-id={"header.confirm-delete-account"}
            >
              <FormattedMessage id={"settings.profile.yesDeleteMyAccount"} />
            </a>
          </div>
        </Modal>
      )}
    </div>
  );
}

function HeaderWrapper({ children, isOnBoardingPage }) {
  return (
    <header
      className={"header-container"}
      style={isOnBoardingPage ? { background: "none" } : {}}
    >
      <div className="container-fluid">
        <div className="top-header">
          <div className="inner-header-block">{children}</div>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    credits: state.credits,
  };
};
export default connect(mapStateToProps)(Header);
