import React, { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger } from "react-bootstrap";
import {
  createEmailTemplate,
  getEmailTemplates,
  sendEmail,
  deleteEmailTemplate,
  getEmailSignature,
  updateEmailTemplate,
} from "../../../../services/api/integrations.api";
import { getMembers } from "../../../../services/api/organization.api";
import ReactQuill, { Quill } from "react-quill";
import "quill/dist/quill.snow.css";
import { Animation, Pagination, Tooltip } from "../../../../components";
import EmailField from "../EmailField/EmailField";
import { formatNotificationsDate } from "../../../../services/utils/formatDate";
import { FormattedMessage, injectIntl } from "react-intl";

function SelectEmailTemplate({
  toggle,
  setToggle,
  handleTemplateChange,
  intl,
}) {
  const [allTemplates, setAllTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState("");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [members, setMembers] = useState("");
  const [error, setError] = useState("");
  const [selectToggle, setSelectToggle] = useState(false);
  const [updateToggle, setUpdateToggle] = useState(false);
  const [search, setSearch] = useState("");
  const [create, setCreate] = useState(false);
  const [page, setPage] = useState(1);
  const [confirmDeleteTemplate, setConfirmDeleteTemplate] = useState(-1);
  const [signature, setSignature] = useState();
  const [index, setIndex] = useState(0);
  const [_id, set_id] = useState(0);
  const [quillRef, setQuillRef] = useState(0);

  const sortByDate = (a, b) => {
    let dateA = a.createdAt,
      dateB = b.createdAt;
    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  };

  const buttonLeftStyle = {
    width: "50%",
    borderRadius: 0,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    border: "none",
    color: "white",
  };

  const buttonRightStyle = {
    width: "50%",
    borderRadius: 0,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    border: "none",
    color: "white",
  };

  const initTemplates = ({ newTemplates }) => {
    setTemplate("");
    setSubject("");
    setTitle("");
    setSearch("");
    setPage(1);
    setTemplates(newTemplates);
    setAllTemplates(newTemplates);
  };
  const handleCreateOrUpdateTemplate = async (e) => {
    if (e) e.stopPropagation();
    const { data, statusCode } = updateToggle
      ? await updateEmailTemplate({ _id, template, subject, title })
      : await createEmailTemplate({ template, subject, title });
    if (statusCode === 200) {
      let newTemplates = Object.assign([], allTemplates);
      if (updateToggle) {
        for (let i in newTemplates)
          if (newTemplates[i]._id === _id) {
            newTemplates[i] = {
              ...newTemplates[i],
              template,
              subject,
              title,
            };
            break;
          }
      } else {
        newTemplates.push(data.template);
      }
      initTemplates({ newTemplates });
      let sign = signature ? " <br/><br/>------------<br/>" + signature : "";
      handleTemplateChange(data.template.template + sign, "template");
      handleTemplateChange(data.template.subject, "subject");
      initTemplates({ newTemplates });
      setError("");
    } else {
      setError(data.messageTranslated || data.message);
    }
    setUpdateToggle(false);
    setCreate(false);
  };

  const handleConfirmDeleteTemplate = async (e, index) => {
    if (e) e.stopPropagation();
    setConfirmDeleteTemplate(index);
  };

  const handleEditTemplate = async (e, index) => {
    if (e) e.stopPropagation();
    setUpdateToggle(true);
    setCreate(true);
    setTitle(templates[index + (page - 1) * 5].title);
    setSubject(templates[index + (page - 1) * 5].subject);
    setTemplate(templates[index + (page - 1) * 5].template);
    set_id(templates[index + (page - 1) * 5]._id);
  };

  const handleDeleteTemplate = async (e, index) => {
    if (e) e.stopPropagation();
    let templateId = templates[index + (page - 1) * 5]._id;
    deleteEmailTemplate({ template: templates[index + (page - 1) * 5] }).then(
      ({ statusCode }) => {
        if (statusCode === 200) {
          let newTemplates = Object.assign([], allTemplates);
          newTemplates = newTemplates.filter((temp) => {
            return temp._id !== templateId;
          });
          setConfirmDeleteTemplate(-1);
          initTemplates({ newTemplates });
        }
      }
    );
  };

  const selectTemplate = async (temp) => {
    let sign = signature ? "<br/><br/>------------<br/>" + signature : "";
    handleTemplateChange(temp.template + sign, "template");
    handleTemplateChange(temp.subject, "subject");
    setTemplates(allTemplates);
    setToggle();
  };

  const MemberItem = ({ member, onMemberSelect, banSelect }) => {
    return (
      <li
        onClick={() => {
          if (!banSelect) onMemberSelect(member);
        }}
      >
        <a
          href="#"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textTransform: "uppercase",
              height: "38px",
              width: "38px",
              display: "inline-block",
              borderRadius: "50%",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "-0.32px",
              lineHeight: "38px",
              textAlign: "center",
              marginRight: "10px",
              background: "#FFDEDE",
              color: "#B54848",
            }}
          >
            {(member.firstName ? member.firstName[0] : "") +
              (member.lastName ? member.lastName[0] : "")}
          </span>

          <span className="name_ui">
            {" "}
            {member.firstName + " " + member.lastName}
          </span>
        </a>
      </li>
    );
  };

  const handleMemberFilter = ({ member }) => {
    setPage(1);
    if (member && member.email) {
      let data = Object.assign(allTemplates);
      data = data.filter((template) => template.user === member.email);
      setTemplates(data.sort(sortByDate));
      setTemplate(data && data.length > 0 ? data[0].template : "");
      setSelectToggle(false);
    } else setTemplates(allTemplates);
  };

  const onSelectVar = (variable) => {
    if (quillRef && typeof quillRef.getEditor === "function") {
      let editor = quillRef.getEditor();
      editor.insertText(index, "{{" + variable + "}}");
    }
  };

  const handleIndexChange = (newIndex, newQuillRef) => {
    setIndex(newIndex);
    setQuillRef(newQuillRef);
  };

  const filterTemplates = async (value) => {
    setPage(1);
    setSearch(value);
    setTemplates(
      allTemplates
        .filter((template) => {
          return (
            template.title.indexOf(value) !== -1 ||
            template.user.indexOf(value) !== -1
          );
        })
        .sort(sortByDate)
    );
  };

  useEffect(() => {
    getEmailTemplates({}).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        setAllTemplates(data.templates);
        setTemplates(data.templates.sort(sortByDate));
      }
    });
    getMembers().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        setMembers(data.members);
      }
    });
    getEmailSignature().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        setSignature(data && data.signature && data.signature.signature);
      }
    });
    return () => {
      setAllTemplates([]);
      setTemplates([]);
      setMembers("");
      setSignature();
    };
  }, []);

  return (
    <Modal
      show={toggle}
      onHide={(e) => {
        setToggle(e);
        setTemplates(allTemplates);
        setUpdateToggle(false);
        setCreate(false);
      }}
    >
      <Modal.Header
        closeButton
        className="modal-header border-0"
        style={{ paddingBottom: "10px" }}
      >
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={"leads.gmail.emailTemplates"} />
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body p-0">
        <div className="inner-content">
          <div
            style={{
              position: "relative",
              textAlign: "center",
              marginBottom: "35px",
              width: "50%",
              left: "25%",
            }}
          >
            <button
              style={
                create
                  ? {
                      ...buttonLeftStyle,
                      backgroundColor: "#bbbbbb",
                    }
                  : {
                      backgroundColor: "#3B53D1",
                      ...buttonLeftStyle,
                    }
              }
              className={"btn xs"}
              onClick={() => {
                setUpdateToggle(false);
                setCreate(false);
              }}
            >
              {" "}
              <FormattedMessage id={"leads.gmail.select"} />{" "}
            </button>
            <button
              style={
                create
                  ? {
                      ...buttonRightStyle,
                      backgroundColor: "#3B53D1",
                    }
                  : { ...buttonRightStyle, backgroundColor: "#bbbbbb" }
              }
              className={"btn xs"}
              onClick={() => setCreate(true)}
            >
              {" "}
              {updateToggle
                ? intl.formatMessage({ id: "leads.gmail.update" })
                : intl.formatMessage({ id: "leads.gmail.create" })}{" "}
            </button>
          </div>
          {!create && (
            <div style={{ display: "inline" }}>
              <div
                className="search-ui"
                style={{ display: "inline-block", marginLeft: "20px" }}
              >
                <input
                  className="search-ui"
                  type="text"
                  value={search}
                  placeholder={intl.formatMessage({
                    id: "leads.gmail.searchByTitle",
                  })}
                  onChange={(e) => filterTemplates(e.target.value)}
                />
              </div>
              {members.length !== 0 && (
                <div
                  className="cstm-dropdown addedby user_filter"
                  style={{
                    display: "inline-block",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="dropdown">
                    <Button
                      variant={"primary"}
                      className="dropdown-toggle"
                      onClick={() => setSelectToggle("member")}
                      disabled={false}
                    >
                      <FormattedMessage id={"leads.gmail.author"} />
                    </Button>
                    <Animation.SlideDown
                      className={"dropdown-menu show"}
                      toggle={selectToggle === "member" ? selectToggle : false}
                      setToggle={() => setSelectToggle(false)}
                      style={{ width: "max-content" }}
                    >
                      <ul>
                        <MemberItem
                          member={{
                            firstName: intl.formatMessage({
                              id: "organization.allMembers",
                            }),
                            lastName: " ",
                          }}
                          onMemberSelect={() => handleMemberFilter({})}
                        />
                        {members &&
                          members.map((member, i) => (
                            <MemberItem
                              key={i}
                              member={member}
                              onMemberSelect={(member) =>
                                handleMemberFilter({ member })
                              }
                            />
                          ))}
                      </ul>
                    </Animation.SlideDown>
                  </div>
                </div>
              )}
            </div>
          )}
          {!create && templates && templates.length === 0 && (
            <h5 style={{ textAlign: "center", margin: "50px" }}>
              <FormattedMessage id={"leads.gmail.noTemplatesFound"} />
            </h5>
          )}
          {!create && templates && templates.length !== 0 && (
            <div className="table-content">
              <table style={{ minWidth: "auto", width: "100%" }}>
                <thead>
                  <tr>
                    {["Title", "Author", "Created at", " "].map((prop, i) => {
                      return (
                        <th key={i} style={{ maxWidth: "max-content" }}>
                          {prop}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {templates &&
                    templates
                      .slice(5 * (page - 1), 5 * (page - 1) + 5)
                      .map((template, index) => (
                        <tr
                          key={index}
                          onClick={() => selectTemplate(template)}
                        >
                          <td>
                            <div style={{ width: "100px" }}>
                              <p>
                                {template.title && template.title.length > 10
                                  ? template.title.slice(0, 10) + "..."
                                  : template.title}{" "}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <MemberItem
                                member={
                                  members &&
                                  members.find((o) => o.email === template.user)
                                    ? members.find(
                                        (o) => o.email === template.user
                                      )
                                    : {
                                        firstName: template.user,
                                        lastName: " ",
                                      }
                                }
                                onMemberSelect={(member) =>
                                  handleMemberFilter({ member })
                                }
                                banSelect={true}
                              />
                              {/*<p style={{fontWeight: '400'}}>{template.user}</p>*/}
                            </div>
                          </td>
                          <td>
                            <div>
                              <p style={{ fontWeight: "400" }}>
                                {formatNotificationsDate(template.createdAt)}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="button-ui delete-account">
                                {index === confirmDeleteTemplate ? (
                                  <a
                                    href="#"
                                    className="btn1"
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "0px 10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={(e) =>
                                      handleDeleteTemplate(e, index)
                                    }
                                  >
                                    <FormattedMessage id={"confirm"} />
                                  </a>
                                ) : (
                                  <div
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "0px 10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src="/assets/images/ic_delete_g.png"
                                      alt=""
                                      style={{ padding: "35%" }}
                                      onClick={(e) =>
                                        handleConfirmDeleteTemplate(e, index)
                                      }
                                    />
                                    <span className="ic-btn">
                                      <img
                                        src="/assets/images/ic_edit_g.png"
                                        alt=""
                                        style={{ padding: "35%" }}
                                        onClick={(e) =>
                                          handleEditTemplate(e, index)
                                        }
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          )}
          {!create && templates.length > 5 && (
            <div className="container">
              <Pagination
                pages={
                  Math.floor(templates.length / 5) +
                  (templates.length % 5 > 0 ? 1 : 0)
                }
                currentPage={page}
                goToPage={setPage}
                top
              />
            </div>
          )}

          {create && (
            <div>
              <div
                style={{
                  marginLeft: "20px",
                  marginBottom: "18px",
                  display: "inline",
                }}
              >
                <FormattedMessage id={"leads.gmail.title"} />:
                <input
                  type="text"
                  style={{
                    width: "80%",
                    outline: "none",
                    border: "none",
                    marginLeft: "10px",
                    marginBottom: "18px",
                    display: "inline",
                    borderBottom: "1px solid",
                    borderColor: "grey",
                  }}
                  value={title}
                  placeholder={intl.formatMessage({ id: "leads.gmail.title" })}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  marginBottom: "18px",
                  display: "inline",
                }}
              >
                <FormattedMessage id={"leads.gmail.subject"} />
                <input
                  type="text"
                  style={{
                    width: "80%",
                    outline: "none",
                    border: "none",
                    marginLeft: "10px",
                    marginBottom: "18px",
                    display: "inline",
                    borderBottom: "1px solid",
                    borderColor: "grey",
                  }}
                  value={subject}
                  placeholder={intl.formatMessage({
                    id: "leads.gmail.subject",
                  })}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group" style={{ margin: "15px" }}>
                <EmailField
                  value={template}
                  onChange={(a) => {
                    setTemplate(a);
                  }}
                  placeholder={intl.formatMessage({
                    id: "leads.gmail.startMakingYourOwnMagic",
                  })}
                  handleIndexChange={handleIndexChange}
                />
              </div>
              <div className="textarea-footer-ui">
                <div className="dropdown select-container">
                  <a
                    className="btn btn-secondary dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "white",
                      borderColor: "white",
                      color: "#4f64dc",
                    }}
                  >
                    <span className="border-plus-icon">
                      <img src="/assets/images/ic_insert_variable.svg" alt="" />
                    </span>
                    <FormattedMessage id={"leads.gmail.insertVariables"} />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    style={{ maxHeight: "200px", overflow: "scroll" }}
                  >
                    {[
                      "firstName",
                      "lastName",
                      "company",
                      "job",
                      "phone",
                      "description",
                      "comment",
                      "email",
                      "currentProEmail",
                      "currentPersonalEmail",
                      "location",
                      "linkedin",
                      "companyInfo_companyPageUrl",
                      "companyInfo_staffCount",
                      "companyInfo_industryName",
                    ].map((item, i) => (
                      <div
                        key={i}
                        className="dropdown-item"
                        onClick={() => onSelectVar(item)}
                      >
                        <FormattedMessage id={"leads.properties." + item} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {error && (
          <div
            className="btn-grp text-center  error  kaspr-form-text-error"
            style={{ top: 7, width: "90%" }}
          >
            {error + " "}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0">
        <a href="#" className="btn2 mr14" onClick={() => setToggle(false)}>
          <FormattedMessage id={"leads.gmail.back"} />
        </a>
        {create &&
          (template && template !== "<p><br></p>" && title && subject ? (
            <a
              href="#"
              className="btn1 "
              onClick={() => handleCreateOrUpdateTemplate()}
            >
              <FormattedMessage id={"save"} />
            </a>
          ) : (
            <a
              href="#"
              className="btn1 disabled"
              style={{ background: "grey", textShadow: "-1px 1px 0 grey" }}
            >
              <FormattedMessage id={"save"} />
            </a>
          ))}
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(SelectEmailTemplate);
