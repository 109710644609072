import React, { useContext, useEffect, useReducer, useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import allIntegrations from "../allIntegrations";
import { NavLink } from "react-router-dom";
import {
  addLeadsIntegration,
  getAccounts,
  getIntegrationsLists,
} from ".././../../../services/api/integrations.api";

import { FormattedMessage, injectIntl } from "react-intl";
import { getLeadFeatures } from "../../../../services/api/leads.api";
import {
  actions,
  leadsInitialState,
  leadsReducer,
} from "../../../Leads/store/leads.store";
import { usePermissions } from "../../../../components/Permissions/permissions.hooks";
import { Permissions } from "../../../../components";
import { capitalizeFirstLetter } from "../../../../services/utils/tools";
import { integrationUpdatedNames } from "../../../Settings/Integration/constants";

function IntegrationsPopUp({
  hasPermission = true,
  toggle,
  setToggle,
  leads,
  filters,
  showToastMessage,
  intl,
  leadFromDetailsPageId,
  organizationCredits,
  exportRemaining,
  isVersion2,
}) {
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationList, setIntegrationList] = useState(null);
  const [selectedIntegrationList, selectIntegrationList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [state, dispatch] = useReducer(leadsReducer, leadsInitialState);

  const [checkPlanPermissions] = usePermissions(
    organizationCredits &&
      organizationCredits.plan &&
      organizationCredits.plan.permissions,
    null,
    "Integration"
  );
  useEffect(() => {
    const loadData = async () => {
      const _integrations = allIntegrations.filter(
        (intg) => intg.active && intg.name !== "linkedin"
      );
      let { data, statusCode } = await getAccounts();
      if (data && data.accounts)
        _integrations.map((intg) => {
          intg.isConnected = data.accounts[intg.name];
          return intg;
        });
      setIntegrations(_integrations);
    };
    loadData();
  }, []);

  const disconnectIntegration = (integrationName) => {
    let _integrations = Object.assign([], integrations);
    _integrations = _integrations.map((integ) => {
      if (integ.name === integrationName) {
        integ.isConnected = null;
      }
      return integ;
    });
    setIntegrations(_integrations);
  };

  const handleSelectIntegration = async (integrationName) => {
    let unselect = false;
    switch (integrationName) {
      case "sendinblue":
        const {
          data: sendinBlueData,
          statusCode: sendinblueStatus,
        } = await getIntegrationsLists({
          integrationName,
        });
        if (sendinblueStatus == 200) {
          setIntegrationList({
            name: integrationName,
            lists: sendinBlueData.lists,
          });
        } else {
          if (
            sendinBlueData.message &&
            sendinBlueData.message.indexOf("API key is not valid") > -1
          ) {
            disconnectIntegration("sendinblue");
            unselect = true;
          }
          setError(sendinBlueData.messageTranslated || sendinBlueData.message);
          selectIntegrationList(null);
        }
        break;
      case "zapier":
        const {
          data: zapierData,
          statusCode: zapierStatus,
        } = await getIntegrationsLists({
          integrationName,
        });
        if (zapierStatus === 200) {
          zapierData.lists.push({ name: "Export to all zaps" });
          setIntegrationList({
            name: integrationName,
            lists: zapierData.lists,
          });
        } else {
          setError(zapierData.messageTranslated || zapierData.message);
          selectIntegrationList(null);
        }
        break;
      case "lemlist":
        const {
          data: lemlistData,
          statusCode: lemlistStatus,
        } = await getIntegrationsLists({
          integrationName,
        });
        if (lemlistStatus == 200) {
          setIntegrationList({
            name: integrationName,
            lists: lemlistData.lists,
          });
        } else {
          if (
            lemlistData.message &&
            lemlistData.message.indexOf("API key is not valid") > -1
          ) {
            disconnectIntegration("lemlist");
            unselect = true;
          }
          setError(lemlistData.messageTranslated || lemlistData.message);
          selectIntegrationList(null);
        }
        break;
      case "salesforce":
        setIntegrationList({
          name: integrationName,
          lists: [{ name: "lead" }, { name: "contact" }],
        });
        break;
    }
    setSelectedIntegration(unselect ? undefined : integrationName);
  };
  const handleExportToIntegration = async (e) => {
    e.preventDefault();
    if (!selectedIntegration) {
      setError(intl.formatMessage({ id: "youNeedToSelectAndIntegration" }));
      return;
    }
    if (
      ["sendinblue", "lemlist", "salesforce"].indexOf(selectedIntegration) >
        -1 &&
      !selectedIntegrationList
    ) {
      setError(
        intl.formatMessage({ id: "youNeedToSelectAListFor" }) +
          selectedIntegration
      );
      return;
    }
    if (selectedIntegration === "zapier" && !selectedIntegrationList) {
      setError(intl.formatMessage({ id: "youNeedToSelectOneZap" }));
      return;
    }
    setLoading(true);
    let { statusCode, data } = await addLeadsIntegration({
      leads,
      isExportAllLeads: leads.length > 0 ? leads : false,
      filters,
      integrationName: selectedIntegration,
      listIds:
        selectedIntegration === "zapier" &&
        selectedIntegrationList === "Export to all zaps"
          ? []
          : [selectedIntegrationList],
    });
    setLoading(false);
    if (statusCode === 200) {
      if (leadFromDetailsPageId) {
        ({ data } = await getLeadFeatures({ leadId: leadFromDetailsPageId }));
        dispatch({ type: actions.LEAD_DETAILS, payload: data });
      }
      setToggle(false);
      if (data.total) {
        if (data && data.total && data.created + data.updated === data.total) {
          showToastMessage("Lead(s) successfully exported", "success");
        } else if (data && data.created + data.updated === 0)
          showToastMessage(data.error || "An Error has occurred");
        else {
          let message =
            "You tried to export " +
            data.total +
            " lead" +
            (data.total > 1 ? "s" : "") +
            ". ";
          if (data.created) {
            message += data.created;
            message +=
              (data.created === 1 ? " lead was " : " leads were") +
              " created. ";
          }
          if (data.updated) {
            message += data.updated;
            message +=
              (data.updated === 1 ? " lead was " : " leads were") +
              " updated. ";
          }
          if (data.error || data.updated + data.created < data.total)
            message +=
              data.error ||
              "An error has occurred while exporting the other " +
                (data.total - data.created - data.updated) +
                " lead" +
                (data.total - data.created - data.updated > 1 ? "s." : ".");
          showToastMessage(message, "info");
        }
      } else {
        showToastMessage("Lead(s) successfully exported", "success");
      }
    } else {
      showToastMessage(
        intl.formatMessage({ id: "anErrorHasOccurredWhile" }) +
          ": " +
          (data.messageTranslated || data.message)
      );
      setToggle(false);
    }
  };

  return (
    <Modal
      className="modal fade integration-workflow"
      show={toggle}
      onHide={() => setToggle(false)}
    >
      <Modal.Header closeButton className="modal-header">
        <div className="modal-inner-header">
          <p className="title">
            {leads ? (
              <FormattedMessage
                id={"settings.integration.exportToIntegrations"}
              />
            ) : (
              <FormattedMessage
                id={"settings.integration.integrationWorkflow"}
              />
            )}
          </p>

          {isVersion2 && organizationCredits && organizationCredits.plan && (
            <div className={"pushes-remaining "} style={{ marginLeft: 0 }}>
              {exportRemaining} Exports remaining
            </div>
          )}
          {/*  <div className={"pushes-remaining "} style={{marginLeft:0,marginTop:10,width:"max-content"}}>
            <i className="fas fa-registered"></i>{" "}
            10
            Exports remaining
          </div>*/}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="create-workflow-wrap">
          <label className="sub-title2">
            <FormattedMessage id={"settings.integration.selectAnIntegration"} />{" "}
          </label>
          <div className="select-integration">
            <ul>
              {integrations.map((integration, i) => (
                <Integration
                  key={i}
                  integration={integration}
                  name={integration.name}
                  image={integrationUpdatedNames[integration.name] + ".png"}
                  onSelect={(name) =>
                    integration.isConnected && handleSelectIntegration(name)
                  }
                  isConnected={integration.isConnected}
                  selectedIntegration={selectedIntegration}
                  integrationList={integrationList}
                  selectList={selectIntegrationList}
                  checkPlanPermissions={checkPlanPermissions}
                />
              ))}
            </ul>
          </div>
        </div>
        {error && (
          <div
            className="text-center  error  kaspr-form-text-error"
            style={{ top: 10, width: "90%" }}
          >
            {error + " "}
          </div>
        )}
      </Modal.Body>
      <div className="modal-footer">
        {hasPermission ? (
          //user hasPermission to use integrations
          leads ? (
            loading ? (
              <a
                href={"#"}
                className="btn1 w-141 text-center"
                style={{ cursor: "default" }}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i>Loading
              </a>
            ) : (
              <a
                href={"#"}
                className="btn1 w-141 text-center"
                onClick={handleExportToIntegration}
              >
                <FormattedMessage id={"export"} />
              </a>
            )
          ) : (
            <NavLink
              className="btn1 w-141 text-center"
              to={
                selectedIntegration
                  ? "createworkflow/integration/" + selectedIntegration
                  : "worfklow"
              }
            >
              <FormattedMessage id={"workflow.proceed"} />
            </NavLink>
          )
        ) : (
          //no permission
          <NavLink to="/billing" className="btn1">
            <span className="ic-lock">
              <img src="/assets/images/ic-lock.svg" alt="" />
            </span>{" "}
            <FormattedMessage
              id={"settings.integration.upgradeToUseThisFeature"}
            />
          </NavLink>
        )}
      </div>
    </Modal>
  );
}

export default injectIntl(IntegrationsPopUp);

function Integration({
  name,
  image,
  onSelect,
  isConnected,
  selectedIntegration,
  integrationList,
  selectList,
  intl,
  checkPlanPermissions,
}) {
  const handleIntgClick = () => {
    onSelect(name);
  };

  if (
    name == "salesforce"
      ? !(
          checkPlanPermissions(capitalizeFirstLetter("salesforceContact")) ||
          checkPlanPermissions(capitalizeFirstLetter("SalesforceLead"))
        )
      : name === "zohocrm"
      ? !checkPlanPermissions("Zoho-crm")
      : !checkPlanPermissions(capitalizeFirstLetter(name))
  ) {
    return (
      <li>
        <Permissions.CallToAction
          withoutIcon={true}
          isPlan={true}
          toolTipStyle={{ width: 250 }}
          containerStyle={{ width: "100%", display: "block" }}
          tooltipContainerStyle={{ display: "block", width: "100%" }}
        >
          <div className="radio-btn">
            <input
              type="radio"
              name="selectintegration"
              checked={selectedIntegration === name}
              disabled={!isConnected}
            />
            <span className="radio-icon"></span>
            <div className="radio-text">
              <div className="img-block">
                <img src={"/assets/images/" + image} alt="" />
              </div>
              <div className="content-block">
                <p>{integrationUpdatedNames[name]}</p>
                {isConnected ? (
                  <span className="connected-tag">
                    <FormattedMessage id={"connected"} />
                  </span>
                ) : (
                  <span className="not-connected-tag">
                    <FormattedMessage id={"notConnected"} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </Permissions.CallToAction>
      </li>
    );
  }

  return (
    <>
      <li onClick={isConnected ? handleIntgClick : () => {}}>
        <div className="radio-btn">
          <input
            type="radio"
            name="selectintegration"
            checked={selectedIntegration === name}
            disabled={!isConnected}
          />
          <span className="radio-icon"></span>
          <div className="radio-text">
            <div className="img-block">
              <img src={"/assets/images/" + image} alt="" />
            </div>
            <div className="content-block">
              <p>{integrationUpdatedNames[name]}</p>
              {isConnected ? (
                <span className="connected-tag">
                  <FormattedMessage id={"connected"} />
                </span>
              ) : (
                <span className="not-connected-tag">
                  <FormattedMessage id={"notConnected"} />
                </span>
              )}
            </div>
          </div>
        </div>
      </li>
      {integrationList && integrationList.name == name && (
        <div className={"custom-integration-list"}>
          <select id="" onChange={(e) => selectList(e.target.value)}>
            <option style={{ backgroundColor: "#F5F5F9" }}>
              -
              {intl.formatMessage({ id: "settings.select" }) +
                (selectedIntegration === "zapier"
                  ? "zap"
                  : selectedIntegration === "lemlist"
                  ? intl.formatMessage({ id: "settings.campaign" })
                  : intl.formatMessage({ id: "settings.list" }))}{" "}
              -
            </option>

            {integrationList.lists.map((list) => (
              <option value={list.id || list.name || list.link}>
                {list.name || list.link}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
Integration = injectIntl(Integration);
