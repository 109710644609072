import React, { useContext, useEffect, useState } from "react";
import { Animation } from "../../../../components";
import { FormattedMessage, useIntl } from "react-intl";
import "./MemebrSelect.css";
import { actions, OrganizationContext } from "../../store/Organization.store";
import { getMembers } from "../../../../services/api/organization.api";
function MemberSelect({ onChange, labelsStyle, zIndex, members = [] }) {
  const [selectedMember, setSelectedMember] = useState("");
  const [memberName, setMemberName] = useState("");
  const [selectToggle, setSelectToggle] = useState(false);
  const { state, dispatch } = useContext(OrganizationContext);
  const intl = useIntl();
  const handleSelectLabel = async (member) => {
    setSelectedMember(member);
    if (member.email) {
      setMemberName(member.firstName + " " + member.lastName);
    } else {
      setMemberName("");
      const { data, statusCode } = await getMembers({ search: "" });
      dispatch({
        type: actions.CHART_FILTERS,
        payload: {
          chartFilters: {
            ...state.chartFilters,
            emails: data.members.map((member) => member.email),
          },
        },
      });
      const org = state.organization;
      org.members = data.members;
      dispatch({ type: actions.GET_ORGANIZATION, payload: org });
    }

    onChange(member);
    setSelectToggle(false);
  };
  const handleMemberSearch = async (e) => {
    setMemberName(e.target.value);
    const { data, statusCode } = await getMembers({ search: e.target.value });
    const org = state.organization;
    org.members = data.members;
    dispatch({ type: actions.GET_ORGANIZATION, payload: org });
  };
  return (
    <div className={"custom-member-select"}>
      <div
        className={"custom-select-picker"}
        style={{
          ...(zIndex ? { zIndex } : {}),
        }}
      >
        <div
          className={"dropdown-toggle selected-label member-select-input"}
          onClick={() => setSelectToggle(true)}
        >
          <input
            type="text"
            placeholder={
              intl.formatMessage({ id: "organization.member" }) + "..."
            }
            value={memberName}
            onChange={handleMemberSearch}
          />
          {/* {selectedMember.firstName + " " + selectedMember.firstName}*/}
        </div>
        <Animation.SlideDown
          className={"labels"}
          toggle={selectToggle}
          setToggle={setSelectToggle}
          style={labelsStyle}
        >
          <ul>
            <li onClick={() => handleSelectLabel({})}>
              <span className={"custom_tag_name"}>all</span>
              <FormattedMessage id={"organization.allMembers"} />
              <hr />
            </li>
            {members.length > 0 ? (
              members.map((member, i) => (
                <li
                  className={""}
                  key={i}
                  style={
                    member === selectedMember
                      ? { background: "#3B53D1", color: "#fff" }
                      : {}
                  }
                  onClick={() => handleSelectLabel(member)}
                >
                  {member.leavedOrg ? (
                    <span>
                      <span
                        className={"custom_tag_name"}
                        style={{ background: "#D3D2D2", color: "#4F4D4D" }}
                      >
                        {member.firstName ? member.firstName[0] : " "}
                      </span>
                      {"  "}
                      <span>{member.firstName}</span>
                    </span>
                  ) : (
                    <span>
                      <span className={"custom_tag_name"}>
                        {(member.firstName ? member.firstName[0] : " ") +
                          (member.lastName ? member.lastName[0] : " ")}
                      </span>
                      {"  "}
                      <span className={"capital"}>{member.firstName}</span>{" "}
                      <span className={"capital"}>{member.lastName}</span>
                    </span>
                  )}
                </li>
              ))
            ) : (
              <li style={{ textAlign: "center" }}>
                <FormattedMessage id={"workflow.noResultsFound"} />
              </li>
            )}
          </ul>
        </Animation.SlideDown>
      </div>
    </div>
  );
}

export default MemberSelect;
