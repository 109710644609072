import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { checkPermission } from "../../../../services/utils";
import {
  createWorkflow,
  getTemplates,
  updateBlock,
  updateWorkflowActivationState,
} from "../../../../services/api/workflows.api";
import { actions, WorkflowContext } from "../../store/workflow.store";
import { useHistory } from "react-router-dom";

const WorkflowNavBar = ({
  name,
  setVerifyActivation,
  blockOptions,
  profile,
}) => {
  const { state, dispatch } = useContext(WorkflowContext);
  const [workflowId, setWorkflowId] = useState(null);
  const history = useHistory();

  const handleAttachBlock = async (
    workflowToAttach,
    workflowType,
    blockOptions,
    profile
  ) => {
    let workflowToAttachCopy = { ...workflowToAttach[0] };
    if (workflowType === "new") {
      workflowToAttachCopy = await handleCreateWorkflow(workflowToAttach[0]);

      if (!workflowToAttachCopy) return;
    }
    setWorkflowId(workflowToAttachCopy._id);
    if (blockOptions._id || !workflowToAttachCopy._id) {
      if (
        checkPermission({
          permissions: profile.permissions,
          name: "Workflow",
          action: "Edit",
        })
      ) {
        if (!workflowToAttachCopy._id) return;
        if (blockOptions.options) {
          blockOptions.options.workflowId = workflowToAttachCopy._id;
          blockOptions.options.name = workflowToAttachCopy.name;
        } else {
          blockOptions.options = {
            workflowId: workflowToAttachCopy._id,
            name: workflowToAttachCopy.name,
          };
        }
        const { data, statusCode } = await updateBlock(blockOptions);
        if (statusCode === 200) {
          console.log(data);
        }
      } else {
        toast.error(<FormattedMessage id={"permissions.noAccess"} />);
      }
    } else {
      let template = state.selectedTemplate;
      template.blocks = state.selectedTemplate.blocks.map((block) => {
        if (block.id === "2") {
          block.options.workflowId = workflowToAttachCopy._id;
        }
        return block;
      });
      dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });
    }
  };

  const handleCreateWorkflow = async (intg) => {
    let intgOnly = false;
    if (
      !intg &&
      state.selectedTemplate.blocks[0] &&
      state.selectedTemplate.type === "integration"
    ) {
      intgOnly = true;
      intg = state.selectedTemplate.blocks[0];
    }
    const { data } = await getTemplates();
    const myTemplate = data.templates.find(
      (template) =>
        template.type == "enrichment" || template.type === "integration"
    );
    if (intg) {
      myTemplate.type = "integration";
      myTemplate.blocks = [
        {
          id: 1,
          name: intg.name,
          action: "integration-" + intg.name,
          type: "flow_action",
          options: { email: "" },
        },
      ];
    }
    if (myTemplate.type == "enrichment") {
      myTemplate.blocks = myTemplate.blocks.map((block) => {
        if (block.id == "1") {
          block.options.addLead = true;
          block.options.isEmailProRequired =
            state.enrichConfig.isEmailProRequired;
          block.options.isPhoneRequired = state.enrichConfig.isPhoneRequired;
          block.options.dataToGet = state.enrichConfig.dataToGet;
        }
        if (block.id == "2") {
          block.options.workflowId =
            state.selectedTemplate.blocks[1].options.workflowId;
        }
        return block;
      });
    }
    if (
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Edit",
      })
    ) {
      if (myTemplate.type == "enrichment") {
        myTemplate.name = name;
      } else {
        myTemplate.name = intgOnly ? name : intg.name;
      }

      const { data, statusCode } = await createWorkflow(myTemplate);
      if (statusCode === 200) {
        if (myTemplate.type === "integration") {
          updateWorkflowActivationState({
            _id: data.workflow._id,
            active: true,
          });
          if (intgOnly) {
            history.push(`/workflow/${data.workflow._id}`);
          }
        } else {
          setVerifyActivation(data.workflow);
        }

        return data.workflow;
      }
    } else {
      toast.error(<FormattedMessage id={"permissions.noAccess"} />);
      setVerifyActivation(null);
    }
  };

  const handleSaveAndPublish = async () => {
    if (!name) {
      toast.error(<FormattedMessage id="workflow.enterWorkflowName" />);
      return;
    } else {
      if (state.selectedIntgDropdown && state.selectedIntgDropdown.length > 0) {
        await handleAttachBlock(
          state.selectedIntgDropdown,
          state.selectedIntgDropdown[0].type === "integration" ? "" : "new",
          blockOptions,
          profile
        );
      }
      await handleCreateWorkflow();
    }
  };

  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li>
            <a
              data-toggle="tab"
              id={"workflow"}
              href="#workflow_tab"
              className="active"
            >
              <FormattedMessage id="organization.settings" />
            </a>
          </li>{" "}
        </ul>
      </div>
      <div className="save-publish-ui">
        <a href="#" className="btn1" onClick={handleSaveAndPublish}>
          <FormattedMessage id={"workflow.saveAndPublish"} />
        </a>
      </div>
    </div>
  );
};

export default WorkflowNavBar;
