import React from "react";
import PopUp from "../../../../../components/PopUp/PopUp";
import "./SubscriptionCancelledModal.css";
import { FormattedMessage } from "react-intl/lib";

function SubscriptionCancelledModal({ toggle, handleCloseModal }) {
  const handleGoToAccount = () => {
    toggle(false);
    window.location = "/home";
  };

  return (
    <PopUp
      toggle={toggle}
      onClose={handleCloseModal}
      style={{ width: "80vw", maxWidth: "500px", height: "auto" }}
    >
      <div id="subscription-cancelled-container">
        <img src="/assets/images/dog.png" alt="dog" />
        <h1>
          <FormattedMessage id="cancellationflow.subscriptionCancelled" />
        </h1>
        <h2>
          <FormattedMessage id="cancellationflow.weHopeToSeeYouAgain" />
        </h2>
      </div>
      <div id="subscription-account-btn">
        <button
          className="btn btn1 btn-ripple"
          style={{ width: "100%" }}
          onClick={handleGoToAccount}
        >
          <FormattedMessage id="cancellationflow.goToAccount" />
        </button>
      </div>
    </PopUp>
  );
}

export default SubscriptionCancelledModal;
