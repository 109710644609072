import React, { useState } from "react";
import "./FilesItem.css";
import { FormattedMessage, useIntl } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import { InfoToolTip } from "../../../../../components";
import {
  bulkExport,
  bulkPagination,
  deleteBulkEnrichment,
} from "../../../../../services/api/bulkEnrichment.api";
import PopUp from "../../../../../components/PopUp/PopUp";
import { actions } from "../../../store/bulkEnrichment.store";
import { usePermissions } from "../../../../../components/Permissions/permissions.hooks";
import { permissions } from "../../../../../redux/reducers/permissions";
import { Permissions } from "../../../../../components";

function FilesItem({
  name,
  _id,
  nbContacts,
  nbContactsEnriched,
  nbPhone,
  nbWorkEmail,
  nbDirectEmail,
  status,
  setUpdateTrigger,
  error,
  profile,
  credits,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const intl = useIntl();
  const [checkPlanPermissionsWk, checkMemberPermissionsWk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    "Workflow"
  );
  const [checkPlanPermissionsBulk, checkMemberPermissionsBulk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    "BulkEnrichment"
  );
  const handleDownload = async () => {
    const body = { _id };

    try {
      const { data, statusCode } = await bulkExport(body);

      if (statusCode === 200 && data.bulkEnrichment.upload) {
        const link = document.createElement("a");
        link.href = data.bulkEnrichment.upload;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(data.messageTranslated || data.message);
      }
    } catch (error) {
      toast.error(
        `Error during data fetch: ${error.message || "Unknown error"}`
      );
    }
  };

  const handleDeleteClick = () => {
    setShowPopUp(true);
  };

  const confirmDelete = async () => {
    try {
      const { data, statusCode } = await deleteBulkEnrichment(_id);
      if (statusCode === 200 && data.success) {
        setUpdateTrigger((prev) => prev + 1);
      } else {
        toast.error(data.messageTranslated || data.message);
      }
    } catch (error) {
      toast.error("Error during data fetch:", error);
    }
    setShowPopUp(false);
  };

  let itemStatus;
  const getStatusClass = () => {
    if (status === "ok") {
      itemStatus = <FormattedMessage id="bulkEnrichment.files.ok" />;
      return "status-ok";
    } else if (status === "failed") {
      itemStatus = <FormattedMessage id="bulkEnrichment.files.failed" />;
      return "status-failed";
    } else if (status === "pending") {
      itemStatus = <FormattedMessage id="pending" />;
      return "status-pending";
    } else if (status === "processing") {
      itemStatus = <FormattedMessage id="bulkEnrichment.files.processing" />;
      return "status-processing";
    }
    return "";
  };

  const errorMessage = {
    NO_DATA_FOUND : "bulkEnrichment.noDataFound",
    NOT_ENOUGH_CREDITS : "bulkEnrichment.notEnoughCredits",
    UNEXPECTED_ERROR : "bulkEnrichment.unexpectedError",
  }

  return (
    <>
      <ToastContainer position="bottom-right" style={{ top: 100 }} />
      <tr className="item-container">
        <td className="item-name">
          {name.lastIndexOf(".") !== -1
            ? name.substring(0, name.lastIndexOf("."))
            : name}
        </td>
        <td>{nbContacts}</td>
        <td>{nbContactsEnriched}</td>
        <td>{nbPhone}</td>
        <td>{nbWorkEmail}</td>
        <td>{nbDirectEmail}</td>
        <td className={`items-status ${getStatusClass()}`}>
          {itemStatus && getStatusClass() !== "status-failed" && itemStatus}
          {itemStatus && getStatusClass() === "status-failed" && (
            <div className="info">
              {itemStatus}
              {error && (
                <InfoToolTip
                  text={errorMessage[error] ? <FormattedMessage id={errorMessage[error]}/> : error}
                  style={{
                    width: "13px",
                    marginLeft: "3px",
                    marginTop: "-10px",
                    cursor: "pointer",
                    filter: "invert(53%) sepia(74%) saturate(4000%) hue-rotate(-14deg) brightness(97%) contrast(89%)"

                  }}
                  containerStyle={{
                    lineHeight: "18px",
                    padding: "8px",
                  }}
                />
              )}
            </div>
          )}
        </td>
        <td>
          <div className="actions-icons">
            {status === "ok" &&
            (!checkMemberPermissionsWk("View") ||
              !checkPlanPermissionsBulk("Export")) ? (
              <Permissions.ToolTip
                text={
                  !checkPlanPermissionsBulk("Edit")
                    ? intl.formatMessage({
                        id: "permissions.upgradePlan",
                      })
                    : intl.formatMessage({
                        id: "permissions.noAccess",
                      })
                }
                containerStyle={{
                  lineHeight: "18px",
                  padding: "8px",
                }}
              >
                <img
                  src="/assets/images/download.svg"
                  alt="Download"
                  className="icon"
                  style={{ cursor: "not-allowed", opacity: "0.5" }}
                />
              </Permissions.ToolTip>
            ) : (
              status === "ok" && (
                <img
                  src="/assets/images/download.svg"
                  alt="Download"
                  className="icon"
                  onClick={handleDownload}
                />
              )
            )}
            {!checkMemberPermissionsWk("Edit") ||
            !checkPlanPermissionsBulk("Edit") ? (
              <Permissions.ToolTip
                text={
                  !checkPlanPermissionsBulk("Edit")
                    ? intl.formatMessage({
                        id: "permissions.upgradePlan",
                      })
                    : intl.formatMessage({
                        id: "permissions.noAccess",
                      })
                }
                containerStyle={{
                  lineHeight: "18px",
                  padding: "8px",
                }}
              >
                <img
                  src="/assets/images/ic_bin.svg"
                  alt="Delete"
                  className="icon"
                  style={{ cursor: "not-allowed", opacity: "0.5" }}
                />
              </Permissions.ToolTip>
            ) : (
              <img
                src="/assets/images/ic_bin.svg"
                alt="Delete"
                className="icon"
                onClick={handleDeleteClick}
              />
            )}
          </div>
        </td>
      </tr>
      {showPopUp && (
        <PopUp
          toggle={showPopUp}
          onClose={() => setShowPopUp(false)}
          style={{ width: "auto", height: "auto" }}
        >
          <div className="delete-popup">
            <p>
              <FormattedMessage id="bulkEnrichment.files.confirmDelete" />
            </p>
            <div className="delete-popup-btn">
              <button className="btn btn1" onClick={confirmDelete}>
                <FormattedMessage id="Confirm" />
              </button>
              <button className="btn btn2" onClick={() => setShowPopUp(false)}>
                <FormattedMessage id="billing.cancel" />
              </button>
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
}

export default FilesItem;
