import React, { useMemo, useReducer } from "react";
import { authInitialState, authReducer } from "./Store/auth.store";
import AuthProvider from "./Store/auth.provider";
import SigninForm from "./Signin/Signin";
import SignupForm from "./Signup/Signup";
import ConfirmAccount from "./ConfirmAccount/ConfirmAccount";
import AuthHeader from "./common/AuthHeader/AuthHeader";
import { OnBoardingSteps } from "./Onboarding/Onboarding";
import { Header } from "../../components";
import "./Auth.css";
import {
  ForgotPassword,
  ResetForm,
  ResetPassword,
} from "./ForgotPassword/ForgotPassword";
import { toast, ToastContainer } from "react-toastify";
import InviteFriend from "./Onboarding/InviteFriend/InviteFriend";
import { injectIntl } from "react-intl";
import SignupFromLink from "./SingupFromLink/SignupFromLink.jsx";

function Signin() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <AuthHeader />
        <SigninForm />
      </div>
    </AuthProvider>
  );
}

function Signup() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <AuthHeader type={"signup"} />
        <SignupForm />
      </div>
    </AuthProvider>
  );
}

function SignupCodeConfirmation(props) {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <Header
          currentPage={""}
          profile={props.redux.profile}
          isOnBoardingPage
          organization={props.redux.organization}
        />
        {/*<AuthHeader type={"signup"} />*/}
        <ConfirmAccount
          profile={props.redux.profile}
          showToastMessage={(response) => {
            response == "success"
              ? toast.success(
                  props.intl.formatMessage({
                    id: "auth.codeSuccessfullyResent",
                  })
                )
              : toast.error(response);
          }}
        />
      </div>
      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </AuthProvider>
  );
}

function Onboarding(props) {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <Header
          currentPage={""}
          profile={props.redux.profile}
          isOnBoardingPage
          organization={props.redux.organization}
        />
        <OnBoardingSteps
          profile={props.redux.profile}
          resetCredits={props.redux.resetCredits}
        />
      </div>
    </AuthProvider>
  );
}

function ForgotPasswd() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <AuthHeader />
        <ForgotPassword />
      </div>
    </AuthProvider>
  );
}

function ResetPasswd() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <AuthHeader />
        <ResetPassword />
      </div>
    </AuthProvider>
  );
}
function SignupFromLinkParams() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AuthProvider store={store}>
      <div className={"main-wraper"}>
        <AuthHeader type={"confirmAccount"} />
        <SignupFromLink handleOnSubmit={console.log} data={{ email: "test" }} />
      </div>
    </AuthProvider>
  );
}

SignupCodeConfirmation = injectIntl(SignupCodeConfirmation);
export {
  Signin,
  Signup,
  Onboarding,
  SignupCodeConfirmation,
  ForgotPasswd,
  ResetPasswd,
  SignupFromLinkParams,
};
