import { convertToJsonAndHandleErrors, getToken } from "../utils";
import { API_URL } from "../../constants";

export const getEnrichedCSV = (formData) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment/csv`, {
    method: "POST",
    headers: {
      Accept: { "Content-Type": "multipart/form-data" },
      json: true,
      Authorization: getToken(),
    },
    body: formData,
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const bulkPagination = (bodyData) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(bodyData),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const bulkExport = (bodyData) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment/export`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(bodyData),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteBulkEnrichment = (_id) => {
  return fetch(`${API_URL}/workflows/bulkEnrichment`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
    body: JSON.stringify({ _id }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
