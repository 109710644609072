import React from "react";
import { FormattedMessage } from "react-intl";
import { Permissions } from "../../../components";
import CustomToolTip from "../../../components/CustomToolTip/CustomToolTip";

function SettingsTabs({
  organizationCredits,
  onTabCLick,
  currentTab,
  organization,
}) {
  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li onClick={() => onTabCLick("profile")}>
            <a
              id={"profile"}
              data-toggle="tab"
              href="#profile_tab"
              className={currentTab === "profile" ? "active" : ""}
            >
              <FormattedMessage id={"tabs.yourprofile"} />
            </a>
          </li>
          {organization && (
            <li onClick={() => onTabCLick("workspace")}>
              <a
                id={"workspace"}
                data-toggle="tab"
                href="#workspace_tab"
                className={currentTab === "workspace" ? "active" : ""}
              >
                <FormattedMessage id={"sidenav.organization"} />
              </a>
            </li>
          )}
          <li onClick={() => onTabCLick("integration")}>
            <a
              id={"integration_tab"}
              data-toggle="tab"
              href="#integration_tab"
              className={currentTab === "integration" ? "active" : ""}
            >
              <FormattedMessage id={"tabs.integration"} />
            </a>
          </li>
          {organization && (
            <li onClick={() => onTabCLick("api")}>
              <a
                id={"api_tab"}
                data-toggle="tab"
                href="#api_tab"
                className={currentTab === "api" ? "active" : ""}
              >
                API
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default SettingsTabs;
