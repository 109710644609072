import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { FormattedMessage } from "react-intl";
import "./ReceivedInvitations.css";
import ChooseAdminFromMemberModal from "./ChooseAdminFromMemberModal/ChooseAdminFromMemberModal";
import MoveToAnotherWorkspaceModal from "./MoveToAnotherWorkspaceModal/MoveToAnotherWorkspaceModal";
import DeleteOrganizationModal from "./DeleteOrganizationModal/DeleteOrganizationModal";
import { useReceivedInvitations } from "./ReceivedInvitations.hooks";
import { actions, OrganizationContext } from "../../store/Organization.store";

export default function ReceivedInvitations({
  invitations,
  hasOrganization = false,
  organizationCredits,
}) {
  const {
    state,
    loading,
    handleAcceptClick,
    handleDeclineInvitation,
    handleSubmit,
    handleAcceptInvitation,
  } = useReceivedInvitations(invitations, hasOrganization, organizationCredits);
  const { dispatch } = useContext(OrganizationContext);

  return (
    <div
      id="received-invitations"
      className="join-invite-msg"
      style={{ minWidth: "100%" }}
    >
      {state.organizationInvitations.map((invitation, i) => (
        <div key={i} className="invite-notification-ui">
          <div className="left-ui">
            <figure>
              <img
                src={
                  invitation.inviterPicture || "/assets/images/ic_profile.png"
                }
                alt=""
              />
            </figure>
            <div className="content-ui">
              <p>
                <strong>{invitation.inviter}</strong>{" "}
                <FormattedMessage id="organization.createJoin.invitedYouOn" />{" "}
                <strong className="capital">
                  ‘ {invitation.organizationId.name} ’
                </strong>
              </p>
            </div>
          </div>
          <div className="right-ui">
            <a
              className="btn-ui"
              onClick={() => (!loading ? handleAcceptClick(invitation) : false)}
            >
              <span className="icon">
                <img src="/images/ic-chk-permision.svg" alt="" />
              </span>
              <FormattedMessage id="accept" />
            </a>
            <a
              className="btn-ui"
              style={{ marginLeft: 20 }}
              onClick={() => handleDeclineInvitation(invitation)}
            >
              <span className="icon">
                <img src="/images/ic-chk-permision.svg" alt="" />
              </span>
              <FormattedMessage id="decline" />
            </a>
          </div>
          <ToastContainer position="bottom-right" style={{ top: 100 }} />
        </div>
      ))}
      {state.moveWorkspace && (
        <MoveToAnotherWorkspaceModal
          hasOrganization={hasOrganization}
          handleAcceptInvitation={handleAcceptInvitation}
        />
      )}
      {state.deleteTeamModal && state.isMoving && hasOrganization && (
        <DeleteOrganizationModal
          handleAcceptInvitation={handleAcceptInvitation}
        />
      )}
      {state.selectAdminsToggle && (
        <ChooseAdminFromMemberModal
          setToggle={() => {
            dispatch({
              type: actions.SET_SELECT_ADMINS_TOGGLE,
              payload: { selectAdminsToggle: null },
            });
            dispatch({
              type: actions.SET_SELECTED_ADMINS,
              payload: { selectedAdmins: null },
            });
          }}
          onSubmit={handleSubmit}
          selectedPlan={organizationCredits.plan}
        />
      )}
    </div>
  );
}
