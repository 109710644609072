const actions = {
  TOGGLE: "TOGGLE",
  GET_TEMPLATES: "GET_TEMPLATES",
  SELECTED_TEMPLATE: "SELECTED_TEMPLATE",
  GET_WORKFLOWS: "GET_WORKFLOWS",
  SELECT_WORKFLOW: "SELECT_WORKFLOW",
  ON_ERROR: "ON_ERROR",
  GET_PERFORMANCE: "GET_PERFORMANCE",
  GET_PROFILES: "GET_PROFILES",
  GET_AUTOMATIONS: "GET_AUTOMATIONS",
  SEARCH_PROFILE: "SEARCH_PROFILE",
  WORKFLOWS_FILTERS: "WORKFLOWS_FILTERS",
  SET_SELECTED_INTEGRATION: "SET_SELECTED_INTEGRATION",
  SET_WORKFLOWS_DETAILS: "SET_WORKFLOWS_DETAILS",
  SELECTED_INTG_DROPDOWN: "SELECTED_INTG_DROPDOWN",
  SET_ENRICH_CONFIG: "SET_ENRICH_CONFIG",
};
export default actions;
