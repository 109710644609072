import React from "react";
import { useIntl } from "react-intl";
import staticPlans from "./staticPlans";

export default function usePlans(plan, isCustom) {
  const intl = useIntl();

  let currentPlan = staticPlans({ intl, plan }).find(
    (pln) => pln.planId === plan.planId
  );
  if (isCustom) {
    currentPlan = currentPlan || plan;
    // currentPlan.planId = "custom";
  }
  return currentPlan;
}
