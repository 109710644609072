import { Pagination, SelectPicker, Tooltip } from "../../../../../components";
import DataNotFound from "../../../../../components/DataNotFound/DataNotFound";
import { injectIntl } from "react-intl";

import React, { useContext, useEffect, useState } from "react";
import {
  deleteBlock,
  getFlowInputs,
  getProfiles,
  getWorkflows,
} from "../../../../../services/api/workflows.api";
import { actions, WorkflowContext } from "../../../store/workflow.store";
import { Form, OverlayTrigger } from "react-bootstrap";
import DeleteWorkflowModal from "../../../common/DeleteWorkflowModal/DeleteWorkflowModal";
import { checkPermission } from "../../../../../services/utils";
import { toast } from "react-toastify";
import DeleteBlockModal from "../../../common/DeleteWorkflowModal/DeleteBlockModal";
import { FormattedMessage } from "react-intl";

function AutomationItem({
  automation,
  goToProfiles,
  setAutomationToRemove,
  intl,
}) {
  return (
    <tr>
      <td> {automation.name} </td>
      <td>
        <p>
          {automation.createdAt &&
            Intl.DateTimeFormat("fr-FR").format(new Date(automation.createdAt))}
        </p>
        <span>
          {" "}
          <FormattedMessage
            id={"workflow.byCreatedBy"}
            values={{ value: automation.createdBy }}
          />
        </span>
      </td>

      <td>
        <p>
          {automation.options.totalDelivered ? (
            automation.options.totalDelivered
          ) : automation.options.totalDelivered === 0 ? (
            0
          ) : (
            <FormattedMessage id={"pending"} />
          )}
          {automation.options.totalErrors !== undefined &&
            automation.options.totalErrors !== 0 &&
            automation.options.totalErrors > 0 && (
              <OverlayTrigger
                key={"automation-" + automation._id}
                placement={"top"}
                overlay={(props) =>
                  Tooltip({
                    ...props,
                    data:
                      automation.options.totalErrors +
                      intl.formatMessage({
                        id: "workflow.profileAlreadyExist",
                      }),
                  })
                }
              >
                <span
                  className="contact-more tip"
                  style={{
                    background: "#e53935",
                    color: "white",
                    cursor: "default",
                  }}
                >
                  {automation.options.totalErrors}
                </span>
              </OverlayTrigger>
            )}
        </p>
      </td>
      <td>
        <button className={"btn1"} onClick={() => goToProfiles(automation.id)}>
          <FormattedMessage id={"workflow.profiles"} />
        </button>
      </td>
      <td>
        <a
          href={automation.options.searchUrl}
          target={"_blank"}
          style={{ color: "#3b53d1", textDecoration: "underline" }}
          rel={"noopener noreferrer nofollow"}
        >
          <FormattedMessage id={"workflow.linkedinAutomation"} />{" "}
          <img src="/assets/images/link_icon.svg" alt="" />
        </a>
      </td>
      <td style={{ textAlign: "right" }}>
        <div
          className="delete-icon-btn"
          onClick={() => setAutomationToRemove(true)}
          style={{ marginRight: 50 }}
        >
          <span className="delete-icon">
            <img src="/assets/images/ic_delete_g.png" alt="" />
          </span>
        </div>
      </td>
    </tr>
  );
}
AutomationItem = injectIntl(AutomationItem);
export default function Automations({
  flowInputs,
  workflow,
  numberOfPages,
  profile,
}) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [automationToRemove, setAutomationToRemove] = useState(null);
  const [page, setPage] = useState(1);
  const handleGoToProfiles = (source) => {
    getProfiles({ workflow: workflow._id, inputBlocks: [source] }).then(
      ({ data }) => {
        dispatch({
          type: actions.GET_PROFILES,
          payload: { ...data, automationId: source },
        });
      }
    );
    document.querySelector("#profiles").click();
  };
  const handleGoToPage = (page) => {
    getFlowInputs({ workflow: workflow._id, page: page - 1 }).then(
      ({ data, statusCode }) => {
        if (statusCode === 200) {
          data.flowInputs.unshift({ name: "All", id: null });
          dispatch({ type: actions.GET_AUTOMATIONS, payload: data });
          setPage(page);
        }
      }
    );
  };
  if (flowInputs.length == 0) {
    return (
      <div id="automations_tab" className="tab-pane fade">
        <div className="table-wrap profile_detail_block">
          <div className="head_block">
            <div className="left_block">
              <div className={"title-box"}>
                <p>
                  {" "}
                  <FormattedMessage id={"workflow.automations"} />
                </p>
              </div>
            </div>
          </div>
          <DataNotFound
            image={"/assets/images/img_empty_no_search.png"}
            imgWith={300}
            style={{ margin: "20px 0 " }}
            elements={
              <div
                style={{ marginTop: "-10px", paddingBottom: 20 }}
                className="join-organization-inner join-organization-page"
              >
                <h5>
                  <FormattedMessage id={"workflow.noResultsFound"} />
                </h5>
                <p className="desc">
                  <FormattedMessage id={"workflow.yourSearchHasNoResults"} />
                </p>
              </div>
            }
          />
        </div>
      </div>
    );
  }
  const handleRemoveBlock = async (automation) => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Edit",
      })
    ) {
      const { data, statusCode } = await deleteBlock(automation);
      if (statusCode === 200) {
        getFlowInputs({ workflow: workflow._id, page: page - 1 }).then(
          ({ data, statusCode }) => {
            if (statusCode == 200) {
              data.flowInputs.unshift({ name: "All", id: null });
              dispatch({ type: actions.GET_AUTOMATIONS, payload: data });
              setAutomationToRemove(false);
            }
          }
        );
      }
    } else {
      setAutomationToRemove(false);
      toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
  };
  return (
    <div id="automations_tab" className="tab-pane fade">
      <div className="table-wrap profile_detail_block">
        <div className="head_block">
          <div className="left_block">
            <div className={"title-box"}>
              <p>
                {" "}
                <FormattedMessage id={"workflow.automations"} />
              </p>
            </div>
          </div>
          <div className="right_block">
            <div className={"title-box"}>
              <div className="cstm-pagination pagination_one">
                <Pagination
                  pages={numberOfPages}
                  currentPage={page}
                  goToPage={handleGoToPage}
                  top
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-content table-width-equal">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id={"workflow.AutomationName"} />
                </th>
                <th>
                  <FormattedMessage id={"workflow.launchedOn"} />{" "}
                </th>
                <th>
                  <FormattedMessage id={"workflow.deliveredLeads"} />
                </th>
                <th>
                  <FormattedMessage id={"workflow.results"} />
                </th>
                <th>
                  <FormattedMessage id={"workflow.link"} />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {flowInputs.map((automation, i) => (
                <AutomationItem
                  key={i}
                  automation={automation}
                  goToProfiles={handleGoToProfiles}
                  profile={profile}
                  automationToRemove={automationToRemove}
                  setAutomationToRemove={() =>
                    setAutomationToRemove(automation)
                  }
                />
              ))}
            </tbody>
          </table>
          {automationToRemove && (
            <DeleteBlockModal
              toggle={automationToRemove}
              setBlockToRemove={setAutomationToRemove}
              handleRemoveBlock={() => handleRemoveBlock(automationToRemove)}
              profilesCount={
                automationToRemove && automationToRemove.options.totalDelivered
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
