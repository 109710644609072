import React, { useState } from "react";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import "./PhoneField.css";
function PhoneField({ phone, onChange, id }) {
  const [internationalValue, setInternationalValue] = useState();
  const [editedPhone, setEditedPhone] = useState(null);
  const handleValue = (value) => {
    if (value) {
      let international = formatPhoneNumberIntl(value);
      let phoneArray = international.split(" ");
      const countryCode = phoneArray[0];
      phoneArray = phoneArray.filter((chunk) => chunk !== countryCode);
      const tel = {
        countryCode: countryCode || "",
        phoneNumber: phoneArray.join(" "),
      };
      setEditedPhone(phoneArray.join(""));
      setInternationalValue(countryCode);
      onChange(tel);
    }
  };
  const handleOnBlur = () => {
    onChange({
      countryCode: internationalValue,
      phoneNumber: phone.phoneNumber,
    });
  };
  const handleCountryChange = (value) => {
    if (value) {
      setInternationalValue("+ " + getCountryCallingCode(value));
      onChange({
        countryCode: "+ " + getCountryCallingCode(value),
        phoneNumber: phone.phoneNumber,
      });
    }
  };
  return (
    <div className={"phone-user-edit-container"}>
      <PhoneInput
        placeholder="..."
        value={phone ? phone.countryCode + " " + phone.phoneNumber : ""}
        defaultCountry={"FR"}
        onBlur={handleOnBlur}
        onChange={handleValue}
        onCountryChange={handleCountryChange}
      />
    </div>
  );
}

export default PhoneField;
