import React from "react";
import { FormattedMessage } from "react-intl";
import { CREDITS } from "../../../../constants";
import CoinUsage from "../CoinUsage/CoinUsage";

export default function ActivityItem({ item }) {
  return (
    <tr>
      <td style={{ width: "200px" }}>
        {item.createdat
          ? Intl.DateTimeFormat("us-US", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(item.createdat))
          : "--"}
      </td>
      <td>{item.email}</td>
      <td>
        {!item.source || item.source == "None" ? (
          "--"
        ) : (
          <FormattedMessage id={"organization.filters." + item.source} />
        )}
      </td>
      <td>{item.id}</td>
      <td className="spent-credit">
        <CoinUsage
          creditUsage={(item.isdecreased ? "-" : "+") + item.amount}
          type={item.type}
        />
      </td>
    </tr>
  );
}

/*
*
* {
    "_id": "6531324e80d1aa3f29c17faa",
    "credits": {
        "legacyCredits": {
            "amount": 0
        },
        "workEmailCredits": {
            "amount": 0
        },
        "personalEmailCredits": {
            "amount": 0
        },
        "phoneCredits": {
            "amount": 0
        },
        "exportCredits": {
            "amount": 108000,
            "createdAt": "2023-10-19T13:42:38.179Z"
        }
    },
    "infos": {
        "decreasedAnyway": false,
        "usageType": "upgrade",
        "integrationNames": [],
        "createdAt": "2023-10-19T13:42:38.178Z",
        "updatedAt": "2023-10-19T13:42:38.178Z"
    },
    "isDecreased": false,
    "organizationId": "65254de06cc10b6077313850",
    "intentId": "pi_3O2wUfJEQVJnnIbb1Y2joItO",
    "__v": 0,
    "email": null
}*/
