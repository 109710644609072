import cookie from "react-cookies";
import { BASE_URL } from "../../constants";
import { saveAsCookie } from "./index";

export async function convertToJsonAndHandleErrors(res) {
  const hasError = !res.ok;
  const statusCode = await res.status;
  let data = {};
  try {
    data = await res.json();
  } catch (err) {}
  if (hasError && data && data.message) {
    if (
      data.message == "jwt malformed" ||
      data.message == "Invalid token" ||
      data.message ==
        "You do not provide a JSON Web Token in the Authorization header" ||
      data.message == "This account was deleted" ||
      data.message == "Session is no longer valid"
      // || data.message == "This account has been deleted"
    ) {
      cookie.remove("access_token", { path: "/", domain: BASE_URL });
      window.location = "/";
    }
  }
  const token = cookie.load("access_token", { path: "/", domain: ".kaspr.io" });
  if (token) {
    saveAsCookie(token);
    cookie.remove("access_token", { path: "/", domain: ".kaspr.io" });
  }
  return { data, statusCode };
}
