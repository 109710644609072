import React, { useContext, useEffect, useState } from "react";
import "./FileUpload.css";
import { FormattedMessage } from "react-intl";
import Dropzone from "./Dropzone/Dropzone";
import PopUp from "../../../components/PopUp/PopUp";
import MappingField from "./MappingField/MappingField";
import EnrichmentOptions from "./EnrichmentOptions/EnrichmentOptions";
import { getLabels } from "../../../services/api/labels.api";
import { getRemainingCredits } from "../../../services/api/credits.api";
import { BulkEnrichmentContext } from "../store/bulkEnrichment.store";
import actions from "../store/bulkEnrichment.actions";
import { usePermissions } from "../../../components/Permissions/permissions.hooks";
import { Permissions } from "../../../components";
import PreLoader from "../../../components/PreLoader/PreLoader";

const FileUpload = ({ profile, credits }) => {
  const { state, dispatch } = useContext(BulkEnrichmentContext);
  const [launchesRemaining, setLaunchesRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [checkPlanPermissionsWk, checkMemberPermissionsWk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    "Workflow"
  );
  const [checkPlanPermissionsBulk, checkMemberPermissionsBulk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    "BulkEnrichment"
  );
  const handleCloseModal = () => {
    dispatch({
      type: actions.IS_MODAL_OPEN,
      payload: false,
    });
    dispatch({
      type: actions.SET_LINKEDIN_COLUMN,
      payload: "",
    });
    dispatch({
      type: actions.SET_FIRSTNAME_COLUMN,
      payload: "",
    });
    dispatch({
      type: actions.SET_LASTNAME_COLUMN,
      payload: "",
    });
    dispatch({
      type: actions.SET_SELECTED_LABEL,
      payload: [{ id: null, name: "Default list" }],
    });
    dispatch({
      type: actions.CURRENT_STEP,
      payload: 1,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getRemainingCredits();
        setLaunchesRemaining(data.remainingBulkEnrichmentLaunches);

        const labelsData = await getLabels();
        dispatch({
          type: actions.SET_LABELS,
          payload: labelsData.labels,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) return <PreLoader />;

  if (!checkPlanPermissionsBulk("View") || !checkMemberPermissionsWk("View"))
    return (
      <Permissions.View
        title="permissions.denied"
        description="permissions.noAccess"
      />
    );

  return (
    <div id="bulk-home" className={"file-upload-container"}>
      <div className={"file-upload-wrapper"}>
        <div className={"file-upload"}>
          <h1>
            <FormattedMessage id={"bulkEnrichment.uploadFiles.enrichYourCSV"} />
          </h1>
          {!checkPlanPermissionsBulk("Execute") ||
          !checkMemberPermissionsWk("Execute") ? (
            <Dropzone accessDenied={true} />
          ) : launchesRemaining === 0 ? (
            <Dropzone noRemainingLaunch={true} />
          ) : (
            <Dropzone />
          )}
        </div>
        <div className="info-credit">
          <img
            src={"/assets/images/ic-info.png"}
            alt={"info icon"}
            width={"15px"}
            height={"15px"}
          />
          <p>
            <FormattedMessage id="bulkEnrichment.uploadFiles.leadEnrichedCost" />
          </p>
        </div>
      </div>
      {state.isModalOpen && (
        <PopUp
          toggle={state.isModalOpen}
          onClose={handleCloseModal}
          style={{ width: "60vw", maxWidth: "55rem", height: "auto" }}
        >
          <div id="bulk-enrichment" className={"pop-up-container"}>
            {state.currentStep === 1 ? <MappingField /> : <EnrichmentOptions />}
          </div>
        </PopUp>
      )}
      {!isLoading && (
        <div className="launches-limits" style={{ width: "100%" }}>
          {launchesRemaining >= 5000 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <p className="infinite-limits">
                <FormattedMessage
                  id="bulkEnrichment.uploadFiles.launchesRemaining"
                  values={{
                    value: (
                      <i
                        className="fas fa-infinity"
                        style={{ fontSize: "11px" }}
                      ></i>
                    ),
                  }}
                />
              </p>
              <span
                className={"beta-badge"}
                style={{
                  fontSize: 14,
                  zIndex: 100,
                }}
              >
                BETA
              </span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <p>
                <FormattedMessage
                  id="bulkEnrichment.uploadFiles.launchesRemaining"
                  values={{ value: launchesRemaining }}
                />
              </p>
              <div
                className={"beta-badge"}
                style={{
                  fontSize: 14,
                  zIndex: 100,
                }}
              >
                BETA
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
