import { API_URL } from "../../constants";
import { convertToJsonAndHandleErrors, getToken } from "../utils";
export const exploreProfile = ({ id, name, type }) => {
  return fetch(`${API_URL}/linkedin/v2/explore`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
    body: JSON.stringify({ id, name, type }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getProfileData = ({ id, name, types, source }) => {
  return fetch(`${API_URL}/linkedin/v2/getEmails`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
    body: JSON.stringify({ id, name, types, source }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLead = ({ leads }) => {
  return fetch(`${API_URL}/organization/lead/add`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
    body: JSON.stringify({ leads }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
