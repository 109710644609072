import React from "react";
import { WorkflowContext } from "./workflow.store";
import { PropTypes } from "prop-types";

function WorkflowProvider({ children, store }) {
  return (
    <WorkflowContext.Provider value={store}>
      <main id="workflow">{children}</main>
    </WorkflowContext.Provider>
  );
}

WorkflowProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default WorkflowProvider;
