import React, { useContext, useEffect, useState } from "react";
import "./ProfileInfos.css";
import { FormattedMessage } from "react-intl";
import { EditableField } from "../../../../components";
import PhoneField from "./PhoneField/PhoneField";
import actions from "../../../../redux/actions";
import {
  getUser,
  updateImage,
  updateProfileImage,
  updateUser,
} from "../../../../services/api/auth.api";
import { SettingsContext } from "../../store/Settings.store";
import { connect } from "react-redux";
import NewPasswordModal from "./NewPasswordModal/NewPasswordModal";
import cookie from "react-cookies";

function ProfileInfos({ profile, resetProfile }) {
  const [toggle, setToggle] = useState(null);
  const [editedProfile, setEditedProfile] = useState(profile);
  const [imageBase64, setImageBase64] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const { state, dispatch } = useContext(SettingsContext);
  const fieldAreChanged =
    JSON.stringify(profile) == JSON.stringify(editedProfile);

  const onFieldsChange = ({ value, property }) => {
    const profileToEdit = Object.assign({}, editedProfile);
    profileToEdit[property] = value;
    setEditedProfile(profileToEdit);
  };
  const updateImage = async (e) => {
    let image = e.target.files[0];
    const acceptedImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    if (!(image && acceptedImageTypes.includes(image.type))) return;
    //  dispatch({ type: actions.EDIT_PROFILE, payload: { image } });
    setEditedProfile({ ...editedProfile, image });
    let file = e.target.files[0],
      reader = new FileReader();

    reader.onloadend = function () {
      setImageBase64(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onSave = async () => {
    await updateUser(editedProfile);
    //resetProfile(editedProfile);
    window.location = "/settings";
  };
  const onCancel = () => {
    setEditedProfile(profile);
    setImageBase64(profile.image);
  };

  return (
    <div className="content-box-ui user-edit-container">
      <div className="content-header p-16">
        <div className="title-ui">
          <label>
            <FormattedMessage id={"settings.profile.profile"} />
          </label>
        </div>
      </div>
      <div className={"profile-infos"}>
        <div className={"left-section"}>
          <div className={"profile-img"}>
            <img
              src={
                imageBase64 ||
                profile.image ||
                "assets/images/profile-placeholder.png"
              }
              alt=""
              style={imageBase64 || profile.image ? { width: "100%" } : {}}
            />
          </div>
          <label className={"change-picture"} htmlFor={"image"}>
            <i className="fas fa-camera"></i>{" "}
            <FormattedMessage id={"settings.profile.addPicture"} />
            <input
              type="file"
              id="image"
              style={{ display: "none" }}
              className="btn3 grey-btn"
              onChange={updateImage}
            />
          </label>
        </div>
        <div className={"right-section"}>
          <div className={"fields"}>
            <div className={"fields-form-group"}>
              <label htmlFor="">
                {" "}
                <FormattedMessage id={"info.firstName"} />
              </label>
              <div className={"edit-user-field capital"}>
                <EditableField
                  value={editedProfile.firstName}
                  onChange={onFieldsChange}
                  toggle={toggle}
                  setToggle={setToggle}
                  property={"firstName"}
                  toggleValue={"firstName"}
                  editIconStyle={{ right: 2, top: 0 }}
                />
              </div>
            </div>
            <div className={"fields-form-group"}>
              <label htmlFor="">
                <FormattedMessage id={"info.lastName"} />
              </label>
              <div className={"edit-user-field capital"}>
                <EditableField
                  value={editedProfile.lastName}
                  onChange={onFieldsChange}
                  toggle={toggle}
                  setToggle={setToggle}
                  property={"lastName"}
                  toggleValue={"lastName"}
                  editIconStyle={{ right: 2, top: 0 }}
                />
              </div>
            </div>
            <div className={"fields-form-group"}>
              <label htmlFor="">
                <FormattedMessage id={"info.job"} />
              </label>
              <div className={"edit-user-field capital"}>
                <EditableField
                  value={editedProfile.title}
                  onChange={onFieldsChange}
                  toggle={toggle}
                  setToggle={setToggle}
                  property={"title"}
                  toggleValue={"title"}
                  editIconStyle={{ right: 2, top: 0 }}
                />
              </div>
            </div>
            <div className={"fields-form-group capital"}>
              <label htmlFor="">
                <FormattedMessage id={"info.company"} />
              </label>
              <div className={"edit-user-field"}>
                <EditableField
                  value={editedProfile.company}
                  onChange={onFieldsChange}
                  toggle={toggle}
                  setToggle={setToggle}
                  property={"company"}
                  toggleValue={"company"}
                  editIconStyle={{ right: 2, top: 0 }}
                />
              </div>
            </div>
          </div>
          <div className={"fields"}>
            <div className={"fields-form-group"}>
              <label htmlFor="">
                <FormattedMessage id={"info.phone"} />
              </label>
              <div className={"edit-user-field"}>
                <PhoneField
                  onChange={(phoneValues) =>
                    onFieldsChange({ value: phoneValues, property: "phone" })
                  }
                  phone={{
                    countryCode: editedProfile.phone.countryCode || "",
                    phoneNumber: editedProfile.phone.phoneNumber || "",
                  }}
                />
                <span className={"edit_user-icon"}>
                  <img src="/assets/images/ic_edit_g.png" alt="" />
                </span>
              </div>
            </div>
            <div className={"fields-form-group"}>
              <label htmlFor="">
                <FormattedMessage id={"info.password"} />
              </label>
              <div className={"edit-user-field password-change"}>
                <p onClick={() => setShowPasswordModal(!showPasswordModal)}>
                  *********
                </p>

                <NewPasswordModal
                  showPasswordModal={showPasswordModal}
                  toggleShowPasswordModal={() =>
                    setShowPasswordModal(!showPasswordModal)
                  }
                />
              </div>
            </div>
            <div className={"fields-form-group capital"}>
              <label htmlFor="">Email</label>
              <p> {profile.email} </p>
            </div>
          </div>
        </div>
        {!fieldAreChanged && (
          <div className={"buttons-saveAndCancel"}>
            <button className={"btn btn2"} onClick={onCancel}>
              <FormattedMessage id={"cancel"} />
            </button>
            <button className={"btn btn1"} onClick={onSave}>
              <FormattedMessage id={"save"} />
            </button>
          </div>
        )}
      </div>{" "}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  resetProfile: () => {
    getUser().then(({ data }) => {
      dispatch({ type: actions.GET_USER, payload: data });
    });
  },
});

const mapStateToProps = (state) => ({ redux: state });
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfos);
