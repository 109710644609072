import { useEffect, useState, useRef } from "react";
import {
  getALLInvitations,
  getMembersOutsideWorkspace,
  inviteMember,
} from "../../../services/api/organization.api";
import { calculateLastActive, dataDrive } from "../../../services/utils/tools";
import { toast } from "react-toastify";

const useMembersListModal = ({
  organizationId,
  organization,
  organizationCredits,
  setPopUpToggle,
  subOrganizations,
}) => {
  const myRef = useRef();
  const [lastMemberIsVisible, setLastMemberIsVisible] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [invitationsSent, setInvitationsSent] = useState([]);
  let licencesToBy =
    organization.members.length + pendingInvitations.length + isCheck.length;
  const hasEnoughLicences =
    organizationCredits.plan.numberOfSeats >= licencesToBy;

  const fetchData = (payload) => {
    getMembersOutsideWorkspace(payload).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        const members = data.members.map((member) => ({
          ...member,
          lastActive: calculateLastActive(
            member.lastCreditSpendDate || member.clientDate
          ),
        }));
        setList([...list, ...members]);
        setPaginationData({
          totalMembers: data.totalMembers,
          totalAdmins: data.totalAdmins,
          resultPerPage: data.resultPerPage,
          pages: data.pages,
        });
        setPage(payload.page);
        setLoading(false);
      } else {
        setError(true);
      }
    });
  };

  useEffect(() => {
    getALLInvitations().then(({ data }) => {
      setPendingInvitations(data.invitations);
    });

    if (organizationId) {
      getMembersOutsideWorkspace({
        organizationId: organizationId,
        page: 1,
        resultPerPage: 10,
        search: "",
      }).then(({ data, statusCode }) => {
        if (statusCode === 200) {
          const members = data.members.map((member) => ({
            ...member,
            lastActive: calculateLastActive(
              member.lastCreditSpendDate || member.clientDate
            ),
          }));
          setList(members);
          setPaginationData({
            totalMembers: data.totalMembers,
            totalAdmins: data.totalAdmins,
            resultPerPage: data.resultPerPage,
            pages: data.pages,
          });
          setInitialLoad(false);
        } else {
          setInitialError(true);
          setInitialLoad(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      isCheck.length ===
      list.filter(
        (member) =>
          !pendingInvitations.find(
            (invitation) => invitation.invitee === member.email
          )
      ).length
    ) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [list, isCheck]);

  useEffect(() => {
    if (!organizationId) return;
    if (!initialLoad && lastMemberIsVisible) {
      if (paginationData.pages > page) {
        setLoading(true);
        fetchData({
          organizationId: organizationId,
          page: page + 1,
          resultPerPage: 10,
          search: "",
        });
      }
    }
  }, [lastMemberIsVisible]);

  useEffect(() => {
    if (!organizationId) return;
    if (!initialLoad) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setLastMemberIsVisible(entry.isIntersecting);
      });
      if (observer.current) {
        observer.disconnect();
      }

      if (myRef.current && organizationId !== "none")
        observer.observe(myRef.current);
    }
  }, [initialLoad, page]);
  const handleSelectAll = (e) => {
    let newList = [...list];

    newList = newList.filter(
      (li) =>
        !pendingInvitations.find((invitation) => invitation.invitee == li.email)
    );

    setIsCheckAll(!isCheckAll);
    setIsCheck(newList.map((li) => li._id));
    setSelectedMembers(newList);
    if (isCheckAll) {
      setIsCheck([]);
      setSelectedMembers([]);
    }
  };
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    let selectedMembersList = [...selectedMembers];
    list.forEach((member) => {
      if (member._id === id) selectedMembersList.push(member);
    });
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      list.forEach((member) => {
        if (member._id === id)
          selectedMembersList = selectedMembersList.filter(
            (member) => member._id !== id
          );
      });
    }
    setSelectedMembers(selectedMembersList);
  };
  const handleAddLicences = async () => {
    let membersToInvite = {
      organizationId: organization._id,
      memberSettings: {
        type: "member",
        permissions: [
          {
            name: "Leads",
            actions: ["View", "Edit", "Save", "Export"],
          },
          {
            name: "Workflow",
            actions: ["View", "Edit", "Execute"],
          },
          {
            name: "Integration",
            actions: ["Export", "Mapping", "Sync"],
          },
        ],
        limit: {
          legacyCredits: -1,
          workEmailCredits: -1,
          personalEmailCredits: -1,
          phoneCredits: -1,
          exportCredits: -1,
        },
      },
    };
    membersToInvite.invitees = selectedMembers.map((member) => member.email);
    if (hasEnoughLicences) {
      const { data, statusCode } = await inviteMember(membersToInvite);
      if (statusCode === 200) {
        let invitations = [];
        data.invitations.forEach((invitation) => {
          const member = selectedMembers.find(
            (member) => member.email === invitation.invitee
          );
          invitations.push(member);
        });
        setInvitationsSent(invitations);
      } else {
        setError(data.message);
        toast.error(data.message);
        setPopUpToggle(false);
      }
    } else {
      dataDrive({
        to: "/billing",
        key: "invitees",
        state: membersToInvite.invitees,
      });
      dataDrive({
        to: "/billing",
        key: "licences",
        state: licencesToBy - organizationCredits.plan.numberOfSeats,
      });
    }
  };

  return {
    invitationsSent,
    hasEnoughLicences,
    myRef,
    lastMemberIsVisible,
    isCheckAll,
    isCheck,
    list,
    loading,
    initialLoad,
    error,
    initialError,
    paginationData,
    page,
    pendingInvitations,
    fetchData,
    handleSelectAll,
    handleClick,
    handleAddLicences,
  };
};

export default useMembersListModal;
