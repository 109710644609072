import React, { useContext, useEffect, useRef, useState } from "react";
import {
  createOrganization,
  deleteMember,
  deleteOrganization,
  getOrganization,
  leaveOrganization,
  updateOrganization,
} from "../../../../services/api/organization.api";
import { Modal } from "react-bootstrap";
import InputTags from "../../../../components/InputTags/InputTags";
import { SelectPicker } from "../../../../components";
import { actions, OrganizationContext } from "../../store/Organization.store";
import { FormattedMessage } from "react-intl";

export default function DeleteMember({
  toggle,
  setToggle,
  member,
  isLeaveOrganization,
}) {
  const { state, dispatch } = useContext(OrganizationContext);
  const [error, setError] = useState(false);

  const handleDeleteMember = async () => {
    const { data: deleteData, statusCode } = isLeaveOrganization
      ? await leaveOrganization()
      : await deleteMember({ memberToDelete: member.email });
    if (statusCode === 204 || statusCode === 200) {
      getOrganization().then(({ data, statusCode: status }) => {
        if (status == 200) {
          dispatch({ type: actions.GET_ORGANIZATION, payload: data });
          setToggle(false);
        } else {
          setError(data.messageTranslated || data.message);
        }
      });
    } else {
      setError(deleteData.message);
    }
    if (isLeaveOrganization && statusCode === 200) window.location = "/home";
  };
  return (
    <Modal
      show={toggle}
      onHide={() => setToggle(false)}
      className=" fade  delete-member-wrapper"
      id="delete-member"
    >
      <Modal.Header closeButton className="modal-header border-0">
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={"organization.remove"} /> "
            {member.firstName + " " + member.lastName} ?"
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-0">
        <div className="unsubscribe-type-list p-24">
          {isLeaveOrganization ? (
            <p>
              <FormattedMessage
                id={"organization.areYouSureYouWantToLeaveTheOrganization"}
              />
            </p>
          ) : (
            <p>
              <FormattedMessage
                id={"organization.areYouSureYouWantToRemove_"}
              />
              <strong>{member.firstName + " " + member.lastName}?</strong>
              <FormattedMessage
                id={"organization.theUserWillNoLongerHaveAccess_"}
              />{" "}
            </p>
          )}
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">
          {error + " "}
        </div>
      )}
      <div className="modal-footer border-0">
        <a href="#" className="btn3 btn-md" onClick={() => setToggle(false)}>
          <FormattedMessage id={"cancel"} />
        </a>
        <a href="#" className="btn1 btn-md ml-20" onClick={handleDeleteMember}>
          <FormattedMessage id={"organization.confirmRemoval"} />
        </a>
      </div>
    </Modal>
  );
}
