import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  bulkEnrichmentReducer,
  bulkEnrichmentInitialState,
} from "./store/bulkEnrichment.store";
import BulkEnrichmentProvider from "./store/bulkEnrichment.provider";
import { FormattedMessage } from "react-intl";
import { Header, ContentWrapperUi, SideNav } from "../../components";
import BulkEnrichmentNav from "./common/BulkEnrichmentNav/BulkEnrichmentNav";
import Files from "./Files/Files";
import FileUpload from "./FileUpload/FileUpload";
import BulkEnrichmentWrapper from "./common/BulkEnrichmentWrapper/BulkEnrichmentWrapper";
import { actions } from "./store/bulkEnrichment.store";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import { NavLink } from "react-router-dom";

function BulkEnrichment(props) {
  const [state, dispatch] = useReducer(
    bulkEnrichmentReducer,
    bulkEnrichmentInitialState
  );
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    const path = props.location.pathname;
    if (
      path === "/bulk-enrichment" ||
      path === "/bulk-enrichment/file-upload"
    ) {
      setCurrentTab("upload-file");
    } else {
      setCurrentTab("files");
    }
  }, []);

  if (!props.redux.organization.organization) {
    return (
      <BulkEnrichmentProvider store={store}>
        <Header
          currentPage={<FormattedMessage id={"sidenav.bulkEnrichment"} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          organization={props.redux.organization}
        />
        <ContentWrapperUi>
          <SideNav />
          <BulkEnrichmentWrapper>
            <DataNotFound
              image={"/assets/images/img_empty_no_leads.png"}
              elements={
                <div
                  style={{ marginTop: "0" }}
                  className="join-organization-inner join-organization-page"
                >
                  <h4>
                    <FormattedMessage
                      id={"organization.youHavetoCreateAnOrganization"}
                    />
                  </h4>
                  <p className="desc">
                    <FormattedMessage
                      id={"organization.youHavetoCreateAnOrganization.text"}
                    />
                  </p>
                  <button className={"btn1"}>
                    <NavLink
                      to={"/workspace"}
                      style={{ color: "white" }}
                      data-tracking-id={"leads.createOrganization"}
                    >
                      <FormattedMessage
                        id={"organization.createOrganization"}
                      />
                    </NavLink>
                  </button>
                </div>
              }
            />
          </BulkEnrichmentWrapper>
        </ContentWrapperUi>
      </BulkEnrichmentProvider>
    );
  }

  return (
    <BulkEnrichmentProvider store={store}>
      <Header
        currentPage={<FormattedMessage id={"sidenav.bulkEnrichment"} />}
        profile={props.redux.profile}
        credits={props.redux.credits}
        remaining={props.redux.credits.remaining}
        organization={props.redux.organization}
      />
      <ContentWrapperUi>
        <SideNav />
        <BulkEnrichmentWrapper>
          <BulkEnrichmentNav
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {currentTab === "files" && (
            <Files
              profile={props.redux.profile}
              credits={props.redux.credits}
            />
          )}
          {currentTab === "upload-file" && (
            <FileUpload
              profile={props.redux.profile}
              credits={props.redux.credits}
            />
          )}
        </BulkEnrichmentWrapper>
      </ContentWrapperUi>
    </BulkEnrichmentProvider>
  );
}

export default BulkEnrichment;
