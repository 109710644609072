import { FormattedMessage, useIntl } from "react-intl";
import { InfoToolTip } from "../index";
import { Row } from "react-bootstrap";
import ColleaguesCard from "./ColleaguesCard/ColleaguesCard";
import MembersListModal from "./MembersListModal/MembersListModal";
import React, { useEffect, useState } from "react";
import { getALLInvitations } from "../../services/api/organization.api";
import "./WorkspacesCards.css";
/**
 * WORKSPACES CARDS
 * @param subOrganizations {Array} list of workspaces
 * @param organization {Object} user workspace
 * @param credits {Object} credits and payment info (contains current plan)
 * @returns {JSX.Element}
 * @constructor
 */
export default function WorkspacesCards({
  subOrganizations,
  organization,
  credits,
}) {
  const [position, setPosition] = useState("");
  const [popUpToggle, setPopUpToggle] = useState(false);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [cardsToHide, setCardsToHide] = useState([]);
  const [cardsContainerAnimation, setCardsContainerAnimation] = useState("");
  const intl = useIntl();

  useEffect(() => {
    const element = document.querySelector(".cards-container");
    if (element && cardsToHide.length > 0) {
      handleFixHeadOnScroll(element)();
      document.addEventListener("scroll", handleFixHeadOnScroll(element));
    } else {
      setPosition("");
    }
    return () => {
      document.removeEventListener("scroll", handleFixHeadOnScroll(element));
    };
  }, [cardsToHide]);

  const handleFixHeadOnScroll = (element) => {
    return () => {
      if (window.scrollY > element.offsetTop - 20) {
        setPosition("fix-head");
      } else {
        setPosition("");
      }
    };
  };
  useEffect(() => {
    getALLInvitations().then(({ data }) => {
      setPendingInvitations(data.invitations);
    });
  }, []);
  const hideCard = (card) => {
    setCardsToHide([...cardsToHide, card._id]);
  };
  const showCards = () => {
    setCardsToHide([]);
    setTimeout(() => {
      setCardsContainerAnimation("animate-card-container");
      setTimeout(() => {
        setCardsContainerAnimation("");
      }, 400);
    }, 200);
  };
  if (
    !subOrganizations ||
    subOrganizations.message === "UNSUBSCRIBED_ORGANIZATION"
  ) {
    return null;
  }
  return (
    <div className={"workspaces-container"}>
      <div className={"sub_org_cards_container"}>
        <h2
          className={"cards-title " + (cardsToHide.length > 0 ? position : "")}
        >
          <span>
            <FormattedMessage id={"organizations.weFoundOutColleagues"} />{" "}
            <InfoToolTip
              text={intl.formatMessage({
                id: "organizations.weFoundOutColleaguesToolTip",
              })}
              position={cardsToHide.length ? "right" : "top"}
            />
          </span>
          {cardsToHide.length > 0 && (
            <button className={"btn btn2"} onClick={showCards}>
              <FormattedMessage id={"organizations.showHiddenCards"} />
            </button>
          )}
        </h2>
        <div className={"cards-container " + cardsContainerAnimation}>
          <Row>
            {subOrganizations.nonMembers &&
              subOrganizations.nonMembers.length > 0 &&
              subOrganizations.nonMembers.map((member, key) => (
                <ColleaguesCard
                  key={key}
                  member={member}
                  organization={organization}
                  pendingInvitations={pendingInvitations}
                  organizationCredits={credits && credits.organizationCredits}
                  cardsToHide={cardsToHide}
                  setPopUpToggle={setPopUpToggle}
                  onHide={hideCard}
                  isNotMember
                />
              ))}
            {subOrganizations &&
              subOrganizations.organizations &&
              subOrganizations.organizations.length > 0 &&
              subOrganizations.organizations.map((subOrganization, key) => (
                <ColleaguesCard
                  key={key}
                  subOrganization={subOrganization}
                  organization={organization}
                  organizationCredits={credits && credits.organizationCredits}
                  setPopUpToggle={setPopUpToggle}
                  pendingInvitations={pendingInvitations}
                  onHide={hideCard}
                  cardsToHide={cardsToHide}
                  member={
                    subOrganization.members.length === 1
                      ? subOrganization.members[0]
                      : false
                  }
                />
              ))}
            {popUpToggle && (
              <MembersListModal
                organizationId={popUpToggle}
                popUpToggle={popUpToggle}
                organization={organization}
                organizationCredits={credits && credits.organizationCredits}
                setPopUpToggle={setPopUpToggle}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
