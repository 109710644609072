import React, { useEffect, useRef, useState } from 'react';
import './ComparePlans.css';
import { checkPermission, pricePlanCalc } from '../../../../services/utils';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';
import Faq from './Faq/Faq';
export default function ComparePlans({
  plans,
  toggle,
  onSelectPlan,
  organizationCredits,
  subscriptionType,
  customPlan,
}) {
  const [position, setPosition] = useState('');

  useEffect(() => {
    const element = document.querySelector('#compare-plans');
    if (element) {
      document.addEventListener('scroll', (event) => {
        if (window.scrollY > element.offsetTop) {
          setPosition('fix-head');
        } else {
          setPosition('');
        }
      });
    }
  }, [toggle]);

  return (
    toggle && (
      <PlansTable
        plans={plans}
        onSelectPlan={(plan) => {
          onSelectPlan({ ...plan, yearly: subscriptionType == 'yearly' });
        }}
        headPosition={position}
        currentPlan={organizationCredits && organizationCredits.plan}
        subscriptionType={subscriptionType}
        customPlan={customPlan}
      />
    )
  );
}

function PlansTable({
  plans,
  onSelectPlan,
  headPosition,
  currentPlan,
  subscriptionType,
  customPlan,
}) {
  return (
    <div id={'compare-plans'}>
      <div className={'plan-info-container'}>
        <div
          className={'head-wrapper ' + headPosition + (!!customPlan ? ' custom-item-head ' : ' ')}
        >
          <div className={'itm-head itm bdy-action-name'}></div>
          <div className={'itm-head-wrapper '}>
            <div className={'top-gradient-line compare'} />
            <div className={'itm-choose-plan '}>
              {[...(customPlan ? [customPlan] : plans)].map((plan, i) => (
                <div className={'itm-head itm '} key={i}>
                  <h4>{plan.name}</h4>{' '}
                  <div className={'plan-btn-wrapper plan-card-content'}>
                    {currentPlan && !customPlan && currentPlan.planId === plan.planId ? (
                      <button className="plan-btn current">
                        <FormattedMessage id={'billing.currentPlan'} />
                      </button>
                    ) : (
                      <button className={'plan-btn'} onClick={() => onSelectPlan(plan)}>
                        <FormattedMessage id={'billing.choosePlan'} />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {plans[3].permissions.map((permission, i) => {
          // Filter out unnecessary permissions
          if (
            [
              'TeamActivity',
              'TeamReports',
              'Tags',
              'PublicApi',
              'Sequence',
              'Tasks',
              'Notes',
              'WaitingList',
            ].indexOf(permission.name) > -1
          ) {
            return null; // Skip rendering this permission if it's in the excluded list
          }

          // Check if there are any meaningful actions to display
          const hasContent = permission.actions.some((action) => {
            // Ensure action is significant and not filtered out
            return (
              action !== 'Gmail' &&
              action !== 'LinkedinMessages' &&
              action !== 'LinkedinInvitations'
            );
          });

          if (!hasContent) {
            return null; // Do not render the row if there are no significant actions
          }

          return (
            <div className={'bdy-wrapper ' + (!!customPlan ? ' custom-bdy-wrapper ' : ' ')} key={i}>
              <div className={'bdy-itm-wrapper'}>
                <div className={'permission-name itm'}>
                  <FormattedMessage id={'billing.permissions.' + permission.name} />
                </div>
              </div>
              {permission.name === 'MemberPermission' && (
                <>
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'workEmailCreditsPerSeat'}
                    tid={'workEmailCredits'}
                    isAnnual={subscriptionType === 'yearly'}
                    isCredit
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'phoneCredits'}
                    tid={'phoneCredits'}
                    isAnnual={subscriptionType === 'yearly'}
                    isCredit
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'personalEmailCredits'}
                    tid={'personalEmailCredits'}
                    isAnnual={subscriptionType === 'yearly'}
                    isCredit
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'exportCredits'}
                    tid={'exportCredits'}
                    isAnnual={subscriptionType === 'yearly'}
                    isCredit
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'isCumulative'}
                    tid={'creditsRollOver'}
                    positive={<FormattedMessage id={'billing.yes'} />}
                    negative={<FormattedMessage id={'billing.no'} />}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'maxAdmins'}
                    tid={'adminRole'}
                    tooltipId={'billing.plan.adminRoleTooltip'}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'MemberPermission'}
                    permission={'MemberPermission'}
                    action={'Edit'}
                    positive={<FormattedMessage id={'billing.customPermissions'} />}
                    negative={<FormattedMessage id={'billing.notCustomizable'} />}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'TeamActivity'}
                    permission={'TeamActivity'}
                    showActions={true}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'TeamReports'}
                    permission={'TeamReports'}
                    showActions={true}
                  />
                </>
              )}
              {permission.name === 'Workflow' && (
                <>
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'numberOfWorkflow'}
                    tid={'enrichmentWorkflowB2b'}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'numberOfWorkflowLaunches'}
                    tid={'workflowLaunchesPerMonth'}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'contactPerLaunch'}
                    tid={'enrichmentPerSearch'}
                  />
                </>
              )}

              {permission.actions.map((action, i) => {
                // Skip certain actions that are not meant to be displayed
                if (
                  permission.name === 'MemberPermission' ||
                  permission.name === 'Workflow' ||
                  action === 'Allwebsites'
                )
                  return null;
                if (
                  permission.name === 'SupportedAutomationTypes' &&
                  (action === 'Linkedin' || action === 'SalesNavigator' || action === 'Recruiter')
                ) {
                  return null;
                }

                if (permission.name === 'BulkEnrichment') return null;
                if (
                  action === 'LinkedinPost' ||
                  action === 'Gmail' || // Skip Gmail row
                  action === 'LinkedinMessages' || // Skip Messages row
                  action === 'LinkedinInvitations' // Skip Invitations row
                )
                  return null;

                return (
                  <div className={'bdy-itm-wrapper line'} key={i}>
                    <div className={'bdy-action-name itm'}>
                      <FormattedMessage id={'billing.actions.' + action} />
                    </div>

                    {[...(customPlan ? [customPlan] : plans)].map((plan, indx) => {
                      if (
                        plan.planId === '0' &&
                        permission.name === 'SupportedWebsites' &&
                        (action === 'SalesNavigator' || action === 'Recruiter')
                      ) {
                        return (
                          <div className={'bdy-permission itm'} key={indx}>
                            <FormattedMessage id={'billing.no'} />
                          </div>
                        );
                      }
                      if (
                        plan.planId === 'plan_1' &&
                        permission.name === 'SupportedWebsites' &&
                        action === 'Recruiter'
                      ) {
                        return (
                          <div className={'bdy-permission itm'} key={indx}>
                            <FormattedMessage id={'billing.no'} />
                          </div>
                        );
                      }
                      if (permission.name === 'Support' && plan.planId !== 'plan_3') {
                        return (
                          <div className={'bdy-permission itm'} key={indx}>
                            <FormattedMessage id={'billing.no'} />
                          </div>
                        );
                      }
                      if (
                        permission.name === 'Integration' &&
                        action === 'Zapier' &&
                        plan.planId === '0'
                      ) {
                        return (
                          <div className={'bdy-permission itm'} key={indx}>
                            <FormattedMessage id={'billing.no'} />
                          </div>
                        );
                      }
                      return (
                        <div className={'bdy-permission itm'} key={indx}>
                          {checkPermission({
                            permissions: plan.permissions,
                            name: permission.name,
                            action,
                          }) ? (
                            <FormattedMessage
                              id={action === 'Gmail' ? 'workflow.comingSoon' : 'billing.yes'}
                            />
                          ) : (
                            <FormattedMessage
                              id={action === 'Gmail' ? 'workflow.comingSoon' : 'billing.yes'}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {permission.name === 'Leads' && (
                <>
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'maxLists'}
                    tid={'maxLists'}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'Tags'}
                    permission={'Tags'}
                    positive={<FormattedMessage id={'billing.yes'} />}
                    negative={<FormattedMessage id={'billing.no'} />}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'Tasks'}
                    permission={'Tasks'}
                    positive={<FormattedMessage id={'billing.yes'} />}
                    negative={<FormattedMessage id={'billing.no'} />}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'Notes'}
                    permission={'Notes'}
                    positive={<FormattedMessage id={'billing.yes'} />}
                    negative={<FormattedMessage id={'billing.no'} />}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    tid={'WaitingList'}
                    permission={'WaitingList'}
                    positive={<FormattedMessage id={'billing.yes'} />}
                    negative={<FormattedMessage id={'billing.no'} />}
                    tooltipId={'billing.permissions.WaitingListTooltio'}
                  />
                </>
              )}
              {permission.name === 'BulkEnrichment' && (
                <>
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'bulkEnrichmentLimits'}
                    secondObjectKey={'perMonth'}
                    tid={'bulkEnrichmentLaunchesPerMonth'}
                  />
                  <GetPlanParams
                    plans={customPlan ? [customPlan] : plans}
                    objectKey={'contactPerLaunch'}
                    tid={'enrichmentPerSearch'}
                  />
                </>
              )}
              {permission.name === 'SupportedWebsites' && (
                <GetPlanParams
                  plans={customPlan ? [customPlan] : plans}
                  customTid={'billing.apiAccessUponRequest'}
                  tid={'PublicApi'}
                  permission={'PublicApi'}
                  positive={<FormattedMessage id={'billing.yes'} />}
                  negative={<FormattedMessage id={'billing.no'} />}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function GetPlanParams({
  plans,
  objectKey,
  tid,
  permission,
  positive,
  negative,
  action,
  showActions,
  tooltipId,
  isAnnual,
  isCredit,
  customTid,
  secondObjectKey,
}) {
  if (showActions) {
    return (
      <div className={'bdy-itm-wrapper line'}>
        <div className={'bdy-action-name itm'}>
          {customTid ? (
            <FormattedMessage id={customTid} />
          ) : (
            <FormattedMessage id={'billing.permissions.' + tid} />
          )}{' '}
          {tooltipId && (
            <CustomToolTip element={<FormattedMessage id={tooltipId} />}>
              <img src="/assets/images/icon-info.svg" alt="" />
            </CustomToolTip>
          )}
        </div>
        {plans.map((plan) => {
          let currentPermission = plan.permissions.find((p) => p.name === permission);
          let actions = currentPermission && currentPermission.actions.join(' & ');
          return <div className={'bdy-permission itm'}>{actions || '--'}</div>;
        })}
      </div>
    );
  }
  if (positive && negative && permission) {
    return (
      <div className={'bdy-itm-wrapper line'}>
        <div className={'bdy-action-name itm'}>
          {customTid ? (
            <FormattedMessage id={customTid} />
          ) : (
            <FormattedMessage id={'billing.permissions.' + tid} />
          )}
          {tooltipId && (
            <CustomToolTip element={<FormattedMessage id={tooltipId} />} style={{ width: 250 }}>
              <img src="/assets/images/icon-info.svg" alt="" />
            </CustomToolTip>
          )}
        </div>
        {plans.map((plan, i) => {
          let currentPermission = plan.permissions.find((p) => p.name === permission);
          if (permission === 'PublicApi') {
            return (
              <div className={'bdy-permission itm'} key={i}>
                {i === 0 ? negative : positive}
              </div>
            );
          }

          return (
            <div className={'bdy-permission itm'} key={i}>
              {!!currentPermission ? positive : negative}
            </div>
          );
        })}
      </div>
    );
  }
  if (permission) {
    return (
      <div className={'bdy-itm-wrapper line'}>
        <div className={'bdy-action-name itm'}>
          {customTid ? (
            <FormattedMessage id={customTid} />
          ) : (
            <FormattedMessage id={'billing.plan.' + tid} />
          )}{' '}
          {tooltipId && (
            <CustomToolTip element={<FormattedMessage id={tooltipId} />} style={{ width: 250 }}>
              <img src="/assets/images/icon-info.svg" alt="" />
            </CustomToolTip>
          )}
        </div>
        {plans.map((plan) => {
          let currentPermission = plan.permissions.find((p) => p.name === permission);
          let isActionAllowed = currentPermission && currentPermission.actions.indexOf(action) > -1;
          return (
            <div className={'bdy-permission itm'}>{isActionAllowed ? positive : negative}</div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={'bdy-itm-wrapper line'}>
      <div className={'bdy-action-name itm'}>
        <FormattedMessage id={'billing.plan.' + tid} />{' '}
        {tooltipId && (
          <CustomToolTip element={<FormattedMessage id={tooltipId} />} style={{ width: 250 }}>
            <img src="/assets/images/icon-info.svg" alt="" />
          </CustomToolTip>
        )}
      </div>
      {plans.map((plan, i) => {
        return (
          <div className={'bdy-permission itm'} key={i}>
            {(() => {
              if (typeof plan[objectKey] == 'boolean') {
                return plan[objectKey] ? positive : negative;
              }
              if (objectKey && secondObjectKey) {
                return plan[objectKey][secondObjectKey];
              }
              if (plan[objectKey] === -1 || plan[objectKey] === 10000) {
                return 'Unlimited';
              }
              if (plan.planId == '0' && objectKey == 'maxAdmins') {
                return '--';
              }
              if (
                (isAnnual || plan.planId == 'plan_3') &&
                objectKey !== 'maxAdmins' &&
                objectKey !== 'contactPerLaunch' &&
                plan.planId !== '0'
              ) {
                return plan[objectKey] * 12;
              }
              return plan[objectKey];
            })()}
          </div>
        );
      })}
    </div>
  );
}
