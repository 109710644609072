import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";
import { FormattedMessage } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import { BulkEnrichmentContext } from "../../store/bulkEnrichment.store";
import actions from "../../store/bulkEnrichment.actions";

function Dropzone({ accessDenied, noRemainingLaunch }) {
  const { dispatch } = useContext(BulkEnrichmentContext);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const firstLineRegex = /^(.*?)(\r\n|\n|$)/;
  const isDisabled = accessDenied || noRemainingLaunch;

  const handleDrop = (event) => {
    if (isDisabled) {
      if (accessDenied)
        toast.error(<FormattedMessage id={"permissions.noAccess"} />);
      if (noRemainingLaunch)
        toast.error(
          <FormattedMessage
            id={"bulkEnrichment.uploadFiles.noLaunchesRemaining"}
          />
        );
      event.preventDefault();
    }
  };

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const getHoverMessage = () => {
    if (accessDenied || noRemainingLaunch) {
      return (
        <div className="hover-container">
          <div className="hover-message">
            {accessDenied ? (
              <FormattedMessage id="permissions.denied" />
            ) : (
              <FormattedMessage id="bulkEnrichment.uploadFiles.noLaunchesRemaining" />
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const { getRootProps, getInputProps } = useDropzone(
    {
      accept: {
        "text/csv": [".csv"],
      },

      disabled: isDisabled,

      onDragEnter: () => {
        if (!accessDenied && !noRemainingLaunch) setIsDragActive(true);
      },

      onDragLeave: () => {
        if (!accessDenied && !noRemainingLaunch) setIsDragActive(false);
      },

      onDrop: (acceptedFiles, rejectedFiles) => {
        setIsDragActive(false);
        if (rejectedFiles.length > 0) {
          toast.error(
            <FormattedMessage
              id={"bulkEnrichment.uploadFiles.invalidFileType"}
            />
          );
          return;
        }

        const file = acceptedFiles[0];

        // Check file size (2MB = 2 * 1024 * 1024 bytes)
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error(
            <FormattedMessage id={"bulkEnrichment.uploadFiles.fileTooLarge"} />
          );
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          if (!reader.result) {
            toast.error(
              <FormattedMessage
                id={"bulkEnrichment.uploadFiles.csvFileCantBeRead"}
              />
            );
            return;
          }
          // Check if no headers
          const firstLine = reader.result.split(/\r\n|\n|\r/)[0];
          if (firstLine.trim().length === 1) {
            toast.error(
              <FormattedMessage
                id={"bulkEnrichment.uploadFiles.noHeadersFound"}
              />
            );
            return;
          }
          // Check Headers
          const matches = reader.result.match(firstLineRegex);
          if (matches && matches[1]) {
            const headers = matches[1].split(",");
            dispatch({
              type: actions.SET_HEADERS,
              payload: headers,
            });
          }
          dispatch({
            type: actions.CSV_CONTENT,
            payload: file,
          });
          dispatch({
            type: actions.IS_MODAL_OPEN,
            payload: true,
          });
        };

        reader.readAsText(file);
      },

      multiple: false,
    },
    []
  );

  const handleDropzoneClick = (event) => {
    if (accessDenied || noRemainingLaunch) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const dropzoneProps = isDisabled
    ? { ...getRootProps(), onDrop: handleDrop }
    : { ...getRootProps() };

  return (
    <div
      className={`dropzone-container ${
        accessDenied || noRemainingLaunch ? "access-denied" : ""
      }`}
      onMouseDown={handleDropzoneClick}
      {...dropzoneProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ToastContainer />
      <input className={"dropzone"} {...getInputProps()} />

      <div
        className={`dropzone-content ${isDragActive ? "is-drag-active" : ""}`}
      >
        <img
          src={"/assets/images/upload-csv.png"}
          width={50}
          alt={"csv logo"}
        />
        <h2>
          <FormattedMessage id={"bulkEnrichment.uploadFiles.uploadCSVFile"} />
        </h2>
        <p>
          <FormattedMessage
            id={"bulkEnrichment.uploadFiles.dragAndDropYourFiles"}
          />
        </p>
        {isHovering && getHoverMessage()}
      </div>
    </div>
  );
}

export default Dropzone;
