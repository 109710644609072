import React, { useContext, useEffect, useRef, useState } from "react";
import {
  approveJoinRequest,
  getOrganization,
  inviteMember,
  updateMemberSettings,
} from "../../../../services/api/organization.api";
import { getRemainingCredits } from "../../../../services/api/credits.api";
import { Form, Modal } from "react-bootstrap";
import { actions, OrganizationContext } from "../../store/Organization.store";
import { FormattedMessage, useIntl } from "react-intl";
import CustomToolTip from "../../../../components/CustomToolTip/CustomToolTip";
import { CREDITS } from "../../../../constants";
import InputEmailTagsLight from "../../../../components/InputEmailTagsLight/InputEmailTagsLight";
import { dataDrive } from "../../../../services/utils/tools";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./AddEditMemberPopUp.css";

export default function AddEditMemberPopUp({
  setAddMemberModal,
  organization,
  member,
  joinRequestMember,
  isAdd,
  isJoin,
  isEdit,
  isMemberAdmin,
  updateInvitations,
  resetRemaining,
  remainingCredits,
  remainingInviations,
  subscription,
  checkPlanPermissions,
  isVersion2,
  organizationCredits,
}) {
  const [error, setError] = useState(false);
  const inputRef = useRef(null);
  const [isAdmin, setRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monthlySpent, setMonthlySpent] = useState(0);
  const [monthlyLimit, setMonthlyLimit] = useState(
    (member && member.limit) || remainingCredits.limit
  );

  const intl = useIntl();

  const [monthlyRemaining, setMonthlyRemaining] = useState(null);
  const { state, dispatch } = useContext(OrganizationContext);
  const [invitees, setInvitees] = useState([]);
  const [tags, setTags] = useState([]);

  const [memberInfos, setMemberInfos] = useState({
    invitee: "",
    organizationId: organization._id,
    memberSettings: {
      type: "member",
      permissions: [
        { name: "Leads", actions: ["View", "Edit", "Save", "Export"] },
        { name: "Workflow", actions: ["View", "Edit", "Execute"] },
        { name: "Integration", actions: ["Export", "Mapping", "Sync"] },
      ],
      limit: monthlyLimit,
    },
    clientDate: new Date(Date.now()),
  });

  const isFreePlan =
    organizationCredits &&
    organizationCredits.plan &&
    organizationCredits.plan.planId === "0";

  const history = useHistory();

  useEffect(() => {
    if (member) {
      memberInfos.memberSettings.type =
        member.type === "owner" ? "admin" : member.type;
      setRole(memberInfos.memberSettings.type === "admin");
      const permissions = member.permissions.filter(
        (permission) => permission.name !== "Administration"
      );
      memberInfos.memberSettings.permissions = permissions;
      memberInfos.memberSettings.limit = member.limit;
      setMemberInfos({ ...memberInfos, invitee: member.email });
      setMonthlySpent(remainingCredits.creditUsage);
      if (monthlyLimit != -1)
        setMonthlyRemaining(
          Math.max(
            Math.min(
              monthlyLimit - remainingCredits.creditUsage,
              remainingCredits.organizationCredits
            ),
            0
          )
        );
      getRemainingCredits(member.email, member.limit).then(
        ({ data, statusCode }) => {
          setLoading(true);
          if (statusCode == 200) {
            setMonthlySpent(data.creditUsage);
            if (monthlyLimit != -1)
              setMonthlyRemaining(
                Math.max(
                  Math.min(
                    monthlyLimit - data.creditUsage,
                    data.organizationCredits
                  ),
                  0
                )
              );
          }
        }
      );
    } else {
      if (!subscription && (isAdd || isJoin)) {
        let memberInfosCopy = { ...memberInfos };
        memberInfosCopy.memberSettings.type = "admin";
        setMemberInfos(memberInfosCopy);
      }
    }
    setMonthlyLimit((member && member.limit) || remainingCredits.limit);
    setMonthlyRemaining(remainingCredits.limit);
  }, []);
  const checkEmailValidity = (email) => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    return emailRegex.test(email);
  };
  const handleOnSubmit = async () => {
    memberInfos.memberSettings.limit = monthlyLimit;
    if (inputRef.current && !inputRef.current.reportValidity()) return;
    let data, statusCode;
    if (member) {
      ({ data, statusCode } = await updateMemberSettings({
        settings: memberInfos.memberSettings,
        userToUpdate: member.email,
      }));
    } else if (isJoin) {
      ({ data, statusCode } = await approveJoinRequest({
        joiner: joinRequestMember.joiner,
        memberSettings: memberInfos.memberSettings,
        clientDate: new Date(Date.now()),
      }));
    } else {
      ({ data, statusCode } = await inviteMember({
        invitees: invitees.map((invitee) => invitee.value),
        ...memberInfos,
        invitee: invitees.length > 0 ? undefined : memberInfos.invitee,
      }));
      if (statusCode === 200) {
        if (data.success) {
          updateInvitations(data.invitations);
        } else {
          let alreadyInvited = data.usersPartOfTheOrganization
            ? data.usersPartOfTheOrganization.join(", ")
            : "";
          alreadyInvited += data.usersWithSentJoinReq
            ? data.usersWithSentJoinReq.join(", ")
            : "";
          alreadyInvited += data.usersWithReceivedInvitation
            ? data.usersWithReceivedInvitation.join(", ")
            : "";
          setAddMemberModal(false);
          return toast.error(
            intl.formatMessage(
              { id: "organization.userAlreadyInvited" },
              { value: alreadyInvited }
            )
          );
        }
      }
    }
    if (statusCode === 200) {
      resetRemaining();
      getOrganization().then(({ data, statusCode }) => {
        dispatch({ type: actions.GET_ORGANIZATION, payload: data });

        if (statusCode !== 200) {
          window.location = "/workspace/createjoin";
        }
      });
      setAddMemberModal(false);
    } else {
      setError(data.messageTranslated || data.message);
    }
  };

  const handlePermissionsChange = (e, type, permissionName) => {
    let permissions = memberInfos.memberSettings.permissions;
    permissions = permissions.map((permission) => {
      let actions = permission.actions;
      if (type === permission.name)
        if (e.target.checked) {
          actions.push(permissionName);
        } else {
          if (permissionName === "View") {
            actions = [];
          } else {
            actions = actions.filter(
              (currentAction) => currentAction !== permissionName
            );
          }
        }
      permission.actions = actions;
      return permission;
    });
    setMemberInfos({ ...memberInfos, permissions });
  };
  const handleRoleChange = (e) => {
    if (e.target.checked) {
      setRole(true);
      const memberSettings = memberInfos.memberSettings;
      memberSettings.type = "admin";
      memberSettings.permissions = [
        { name: "Leads", actions: ["View", "Edit", "Export", "Save"] },
        { name: "Workflow", actions: ["View", "Edit", "Execute"] },
        { name: "Integration", actions: ["Export", "Mapping", "Sync"] },
      ];
      setMemberInfos({ ...memberInfos, memberSettings });
    } else {
      setRole(false);
      const memberSettings = memberInfos.memberSettings;
      memberSettings.type = "member";
      memberSettings.permissions = [
        { name: "Leads", actions: ["View"] },
        { name: "Workflow", actions: ["View"] },
        { name: "Integration", actions: [] },
      ];
      setMemberInfos({ ...memberInfos, memberSettings });
    }
  };
  const handleOnChange = (tags, txtValue) => {
    let emails = [...tags];
    let numberOfValidEmails = 0;
    emails = emails.map((email, i) => {
      let item = { ...email };
      if (email.isValid) numberOfValidEmails += 1;
      if (numberOfValidEmails > remainingInviations) {
        item.requireLicence = true;
      }
      return item;
    });
    setInvitees(emails);
  };
  const handleAddLicences = () => {
    let members = invitees.filter((invitee) => invitee.isValid);
    members = members.map((invitee) => invitee.value);
    dataDrive({
      to: "/billing",
      key: "invitees",
      state: members,
    });
    dataDrive({
      to: "/billing",
      key: "licences",
      state: members.length - remainingInviations,
    });
  };

  const handleAddMembersClick = () => {
    history.push("/billing/info", { usersManagement: true });
  };

  return (
    <Modal
      id="add-edit-member-pop-up"
      show={true}
      onHide={() => setAddMemberModal(false)}
      className="fade add-member-wrapper  addmember-modal"
    >
      <Modal.Header className="modal-header border-0" closeButton>
        <div className="modal-inner-header">
          <div className="profile-info">
            {member ? (
              <h5 className="heading mb-8">
                <FormattedMessage id={"organization.editMember"} />
              </h5>
            ) : isJoin ? (
              <h5 className="heading mb-8">
                <FormattedMessage id={"organization.acceptJoinRequest"} />
              </h5>
            ) : (
              <h5 className="heading mb-8">
                <FormattedMessage id={"organization.addInvite"} />
              </h5>
            )}
          </div>
        </div>
      </Modal.Header>
      <div className="modal-body p-0">
        <div className="add-member-fields" style={{ marginBottom: 20 }}>
          <form>
            {isAdd ||
              (joinRequestMember && (
                <div className="modal-heading">
                  <span>
                    <FormattedMessage id={"organization.memberInfo"} />
                  </span>
                  <div style={{ marginTop: 12 }}>
                    {joinRequestMember.joiner}
                  </div>
                </div>
              ))}
            <div className="form-wrap">
              {isAdd && (
                <div className="form-group">
                  <label>Email</label>

                  <InputEmailTagsLight
                    remainingInviations={remainingInviations}
                    domains={[]}
                    items={invitees}
                    onChange={handleOnChange}
                    checkValidity={checkEmailValidity}
                    placeholder={intl.formatMessage({
                      id: "auth.typeYourEmailAddress",
                    })}
                    tags={tags}
                    setTags={setTags}
                  />
                  <div className={"add-member-licences"}>
                    {!isFreePlan && (
                      <>
                        <img
                          style={{ width: "20px" }}
                          src="/assets/images/icon-info.svg"
                          alt=""
                        />
                        <div>
                          <FormattedMessage
                            id={"organization.youCanAddUpToXMembers"}
                            values={{
                              value: remainingInviations,
                              a: (chunk) => (
                                <button
                                  type="button"
                                  onClick={handleAddMembersClick}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: 0,
                                    color: "#007bff",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {chunk}
                                </button>
                              ),
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {!checkPlanPermissions("Edit") && (
                <div className={"add-member-upgrade"}>
                  <span style={{ width: "60%" }}>
                    <FormattedMessage
                      id={"organization.ToAccessMoreFeaturesUpgrade"}
                    />
                  </span>
                  <button
                    type="button"
                    className={"btn btn2"}
                    onClick={() => history.push("/billing")}
                  >
                    <img src="/assets/images/export-icon.svg" alt="" />{" "}
                    <FormattedMessage id={"billing.upgrade"} />
                  </button>
                </div>
              )}
            </div>
            <div className={"member-permissions"}>
              <div
                style={
                  !checkPlanPermissions("Edit")
                    ? {
                        pointerEvents: "none",
                        filter: "blur(1px)",
                        opacity: ".5",
                        userSelect: "none",
                      }
                    : {}
                }
              >
                <div className="modal-heading mt-16">
                  <span>
                    <FormattedMessage id={"organization.userRole"} />
                  </span>
                </div>
                <div className="user-role-listing">
                  <div className="user-role-block">
                    {/*   <div className="left-block">Admin</div>*/}
                    <div className="right-block">
                      <div className="checkbox-wrap">
                        <label>
                          <input
                            type="checkbox"
                            onChange={handleRoleChange}
                            checked={
                              memberInfos.memberSettings.type ===
                              ("admin" || "owner")
                            }
                            disabled={!isMemberAdmin && isEdit}
                          />
                          <span className="checkbox-icon" />
                          <span
                            className="checkbox-text"
                            style={{
                              color: "#3b53d1",
                              fontSize: "16px",
                              fontWeight: 500,
                            }}
                          >
                            Admin
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-heading mt-16">
                  <span>
                    <FormattedMessage id={"organization.userPermissions"} />
                  </span>
                </div>
                <div>
                  {memberInfos.memberSettings.permissions.map(
                    (permission, i) => (
                      <PermissionsField
                        key={i}
                        permission={permission}
                        handlePermissionsChange={handlePermissionsChange}
                        isAdmin={false}
                        disabled={!isMemberAdmin && isEdit}
                        checkPlanPermissions={checkPlanPermissions}
                      />
                    )
                  )}
                </div>
                <div className="modal-heading">
                  <span>
                    <FormattedMessage id={"organization.creditsLimit"} />
                  </span>
                </div>
                <div className="form-wrap">
                  {isVersion2 ? (
                    <div className="row">
                      {monthlyLimit &&
                        CREDITS.filter(
                          (credit) => credit !== "legacyCredits"
                        ).map((credit, i) => (
                          <CreditsLimit
                            key={i}
                            type={credit}
                            monthlyLimit={monthlyLimit[credit]}
                            setMonthlyLimit={(val) => {
                              setMonthlyLimit({ ...monthlyLimit, ...val });
                            }}
                            setMonthlyRemaining={setMonthlyRemaining}
                            remainingInviations={remainingInviations}
                            monthlySpent={monthlySpent}
                            isMemberAdmin={isMemberAdmin}
                            isEdit={isEdit}
                            member={member}
                            memberInfos={memberInfos}
                            remainingCredits={remainingCredits}
                            loading={loading}
                          />
                        ))}
                    </div>
                  ) : (
                    <div className="row">
                      {monthlyLimit && (
                        <CreditsLimit
                          type={"legacyCredits"}
                          monthlyLimit={monthlyLimit["legacyCredits"]}
                          setMonthlyLimit={(val) => {
                            setMonthlyLimit({ ...monthlyLimit, ...val });
                          }}
                          setMonthlyRemaining={setMonthlyRemaining}
                          remainingInviations={remainingInviations}
                          monthlySpent={monthlySpent}
                          isMemberAdmin={isMemberAdmin}
                          isEdit={isEdit}
                          member={member}
                          memberInfos={memberInfos}
                          remainingCredits={remainingCredits}
                          loading={loading}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {error && (
        <div
          className="text-center  error  kaspr-form-text-error"
          style={{ top: 10, width: "90%" }}
        >
          {error + " "}
        </div>
      )}

      {(isAdd || isJoin || (isEdit && isMemberAdmin)) && (
        <div className="modal-footer border-0">
          <a
            href="#"
            className="btn2 mr14 btn48"
            onClick={() => setAddMemberModal(false)}
            style={{ fontSize: 15 }}
          >
            <FormattedMessage id={"cancel"} />
          </a>

          {member ? (
            checkPlanPermissions("Edit") && (
              <a
                href="#"
                className="btn1 btn48"
                onClick={handleOnSubmit}
                style={{ fontSize: 15 }}
              >
                <FormattedMessage id={"organization.editMember"} />
              </a>
            )
          ) : invitees.filter((invitee) => invitee.isValid).length >
            remainingInviations ? (
            <button
              className="btn1 btn48"
              style={{
                fontSize: 15,
                ...(invitees.filter((invitee) => invitee.isValid).length == 0
                  ? { pointerEvents: "none", opacity: 0.6 }
                  : {}),
              }}
              onClick={handleAddLicences}
            >
              <FormattedMessage
                id={"organizations.addXLicences"}
                values={{
                  value: tags.length - remainingInviations,
                }}
              />
            </button>
          ) : isJoin ? (
            <a
              href="#"
              className="btn1 btn48 join-rqst-btn"
              onClick={handleOnSubmit}
            >
              <FormattedMessage id="organization.acceptJoinRequest" />
            </a>
          ) : (
            <a
              href="#"
              className="btn1 btn48"
              onClick={handleOnSubmit}
              style={{
                fontSize: 15,
                ...(invitees.filter((invitee) => invitee.isValid).length == 0
                  ? { pointerEvents: "none", opacity: 0.6 }
                  : {}),
              }}
            >
              <FormattedMessage id={"home.onboarding.sendInvite"} />
            </a>
          )}
        </div>
      )}
    </Modal>
  );
}

function PermissionsField({
  handlePermissionsChange,
  permission,
  isAdmin,
  disabled,
  checkPlanPermissions,
}) {
  if (permission.name === "Leads") {
    return (
      <div className="user-role-listing">
        <div className="user-role-block">
          <div className="left-block">
            <FormattedMessage id={"sidenav.leads"} />
          </div>
          <div className="right-block">
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "View")
                  }
                  checked={
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={isAdmin || disabled}
                />
                <span className="checkbox-icon" />
                <span className="checkbox-text">
                  {" "}
                  <FormattedMessage id={"view"} />
                </span>
              </label>
            </div>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Edit")
                  }
                  checked={
                    permission.actions.find((action) => action === "Edit") &&
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={
                    (permission.actions.indexOf("View") > -1 ? false : true) ||
                    isAdmin ||
                    disabled
                  }
                />
                <span className="checkbox-icon" />
                <span
                  className="checkbox-text"
                  style={
                    permission.actions.indexOf("View") > -1
                      ? {}
                      : { color: "#ccc" }
                  }
                >
                  <FormattedMessage id={"edit"} />
                </span>
              </label>
            </div>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Export")
                  }
                  checked={
                    permission.actions.find((action) => action === "Export") &&
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={
                    (permission.actions.indexOf("View") > -1 ? false : true) ||
                    isAdmin ||
                    disabled
                  }
                />
                <span className="checkbox-icon" />
                <span
                  className="checkbox-text"
                  style={
                    permission.actions.indexOf("View") > -1
                      ? {}
                      : { color: "#ccc" }
                  }
                >
                  <FormattedMessage id={"export"} />
                </span>
              </label>
            </div>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Save")
                  }
                  checked={
                    permission.actions.find((action) => action === "Save") &&
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={
                    (permission.actions.indexOf("View") > -1 ? false : true) ||
                    isAdmin ||
                    disabled
                  }
                />
                <span className="checkbox-icon" />
                <span
                  className="checkbox-text"
                  style={
                    permission.actions.indexOf("View") > -1
                      ? {}
                      : { color: "#ccc" }
                  }
                >
                  <FormattedMessage id={"save"} />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (permission.name === "Workflow") {
    return (
      <div className="user-role-listing">
        <div className="user-role-block">
          <div className="left-block">
            {permission.name} &<br />
            <FormattedMessage id="billing.permissions.BulkEnrichment" />
          </div>
          <div className="right-block">
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "View")
                  }
                  checked={
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={isAdmin || disabled}
                />
                <span className="checkbox-icon" />
                <span className="checkbox-text">
                  {" "}
                  <FormattedMessage id={"view"} />
                </span>
              </label>
            </div>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Edit")
                  }
                  checked={
                    permission.actions.find((action) => action === "Edit") &&
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={
                    (permission.actions.indexOf("View") > -1 ? false : true) ||
                    isAdmin ||
                    disabled
                  }
                />
                <span className="checkbox-icon" />
                <span
                  className="checkbox-text"
                  style={
                    permission.actions.indexOf("View") > -1
                      ? {}
                      : { color: "#ccc" }
                  }
                >
                  <FormattedMessage id={"edit"} />
                </span>
              </label>
            </div>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Execute")
                  }
                  checked={
                    permission.actions.find((action) => action === "Execute") &&
                    permission.actions.find((action) => action === "View")
                      ? true
                      : false
                  }
                  disabled={
                    (permission.actions.indexOf("View") > -1 ? false : true) ||
                    isAdmin ||
                    disabled
                  }
                />
                <span className="checkbox-icon" />
                <span
                  className="checkbox-text"
                  style={
                    permission.actions.indexOf("View") > -1
                      ? {}
                      : { color: "#ccc" }
                  }
                >
                  <FormattedMessage id={"execute"} />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (permission.name === "Integration") {
    return (
      <div className="user-role-listing">
        <div className="user-role-block">
          <div className="left-block">
            {" "}
            <FormattedMessage id={"tabs.integration"} />
          </div>
          <div className="right-block">
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Export")
                  }
                  checked={
                    permission.actions.find((action) => action === "Export")
                      ? true
                      : false
                  }
                  disabled={isAdmin || disabled}
                />
                <span className="checkbox-icon" />
                <span className="checkbox-text">
                  {" "}
                  <FormattedMessage id={"export"} />
                </span>
              </label>
            </div>

            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Mapping")
                  }
                  checked={
                    permission.actions.find((action) => action === "Mapping")
                      ? true
                      : false
                  }
                  disabled={isAdmin || disabled}
                />

                <span className="checkbox-icon" />
                <span className="checkbox-text">
                  {" "}
                  <FormattedMessage id={"mapping"} />
                </span>
              </label>
            </div>

            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handlePermissionsChange(e, permission.name, "Sync")
                  }
                  checked={
                    permission.actions.find((action) => action === "Sync")
                      ? true
                      : false
                  }
                  disabled={isAdmin || disabled}
                />

                <span className="checkbox-icon" />
                <span className="checkbox-text">
                  {" "}
                  <FormattedMessage id={"sync"} />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function CreditsLimit({
  monthlyLimit,
  setMonthlyLimit,
  setMonthlyRemaining,
  monthlySpent,
  isMemberAdmin,
  isEdit,
  member,
  memberInfos,
  remainingCredits,
  type,
}) {
  let creditLimit = {};
  const handleOnChange = (e) => {
    creditLimit[type] = parseInt(e.target.value);
    setMonthlyLimit(creditLimit);
    setMonthlyRemaining(
      Math.max(
        Math.min(
          e.target.value - monthlySpent,
          remainingCredits.organizationCredits
        ),
        0
      )
    );
  };
  const handleOnBlur = (e) => {
    creditLimit[type] = parseInt(e.target.value) || -1;
    setMonthlyLimit(creditLimit);
  };
  return (
    <>
      <div className="col-md-6" style={{ marginBottom: 10 }}>
        <div className="form-group" style={{ marginBottom: 7 }}>
          <label>
            {/*
            <FormattedMessage id={"organization.monthlyLimit"} />
*/}
            <FormattedMessage id={"billing." + type} />
          </label>
          <FormattedMessage id={"organization.enterCreditsLimit"}>
            {(placeholder) => (
              <input
                type="Number"
                value={monthlyLimit != -1 ? monthlyLimit : ""}
                onChange={handleOnChange}
                disabled={monthlyLimit == -1 || (!isMemberAdmin && isEdit)}
                placeholder={placeholder}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="checkbox-wrap">
          <label>
            <input
              type="checkbox"
              onChange={(e) => {
                creditLimit[type] = e.target.checked
                  ? -1
                  : member && member.limit != -1
                  ? member.limit
                  : 20;
                setMonthlyLimit(creditLimit);
                setMonthlyRemaining(
                  e.target.checked
                    ? Math.max(remainingCredits.organizationCredits[type], 0)
                    : Math.max(
                        Math.min(
                          member && member.limit != -1
                            ? member.limit - monthlySpent
                            : 20 - monthlySpent,
                          remainingCredits.organizationCredits[type]
                        ),
                        0
                      )
                );
                memberInfos.memberSettings.limit = e.target.checked
                  ? -1
                  : member && member.limit != -1
                  ? member.limit
                  : 20;
              }}
              checked={monthlyLimit == -1}
              disabled={!isMemberAdmin && isEdit}
            />
            <span className="checkbox-icon" />
            <span className="checkbox-text">
              <FormattedMessage id={"organization.unlimited"} />
            </span>
          </label>
        </div>
      </div>
    </>
  );
}
