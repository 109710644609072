import React from "react";
import { CLIENT_ID_SALESFORCE } from "../../../../constants";
const jsforce = require("jsforce");

function GetOauthUrl(
  clientId,
  redirectUri,
  scopes,
  responseType,
  oauthUrl,
  onLogin,
  text,
  type
) {
  if (text === "Outlook") {
    return `${oauthUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scopes.join(
      " "
    )}&redirect_uri=${redirectUri}`;
  }

  switch (type) {
    case "gmail":
      return `${
        this.oauthUrl
      }?client_id=${clientId}&response_type=${responseType}&scope=${scopes.join(
        " "
      )}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&prompt=consent&access_type=offline&response_mode=query`;

    case "salesforce":
      const oauth2 = new jsforce.OAuth2({
        clientId: CLIENT_ID_SALESFORCE,
        redirectUri: redirectUri,
      });
      return oauth2.getAuthorizationUrl({});

    case "hubspot":
      return `${oauthUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scopes.join(
        " "
      )}&redirect_uri=${encodeURIComponent(redirectUri)}`;

    case "zohocrm":
      return `${oauthUrl}?client_id=${clientId}&response_type=code&scope=${scopes.join(
        ","
      )}&access_type=offline&redirect_uri=${encodeURIComponent(redirectUri)}`;

    default:
      throw new Error("Erreur dans la creation de l'URL");
  }
}
export default GetOauthUrl;
