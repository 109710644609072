import React, { useContext, useEffect, useState } from "react";
import { OrganizationContext, actions } from "../store/Organization.store";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import * as Permissions from "../../../components/Permissions/Permissions";
import { PreLoader } from "../../../components";
import {
  getActivity,
  getOrgMembersFromCreditsUsages,
} from "../../../services/api/credits.api";
import { FormattedMessage, injectIntl } from "react-intl";
import { usePermissions } from "../../../components/Permissions/permissions.hooks";
import ActivityItem from "../common/ActivityItem/ActivityItem";
import ActivityFilters from "./ActivityFilters/ActivityFilters";

export default function Activity({
  organization,
  checkPlanActivityPermissions,
}) {
  const { state, dispatch } = useContext(OrganizationContext);

  const [currentPage, setPage] = useState(1);
  const [member, setMember] = useState(null);
  const [membersLeavedOrg, setMembersLeavedOrg] = useState([]);

  useEffect(() => {
    if (checkPlanActivityPermissions("View")) {
      getOrgMembersFromCreditsUsages({
        organizationId: state.organization._id,
      }).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          let members = state.organization.members.map(
            (member) => member.email
          );
          let membersLeavedOrg = data.filter(
            (member) => !members.includes(member)
          );
          membersLeavedOrg = membersLeavedOrg.map((email) => {
            return { email, firstName: email, lastName: "", leavedOrg: true };
          });
          setMembersLeavedOrg(membersLeavedOrg);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (checkPlanActivityPermissions("View")) {
      getActivity({
        startDate: organization.organization
          ? organization.organization.createdAt
          : new Date(),
        endDate: new Date(),
      }).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          dispatch({
            type: actions.GET_ACTIVITY,
            payload: {
              activity: data,
              activityFilters: {
                member: undefined,
                startDate: organization.organization
                  ? organization.organization.createdAt
                  : new Date(),
                endDate: new Date(),
              },
            },
          });
        } else {
          dispatch({
            type: actions.GET_ACTIVITY,
            payload: { activity: "not_allowed" },
          });
        }
      });
    }
  }, []);
  const handleGoToPage = (page) => {
    console.log(member);
    getActivity({
      ...state.activityFilters,
      member:
        (member && member.email) || state.activityFilters.member || undefined,
      page,
    }).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: {
            activity: data,
            activityFilters: { ...state.activityFilters },
          },
        });
        setPage(page);
      } else {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: { activity: "not_allowed" },
        });
      }
    });
  };
  const handleActivityFilter = ({ startDate, endDate, member, sources }) => {
    getActivity({
      ...state.activityFilters,
      startDate,
      endDate,
      member: member || state.activityFilters.member || undefined,
      sources,
    }).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: {
            activity: data,
            activityFilters: {
              member,
              startDate,
              endDate,
              sources,
            },
          },
        });
        setMember((member && member.email) || null);
        setPage(data.pages.page);
      } else {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: { activity: "not_allowed" },
        });
      }
    });
  };
  if (!checkPlanActivityPermissions("View"))
    return (
      <Permissions.View
        title={"Title"}
        description={"Description"}
        img={"activity-bg.png"}
      />
    );

  if (!state.activity) {
    return <PreLoader />;
  }

  return (
    <ActivityWrapper
      page={currentPage}
      pages={state.activity.pages}
      membersLeavedOrg={membersLeavedOrg}
      handleGoToPage={handleGoToPage}
      handleActivityFilter={handleActivityFilter}
      checkPlanActivityPermissions={checkPlanActivityPermissions}
    >
      {state.activity.creditUsage.length === 0 ? (
        <DataNotFound style={{ overflow: "hidden" }} />
      ) : (
        <table width="100%" className="table-fixed">
          <thead>
            <tr className="sticky-header">
              <th>
                <FormattedMessage id={"organization.date"} />
              </th>
              <th>Email</th>
              <th>
                <FormattedMessage id={"organization.source"} />
              </th>
              <th>
                <FormattedMessage id={"organization.activity.request"} />
              </th>
              <th>
                <FormattedMessage id={"organization.activity.spentCredits"} />
              </th>
            </tr>
          </thead>
          <tbody>
            {state.activity.creditUsage.map((item, i) => (
              <ActivityItem key={i} item={item} />
            ))}
          </tbody>
        </table>
      )}
    </ActivityWrapper>
  );
}

function ActivityWrapper({
  children,
  page,
  handleGoToPage,
  pages,
  membersLeavedOrg,
  handleActivityFilter,
  checkPlanActivityPermissions,
}) {
  return (
    <div id="activity_tab" className="activity-wrapper tab-pane active ">
      <div className="table-wrap">
        <ActivityFilters
          page={page}
          handleGoToPage={handleGoToPage}
          pages={pages}
          membersLeavedOrg={membersLeavedOrg}
          handleActivityFilter={handleActivityFilter}
          checkPlanActivityPermissions={checkPlanActivityPermissions}
        />
        <div className="table-content sticky-table">{children}</div>
      </div>
    </div>
  );
}
