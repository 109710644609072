import React from "react";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";
import { useIntl } from "react-intl";

const Carousel = ({ carouselItems }) => {
  const intl = useIntl();

  function SampleArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    class: "carousel-content",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 3,
    initialSlide: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    nextArrow: <SampleArrow />,
    prevArrow: <SampleArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="ressources-container">
      <h1 className="inner-title">
        <FormattedMessage id="settings.integration.ourRessources" />
      </h1>
      <div className={"slider-carousel-container"}>
        <Slider {...settings}>
          {carouselItems.map((item, index) => (
            <a
              key={index}
              href={item.href[intl.locale]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={item.imageSrc} alt="" />
              <p>
                <FormattedMessage id={item.title} />
              </p>
            </a>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
