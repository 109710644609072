import React from "react";
import "./Faq.css";
import itemList from "../../../../Leads/LeadsInfo/Common/ItemList/ItemList";
import { FormattedMessage } from "react-intl";
export default function Faq() {
  return (
    <div id={"faq-container"}>
      <div className="faq-head">
        <h3>
          <FormattedMessage
            id={"legacy.faq.over152k_"}
            values={{ strong: (chunk) => <strong>{chunk} </strong> }}
          />
        </h3>
        <p>
          <FormattedMessage id={"legacy.faq.theTopSales_"} />
        </p>
      </div>
      <div className="logos">
        <img src="/assets/images/splashr.png" alt="" />
        <img src="/assets/images/uptoo.png" alt="" />
        <img src="/assets/images/visibility.png" alt="" />
        <img src="/assets/images/gymlib.png" alt="" />
        <img src="/assets/images/ringover-fq.png" alt="" />
      </div>
      <div className="faq">
        <div className={"faq-top"}>
          <h3>
            <FormattedMessage id={"legacy.faq.gettingStarted"} />
          </h3>
          <p>
            <FormattedMessage id={"legacy.faq.kasprIsALeadGeneration_"} />
          </p>
        </div>

        <div className="faq-content">
          <FaqItems />
        </div>
      </div>
    </div>
  );
}

function FaqItems() {
  return (
    <div className="faq-item">
      <div className="faq-title">
        <FormattedMessage id={"legacy.faq.willMyPlanBeRenewed_"} />
      </div>
      <p>
        <FormattedMessage
          id={"legacy.faq.yesOurSubscriptions_"}
          values={{
            link1: (chunk) => (
              <a
                href="https://help.kaspr.io/kb-search-results?term=credits"
                target={"_blank"}
              >
                {chunk}{" "}
              </a>
            ),
          }}
        />
      </p>
      <div className="faq-title">
        <FormattedMessage id={"legacy.faq.doIHaveALimit_"} />
      </div>
      <p>
        <FormattedMessage id={"legacy.faq.forAnyQuestions_"} />
      </p>
      <div className="faq-title">
        <FormattedMessage id={"legacy.faq.canIUpdateMySubscription_"} />
      </div>
      <p>
        <FormattedMessage id={"legacy.faq.yesOfCourseOur_"} />
      </p>
      <div className="faq-title">
        <FormattedMessage id={"legacy.faq.canIPurchase_"} />
      </div>
      <p>
        <FormattedMessage
          id={"legacy.faq.yesPurchaseAddOn_"}
          values={{
            link1: (chunk) => (
              <a href="https://www.kaspr.io/privacy-policy" target={"_blank"}>
                {chunk}
              </a>
            ),
            link2: (chunk) => (
              <a
                href="https://help.kaspr.io/best-practices-to-use-kaspr-with-linkedin"
                target={"_blank"}
              >
                {chunk}
              </a>
            ),
          }}
        />
      </p>
      <div className="faq-title">
        <p>
          <FormattedMessage id={"faqs.automationQuestion"} />
        </p>
      </div>
      <p>
        <FormattedMessage
          id={"faqs.automationAnswer"}
          values={{
            ul: (chunk) => <ul>{chunk}</ul>,
            li: (chunk) => <li>{chunk}</li>,
            strong: (chunk) => <strong>{chunk}</strong>,
          }}
        />
      </p>
      <div className="faq-title">
        <p>
          <FormattedMessage id={"faqs.integrationQuestion"} />
        </p>
      </div>
      <p>
        <FormattedMessage
          id={"faqs.integrationAnswer"}
          values={{
            a: (chunks) => (
              <a
                href="https://app.kaspr.io/settings/integration"
                target={"_blank"}
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    </div>
  );
}
