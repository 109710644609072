import React, { useEffect, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import {
  generateKey,
  getKey,
  deleteKey,
  setHidePersonalEmailRequest,
} from "../../../services/api/public.api";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { toast, ToastContainer } from "react-toastify";
import { FormattedMessage } from "react-intl";
import "./api.css";
import { InfoToolTip } from "../../../components";

export default function Api(props) {
  const [planPermission, setPlanPermission] = useState(null);
  const [key, setKey] = useState(null);
  const [hidePersonalEmail, setHidePersonalEmail] = useState(null);
  const [showKey, setShowKey] = useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [error, setError] = useState(null);

  const generateNewKey = async () => {
    try {
      const { data, statusCode } = await generateKey();
      if (statusCode >= 400) {
        setError(data.messageTranslated || data.message);
      } else {
        setKey(data.key);
      }
    } catch (error) {
      setError("An error occurred while generating the key");
    }
  };

  const deleteExistingKey = async () => {
    try {
      const { data, statusCode } = await deleteKey();
      if (statusCode >= 400) {
        setError(data.messageTranslated || data.message);
      } else if (data.message === "deleted") {
        setKey(null);
        setShowDeletePopUp(false);
      }
    } catch (error) {
      setError("An error occurred while deleting the key");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(key)
      .then(() => toast.success("Key copied!"))
      .catch(() => setError("Failed to copy key"));
  };

  const handleApiPreferences = async (e, property) => {
    try {
      if (property === "hidePersonalEmail") {
        const value = e.target.checked;
        const { data } = await setHidePersonalEmailRequest(value);
        if (data && data.success) {
          setHidePersonalEmail(value);
        } else {
          setError(data.messageTranslated || data.message);
        }
      }
    } catch (error) {
      setError("An error occurred while setting preferences");
    }
  };

  useEffect(() => {
    const fetchKey = async () => {
      try {
        const { data, statusCode } = await getKey();
        if (statusCode === 200) {
          setKey(data.key);
          setHidePersonalEmail(data.hidePersonalEmail);
        }
      } catch (error) {
        setError("An error occurred while fetching the key");
      }
    };

    if (props.organizationCredit && props.organizationCredit.plan) {
      const permission = props.organizationCredit.plan.permissions.find(
        (p) => p.name === "PublicApi"
      );
      setPlanPermission(permission);
    }

    fetchKey();
  }, [props.organizationCredit]);

  if (!planPermission) {
    return (
      <DataNotFound
        image={"/assets/images/img_empty_no_search.png"}
        style={{ marginTop: "40px" }}
        elements={
          <React.Fragment>
            <div className="join-organization-inner join-organization-page">
              <h4>
                <FormattedMessage id={"settings.api.accessRequired"} />
              </h4>
              <p className="desc">
                <FormattedMessage id={"settings.api.noAccessDesc"} />{" "}
                <a
                  href="mailto:support@kaspr.io"
                  target="_blank"
                  className="text-btn mr14"
                >
                  support@kaspr.io
                </a>
              </p>
            </div>
          </React.Fragment>
        }
      />
    );
  }

  return (
    <div id="api_tab" className="tab-pane active show">
      <div className="content-box-ui">
        <div className="content-header">
          <div className="title-ui">
            <label>
              <FormattedMessage id={"settings.api"} />
            </label>
          </div>
        </div>

        <div className="body-content">
          <div className="inner-body-content view-profile-ui">
            {key && (
              <React.Fragment>
                <Row>
                  <div className="input-summry">
                    <label>
                      <FormattedMessage id={"settings.apiKey"} />
                    </label>
                    <p>
                      {showKey
                        ? key
                        : "********************************" +
                          key.substring(key.length - 4, key.length)}
                    </p>
                  </div>
                  <div className="icon-container">
                    <i
                      onClick={copyToClipboard}
                      className="fas fa-copy icon-style"
                    >
                      {" "}
                    </i>

                    <i
                      onClick={generateNewKey}
                      className="fas fa-redo-alt icon-style"
                    >
                      {" "}
                    </i>

                    <i
                      onClick={() => setShowDeletePopUp(true)}
                      className="fas fa-trash icon-style"
                    >
                      {" "}
                    </i>

                    {showKey ? (
                      <i
                        onClick={() => setShowKey(false)}
                        className="fas fa-eye icon-style"
                      >
                        {" "}
                      </i>
                    ) : (
                      <i
                        onClick={() => setShowKey(true)}
                        className="fas fa-eye-slash icon-style"
                      >
                        {" "}
                      </i>
                    )}
                  </div>
                </Row>
                <div className="checkbox-listing checkbox-wrap">
                  <div className="checkbox-wrap">
                    <label>
                      <input
                        type="checkbox"
                        value=""
                        onClick={(e) =>
                          handleApiPreferences(e, "hidePersonalEmail")
                        }
                        checked={hidePersonalEmail}
                      />
                      <span className="checkbox-icon"></span>
                      <div className="checkbox-text">
                        <span style={{ fontWeight: 500 }}>
                          <FormattedMessage
                            id={"settings.profile.hidePersonalEmail"}
                          />
                        </span>
                        <p>
                          <FormattedMessage
                            id={"settings.profile.hidePersonalEmail.text"}
                          />
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
                <div>
                  <a
                    href="https://kaspr.stoplight.io/docs/kaspr-api/branches/main/2ptd62aajjv62-introduction"
                    target="_blank"
                    className="text-btn mr14"
                  >
                    <FormattedMessage id={"settings.api.AccessApiDoc"} />
                    <span className="link-icon">
                      <img src="/assets/images/link_icon.svg" alt="" />
                    </span>
                    <span>
                      <InfoToolTip
                        text={
                          <FormattedMessage id={"settings.api.requestsCost"} />
                        }
                        style={{
                          width: "16px",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                        containerStyle={{
                          lineHeight: "18px",
                          padding: "8px",
                        }}
                      />
                    </span>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.kaspr.io/talk-to-sales"
                    target="_blank"
                    className="text-btn mr14"
                  >
                    <FormattedMessage id={"settings.api.moreApiInfo"} />
                    <span className="link-icon">
                      <img src="/assets/images/link_icon.svg" alt="" />
                    </span>
                  </a>
                </div>
              </React.Fragment>
            )}
            {!key && (
              <div className="create-organization-btn">
                <a onClick={generateNewKey}>
                  <span className="ic-user">
                    <img src="/assets/images/ic-create.png" alt="" />
                  </span>
                  <h5>
                    <FormattedMessage id={"settings.api.createANewApiKey"} />
                  </h5>
                  <p>
                    <FormattedMessage
                      id={"settings.api.createANewApiKeyToAccess"}
                    />
                  </p>
                </a>
              </div>
            )}
            {error && (
              <div
                className="text-center  error  kaspr-form-text-error"
                style={{ top: 10, width: "90%" }}
              >
                {error + " "}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" className="toast-container" />
      {showDeletePopUp && (
        <Modal
          show={true}
          onHide={() => setShowDeletePopUp(false)}
          className="fade add-member-wrapper  addmember-modal"
        >
          <Modal.Header className="modal-header" closeButton>
            <div className="modal-inner-header">
              <div className="profile-info">
                <h5 className="heading mb-8">
                  {" "}
                  <FormattedMessage id={"settings.api.deleteYouApiKey"} />
                </h5>
              </div>
            </div>
          </Modal.Header>
          <div className="modal-body p-0">
            <div className="add-member-fields">
              <FormattedMessage id={"settings.api.withoutAnApiKey"} />
            </div>
          </div>
          {error && (
            <div
              className="text-center  error  kaspr-form-text-error"
              style={{ top: 10, width: "90%" }}
            >
              {error + " "}
            </div>
          )}
          <div className="modal-footer">
            <a href="#" className="btn1 btn48" onClick={deleteExistingKey}>
              <FormattedMessage id={"delete"} />
            </a>
          </div>
        </Modal>
      )}
    </div>
  );
}
