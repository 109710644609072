import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./ColleaguesCard.css";
import { getMembersContent, getOverlayText } from "./ColleaguesCard.tools";
import { inviteMember } from "../../../services/api/organization.api";
import { toast } from "react-toastify";
import { calculateLastActive, dataDrive } from "../../../services/utils/tools";
import { useIntl } from "react-intl";

export default function ColleaguesCard({
  organization,
  subOrganization,
  organizationCredits,
  setPopUpToggle,
  member,
  pendingInvitations,
  isNotMember,
  onHide,
  cardsToHide,
}) {
  const [isHidden, setIsHidden] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  let licencesToBy =
    organization.members.length + pendingInvitations.length + 1;
  const hasEnoughLicences =
    organizationCredits.plan.numberOfSeats >= licencesToBy;
  const intl = useIntl();
  useEffect(() => {
    const hideCard = cardsToHide.indexOf((member || subOrganization)._id) > -1;
    setVisibility(hideCard);
    setTimeout(() => {
      setIsHidden(hideCard);
    }, 510);
  }, [cardsToHide]);

  const handleClick = () => {
    setVisibility(true);
    setTimeout(() => {
      setIsHidden(true);
    }, 510);
  };
  const handleAddLicences = async () => {
    let membersToInvite = {
      organizationId: organization._id,
      memberSettings: {
        type: "member",
        permissions: [
          {
            name: "Leads",
            actions: ["View", "Edit", "Save", "Export"],
          },
          {
            name: "Workflow",
            actions: ["View", "Edit", "Execute"],
          },
          {
            name: "Integration",
            actions: ["Export", "Mapping", "Sync"],
          },
        ],
        limit: {
          legacyCredits: -1,
          workEmailCredits: -1,
          personalEmailCredits: -1,
          phoneCredits: -1,
          exportCredits: -1,
        },
      },
    };
    membersToInvite.invitees = [member.email];
    if (hasEnoughLicences) {
      setLoading(true);
      const { data, statusCode } = await inviteMember(membersToInvite);
      setLoading(false);

      if (statusCode === 200) {
        toast.success(
          intl.formatMessage({ id: "home.invitationSuccessfullySent" })
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.message);
      }
    } else {
      dataDrive({
        to: "/billing",
        key: "invitees",
        state: membersToInvite.invitees,
      });
      dataDrive({
        to: "/billing",
        key: "licences",
        state: membersToInvite.invitees.length,
      });
    }
  };
  if (member) {
    return (
      <Col lg={3} md={6} hidden={isHidden}>
        <div
          id="ColleaguesCard"
          className={"home-card card-ui " + (visibility ? "fade-in" : "")}
        >
          <div className={`content`}>
            <div className="titleContainer">
              <h6>
                {/* {(!isNotMember && organization && organization.name) ||
                  member.company}*/}
                {(!isNotMember && subOrganization && subOrganization.name) ||
                  ""}
              </h6>
              <span className="hide" onClick={() => onHide(member)}>
                <FormattedMessage id={"leads.hide"} />
              </span>
            </div>
            <div className="membersAvatarContainer">
              {getMembersContent([member], 1)}{" "}
            </div>
            <div>
              <p style={{ fontWeight: 600 }}>{member.email}</p>
              <p className={"job-title"} style={{ fontWeight: 400 }}>
                {(member.job && member.job.slice(0, 30)) || " "}
              </p>
              <p className={"active-on"}>
                {(member.lastCreditSpendDate || member.clientDate) &&
                  calculateLastActive(
                    member.lastCreditSpendDate || member.clientDate
                  )}{" "}
              </p>
            </div>
            {hasEnoughLicences ? (
              <button
                className="btn membersBtn onBlue"
                onClick={handleAddLicences}
              >
                {loading ? (
                  <img
                    src="/assets/images/loading.gif"
                    style={{ width: 20, margin: 0 }}
                  />
                ) : (
                  <FormattedMessage id={"organizations.inviteMember"} />
                )}
              </button>
            ) : (
              <button
                className="btn membersBtn onBlue"
                onClick={handleAddLicences}
              >
                {loading ? (
                  <img
                    src="/assets/images/loading.gif"
                    style={{ width: 20, margin: 0 }}
                  />
                ) : (
                  <FormattedMessage id={"organizations.addOneLicence"} />
                )}
              </button>
            )}
          </div>
        </div>
      </Col>
    );
  }
  return (
    <Col lg={3} md={6} hidden={isHidden}>
      <div
        id="ColleaguesCard"
        className="home-card home-card-center info-card account-usage-card"
      >
        <div className={`content ${visibility ? "fade-in" : ""}`}>
          <div className="titleContainer">
            <h6>{subOrganization && subOrganization.name}</h6>
            <span className="hide" onClick={() => onHide(subOrganization)}>
              <FormattedMessage id={"leads.hide"} />
            </span>
          </div>
          <div className="membersAvatarContainer">
            {getMembersContent(
              subOrganization.members,
              subOrganization.numberOfUsers
            )}{" "}
            {getOverlayText(subOrganization.numberOfUsers)}
          </div>
          <p style={{ fontWeight: 600 }}>
            <FormattedMessage
              id={"numberOfMembers"}
              values={{ value: subOrganization.numberOfUsers }}
            />
          </p>
          <button
            className="btn membersBtn "
            onClick={() => setPopUpToggle(subOrganization.id)}
          >
            <FormattedMessage id={"organizations.seeAllMembers"} />
          </button>
        </div>
      </div>
    </Col>
  );
}
