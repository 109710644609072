import React, { useEffect, useRef } from "react";
import "./EditableField.css";
function EditableField({
  value,
  toggleValue,
  setToggle,
  toggle,
  editIconStyle,
  onChange,
  property,
  index,
  inputType,
}) {
  const inputContainerRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, []);
  const handleClickOutSide = (e) => {
    if (
      inputContainerRef.current &&
      !inputContainerRef.current.contains(e.target)
    ) {
      setToggle(null);
    }
  };
  const handleToggle = () => {
    setToggle(toggleValue);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 10);
  };

  if (property == "description") {
    return (
      <div
        className={"edit-description-container"}
        onClick={() => (toggle !== toggleValue ? handleToggle() : null)}
      >
        {toggle == toggleValue ? (
          <span ref={inputContainerRef}>
            <textarea
              value={value}
              ref={inputRef}
              spellCheck={false}
              onChange={(e) =>
                onChange({ value: e.target.value, property, index })
              }
              placeholder={"..."}
            />
          </span>
        ) : (
          <div className={"description-toggle-container editable-field"}>
            <p
              className={"edit_profile_field_container"}
              style={{ border: "none" }}
            >
              {value.length > 300 ? value.substring(0, 300) + "..." : value}
              <span
                className={"edit_profile_field"}
                style={{ right: -10, top: -10 }}
              >
                <img src="/assets/images/ic_edit_g.png" alt="" />
              </span>
            </p>
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      className={"editable-field"}
      onClick={() => (toggle !== toggleValue ? handleToggle() : null)}
    >
      {toggle == toggleValue ? (
        <span ref={inputContainerRef}>
          <input
            type={inputType || "text"}
            value={value}
            ref={inputRef}
            spellCheck={false}
            onChange={(e) =>
              onChange({ value: e.target.value, property, index })
            }
            placeholder={"..."}
          />
        </span>
      ) : (
        <p className={"edit_profile_field_container"}>
          <span className={"field_value"}>
            {" "}
            {value
              ? value.length > 22
                ? value.substring(0, 22) + "..."
                : value
              : "---"}
          </span>
          <span className={"edit_profile_field"} style={{ ...editIconStyle }}>
            <img src="/assets/images/ic_edit_g.png" alt="" />
          </span>
        </p>
      )}
    </div>
  );
}
export default EditableField;
