import { FormattedMessage } from "react-intl";
import React, { useEffect, useRef, useState } from "react";
import EditableField from "../EditableField/EditableField";
import { Form } from "react-bootstrap";

function ItemsList({
  data,
  moreItems,
  showMoreItems,
  setEditLeadToggle,
  editLeadToggle,
  property,
  onChange,
  inputType,
}) {
  const [addFieldToggle, setAddFieldToggle] = useState(false);
  /* const input_type =
    property && property.toLowerCase().indexOf("email") > -1
      ? "email"
      : property && property.toLowerCase().indexOf("phone") > -1
      ? "number"
      : "text";*/
  const input_type =
    property && property.toLowerCase().indexOf("email") > -1 ? "email" : "text";
  return (
    <ul className="data-listing">
      {data.length >= 5 && !moreItems && !addFieldToggle ? (
        data.slice(0, 5).map((item, i) => (
          <li key={i}>
            <EditableField
              value={item}
              toggleValue={property + i}
              setToggle={setEditLeadToggle}
              toggle={editLeadToggle}
              index={i}
              property={property}
              onChange={onChange}
              inputType={input_type}
            />
          </li>
        ))
      ) : (
        <>
          {data.map((item, i) => (
            <li key={i}>
              <EditableField
                value={property == "emails" ? item.email : item}
                toggleValue={property + i}
                setToggle={setEditLeadToggle}
                toggle={
                  addFieldToggle ? property + (data.length - 1) : editLeadToggle
                }
                index={i}
                property={property}
                onChange={onChange}
                setAddFieldToggle={() => setAddFieldToggle(false)}
                inputType={input_type}
              />
            </li>
          ))}
        </>
      )}
      {!addFieldToggle && (
        <li style={{ padding: "5px 10px", display: "flex" }}>
          <button
            style={{
              margin: 0,
              boxShadow: "none",
              color: "#573ad7",
              padding: 0,
            }}
            onClick={() => {
              setAddFieldToggle(true);
              onChange({ property, value: "", index: -1 });
            }}
          >
            + <FormattedMessage id={"add"} />
          </button>
        </li>
      )}
      {!moreItems ? (
        data.length > 5 && (
          <li className="vm-btn" onClick={showMoreItems}>
            +{data.length - 5}
          </li>
        )
      ) : (
        <li className="vm-btn" onClick={() => showMoreItems(null)}>
          <FormattedMessage id={"leads.hide"} />
        </li>
      )}
    </ul>
  );
}
export default ItemsList;
