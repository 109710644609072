const creditSelect = ({ min, max }, creditsPerUser) => {
  let arr = Array.from(Array(50).keys(), (x) => (x + 1) * creditsPerUser);
  arr = arr.map((val, i) => ({
    id: "c_" + (i + 1),
    credits: val,
    users: i + 1,
  }));
  return arr.splice(min, max);
};
export default function useSelectUser({ planId, minSeats }) {
  //minSeats = minSeats == 1 ?  minSeats - 1 : minSeats;
  minSeats = minSeats - 1;
  let seatsParams = { min: minSeats, max: 50 };
  let creditsPerUser = 10;
  let defaultUsersAndCredits = { users: minSeats + 1, credits: 50 };
  switch (planId) {
    case "0":
      seatsParams = { min: 0, max: 1 };
      creditsPerUser = 10;
      defaultUsersAndCredits = { users: 1, credits: creditsPerUser };
      break;
    case "plan_1":
      seatsParams = { min: minSeats, max: 50 };
      creditsPerUser = 50;
      defaultUsersAndCredits = { users: minSeats + 1, credits: creditsPerUser };
      break;
    case "plan_2":
      seatsParams = { min: minSeats, max: 50 };
      creditsPerUser = 100;
      defaultUsersAndCredits = { users: minSeats + 1, credits: creditsPerUser };
      break;
    case "plan_3":
      seatsParams = { min: 4, max: 50 };
      creditsPerUser = 200;
      defaultUsersAndCredits = { users: 5, credits: creditsPerUser };
      break;
  }

  const usersAndCredits = creditSelect(seatsParams, creditsPerUser);

  return { usersAndCredits, defaultUsersAndCredits };
}
