import { convertToJsonAndHandleErrors, getToken } from "../utils";
import { API_URL } from "../../constants";

export const leadsFilter = async ({ data, labelId }) => {
  if (!data) {
    data = {
      search: "",
      addedBy: "",
      date: null,
      phone: "",
      page: 1,
      sortBy: "descending",
      labelId: labelId || "",
      labelName: "Default list",
      automationName: "",
    };
  } else {
    if (!data.resultPerPage) data.resultPerPage = 10;
  }
  return fetch(`${API_URL}/organization/lead/filter`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const exportLeadsAsCsv = (data) => {
  return fetch(`${API_URL}/organization/lead/exportCsv`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const exportLeadsAsExcel = (data) => {
  return fetch(`${API_URL}/organization/lead/exportExcel`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const updateLead = (leads, labelId) => {
  return fetch(`${API_URL}/organization/lead/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ leads, labelId, clientDate: new Date() }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateLeadInfos = (lead) => {
  return fetch(`${API_URL}/organization/lead/updateLeadInfo`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ lead }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLeads = (lead) => {
  return fetch(`${API_URL}/organization/leads`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(lead),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteLead = (ids) => {
  return fetch(`${API_URL}/organization/lead/delete`, {
    method: "delete",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ ids }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getWaitingList = (data) => {
  return fetch(`${API_URL}/organization/waitingList/filter`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteWaitingList = (data) => {
  return fetch(`${API_URL}/organization/waitingList/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateWaitingList = (data) => {
  return fetch(`${API_URL}/organization/waitingList/`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const searchLeadActivities = (data) => {
  return fetch(`${API_URL}/organization/activities/search`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const searchLeadNotes = (data) => {
  return fetch(`${API_URL}/organization/notes/search`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const leadNoteUpdate = (data) => {
  return fetch(`${API_URL}/organization/notes/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const leadNoteDelete = (data) => {
  return fetch(`${API_URL}/organization/notes/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const searchLeadTasks = (data) => {
  return fetch(`${API_URL}/organization/tasks/search`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLeadNote = (data) => {
  return fetch(`${API_URL}/organization/notes/add`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const tagLead = (data) => {
  return fetch(`${API_URL}/organization/lead/tag`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const untagLead = (data) => {
  return fetch(`${API_URL}/organization/lead/untag`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLeadTag = (data) => {
  return fetch(`${API_URL}/organization/tags/add`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const searchLeadTag = (data) => {
  return fetch(`${API_URL}/organization/tags/search`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateLeadTag = (data) => {
  return fetch(`${API_URL}/organization/tags/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deleteLeadTag = (data) => {
  return fetch(`${API_URL}/organization/tags/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getLeadFeatures = (data) => {
  return fetch(`${API_URL}/organization/lead/features`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const createTask = (data) => {
  return fetch(`${API_URL}/organization/tasks/add`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deleteTask = (data) => {
  return fetch(`${API_URL}/organization/tasks/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateTask = (data) => {
  return fetch(`${API_URL}/organization/tasks/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const searchTask = (data) => {
  return fetch(`${API_URL}/organization/tasks/search`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
