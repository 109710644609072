import React from "react";
import "./FieldsTable.css";
import { FormattedMessage } from "react-intl";
export default function FieldsTable({ fields }) {
  return (
    <table className={"lead-changes-table"}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id={"leads.activities.property"} />
          </th>
          <th>
            <FormattedMessage id={"leads.activities.previousValue"} />
          </th>
          <th>
            <FormattedMessage id={"leads.activities.newValue"} />
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, i) => (
          <tr key={i}>
            <td>
              <FormattedMessage id={"leads.properties." + field.property} />
            </td>
            <td>
              {Array.isArray(field.previousValue)
                ? field.previousValue.length > 0
                  ? field.previousValue.join(", ")
                  : "--"
                : field.previousValue || "--"}
            </td>
            <td>
              {Array.isArray(field.newValue)
                ? field.newValue.length > 0
                  ? field.newValue.join(", ")
                  : "--"
                : field.newValue || "--"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
