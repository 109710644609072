import {
  Animation,
  Permissions,
  SelectPicker,
} from "../../../../../components";
import Calendar from "react-calendar";
import React, { useContext, useEffect, useState } from "react";
import { OrganizationContext } from "../../../store/Organization.store";
import actions from "../../../store/Organization.actions";
import {
  exportCreditsUsages,
  getCreditsHistory,
  getOrgMembersFromCreditsUsages,
} from "../../../../../services/api/credits.api";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import MemberSelect from "../../../common/MemberSelect/MemberSelect";
import useFullPageLoader from "../../../../../components/FullPageLoader/FullPageLoader";
import { download_file } from "../../../../../services/utils/tools";
import { CREDIT_COLORS } from "../../../../../constants";
import "./HistoryChartFilters.css";
function HistoryChartFilters({
  setStartDateToggle,
  startDateToggle,
  setEndDateToggle,
  endDateToggle,
  checkPlanPermissions,
  isVersion2,
}) {
  const intl = useIntl();
  const [loader, handleLoader, setCounter] = useFullPageLoader();
  const { state, dispatch } = useContext(OrganizationContext);
  const [membersLeavedOrg, setMembersLeavedOrg] = useState([]);

  useEffect(() => {
    getOrgMembersFromCreditsUsages({
      organizationId: state.organization._id,
    }).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        let members = state.organization.members.map((member) => member.email);
        let membersLeavedOrg = data.filter(
          (member) => !members.includes(member)
        );
        membersLeavedOrg = membersLeavedOrg.map((member) => {
          return {
            email: member,
            firstName: member,
            lastName: "",
            leavedOrg: true,
          };
        });
        setMembersLeavedOrg(membersLeavedOrg);
      }
    });
  }, []);

  const onCreditsUsagesExport = async (type) => {
    let data, statusCode;
    handleLoader(true);

    setCounter(null);
    ({ data, statusCode } = await exportCreditsUsages({
      monthly: state.chartFilters.monthly,
      startDate: state.chartFilters.startDate
        ? state.chartFilters.startDate
        : state.organization.createdAt,
      endDate: state.chartFilters.endDate,
      member: state.chartFilters.member,
      emails: state.chartFilters.emails,
      exportType: type == "Export CSV" ? "CSV" : "EXCEL",
    }));
    if (statusCode === 200) {
      download_file(
        data,
        "credits_usages_stats." + (type == "Export CSV" ? "csv" : "xlsx")
      );
    }

    handleLoader(false);
  };

  const handleViewBy = async (type) => {
    const { data, status } = await getCreditsHistory({
      ...state.chartFilters,
      monthly: type === "Monthly",
      startDate:
        type === "Monthly"
          ? state.chartFilters.startDate
          : state.organization.createdAt,
      endDate: state.chartFilters.endDate,
      emails: state.chartFilters.emails,
    });
    dispatch({
      type: actions.GET_DATA_CHART,
      payload: {
        dataChart: data.usagesDateCountDocuments,
        usageByMember: data.usagesMemberCountDocuments,
      },
    });
    dispatch({
      type: actions.CHART_FILTERS,
      payload: {
        chartFilters: { ...state.chartFilters, monthly: type === "Monthly" },
      },
    });
  };

  const handleFilterByDate = async (date, type) => {
    const input = {
      ...state.chartFilters,
      member: undefined,
      emails: state.chartFilters.emails,
    };
    if (type === "start") {
      input.startDate = date;
    } else {
      input.endDate = date;
    }
    const { data, status } = await getCreditsHistory(input);
    dispatch({
      type: actions.GET_DATA_CHART,
      payload: {
        dataChart: data.usagesDateCountDocuments,
        usageByMember: data.usagesMemberCountDocuments,
      },
    });
    dispatch({
      type: actions.CHART_FILTERS,
      payload: { chartFilters: { ...state.chartFilters, ...input } },
    });
    if (type === "start") {
      setStartDateToggle(false);
    } else {
      setEndDateToggle(false);
    }
  };
  const handleFilterByMember = async (member) => {
    const { data, status } = await getCreditsHistory({
      ...state.chartFilters,
      emails:
        (member && member.email && [member.email]) || state.chartFilters.emails,
    });
    dispatch({
      type: actions.GET_DATA_CHART,
      payload: {
        dataChart: data.usagesDateCountDocuments,
        usageByMember: data.usagesMemberCountDocuments,
      },
    });
    dispatch({
      type: actions.CHART_FILTERS,
      payload: {
        chartFilters: { ...state.chartFilters, emails: [member.email] || null },
      },
    });
  };
  const handleFilterBySource = async (sources) => {
    const { data, status } = await getCreditsHistory({
      ...state.chartFilters,
      sources: sources || undefined,
    });
    dispatch({
      type: actions.GET_DATA_CHART,
      payload: {
        dataChart: data.usagesDateCountDocuments,
        usageByMember: data.usagesMemberCountDocuments,
      },
    });
    dispatch({
      type: actions.CHART_FILTERS,
      payload: {
        chartFilters: {
          ...state.chartFilters,
          sources: sources || null,
        },
      },
    });
  };

  const handleFilterByCreditsType = async (creditTypes) => {
    const { data, status } = await getCreditsHistory({
      monthly: state.chartFilters.monthly,
      startDate: state.chartFilters.startDate,
      endDate: state.chartFilters.endDate,
      member: undefined,
      emails:
        (state.chartFilters.member && [state.chartFilters.member]) || undefined,
      creditTypes: creditTypes || undefined,
      sources: state.chartFilters.sources,
    });
    dispatch({
      type: actions.GET_DATA_CHART,
      payload: {
        dataChart: data.usagesDateCountDocuments,
        usageByMember: data.usagesMemberCountDocuments,
      },
    });
    dispatch({
      type: actions.CHART_FILTERS,
      payload: {
        chartFilters: {
          ...state.chartFilters,
          creditTypes: creditTypes || undefined,
        },
      },
    });
  };
  return (
    <div className={"credits-history-filters"}>
      <div
        className={"info-card-header credits-history-filters-head "}
        style={{ border: "none", paddingBottom: 2 }}
      >
        <p>
          <FormattedMessage id={"organization.creditUsage"} />
        </p>
        <div className={"pickers-wp"}>
          <SelectPicker
            values={[
              {
                name: intl.formatMessage({ id: "organization.monthly" }),
                id: "Monthly",
              },
              {
                name: intl.formatMessage({ id: "organization.daily" }),
                id: "Daily",
              },
            ]}
            withId
            onChange={handleViewBy}
          />
          <div className="export-dropdown-ui">
            <div className={"export-btn"}>
              {!checkPlanPermissions("Export") ? (
                <Permissions.CallToAction
                  toolTipStyle={{ width: 200 }}
                  withoutIcon={true}
                  toolTipPosition={"left"}
                  isPlan={true}
                >
                  <SelectPicker
                    values={["Export CSV", "Export Excel"]}
                    onChange={onCreditsUsagesExport}
                    labelsStyle={{ left: 0, width: "100%" }}
                    selectedLabelStyle={{ width: "125px" }}
                    staticLabel={"Export"}
                  />
                </Permissions.CallToAction>
              ) : (
                <SelectPicker
                  values={["Export CSV", "Export Excel"]}
                  onChange={onCreditsUsagesExport}
                  labelsStyle={{ left: 0, width: "100%" }}
                  selectedLabelStyle={{ width: "105px" }}
                  staticLabel={"Export"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="info-card-header ">
        <label>
          <FormattedMessage id={"organization.filterBy"} />
        </label>

        <MemberSelect
          members={[...state.organization.members, ...membersLeavedOrg]}
          onChange={handleFilterByMember}
          labelsStyle={{ width: 206, left: 0 }}
        />

        <div className={"sorting-block"} style={{ margin: 0 }}>
          <SelectThrowButton
            title={"All sources"}
            list={[
              {
                id: "plugin",
              },
              {
                id: "dashboard",
              },
              {
                id: "workflow",
              },
            ]}
            selectItem={handleFilterBySource}
            type={"source"}
          />
          {isVersion2 && (
            <SelectThrowButton
              title={"All credits"}
              list={[
                {
                  id: "workEmailCredits",
                },
                {
                  id: "personalEmailCredits",
                },
                {
                  id: "phoneCredits",
                },
                {
                  id: "exportCredits",
                },
              ]}
              selectItem={handleFilterByCreditsType}
              type={"credits"}
            />
          )}
          <DatePicker
            date={new Date(state.chartFilters.startDate)}
            dateToggle={startDateToggle}
            setDateToggle={setStartDateToggle}
            selectDate={(date) => handleFilterByDate(date, "start")}
            title={<FormattedMessage id={"organization.start"} />}
            minDate={state.chartFilters.minDate}
            monthly={state.chartFilters.monthly}
          />
          <DatePicker
            date={new Date(state.chartFilters.endDate)}
            dateToggle={endDateToggle}
            setDateToggle={setEndDateToggle}
            selectDate={(date) => handleFilterByDate(date, "end")}
            title={<FormattedMessage id={"organization.end"} />}
            minDate={state.chartFilters.minDate}
            monthly={state.chartFilters.monthly}
          />
        </div>
      </div>
    </div>
  );
}
function CreditTypeIcon({ type, stringLimit }) {
  const intl = useIntl();
  return (
    <span
      className="credit-icon"
      style={{
        color: CREDIT_COLORS[type].color,
        // background: CREDIT_COLORS[type].color + "12",
      }}
    >
      <img src={CREDIT_COLORS[type].icon} alt="" />
      {stringLimit ? (
        intl.formatMessage({ id: "billing." + type }).slice(0, stringLimit) +
        "..."
      ) : (
        <FormattedMessage id={"billing." + type} />
      )}
    </span>
  );
}
function SelectThrowButton({ title, list, selectItem, type }) {
  const [toggle, setToggle] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const intl = useIntl();
  return (
    <div
      className="datepicker-ui "
      style={{ width: "auto", margin: " 0 10px" }}
    >
      <div className="datepicker-icon">
        <span
          className="add-on dropdown-toggle"
          onClick={() => setToggle(!toggle)}
          style={{ marginRight: 10 }}
        >
          {type == "credits" &&
            (selectedItem ? (
              <CreditTypeIcon type={selectedItem} stringLimit={10} />
            ) : (
              title
            ))}

          {type == "source" &&
            (selectedItem
              ? intl.formatMessage({
                  id: "organization.filters." + selectedItem,
                })
              : title)}
        </span>
        <Animation.FadeIn
          toggle={toggle}
          setToggle={setToggle}
          style={{ position: "absolute", left: 0 }}
        >
          <div className={"date-picker-container drop-down-cstm"}>
            <div
              onClick={() => {
                selectItem(null);
                setSelectedItem(null);
                setToggle(false);
              }}
            >
              <span
                className="credit-icon"
                style={{
                  color: "#333",
                  marginLeft: type == "credits" ? 7 : 0,
                  fontWeight: 500,
                }}
              >
                {title}
              </span>
            </div>
            {type === "credits"
              ? list.map((creditType) => (
                  <div
                    onClick={() => {
                      selectItem([creditType.id]);
                      setSelectedItem(creditType.id);
                      setToggle(false);
                    }}
                  >
                    <CreditTypeIcon type={creditType.id} />
                  </div>
                ))
              : list.map((item) => (
                  <div className={"cstm-select-hover"}>
                    <span
                      onClick={() => {
                        selectItem([item.id]);
                        setSelectedItem(item.id);
                        setToggle(false);
                      }}
                    >
                      <FormattedMessage
                        id={"organization.filters." + item.id}
                      />
                    </span>
                  </div>
                ))}
          </div>
        </Animation.FadeIn>
      </div>
    </div>
  );
}
function DatePicker({
  date,
  dateToggle,
  setDateToggle,
  selectDate,
  title,
  minDate,
  monthly,
}) {
  const intl = useIntl();
  return (
    <div
      className="datepicker-ui "
      style={{ width: "auto", margin: " 0 10px", position: "relative" }}
    >
      <div className="datepicker-icon">
        <span
          className="add-on dropdown-toggle"
          onClick={() => setDateToggle(!dateToggle)}
          style={{ marginRight: 10 }}
        >
          <img
            src="/assets/images//date-icon.svg"
            alt=""
            style={{ width: 18 }}
          />{" "}
          <small>{title}</small> <small>{intl.formatDate(date)}</small>
        </span>
        <Animation.FadeIn
          toggle={dateToggle}
          setToggle={setDateToggle}
          style={{ left: -80, top: 40, position: "absolute" }}
        >
          <div className={"date-picker-container"} style={{ top: 0, left: 0 }}>
            <Calendar
              onChange={selectDate}
              value={date}
              className={"date-picker"}
              maxDate={new Date()}
              minDate={new Date(minDate)}
              view={monthly ? "year" : "month"}
              onClickMonth={selectDate}
              locale={"fr"}
            />
          </div>
        </Animation.FadeIn>
      </div>
    </div>
  );
}
export default HistoryChartFilters;
