const initState = {};

export const leads = (state = initState, action) => {
  let leads = state.leads;
  let index;
  switch (action.type) {
    case "GET_LEADS":
      state = {
        ...state,
        ...action.leads,
      };
      break;
    case "CHANGE_LABEL":
      index = leads.findIndex((lead) => lead._id === action.leadId);
      leads[index].label = action.labelId;

      state = {
        ...state,
        leads,
      };
      break;
    case "ADD_LEAD":
      leads = state.leads;
      leads.push(action.lead);
      state = {
        ...state,
        leads,
      };
      break;
    case "DELETE_LEAD":
      leads = leads.filter((lead) => lead._id !== action.leadId);
      let pages = state.pages ? state.pages.totalLeads : 0;
      state = {
        ...state,
        leads,
        pages,
      };
      break;
    case "UPDATE_LEAD":
      index = leads.findIndex((lead) => lead._id === action.lead._id);
      leads[index] = action.lead;
      state = {
        ...state,
        leads,
      };
      break;
    case "DELETE_LEADS":
      state = {};
      break;
    case "FILTER_LEADS":
      leads = action.leads;
      state = {
        ...state,
        leads,
        pages: action.pages,
      };
      // leads = action.leads;
      // state = leads;
      break;
    case "GET_AUTOMATION":
      state = {
        ...state,
        automationInfos: action.data.automations,
      };
      break;
  }
  return state;
};
