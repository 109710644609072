import React from "react";
import IntegrationHeader from "./IntegrationHeader/IntegrationHeader";
import AvailableIntegrations from "./AvailableIntegrations/AvailableIntegrations";
import IntegrationCarousel from "./IntegrationCarousel/IntegrationCarousel";

export default function Integration({ profile }) {
  return (
    <div id="integration">
      <IntegrationHeader />
      <IntegrationCarousel />
      <AvailableIntegrations profile={profile} />
    </div>
  );
}
