import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ModalMessage } from "../../../../../components";
import {
  actions,
  OrganizationContext,
} from "../../../store/Organization.store";

export default function ChooseAdminFromMemberModal({ setToggle, onSubmit }) {
  const { state, dispatch } = useContext(OrganizationContext);
  const [error, setError] = useState(null);

  const onChooseAdmins = (e) => {
    setError(null);
    let currentMembers = [...state.orgMembers];
    const numberOfSelectedAdmins = currentMembers.filter(
      (member) => member.checked
    ).length;
    currentMembers = state.orgMembers.map((member) => {
      if (member.email == e.target.id) {
        member.checked = numberOfSelectedAdmins < 1 ? e.target.checked : false;
      }
      return member;
    });
    dispatch({
      type: actions.SET_ORG_MEMBERS,
      payload: { orgMembers: currentMembers },
    });
    dispatch({
      type: actions.SET_SELECTED_ADMINS,
      payload: {
        selectedAdmins: currentMembers
          .filter((member) => member.checked)
          .map((member) => member.email),
      },
    });
  };

  const handleSubmit = () => {
    const allCheckedFalse = state.orgMembers.every(
      (member) => member.checked === false
    );
    if (allCheckedFalse)
      return setError(
        <FormattedMessage id="organization.youHaveToChooseOneOrMoreAdmins" />
      );
    onSubmit();
  };
  return (
    <ModalMessage
      toggle={true}
      setToggle={() => {
        setToggle(false);
      }}
      title={<FormattedMessage id={"organization.selectAdmins"} />}
      textElement={
        <div className={"choose-admins-container"}>
          <br />
          <strong>
            <FormattedMessage id="organization.chooseAnAdminForThisPlan" />
          </strong>
          <div className={"admins-list"}>
            {state.orgMembers.map((member) => (
              <div className="checkbox-wrap">
                <label htmlFor={member.email}>
                  <input
                    type="checkbox"
                    id={member.email}
                    onChange={onChooseAdmins}
                    checked={member.checked}
                  />
                  <span className="checkbox-icon" />
                  <span className="checkbox-text">{member.email}</span>
                </label>
              </div>
            ))}
          </div>

          <div
            style={{
              color: "red",
              fontSize: 15,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {error}
          </div>
        </div>
      }
      action={<FormattedMessage id={"billing.proceed"} />}
      disabled={{
        yes: !!!state.orgMembers.filter((member) => member.checked).length,
      }}
      handleAction={handleSubmit}
    />
  );
}
