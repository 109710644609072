import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Branch, Block, AttachBlock } from "./Tools";
import { actions } from "../../store/workflow.store";
import { FormattedMessage, injectIntl } from "react-intl";

function EnrichementTemplate({
  handleSettingsToggle,
  blockOptions,
  template,
  activeBlockId,
}) {
  const getBlock = (id) => {
    return template.blocks.find((block) => block.id == id);
  };

  useEffect(() => {
    if (!blockOptions) {
      handleSettingsToggle(getBlock(1));
    }
  }, []);

  return <></>;
}
EnrichementTemplate = injectIntl(EnrichementTemplate);

export { EnrichementTemplate };
