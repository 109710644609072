import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { BillingContext } from "../../store/billing.store";
import { PreLoader, Tooltip } from "../../../../components";
import { FormattedMessage, injectIntl } from "react-intl";
import { CheckoutFormWrapper, Field } from "../common/common.tools";
import SubscriptionInfo from "./SubscriptionInfo/SubscriptionInfo";
import DiscountSection from "./DiscountSection/DiscountSection";
import { ExtraCreditsTable } from "./ExtraCreditsTable/ExtraCreditsTable";
import BillingInfoSection from "./BillingInfoSection/BillingInfoSection";
import { CREDITS } from "../../../../constants";
import CurrencyDisplay from "../../../../components/CurrencyDisplay/CurrencyDisplay";

function OrderSummary({
  plan,
  organizationCredits,
  blockProceed,
  error,
  user,
}) {
  const [couponModalToggle, setCouponModalToggle] = useState(false);
  const { state } = useContext(BillingContext);
  if (!plan.credits) return <PreLoader />;
  const currentPlan = organizationCredits.plan;
  let checkCurrentExtraCredits = CREDITS.filter(
    (credit) => credit !== "legacyCredits"
  ).some((credit) => currentPlan[credit] && !!currentPlan[credit].extra);
  return (
    <CheckoutFormWrapper
      title={<FormattedMessage id={"billing.orderSummary"} />}
      style={{ marginTop: 30, marginBottom: 200 }}
    >
      <SubscriptionInfo
        plan={plan}
        currentPlan={currentPlan}
        user={user}
        organizationCredits={organizationCredits}
      />
      {(plan.extraCredits.length > 0 || checkCurrentExtraCredits) && (
        <ExtraCreditsTable
          currentPlan={currentPlan}
          plan={plan}
          user={user}
          organizationCredits={organizationCredits}
        />
      )}
      {organizationCredits.plan.planId == "0" && (
        <DiscountSection
          couponModalToggle={couponModalToggle}
          setCouponModalToggle={setCouponModalToggle}
          user={user}
          organizationCredits={organizationCredits}
        />
      )}
      <BillingInfoSection
        plan={plan}
        currentPlan={currentPlan}
        user={user}
        organizationCredits={organizationCredits}
      />
      <hr />
      <div className="inline-info total-line main-line">
        <span className="key-txt">
          <FormattedMessage id={"billing.dueToday"} />
        </span>
        <span>
          <strong>
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={(plan.total * 0.01).toFixed(2)}
            />
          </strong>{" "}
        </span>
      </div>

      <div className={"proceed-bottom-section"}>
        <div className="inline-info proceed-btm">
          <div className={"proceed-texts"}>
            {/*{state.checkoutForm.country === "France" && (
              <div>
                {" "}
                <label htmlFor={"terms"} className="secure-text-ui">
                  <input
                    type="checkbox"
                    style={{ marginRight: 10 }}
                    required
                    id={"terms"}
                  />
                  J'accepte de manière expresse et irrévocable de renoncer à mon
                  droit de rétraction prévu par les articles L 121-21-3 et
                  suivants du Code de la consommation pour pouvoir bénéficier
                  immédiatement du service commandé auprès de la Société avant
                  la fin du délai légal de rétractation
                </label>
              </div>
            )}*/}
            <div>
              Safe & Secure checkout. <br />
              Charge auto-renews - You can cancel anytime.
            </div>
          </div>
          <button
            type={"submit"}
            className="btn1"
            style={
              blockProceed
                ? { background: "#ccc", width: "max-content" }
                : { width: "max-content" }
            }
          >
            <span className="ic-lock">
              <img src="/assets/images/ic-lock.svg" alt="" />
            </span>
            {plan.total ? (
              <FormattedMessage id={"billing.proceedToPay"} />
            ) : (
              <FormattedMessage id={"billing.saveChanges"} />
            )}
            ·{" "}
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={(plan.total * 0.01).toFixed(2)}
            />
          </button>
        </div>
        {error && (
          <div
            style={{
              textAlign: "right",
              paddingRight: "19%",
              paddingTop: 10,
              color: "darkred",
            }}
          >
            {error}
          </div>
        )}
      </div>

      {/*   <BottomBanner toggle={true} hideClose>
        <div className={"proceed-bottom-section"}>
          <button className={"btn btn1"}>Proceed</button>
        </div>
      </BottomBanner>*/}
    </CheckoutFormWrapper>
  );
}

export default injectIntl(OrderSummary);
