import ReactDOM from "react-dom";
import "./PopUp.css";
import React, { useEffect, useRef, useState } from "react";

function PopUpPortal({
  children,
  toggle,
  onClose,
  withoutClose,
  style,
  styleClose,
}) {
  const ref = useRef(null);
  const [show, setShow] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setShow("show");
    }, 0);
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [toggle]);

  const handleClickOutSide = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handleClose();
    }
  };
  const handleClose = () => {
    if (onClose) {
      setShow("");
      setTimeout(() => {
        onClose();
      }, 100);
    }
  };
  return (
    <div id={"popup"} className={show} ref={ref}>
      <div className={"popup-content"} style={style}>
        <div className={"head-bottom-popup"} style={styleClose}>
          {!withoutClose && (
            <span onClick={handleClose}>
              <img src="/assets/images/ic-cross.png" alt="close" />
            </span>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
export default function PopUp(props) {
  return ReactDOM.createPortal(<PopUpPortal {...props} />, document.body);
}
