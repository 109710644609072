import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import all_countries from "../../common/countriesStates";
import StripeField from "./StripeField";
import { BillingContext } from "../../store/billing.store";
import actions from "../../store/billing.actions";

import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { CheckoutFormWrapper, Field } from "../common/common.tools";
import { getSubscriptionInfos } from "../../../../services/api/credits.api";
import { formatSubscribeInfo } from "../../../../services/utils/tools";
export default function CheckoutForm({
  organizationCredits,
  changeCardToggle,
  setChangeCardToggle,
  updatePaymentToggle,
  error,
}) {
  const { state, dispatch } = useContext(BillingContext);
  const [allCountries, setAllCountries] = useState(null);
  const intl = useIntl();
  let countries = useMemo(() => all_countries, [all_countries]);
  useEffect(() => {
    setAllCountries(countries);
    const choosenCountry = countries.find(
      (cntry) => cntry.name === state.checkoutForm.country
    );
    dispatch({
      type: actions.SET_COUNTRY_STATES,
      payload: choosenCountry.states,
    });
    if (state.profile && state.profile.email) {
      dispatch({
        type: actions.ON_CHECKOUT_FORM,
        payload: { ...state.checkoutForm, email: state.profile.email },
      });
    }
  }, []);
  useEffect(() => {
    if (organizationCredits.customer && !state.checkoutForm.email) {
      const customerCountry = countries.find(
        (cntry) => cntry.name === organizationCredits.customer.country
      );
      //    setStates(customerCountry ? customerCountry.states : states);
      dispatch({
        type: actions.SET_COUNTRY_STATES,
        payload: customerCountry ? customerCountry.states : state,
      });

      if (
        customerCountry &&
        customerCountry.states &&
        customerCountry.states.length === 0
      ) {
        organizationCredits.customer.state = "";
      }
      if (
        customerCountry &&
        customerCountry.states &&
        customerCountry.states.length > 0 &&
        !organizationCredits.customer.state
      ) {
        organizationCredits.customer.state = customerCountry.states[0].name;
      }

      dispatch({
        type: actions.ON_CHECKOUT_FORM,
        payload: { ...state.checkoutForm, ...organizationCredits.customer },
      });
    }
  }, [organizationCredits.customer]);

  if (!allCountries) {
    return <div />;
  }
  const handleSelectCountry = async (selected) => {
    dispatch({
      type: actions.ON_CHECKOUT_FORM,
      payload: {
        ...state.checkoutForm,
        country: selected.name,
        state:
          selected.states && selected.states.length > 0
            ? selected.states[0].name
            : null,
      },
    });
    dispatch({ type: actions.SET_COUNTRY_STATES, payload: selected.states });
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats: state.selectedPlan.numberOfSeats,
      numberOfMonths: state.selectedPlan.numberOfMonths,
      planId: state.selectedPlan.planId,
      extraCredits: state.selectedPlan.extraCreditsToUpgrade.map((credit) => ({
        amount: credit.addedCredits,
        creditType: credit.creditType,
      })),
      country: selected.name,
    });

    dispatch({
      type: actions.GET_SUBSCRIPTION_INFOS,
      payload: {
        ...state.selectedPlan,
        isPrivate: state.selectedPlan.isPrivate,
        automaticRenewal: !!data.nextPeriodItems.length,
        renewDate: data.renewDate,
        ...formatSubscribeInfo(data),
        credits: state.selectedPlan.credits,
      },
    });
  };

  const handleSelectState = (selected) => {
    if (selected) {
      dispatch({
        type: actions.ON_CHECKOUT_FORM,
        payload: { ...state.checkoutForm, state: selected.name },
      });
    }
  };
  const handleOnChange = (value, type) => {
    const checkoutForm = state.checkoutForm;
    checkoutForm[type] = value;
    dispatch({ type: actions.ON_CHECKOUT_FORM, payload: checkoutForm });
  };

  return (
    <CheckoutFormWrapper
      title={<FormattedMessage id={"billing.billingInformation"} />}
    >
      {organizationCredits.customer &&
      organizationCredits.customer.cardInfo &&
      !changeCardToggle ? (
        <PaymentInfos
          customer={organizationCredits.customer}
          setChangeCardToggle={setChangeCardToggle}
        />
      ) : (
        <div className="payment-info-wrap">
          <div className="line-heading">
            <label>
              {" "}
              <FormattedMessage id={"billing.paymentInfo"} />
            </label>
          </div>
          <StripeField />
        </div>
      )}
      <div className="payment-info-wrap">
        <div className="line-heading">
          <label>
            <FormattedMessage id={"billing.companyInfo"} />
          </label>
        </div>
        <div className="card-detail-ui form-fields-wrapper">
          <div className={"fields-wrapper"}>
            <FormattedMessage id={"billing.companyName.placeholder"}>
              {(placeholder) => (
                <Field
                  label={<FormattedMessage id={"billing.companyName"} />}
                  placeholder={placeholder}
                  onChange={(e) =>
                    handleOnChange(e.target.value, "companyName")
                  }
                  value={state.checkoutForm.companyName}
                  required={true}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id={"billing.companyAddress.placeholder"}>
              {(placeholder) => (
                <Field
                  label={<FormattedMessage id={"billing.companyAddress"} />}
                  placeholder={placeholder}
                  onChange={(e) => handleOnChange(e.target.value, "address")}
                  value={state.checkoutForm.address}
                  required={true}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="fields-wrapper">
            <CountriesSelect
              label={<FormattedMessage id={"billing.country"} />}
              options={allCountries}
              onChange={handleSelectCountry}
              selected={state.checkoutForm.country}
              required={true}
            />
            {state.countryStates && state.countryStates.length > 0 && (
              <StatesSelect
                label={<FormattedMessage id={"billing.state"} />}
                options={state.countryStates}
                onChange={handleSelectState}
                selected={state.checkoutForm.state}
              />
            )}
            <FormattedMessage id={"billing.vatNumber.placeholder"}>
              {(placeholder) => (
                <Field
                  label={<FormattedMessage id={"billing.vatNumber"} />}
                  placeholder={placeholder}
                  onChange={(e) => handleOnChange(e.target.value, "vat")}
                  value={state.checkoutForm.vat}
                />
              )}
            </FormattedMessage>
            <Field
              label={<FormattedMessage id={"billing.emailAddressInvoice"} />}
              placeholder={"user@kaspr.io"}
              onChange={(e) => handleOnChange(e.target.value, "email")}
              value={state.checkoutForm.email}
              isEmailField={true}
              required
            />
          </div>
        </div>
      </div>
      {updatePaymentToggle && (
        <div className="btn-block">
          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{ top: -10, width: "90%" }}
            >
              {error + " "}
            </div>
          )}
          <button type={"submit"} className="btn1">
            <span className="ic-lock">
              <img src="/assets/images/ic-lock.svg" alt="" />
            </span>
            <FormattedMessage id={"confirm"} />
          </button>
        </div>
      )}
    </CheckoutFormWrapper>
  );
}
function CountriesSelect({ options, label, onChange, selected, required }) {
  return (
    <div className="form-group half pl-12">
      <label>{label}</label>
      <div className="mycustom-select">
        <select
          className="my-select"
          onChange={(e) =>
            onChange(options.find((country) => country.name === e.target.value))
          }
          value={selected || "France"}
          required={required}
        >
          {options.map((opt, i) => (
            <option key={i} id={opt.name}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
function StatesSelect({ options, label, onChange, selected }) {
  const [alloptions, setOptions] = useState(null);
  useEffect(() => {
    setOptions([]);
    setOptions(options);
  }, [options]);
  return (
    <div className="form-group half pl-12">
      <label>{label}</label>
      {alloptions && (
        <div className="mycustom-select">
          <select
            className="my-select"
            onChange={(e) =>
              onChange(
                alloptions.find((state) => state.name === e.target.value)
              )
            }
            value={selected}
          >
            {alloptions.map((opt, i) => (
              <option key={i} id={opt.name}>
                {opt.name}{" "}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

function PaymentInfos({ customer, setChangeCardToggle }) {
  return (
    <div className="payment-info-wrap">
      <div className="line-heading">
        <label>
          {" "}
          <FormattedMessage id={"billing.paymentInfo"} />
        </label>
      </div>
      <div className="card-detail-ui">
        <div className="card-info card-type">
          <span className="ic-visa">
            <img src="/assets/images/ic-billing-active.png" alt="" />
          </span>{" "}
          · ****{customer.cardInfo.last4}
        </div>
        <div className="card-info card-expiry">
          <FormattedMessage id={"billing.expiry"} />{" "}
          {customer.cardInfo.expMonth + "/" + customer.cardInfo.expYear}
        </div>
      </div>

      <div className="card-info change-card text-right">
        <a
          href="#"
          className="text-btn"
          onClick={() => setChangeCardToggle(true)}
        >
          <FormattedMessage id={"billing.changeCard"} />
        </a>
      </div>
    </div>
  );
}
