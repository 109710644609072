import React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function LinkedinIntegrationCard({
  category,
  name,
  imagesrc,
  account,
  text,
  disabled,
  moreInfoLink,
  apiKeyLink,
  handleConnectionModal,
  setSelectedIntegration,
}) {
  const handleConnectLinkedin = (e) => {
    if (e) e.stopPropagation();
    window.open("https://www.linkedin.com/feed/?synchro=true");
  };

  const selectIntegration = () => {
    if (!disabled)
      setSelectedIntegration({
        integration: name,
        category,
        imagesrc,
        account,
        text,
        disabled,
        moreInfoLink,
        apiKeyLink,
      });
  };

  return (
    <Col sm={6} md={6} lg={4}>
      <div
        className={"integrations-card " + (disabled ? "disabled-card" : "")}
        onClick={(e) => {
          if (!disabled) {
            selectIntegration();
            handleConnectionModal(e);
          }
        }}
      >
        <span className="custom-tag-ui">
          {" "}
          <FormattedMessage id={"tags.recommended"} />{" "}
        </span>

        {/* HEADER*/}
        <div className="top-block">
          <div className="left-ui">
            <label>{category}</label>
            <p className="title username">{name}</p>
          </div>
          <div className="right-ui">
            <figure>
              <img src={imagesrc} alt="" />
            </figure>
          </div>
        </div>

        {/* BODY */}
        <div className="middle-content-ui">
          <p>{text}</p>
        </div>

        {/* FOOTER */}
        {account ? (
          <div className="middle-content-ui">
            <p>{"Linkedin account: " + account}</p>
          </div>
        ) : (
          <div className="bottom-ui">
            <button
              className="connect-btn"
              onClick={!disabled && handleConnectLinkedin}
            >
              <FormattedMessage id={"settings.profile.connect"} />
            </button>
            <button className="more-info-ui">
              {" "}
              <FormattedMessage id={"info.moreInfo"} />
            </button>
          </div>
        )}
      </div>
    </Col>
  );
}

export default LinkedinIntegrationCard;
