import React, { useContext, useEffect, useState } from "react";
import CurrentWorkflow from "./Workflows/CurrentWorkflow";
import Performance from "./Performance/Performance";
import Profiles from "./Profiles/Profiles";
import {
  deleteWorkflow,
  getFlowInputs,
  getPerformance,
  getProfiles,
  getWorkflow,
  getWorkflows,
  updateBlock,
  updateWorkflow,
  updateWorkflowActivationState,
  workflowRelaunch,
} from "../../../../services/api/workflows.api";
import { actions, WorkflowContext } from "../../store/workflow.store";
import {
  ModalMessage,
  Permissions,
  PreLoader,
  Tooltip,
} from "../../../../components";
import Automations from "./Automations/Automations";
import { checkPermission } from "../../../../services/utils";
import { ToastContainer, toast } from "react-toastify";
import DeleteWorkflowModal from "../../common/DeleteWorkflowModal/DeleteWorkflowModal";
import { Form, OverlayTrigger } from "react-bootstrap";
import { checkIsPlanLimited } from "../../../../services/utils/checkPermissions";
import { FormattedMessage, injectIntl } from "react-intl";
import { usePermissions } from "../../../../components/Permissions/permissions.hooks";
import CustomToolTip from "../../../../components/CustomToolTip/CustomToolTip";

function MyWorkflow({ workflow, profile, organizationCredit, intl }) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [editWorkflowName, setEditWorkflowName] = useState(false);
  const [workflowName, setWorkflowName] = useState(workflow.name);
  const [workflowToRemove, setWorkflowToRemove] = useState(null);
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    organizationCredit &&
      organizationCredit.plan &&
      organizationCredit.plan.permissions,
    profile.permissions,
    "Workflow"
  );

  useEffect(() => {
    getPerformance(workflow._id).then(({ data }) => {
      dispatch({ type: actions.GET_PERFORMANCE, payload: data.performance });
    });
    getProfiles({ workflow: workflow._id, query: "" }).then(({ data }) => {
      dispatch({ type: actions.GET_PROFILES, payload: data });
    });
    if (workflow.type !== "integration") {
      getFlowInputs({ workflow: workflow._id, page: 0 }).then(
        ({ data, statusCode }) => {
          if (statusCode == 200) {
            data.flowInputs.unshift({
              name: intl.formatMessage({ id: "all" }),
              id: null,
            });
            dispatch({ type: actions.GET_AUTOMATIONS, payload: data });
          }
        }
      );
    }
  }, []);

  const handleGoToProfile = (profile) => {
    getProfiles({ workflow: workflow._id, blocks: profile.profilesIds }).then(
      ({ data }) => {
        dispatch({
          type: actions.GET_PROFILES,
          payload: { ...data, profileIds: profile.profilesIds },
        });
      }
    );
    document.querySelector("#profiles").click();
  };

  const handleDeleteWorkflow = async () => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Edit",
      })
    ) {
      const { data, statusCode } = await deleteWorkflow(workflow._id);
      if (statusCode === 200) {
        const { data: wkData, statusCode: wkStatus } = await getWorkflows();
        if (wkStatus === 200) {
          dispatch({ type: actions.SELECTED_TEMPLATE, payload: null });
          dispatch({ type: actions.GET_WORKFLOWS, payload: wkData });
          setWorkflowToRemove(false);
        }
      }
    } else {
      setWorkflowToRemove(false);

      toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
  };

  const hanldeChangeWokrflowName = async () => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Edit",
      })
    ) {
      const updateWk = workflow;
      updateWk.name = workflowName;
      const { data, statusCode } = await updateWorkflow(updateWk);
      setEditWorkflowName(false);
    } else {
      toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
  };

  if (!state.selectedTemplate) {
    return <PreLoader />;
  }

  if (workflow.type !== "integration" && !state.flowInputs) {
    return <PreLoader />;
  }

  return (
    <MyWorkflowWrapper>
      <MyWorkflowHeader
        workflow={workflow}
        profile={profile}
        organizationCredit={organizationCredit}
      />
      <div className="tab-content">
        <CurrentWorkflow
          workflow={workflow}
          profile={profile}
          organizationCredits={organizationCredit}
          workflowName={workflowName}
          setWorkflowName={setWorkflowName}
          hanldeChangeWokrflowName={hanldeChangeWokrflowName}
          setWorkflowToRemove={setWorkflowToRemove}
        />
        {workflow.type !== "integration" && (
          <Performance
            performance={state.performance}
            goToProfile={handleGoToProfile}
            wkType={workflow.type}
          />
        )}
        {state.profiles && workflow.type !== "integration" && (
          <Profiles
            profiles={state.profiles}
            workflow={workflow}
            flowInputs={state.flowInputs.flowInputs}
            profilePermissions={profile.permissions}
            planPermissions={
              organizationCredit && organizationCredit.plan.permissions
            }
          />
        )}
        {workflow.type !== "integration" && (
          <Automations
            flowInputs={state.flowInputs.flowInputs.filter(
              (automation) => automation.id
            )}
            workflow={workflow}
            numberOfPages={state.flowInputs.numberOfPages}
            profile={profile}
          />
        )}
      </div>
      <ToastContainer position="bottom-right" style={{ top: 100 }} />
      {workflowToRemove && (
        <DeleteWorkflowModal
          toggle={workflowToRemove}
          setWorkflowToRemove={setWorkflowToRemove}
          handleRemoveWorfklow={handleDeleteWorkflow}
        />
      )}
    </MyWorkflowWrapper>
  );
}
function MyWorkflowHeader({ workflow, profile, organizationCredit }) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [workflowToRemove, setWorkflowToRemove] = useState(null);
  const planPermission = checkIsPlanLimited({
    permissions: organizationCredit && organizationCredit.plan.permissions,
    name: "Workflow",
  });
  const handleGetProfiles = () => {
    getProfiles({ workflow: workflow._id }).then(({ data }) => {
      dispatch({ type: actions.GET_PROFILES, payload: data });
    });
  };
  const handleWorkflowActivation = async (e) => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Edit",
      }) &&
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Execute",
      })
    ) {
      workflow.active = e.target.checked;
      const { data, statusCode } = await updateWorkflowActivationState({
        _id: workflow._id,
        active: e.target.checked,
      });
      if (statusCode === 200) {
        const { data: wkData, statusCode: wkStatus } = await getWorkflow(
          workflow._id
        );
        if (wkStatus === 200) {
          dispatch({ type: actions.GET_WORKFLOWS, payload: wkData });
        }
      }
    } else {
      toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
  };

  const handleRefresh = async () => {
    const { data, statusCode } = await workflowRelaunch({
      workflow: workflow._id,
      type: state.selectedTemplate.type,
    });
    if (statusCode == 200) {
      toast.success(<FormattedMessage id={"workflow.yourWorkflow"} />);
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    handleGetProfiles();
  }, []);

  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li>
            <a
              data-toggle="tab"
              id={"workflow"}
              className="active"
              href="#workflow_tab"
            >
              <FormattedMessage id="organization.settings" />
            </a>
          </li>
          {workflow.type !== "integration" && (
            <li>
              <a data-toggle="tab" id={"performance"} href="#performance_tab">
                <FormattedMessage id={"workflow.performance"} />
              </a>
            </li>
          )}
          {workflow.type !== "integration" ? (
            <li>
              <a data-toggle="tab" id={"automations"} href="#automations_tab">
                <FormattedMessage id={"workflow.searches"} />
              </a>
            </li>
          ) : (
            <li>
              {organizationCredit.plan && (
                <span
                  className={"pushes-remaining"}
                  style={{
                    ...(organizationCredit.plan.integrationPushes == 0
                      ? { color: "#ca5f1a", borderColor: "#ca5f1a" }
                      : {}),
                  }}
                >
                  <strong>
                    {" "}
                    {organizationCredit.plan.integrationPushes == -1 ? (
                      <img
                        className={"infinity-icon"}
                        src="/assets/images/infinity-solid.svg"
                        alt=""
                      />
                    ) : (
                      organizationCredit.plan.integrationPushes
                    )}{" "}
                    Exports{" "}
                    {organizationCredit.plan.integrationPushes == -1
                      ? ""
                      : "remaining"}
                  </strong>{" "}
                </span>
              )}
            </li>
          )}
          {workflow.type !== "integration" && (
            <li>
              <a data-toggle="tab" id={"profiles"} href="#profile_tab">
                <span onClick={handleGetProfiles}>
                  {" "}
                  <FormattedMessage id={"workflow.profiles"} />
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      {/* RIGHT NAV BUTTONS */}
      <div className="btn-blocks" style={{ marginRight: 15 }}>
        {state.selectedTemplate.active ? (
          <div className="tag active-tag">
            {" "}
            <FormattedMessage id={"workflow.active"} />
          </div>
        ) : (
          <div className="tag enrichment-tag">
            {" "}
            <FormattedMessage id={"workflow.disabled"} />
          </div>
        )}

        <div className="switch-btn">
          {planPermission ? (
            <OverlayTrigger
              key={"plan-permission"}
              placement={"top"}
              overlay={(props) =>
                Tooltip({
                  ...props,
                  data: <FormattedMessage id={"permissions.upgradePlan"} />,
                })
              }
            >
              <input
                type="checkbox"
                className="switch"
                onChange={() => false}
                checked={false}
              />
            </OverlayTrigger>
          ) : (
            <input
              type="checkbox"
              className="switch"
              onChange={handleWorkflowActivation}
              checked={state.selectedTemplate.active}
            />
          )}
        </div>
        <OverlayTrigger
          key={"plan-permission"}
          placement={"top"}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: <FormattedMessage id={"workflow.refreshText"} />,
            })
          }
        >
          <div className={"delete-icon-btn"} onClick={handleRefresh}>
            <img src="/assets/images/sync-alt-solid.svg" width={20} alt="" />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
}

function MyWorkflowWrapper({ children }) {
  return (
    <div className="col-md-10 content-wrap" style={{ marginBottom: 70 }}>
      <div className="content-block">
        <div className="inner-content-wrap">
          <div className="wd-wrapper">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(MyWorkflow);
