import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./CreateWorkflow.css";
import {
  EnrichementTemplate,
  // IntegrationTemplate,
} from "../common/Templates/Templates";
import TemplateSettings from "../common/Templates/TemplateSettings";
import {
  createWorkflow,
  getTemplates,
  updateBlock,
  updateWorkflowActivationState,
} from "../../../services/api/workflows.api";
import { WorkflowContext } from "../store/workflow.store";
import actions from "../store/workflow.actions";
import { InfoToolTip, ModalMessage, PreLoader } from "../../../components";
import { checkPermission, dfs } from "../../../services/utils";
import { toast, ToastContainer } from "react-toastify";
import { FormattedMessage } from "react-intl";
import IntegrationsDropdown from "./IntegrationsDropdown/IntegrationsDropdown";
import allIntegrations from "../common/allIntegrations.json";
import { getAccounts } from "../../../services/api/integrations.api";
import MappingModal from "../../Settings/Integration/Modals/MappingModal/MappingModal";
import WorkflowNavBar from "../common/WorkflowNavBar/WorkflowNavBar";

const handleOnChange = (checked, type, blockOptions, state, dispatch) => {
  let template = state.selectedTemplate;
  template.blocks = state.selectedTemplate.blocks.map((block) => {
    if (block.id === blockOptions.id) {
      //dataToGet: {workEmails: true, phones: true, directEmails: true}
      if (["workEmails", "phones", "directEmails"].indexOf(type) > -1) {
        block.options.dataToGet = { ...block.options.dataToGet };
        block.options.dataToGet[type] = checked;
      } else {
        block.options[type] = checked;
      }
    }
    return block;
  });
  dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });
};

function WorkflowTreeWrapper({ children }) {
  return (
    <div className="content-block">
      <div className="inner-content-wrap">
        <div className="create-workflow-container">{children}</div>
      </div>
    </div>
  );
}

function CreateWorkflow({ navToggle, profile, organizationCredits }) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [blockOptions, setBlockOptions] = useState(false);
  const [name, setName] = useState("");
  const [verifyActivation, setVerifyActivation] = useState(null);
  const [activationError, setActivationError] = useState(null);
  const { name: workflowName } = useParams();
  const { integration: integrationName } = useParams();
  const nameRef = useRef(null);
  const [integrations, setIntegrations] = useState([]);
  // Mapping modal states
  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [isBlockAttached, setIsBlockAttached] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const _integrations = allIntegrations.filter(
        (intg) => intg.active && intg.name !== "linkedin"
      );
      let { data, statusCode } = await getAccounts();
      if (data && data.accounts)
        _integrations.map((intg) => {
          intg.isConnected = data.accounts[intg.name];
          return intg;
        });
      setIntegrations(_integrations);
    };

    getTemplates().then(({ data, statusCode }) => {
      if (statusCode == 200) {
        // dispatch({ type: actions.GET_TEMPLATES, payload: data.templates });
        const myTemplate = data.templates.find(
          (template) => template.type === workflowName.toLowerCase()
        );
        if (integrationName) {
          myTemplate.blocks = [
            {
              id: 1,
              name: integrationName,
              action: "integration-" + integrationName,
              type: "flow_action",
              options: { email: "" },
            },
          ];
        }
        if (myTemplate.type == "enrichment") {
          myTemplate.blocks = myTemplate.blocks.map((block) => {
            if (block.id == "1") {
              block.options.addLead = true;
            }
            return block;
          });
        }
        dispatch({
          type: actions.SELECTED_TEMPLATE,
          payload: myTemplate,
        });
      } else {
        dispatch({ type: actions.ERROR, payload: data.message });
      }
    });
    loadData();
  }, []);

  const getBlock = (id) => {
    return state.selectedTemplate.blocks.find((block) => block.id == id);
  };

  const handleCreateWorkflow = async (wkName) => {
    if (
      checkPermission({
        permissions: profile.permissions,
        name: "Workflow",
        action: "Edit",
      })
    ) {
      const workflow = state.selectedTemplate;
      workflow.name = wkName;
      // await updateBlock(blockOptions);
      if (!isBlockAttached) getBlock(2);
      const { data, statusCode } = await createWorkflow(workflow);
      if (statusCode === 200) {
        setVerifyActivation(data.workflow);
        toast.success(<FormattedMessage id="successfullySaved" />);
      }
    } else {
      toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
  };
  const handleWorkflowActivation = async () => {
    const { data, statusCode } = await updateWorkflowActivationState({
      _id: verifyActivation._id,
      active: true,
    });
    if (statusCode == 200) {
      setVerifyActivation(null);
      window.location = "/workflow";
    } else {
      setActivationError(data.message);
    }
  };
  const handleCancelActivation = () => {
    setVerifyActivation(false);
    window.location = "/workflow";
  };
  const handleRefreshAndCloseSettings = (save) => {
    setBlockOptions(false);
  };

  const SELECTED_TEMPLATE = {
    enrichment: (
      <EnrichementTemplate
        blockOptions={blockOptions}
        handleSettingsToggle={(blockOptions) => setBlockOptions(blockOptions)}
        template={state.selectedTemplate}
        // activeBlockId={blockOptions.id}
      />
    ),
    // integration: (
    //   <IntegrationTemplate
    //     blockOptions={blockOptions}
    //     handleSettingsToggle={(blockOptions) =>
    //         setBlockOptions(blockOptions)
    //     }
    //     template={state.selectedTemplate}
    //     integrationName={integrationName}
    //     activeBlockId={blockOptions.id}
    //   />
    // ),
  };
  const handleMappingModal = (e) => {
    if (e) e.stopPropagation();
    setIsMappingModalOpen(!isMappingModalOpen);
  };

  if (!state.selectedTemplate) {
    return <PreLoader />;
  }

  if (isConnectionModalOpen) {
    window.location.href = "/settings/integration";
  }

  return (
    <Col
      md={"10"}
      className={"content-wrap tab-pane  " + (navToggle ? "expand" : "")}
      id="salesautomation-settings"
    >
      <WorkflowNavBar
        handleCreateWorkflow={handleCreateWorkflow}
        name={name}
        blockOptions={blockOptions}
        setVerifyActivation={setVerifyActivation}
        profile={profile}
      />
      <WorkflowTreeWrapper>
        <div
          className="workflow-main-container"
          style={{ marginBottom: "40px" }}
        >
          <div id="salesautomationcontent">
            <div className="workflow-input-field">
              {/*  GENERAL */}
              <h1>
                <FormattedMessage id="workflows.general" />
              </h1>
              <div className="wkinput">
                <h3>
                  <FormattedMessage id={"workflow.workflowName"} />
                </h3>
                <FormattedMessage id={"workflow.workflowName"}>
                  {(placeholder) => (
                    <input
                      type="text"
                      id="wk-name-input"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={placeholder}
                      required
                      ref={nameRef}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="owner-container">
                <h3>
                  <FormattedMessage id="organization.owner" />
                </h3>
                <div className="owner">
                  {profile.firstName} {profile.lastName}
                </div>
              </div>
              <div>
                {/* TEST TEMPLATE SETTINGS */}
                <TemplateSettings
                  blockOptions={blockOptions}
                  profile={profile}
                  organizationCredits={organizationCredits}
                  integrations={integrations}
                  setIsMappingModalOpen={setIsMappingModalOpen}
                  setIsConnectionModalOpen={setIsConnectionModalOpen}
                  setBlockOptions={setBlockOptions}
                  handleSettingsToggle={(blockOptions) =>
                    setBlockOptions(blockOptions)
                  }
                  setIsBlockAttached={setIsBlockAttached}
                />
              </div>
            </div>
            <div className="salesautomation-integrations"></div>
          </div>

          {SELECTED_TEMPLATE[workflowName.toLowerCase()]}
        </div>
      </WorkflowTreeWrapper>

      {/* MAPPING MODAL */}
      {isMappingModalOpen && (
        <MappingModal
          integration={state.selectedIntegration}
          show={isMappingModalOpen}
          onHide={handleMappingModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}

      {/* ACTIVATE NOW MODAL */}
      {verifyActivation && (
        <ModalMessage
          title={
            <>
              <FormattedMessage id={"activate"} /> {verifyActivation.name}{" "}
            </>
          }
          textElement={
            <>
              <p>
                <strong>
                  <FormattedMessage
                    id={"workflow.byDefaultTheWorkflowIsDisabled"}
                  />
                </strong>
                <br />
                <FormattedMessage id={"workflow.pleaseClickToConfirm"} />
              </p>
              {activationError == "Permission denied" && (
                <div style={{ textAlign: "center", color: "darkred" }}>
                  <div>
                    <FormattedMessage id={"permissions.upgradePlan"} />
                  </div>
                  <a
                    className={"btn btn1"}
                    style={{ marginTop: 10 }}
                    href={"/billing"}
                  >
                    <FormattedMessage id={"organization.upgradePan"} />
                  </a>
                </div>
              )}
            </>
          }
          action={<FormattedMessage id={"confirm"} />}
          toggle={verifyActivation}
          setToggle={handleCancelActivation}
          handleAction={handleWorkflowActivation}
          error={activationError !== "Permission denied" && activationError}
        />
      )}

      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </Col>
  );
}

export default CreateWorkflow;
