import React from "react";
import { OrganizationContext } from "./Organization.store";
import { PropTypes } from "prop-types";
function OrganizationProvider({ children, store }) {
  return (
    <OrganizationContext.Provider value={store}>
      <main id="organization">{children}</main>
    </OrganizationContext.Provider>
  );
}

OrganizationProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default OrganizationProvider;
