import React, { useContext, useEffect, useRef, useState } from "react";
import { integrationUpdatedNames } from "../../../../Settings/Integration/constants";
import { capitalizeFirstLetter } from "../../../../../services/utils/tools";
import { getAccounts } from "../../../../../services/api/integrations.api";
import { actions, WorkflowContext } from "../../../store/workflow.store";
import { FormattedMessage } from "react-intl";
import "./IntgDropdown.css";
import { updateBlock } from "../../../../../services/api/workflows.api";
import { DASHBOARD_URL, BASE_URL } from "../../../../../constants";
import { Integrations } from "@sentry/browser";

const IntgDropdown = ({
  existingWk,
  integrations,
  selectWorkflowToAttach,
  setSelectedIntegration,
  defaultName,
  setIsMappingModalOpen,
  setIsConnectionModalOpen,
  setIsBlockAttached,
  handleAttachBlock,
  currentWorkflow,
  setIsWkAttached,
  isWkAttached,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState({});
  const [selectedOptionError, setSelectedOptionError] = useState(false);
  const [selectedOptionConnected, setSelectedOptionConnected] = useState(false);
  const { state, dispatch } = useContext(WorkflowContext);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!currentWorkflow || (currentWorkflow && !isWkAttached))
      setIsOpen(!isOpen);
  };

  const selectOption = (integration) => {
    setSelectedOption({ type: "integration", name: integration.name });
    dispatch({
      type: actions.SET_SELECTED_INTEGRATION,
      payload: integration.name,
    });
    if (integration.name !== defaultName && !integration.isConnected) {
      setSelectedOptionError(true);
      setSelectedOptionConnected(false);
    } else if (
      integration.name === defaultName ||
      (integration.name !== defaultName && integration.isConnected)
    ) {
      setSelectedOptionError(false);
      setSelectedOptionConnected(true);
    }

    // DISPATCH TO DO
    setIsOpen(false);
  };

  const handleClickWk = async (workflow) => {
    if (!currentWorkflow) handleAttachBlock([workflow]);
    selectWorkflowToAttach(workflow);
    setSelectedOption({ type: "workflow", name: workflow.name });
    setSelectedOptionError(false);
    dispatch({
      type: actions.SELECTED_INTG_DROPDOWN,
      payload: [workflow],
    });
    setIsOpen(false);
  };

  const handleClickIntegration = (integration) => {
    setSelectedIntegration(integration.name);
    selectOption(integration);
    dispatch({
      type: actions.SELECTED_INTG_DROPDOWN,
      payload: [integration],
    });
  };

  const handleGoToWorkflow = () => {
    const workflow_id = currentWorkflow.blocks[1].options.workflowId;
    window.open(DASHBOARD_URL + "/workflow/" + workflow_id);
  };

  const getBlock = (id) => {
    return state.selectedTemplate.blocks.find((block) => block.id == id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    if (selectedOption.name === defaultName) {
      setIsBlockAttached(false);
    } else {
      setIsBlockAttached(true);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedOption]);

  const workflowId =
    currentWorkflow &&
    currentWorkflow.blocks &&
    currentWorkflow.blocks[1].options.workflowId;
  let selectedValue = {};

  let found = false;
  if (workflowId) {
    if (existingWk.length > 0 && workflowId) {
      existingWk.map((wk) => {
        if (workflowId === wk._id) {
          selectedValue = { type: "workflow", name: wk.name };
          found = true;
        }
      });
    }
    if (!found) {
      selectedValue = {
        type: "integration",
        name: currentWorkflow.blocks[1].options.name,
      };
    }
  } else {
    selectedValue = selectedOption ? selectedOption : defaultName;
  }

  return (
    <>
      <div
        id="intg-dropdown"
        style={{
          height: isOpen ? "40vh" : "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: isOpen ? "flex-start" : "normal",
        }}
        className={selectedOptionConnected ? "flex-row" : ""}
      >
        <div className={`dropdown-container`} ref={dropdownRef}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              className={`${isOpen ? "button is-open" : "button"} ${
                selectedOptionError ? "button-error" : ""
              }`}
              style={{
                cursor: currentWorkflow && isWkAttached ? "auto" : "pointer",
                backgroundColor:
                  currentWorkflow && isWkAttached ? "#f5f5f5" : "white",
              }}
              onClick={toggleDropdown}
            >
              <div className="inner-input">
                {integrations.map((integration, index) =>
                  integration.name === selectedValue.name &&
                  selectedValue.type === "integration" &&
                  integration.imagesrc ? (
                    <img
                      key={index}
                      src={integration.imagesrc}
                      alt={integration.name}
                    />
                  ) : null
                )}
                <div>
                  <p>
                    {selectedValue.name !== defaultName &&
                    selectedValue.type === "integration"
                      ? capitalizeFirstLetter(
                          integrationUpdatedNames[selectedValue.name]
                        )
                      : selectedValue.name !== defaultName &&
                        selectedValue.type === "workflow"
                      ? capitalizeFirstLetter(selectedValue.name)
                      : defaultName}
                  </p>
                  <span className="radio-icon"></span>
                </div>
              </div>
              {!currentWorkflow ||
                (currentWorkflow && !isWkAttached && (
                  <img
                    height={"auto"}
                    src={"/assets/images/ic-arrow-bottom.png"}
                    alt={"arrow down"}
                  />
                ))}
            </div>
            {currentWorkflow && isWkAttached && (
              <div style={{ width: "100%" }}>
                <button
                  className={"btn btn1"}
                  onClick={() => handleGoToWorkflow()}
                >
                  <FormattedMessage id={"workflow.settings.configure"} />
                </button>
              </div>
            )}
          </div>
          {/* DROPDOWN OPEN */}
          <div>
            {isOpen && (
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0",
                  height: "100%",
                  maxHeight: "35vh",
                }}
              >
                {existingWk && (
                  <>
                    {existingWk.length > 0 && (
                      <div className="ul-title">
                        <FormattedMessage
                          id={"workflow.selectAnExistingWorkflow"}
                        />
                      </div>
                    )}
                    {existingWk.map((workflow, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          paddingRight: "20px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="radio-btn">
                          <div
                            className="radio-text"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span
                              className="tag"
                              style={{ paddingLeft: 0, color: "#E23381" }}
                            >
                              <FormattedMessage
                                id={"workflow." + workflow.type}
                              />
                            </span>
                            {workflow.name}
                          </div>
                          {/* <span className="radio-icon"></span> */}
                        </div>
                        <input
                          type="radio"
                          name="existingworkflow"
                          onClick={() => handleClickWk(workflow)}
                          className="radio-btn-intg"
                          checked={
                            selectedOption.type === "workflow" &&
                            selectedOption.name === workflow.name
                          }
                        />
                      </li>
                    ))}
                  </>
                )}
                <div className="ul-title">
                  <FormattedMessage id="settings.integration.selectAnIntegration" />
                </div>
                {integrations.map((integration, index) => (
                  <li key={index} className="intg-li">
                    <div className="li-left">
                      <div className="img-block">
                        <img src={integration.imagesrc} alt="" />
                      </div>
                      <div className="">
                        <p>
                          {capitalizeFirstLetter(
                            integrationUpdatedNames[integration.name]
                          )}
                        </p>
                        {integration.isConnected ? (
                          <div className="connected">
                            <div className="dot active-color"></div>
                            <div className="active">
                              <FormattedMessage id={"workflow.active"} />
                            </div>
                          </div>
                        ) : (
                          <div className="connected">
                            <div className="dot not-active-color"></div>
                            <div className="not-active">
                              <FormattedMessage id={"workflow.notActive"} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="selectintegration"
                      onClick={() => handleClickIntegration(integration)}
                      className="radio-btn-intg"
                      checked={
                        selectedOption.type === "integration" &&
                        selectedOption.name === integration.name
                      }
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {selectedOption.type === "integration" &&
          selectedOptionConnected &&
          !currentWorkflow && (
            <div className="configure-mapping">
              <button
                className="btn9"
                onClick={() => setIsMappingModalOpen(true)}
                style={{ paddingTop: "10px" }}
              >
                <FormattedMessage id="workflow.configureMapping" />
              </button>
            </div>
          )}
        {selectedOption.type === "integration" && selectedOptionError && (
          <div className="error">
            <img
              src="/assets/images/exclamation-mark.svg"
              alt="exclamation mark"
            />
            <p>
              <FormattedMessage id="workflow.integrationNotConnected" />
            </p>
            <button
              className="btn9"
              onClick={() => setIsConnectionModalOpen(true)}
            >
              <FormattedMessage id="workflow.connectNow" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default IntgDropdown;
