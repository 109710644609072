import React from "react";
import actions from "./Settings.actions";

const SettingsContext = React.createContext();

const settingsInitialState = {
  editProfileToggle: false,
  editProfileForm: {
    firstName: "",
    lastName: "",
    company: "",
    title: "",
    language: "",
    phone: {
      countryCode: "",
      phoneNumber: "",
    },
    image: null,
  },
  integrationType: "",
  isZapierModalOpen: false,
  //   Mapping Modal initial state
  integrationProperties: null,
  kasprProperties: null,
  mappedProperties: [],
  mappedValues: [],
  isCustomValue: [],
  error: "",
  propertiesAutoComplete: null,
  propertyIndex: -1,
  showAutoComplete: false,
  salesforceTab: "lead",
  showKasprPropertiesAutoComplete: false,
};

const settingsReducer = (state, action) => {
  switch (action.type) {
    case actions.EDIT_PROFILE_TOGGLE:
      return {
        ...state,
        editProfileToggle: !state.editProfileToggle,
      };
    case actions.EDIT_PROFILE:
      return {
        ...state,
        editProfileForm: { ...state.editProfileForm, ...action.payload },
      };
    case actions.SET_INTEGRATION_TYPE:
      return {
        ...state,
        integrationType: action.payload,
      };
    case actions.SET_IS_ZAPIER_MODAL_OPEN:
      return {
        ...state,
        isZapierModalOpen: action.payload,
      };

    case actions.SET_INTEGRATION_PROPERTIES:
      return {
        ...state,
        integrationProperties: action.payload,
      };
    case actions.SET_KASPR_PROPERTIES:
      return {
        ...state,
        kasprProperties: action.payload,
      };

    case actions.SET_MAPPED_PROPERTIES:
      return {
        ...state,
        mappedProperties: action.payload,
      };

    case actions.SET_MAPPED_VALUES:
      return {
        ...state,
        mappedValues: action.payload,
      };

    case actions.SET_IS_CUSTOM_VALUE:
      return {
        ...state,
        isCustomValue: action.payload,
      };

    case actions.SET_PROPERTIES_AUTO_COMPLETE:
      return {
        ...state,
        propertiesAutoComplete: action.payload,
      };

    case actions.SET_SHOW_AUTO_COMPLETE:
      return {
        ...state,
        showAutoComplete: action.payload,
      };

    case actions.SET_SHOW_KASPR_PROPERTIES_AUTO_COMPLETE:
      return {
        ...state,
        showKasprPropertiesAutoComplete: action.payload,
      };

    case actions.SET_SALESFORCE_TAB:
      return {
        ...state,
        salesforceTab: action.payload,
      };

    case actions.SET_PROPERTY_INDEX:
      return {
        ...state,
        propertyIndex: action.payload,
      };

    case actions.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export { SettingsContext, settingsInitialState, settingsReducer, actions };
