import React from "react";
import { FormattedMessage } from "react-intl";

export default function DataNotFound({ elements, style, image, imgWith }) {
  if (image) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px", ...style }}>
        <img width={imgWith || 400} src={image} alt="" />
        <div>{elements}</div>
      </div>
    );
  }

  return (
    <div id="workflow_tab" className="tab-pane fade active show" style={style}>
      {elements ? (
        <div className="no-data">{elements}</div>
      ) : (
        <div className="no-data" style={{ fontSize: 20 }}>
          <FormattedMessage id={"dataNotFound"} />
        </div>
      )}
    </div>
  );
}
