import React, { useEffect, useState } from "react";
import LeadExport from "../../Common/LeadExport/LeadExport";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Permissions, Tooltip } from "../../../../../components";
import { OverlayTrigger } from "react-bootstrap";
import useProfile from "../../Hooks/Profile.hooks";
import PhoneDropDown from "../../../common/phoneIntegrations/PhoneDropDown";
import { usePermissions } from "../../../../../components/Permissions/permissions.hooks";
function HeadButtons({
  setShow,
  toggleTemplate,
  profile,
  setExportToggle,
  exportToggle,
  onLeadExport,
  onLeadDelete,
  setCreateNoteToggle,
  isViewDetails,
  setAddTaskToggle,
  phoneToggle,
  setPhoneToggle,
  setAircallToggle,
  setRingoverToggle,
  memberExportPermission,
  planExportPermission,
  memberEditPermission,
  planEditPermission,
  trackingFrom = {},
  organizationPlan,
}) {
  const intl = useIntl();
  const [
    checkIntegrationPlanPermissions,
    checkIntegrationMemberPermissions,
  ] = usePermissions(
    organizationPlan.permissions,
    profile.permissions,
    "Integration"
  );
  return (
    <div>
      <div
        className={
          "action-btn-listing " +
          (profile && !profile.isViewDetails ? "fixed" : "")
        }
      >
        <ul style={{ position: "relative" }}>
          {/* <li>
            {!planEditPermission || !memberEditPermission ? (
              <Permissions.CallToAction
                withoutIcon
                isPlan={!planEditPermission}
              >
                <a href="#" data-tracking-id={trackingFrom + "-sendEmail"}>
                  <span className="icon-ui ic-email"></span>
                  <span className="text-ui">Email</span>
                </a>
              </Permissions.CallToAction>
            ) : (
              <a
                href="#"
                onClick={(e) => {
                  setShow(false);
                  toggleTemplate(e, profile);
                }}
                data-tracking-id={trackingFrom + "-sendEmail"}
              >
                <span className="icon-ui ic-email"></span>
                <span className="text-ui">Email</span>
              </a>
            )}
          </li>*/}
          <li>
            {!planEditPermission || !memberEditPermission ? (
              <Permissions.CallToAction
                withoutIcon
                isPlan={!planEditPermission}
              >
                <a href="#" data-tracking-id={trackingFrom + "-note"}>
                  <span className="icon-ui ic-note"></span>
                  <span className="text-ui">
                    <FormattedMessage id={"leads.addNote"} />
                  </span>
                </a>
              </Permissions.CallToAction>
            ) : (
              <a
                href="#"
                onClick={(e) => {
                  setCreateNoteToggle(true);
                }}
                data-tracking-id={trackingFrom + "-note"}
              >
                <span className="icon-ui ic-note"></span>
                <span className="text-ui">
                  <FormattedMessage id={"leads.addNote"} />
                </span>
              </a>
            )}
          </li>

          <li>
            {!planEditPermission || !memberEditPermission ? (
              <Permissions.CallToAction
                withoutIcon
                isPlan={!planEditPermission}
              >
                <a href="#" data-tracking-id={trackingFrom + "-addTask"}>
                  <span className="icon-ui ic-task"></span>
                  <span className="text-ui">
                    <FormattedMessage id={"leads.addTask"} />
                  </span>
                </a>
              </Permissions.CallToAction>
            ) : (
              <a
                href="#"
                onClick={() => setAddTaskToggle(true)}
                data-tracking-id={trackingFrom + "-addTask"}
              >
                <span className="icon-ui ic-task"></span>
                <span className="text-ui">
                  <FormattedMessage id={"leads.addTask"} />
                </span>
              </a>
            )}
          </li>
          <li>
            <a
              href="#"
              onClick={setExportToggle}
              style={{ position: "relative" }}
              data-tracking-id={trackingFrom + "-export"}
            >
              <span className="icon-ui ic-export"></span>
              <span className="text-ui">
                <FormattedMessage id={"export"} />
              </span>
            </a>
            <LeadExport
              setToggle={setExportToggle}
              toggle={exportToggle}
              style={{
                position: "absolute",
                width: "max-content",
                top: 50,
              }}
              handleExportLeads={onLeadExport}
            />
          </li>

          {memberExportPermission &&
          planExportPermission &&
          profile &&
          profile.phones &&
          profile.phones.length ? (
            <li>
              <a
                href="#"
                onClick={setPhoneToggle}
                data-tracking-id={trackingFrom + "-phone"}
                style={{ position: "relative" }}
              >
                <span className="icon-ui ic-Phone"></span>
                <span className="text-ui">
                  <FormattedMessage id={"info.phone"} />
                </span>
              </a>
              <PhoneDropDown
                setToggle={setPhoneToggle}
                toggle={phoneToggle}
                style={{
                  position: "absolute",
                  width: "max-content",
                  top: 50,
                  right: 120,
                }}
                handleAircallToggle={setAircallToggle}
                handleRingoverToggle={setRingoverToggle}
                checkPlanPermission={checkIntegrationPlanPermissions}
                checkMemberPermission={checkIntegrationMemberPermissions}
              />
            </li>
          ) : (
            <OverlayTrigger
              overlay={(props) =>
                Tooltip({
                  ...props,
                  data: intl.formatMessage({
                    id:
                      (profile &&
                        !(profile.phones && profile.phones.length) &&
                        "leads.noPhones") ||
                      (!planExportPermission && "permissions.upgradePlan") ||
                      (!memberExportPermission && "permissions.noAccess"),
                  }),
                  container_style: { padding: "0px 5px" },
                })
              }
            >
              <div style={{ opacity: 0.4 }}>
                <li>
                  <a href="#">
                    <span className="icon-ui ic-Phone"></span>
                    <span className="text-ui">
                      <FormattedMessage id={"info.phone"} />
                    </span>
                  </a>
                </li>
              </div>
            </OverlayTrigger>
          )}
          <li>
            <a
              href="#"
              onClick={onLeadDelete}
              data-tracking-id={trackingFrom + "-deleteLead"}
            >
              <span className="icon-ui ic-delete"></span>
              <span className="text-ui" style={{ color: "#FF404C" }}>
                <FormattedMessage id={"delete"} />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HeadButtons;
