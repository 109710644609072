import React, { useEffect, useState } from "react";

import "quill/dist/quill.snow.css"; // Add css for snow theme
import ReactQuill, { Quill } from "react-quill";

export default function EmailField({
  value,
  onChange,
  placeholder,
  handleIndexChange,
}) {
  let [quillRef, setQuillRef] = useState(null);
  Quill.register(Quill.import("attributors/style/align"), true);
  let Size = Quill.import("attributors/style/size");
  Size.whitelist = ["14px", "16px", "18px"];
  Quill.register(Size, true);

  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: "" }],
      [{ align: "right" }],
      [{ align: "center" }],
      [{ align: "justify" }],

      [{ list: "ordered" }, { list: "bullet" }],
      //[{indent: '-1'}, {indent: '+1'}],

      [{ size: Size.whitelist }],
      ["link"],
      [{ color: [] }, { background: [] }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const handleChangeSelection = (range) => {
    if (
      handleIndexChange &&
      range &&
      range.index &&
      typeof range.index === "number"
    ) {
      handleIndexChange(range.index, quillRef);
    }
  };

  return (
    <div className="quill" style={{ width: "100%", height: "100%" }}>
      <ReactQuill
        ref={setQuillRef}
        theme={"snow"}
        onChange={onChange}
        value={value}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        onChangeSelection={handleChangeSelection}
      />
    </div>
  );
}
