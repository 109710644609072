import React, {useCallback, useContext, useEffect, useState} from "react";
import {Col, Modal, OverlayTrigger, Row} from "react-bootstrap";
import {Tooltip} from "../../../components";
import {actions, OrganizationContext} from "../store/Organization.store";

import MyTeam from "./MyTeam/MyTeam";
import DeleteMember from "./DeleteMember/DeleteMember";
import AddMemberNav from "./AddMemberNav/AddMemberNav";
import AddEditMemberPopUp from "./AddEditMemberPopUp/AddEditMemberPopUp";
import {
    declineJoinRequest,
    deleteInvitation,
    getALLInvitations,
    getALLJoinsRequests,
    getMembers,
    getNewInvitations,
} from "../../../services/api/organization.api";
import ReceivedInvitations from "../CreateJoinOrganization/ReceivedInvitations/ReceivedInvitations";
import {FormattedMessage, useIntl} from "react-intl";
import {usePermissions} from "../../../components/Permissions/permissions.hooks";
import WorkspacesCards from "../../../components/WorkspacesCards/WorkspacesCards";

export default function Members(props) {
    const {state, dispatch} = useContext(OrganizationContext);
    const intl = useIntl();
    const [editMemberPopUp, setEditMemberPopup] = useState(false);
    const [joinsReqs, setJoinsReqs] = useState([]);
    const [sentInvitations, setSentInvitations] = useState([]);
    const [joinRequestMember, setJoinRequestMemberPopUp] = useState(false);
    const [invitations, setInvitations] = useState(false);
    const [memberFilters, setMemberFilters] = useState({search: "", page: 1});
    const [popUpWorkspacesToggle, setPopUpWorkspacesToggle] = useState(false);
    const [pendingInvitations, setPendingInvitations] = useState([]);

    const [checkPlanPermissions] = usePermissions(
        props.organizationCredits &&
        props.organizationCredits.plan &&
        props.organizationCredits.plan.permissions,
        null,
        "MemberPermission"
    );
    let currentMember = state.organization.members.find(
        (member) => member.email === props.profile.email
    );
    useEffect(() => {
        getALLInvitations().then(({data}) => {
            setPendingInvitations(data.invitations);
        });
    }, []);
    const isAdmin = () => {
        return (
            props.organization.member.type === "admin" ||
            props.organization.member.type === "owner"
        );
    };
    const updateInvitations = (invitations) => {
        let invites = sentInvitations;
        const invitation = invitations[0];
        invitation.inviter = currentMember;
        invites.push(invitation);
        setSentInvitations(invites);
    };

    const removeInvitation = (invitation) => {
        setSentInvitations(sentInvitations.filter((el) => el !== invitation));
    };

    const handleDeclineJoinRequest = async (joinRequest) => {
        let {data, statusCode} = await declineJoinRequest(joinRequest);
        if (statusCode === 200) {
            setJoinsReqs(joinsReqs.filter((el) => el !== joinRequest));
        }
    };

    //TODO add like joinRequest Member
    useEffect(() => {
        getALLJoinsRequests().then(({data}) => {
            setJoinsReqs(data.joinReqs);
        });
        getNewInvitations().then(({data}) => {
            setInvitations(data.invitations);
        });
        if (isAdmin())
            getALLInvitations().then(({data}) => {
                setSentInvitations(data.invitations);
            });
    }, [joinRequestMember]);

    const handleMemberSearch = async (search) => {
        setMemberFilters({...memberFilters, search});
        const {data, statusCode} = await getMembers({
            ...memberFilters,
            ...search,
        });
        if (statusCode == 200) {
            const organization = Object.assign({}, state.organization);
            organization.members = data.members;
            organization.membersPages = data.pages;
            organization.totalMembers = data.totalMembers;
            dispatch({
                type: actions.GET_ORGANIZATION,
                payload: {organization: organization},
            });
        }
    };
    const handlePagination = async (page) => {
        setMemberFilters({...memberFilters, page});
        const {data, statusCode} = await getMembers({
            ...memberFilters.search,
            page,
        });
        if (statusCode == 200) {
            const organization = Object.assign({}, state.organization);
            organization.members = data.members;
            organization.membersPages = data.pages;
            organization.totalMembers = data.totalMembers;
            dispatch({
                type: actions.GET_ORGANIZATION,
                payload: {organization: organization},
            });
        }
    };
    return (
        <div id="member_tab" className="tab-pane active ">
            <div style={{paddingTop: 20}}>
                {invitations && (
                    <ReceivedInvitations
                        invitations={invitations}
                        hasOrganization={true}
                        organizationCredits={props.organizationCredits}
                    />
                )}
                {isAdmin() && (
                    <ReceivedJoinRequests
                        joinReqs={joinsReqs}
                        organization={state.organization}
                        setJoinRequestMember={setJoinRequestMemberPopUp}
                        handleDeclineJoinRequest={handleDeclineJoinRequest}
                    />
                )}
            </div>
            <MyTeam
                organization={state.organization}
                isAdmin={isAdmin()}
                member={props.profile}
                organizationCredits={props.organizationCredits}
                invitationsNumber={sentInvitations.length}
                remainingCredits={props.remainingCredits}
                personalCredits={props.personalCredits}
            />

            <div className="table-wrap nobackground">
                <AddMemberNav
                    organization={state.organization}
                    isAdmin={isAdmin()}
                    updateInvitations={updateInvitations}
                    invitationsNumber={sentInvitations.length}
                    resetRemaining={props.resetRemaining}
                    onMemberSearch={handleMemberSearch}
                    goToPage={handlePagination}
                    memberFilters={memberFilters}
                    remainingCredits={props.remainingCredits}
                    subscription={props.subscription}
                    checkPlanPermissions={checkPlanPermissions}
                    isVersion2={props.isVersion2}
                    organizationCredits={props.organizationCredits}
                />

                <div className="member-lsiting-block">
                    <Row>
                        {state.organization &&
                            state.organization.members &&
                            state.organization.members.map((member, i) => (
                                <MemberCard
                                    isAdmin={isAdmin()}
                                    profile={props.profile}
                                    member={member}
                                    key={i}
                                    setEditMemberPopup={setEditMemberPopup}
                                />
                            ))}
                    </Row>
                </div>
            </div>

            {isAdmin() && sentInvitations && !!sentInvitations.length && (
                <div className="table-wrap nobackground">
                    <div
                        className="head_block"
                        style={{background: "url(/assets/images/mainbg.jpg)"}}
                    >
                        <div className="left_block">
                            <div className="title-box">
                                <p>
                                    {sentInvitations && sentInvitations.length}{" "}
                                    <FormattedMessage id={"organization.invitationSent"}/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="member-lsiting-block">
                        <Row>
                            {sentInvitations.map((invitation, i) => (
                                <InvitationCard
                                    invitation={invitation}
                                    key={i}
                                    removeInvitation={removeInvitation}
                                    reload={
                                        props.subOrganizations &&
                                        props.subOrganizations.organizations &&
                                        props.subOrganizations.organizations.length &&
                                        !!props.subOrganizations.nonMembers.length
                                    }
                                />
                            ))}
                        </Row>
                    </div>
                </div>
            )}
            <WorkspacesCards
                subOrganizations={props.subOrganizations}
                organization={props.organization.organization}
                credits={{organizationCredits: props.organizationCredits}}
            />
            {/* <div className={"workspaces-container"}>
        {props.subOrganizations &&
          props.subOrganizations.organizations &&
          props.subOrganizations.organizations.length > 0 && (
            <div className={"sub_org_cards_container"}>
              <h2>
                <FormattedMessage id={"organizations.weFoundOutColleagues"} />{" "}
                <InfoToolTip
                  text={intl.formatMessage({
                    id: "organizations.weFoundOutColleaguesToolTip",
                  })}
                />
              </h2>{" "}
              <Row>
                {props.subOrganizations &&
                  props.subOrganizations.nonMembers.length > 0 &&
                  props.subOrganizations.nonMembers.map((member, key) => (
                    <ColleaguesCard
                      key={key}
                      member={member}
                      organization={props.organization.organization}
                      pendingInvitations={pendingInvitations}
                      organizationCredits={props.organizationCredits}
                      setPopUpToggle={setPopUpWorkspacesToggle}
                      isNotMember
                    />
                  ))}
                {props.subOrganizations.organizations.map(
                  (organization, key) => (
                    <ColleaguesCard
                      key={key}
                      pendingInvitations={pendingInvitations}
                      organization={organization}
                      organizationCredits={props.organizationCredits}
                      setPopUpToggle={setPopUpWorkspacesToggle}
                      member={
                        organization.members.length === 1
                          ? organization.members[0]
                          : false
                      }
                    />
                  )
                )}
                {popUpWorkspacesToggle && (
                  <MembersListModal
                    organizationId={popUpWorkspacesToggle}
                    popUpToggle={popUpWorkspacesToggle}
                    organization={props.organization.organization}
                    organizationCredits={props.organizationCredits}
                    setPopUpToggle={setPopUpWorkspacesToggle}
                  />
                )}
              </Row>
            </div>
          )}
      </div>*/}
            {editMemberPopUp && (
                <AddEditMemberPopUp
                    organization={state.organization}
                    setAddMemberModal={setEditMemberPopup}
                    member={editMemberPopUp}
                    isEdit
                    resetRemaining={props.resetRemaining}
                    remainingCredits={props.remainingCredits}
                    subscription={props.subscription}
                    isMemberAdmin={isAdmin()}
                    checkPlanPermissions={checkPlanPermissions}
                    isVersion2={props.isVersion2}
                />
            )}
            {joinRequestMember && (
                <AddEditMemberPopUp
                    organization={state.organization}
                    setAddMemberModal={setJoinRequestMemberPopUp}
                    joinRequestMember={joinRequestMember}
                    isJoin
                    resetRemaining={props.resetRemaining}
                    remainingCredits={props.remainingCredits}
                    subscription={props.subscription}
                    isMemberAdmin={isAdmin()}
                    checkPlanPermissions={checkPlanPermissions}
                    isVersion2={props.isVersion2}
                />
            )}
        </div>
    );
}

function MemberCard({
                        member,
                        profile,
                        setEditMemberPopup,
                        setMemberDetails,
                        isAdmin,
                    }) {
    const [deleteMemberPopUp, setDeleteMemberPopup] = useState(false);
    const formatDate = () => {
        return Intl.DateTimeFormat("fr-FR").format(new Date(member.clientDate));
    };

    return (
        <Col md={6} lg={6} xl={4}>
            <div className="member-info-card">
                <figure className="profile-ui">
                    <img src={member.picture || "/assets/images/ic_profile.png"} alt=""/>
                </figure>
                <div className="member-info-ui">
                    <div className="username">
                        {((member.firstName && member.firstName.length) || "") +
                        ((member.lastName && member.lastName.length) || "") +
                        1 >
                        20 ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={(props) =>
                                    Tooltip({
                                        ...props,
                                        data: member.firstName
                                            ? member.firstName[0].toUpperCase() +
                                            member.firstName.slice(1) +
                                            " "
                                            : "" + member.lastName
                                                ? member.lastName[0].toUpperCase() +
                                                member.lastName.slice(1)
                                                : "",
                                    })
                                }
                            >
                                <div>
                  <span className="capital" style={{marginRight: 7}}>
                    {(member.firstName
                            ? member.firstName[0].toUpperCase() +
                            member.firstName.slice(1) +
                            " "
                            : "" + member.lastName
                                ? member.lastName[0].toUpperCase() +
                                member.lastName.slice(1)
                                : ""
                    ).substr(0, 20) + "..."}
                  </span>
                                </div>
                            </OverlayTrigger>
                        ) : (
                            <div>
                <span className="capital" style={{marginRight: 7}}>
                  {member.firstName + " "}
                </span>
                                <span className="capital">{member.lastName}</span>
                            </div>
                        )}

                        <span className="tag owner-tag">
              {<FormattedMessage id={"organization." + member.type}/>}
            </span>
                    </div>
                    {member.job && member.job.length > 20 ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={(props) =>
                                Tooltip({
                                    ...props,
                                    data: member.job[0].toUpperCase() + member.job.slice(1),
                                })
                            }
                        >
              <span className="info-text capital">
                {(member.job[0].toUpperCase() + member.job.slice(1)).substr(
                    0,
                    20
                ) + "..."}
              </span>
                        </OverlayTrigger>
                    ) : (
                        <span className="info-text capital">
              {member.job ? member.job : "---"}
            </span>
                    )}
                    {member.email && member.email.length > 20 ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={(props) =>
                                Tooltip({
                                    ...props,
                                    data: member.email,
                                })
                            }
                        >
                            <p className="desc">{member.email.substr(0, 17) + "..."}</p>
                        </OverlayTrigger>
                    ) : (
                        <p className="desc">{member.email}</p>
                    )}
                    <div className="btn-blocks">
                        {isAdmin ? (
                            <div className="left-ui">
                                <a
                                    href="#"
                                    className="text-btn edit-btn"
                                    onClick={() => setEditMemberPopup(member)}
                                >
                                    <FormattedMessage id={"edit"}/>
                                </a>
                                <a
                                    href="#"
                                    className="grey-text-btn remove-btn"
                                    onClick={() => setDeleteMemberPopup(true)}
                                >
                                    {profile.email !== member.email ? (
                                        <FormattedMessage id={"remove"}/>
                                    ) : (
                                        <FormattedMessage id={"leave"}/>
                                    )}
                                </a>
                            </div>
                        ) : profile.email == member.email ? (
                            <div className="left-ui">
                                <a
                                    href="#"
                                    className="grey-text-btn  edit-btn"
                                    onClick={() => setEditMemberPopup(member)}
                                >
                                    <FormattedMessage id={"organization.myPermissions"}/>{" "}
                                </a>
                            </div>
                        ) : (
                            <div className={"left-ui"} style={{height: 25}}/>
                        )}
                        <div className="right-ui date-ui">
                            <div>{formatDate()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteMemberPopUp && (
                <DeleteMember
                    isLeaveOrganization={profile.email === member.email}
                    profile={profile}
                    toggle={deleteMemberPopUp}
                    setToggle={setDeleteMemberPopup}
                    member={member}
                />
            )}
        </Col>
    );
}

///////
function InvitationCard({invitation, removeInvitation}) {
    const [deleteMemberPopUp, setDeleteMemberPopup] = useState(false);
    const [deleteInvitationPopUp, setDeleteInvitationPopup] = useState(false);
    const [error, setError] = useState(false);
    const formatDate = () => {
        return (
            new Date(invitation.clientDate).getDate() +
            "-" +
            (new Date(invitation.clientDate).getMonth() + 1) +
            "-" +
            new Date(invitation.clientDate).getFullYear()
        );
    };

    const handleDeleteInvitation = async () => {
        let {data, statusCode} = await deleteInvitation(invitation);
        if (statusCode === 200) {
            removeInvitation(invitation);
            window.location.reload();
        } else setError(data.messageTranslated || data.message);
    };

    return (
        <Col md={6} lg={6} xl={4}>
            <div className="member-info-card">
                <figure className="profile-ui">
                    <img src="/assets/images/ic_profile.png" alt=""/>
                </figure>
                <div className="member-info-ui">
                    <div className="username">
                        <p>{invitation.invitee}</p>
                        <span className="tag owner-tag">
              {
                  <FormattedMessage
                      id={"organization." + invitation.memberSettings.type}
                  />
              }
            </span>
                    </div>
                    <span className="info-text">
            <FormattedMessage id={"organization.isInvitedBy"}/>{" "}
          </span>
                    <span className="text-capitalize">
            {invitation.inviter && invitation.inviter.firstName + " "}
          </span>
                    <span className="text-capitalize">
            {invitation.inviter && invitation.inviter.lastName}
          </span>
                    <div className="btn-blocks">
                        <div className="left-ui">
                            <a
                                href="#"
                                className="grey-text-btn remove-btn"
                                onClick={() => setDeleteInvitationPopup(true)}
                            >
                                <FormattedMessage id={"delete"}/>
                            </a>
                        </div>
                        <div className="right-ui date-ui">
                            <div>{formatDate()}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={deleteInvitationPopUp}
                onHide={() => setDeleteInvitationPopup(false)}
                className=" fade  delete-member-wrapper"
                id="delete-member"
            >
                <Modal.Header closeButton className="modal-header border-0">
                    <div className="modal-inner-header">
                        <h4 className="title">
                            <FormattedMessage id={"remove"}/> "{invitation.invitee}" ?
                        </h4>
                    </div>
                </Modal.Header>
                <div className="modal-body p-0">
                    <div className="unsubscribe-type-list p-24">
                        <p>
                            <FormattedMessage id={"organization.areYouSureToDelete_"}/>{" "}
                            {invitation.invitee}{" "}
                            <FormattedMessage id={"organization.nInvitations_"}/>
                        </p>
                    </div>
                </div>
                {error && (
                    <div className="btn-grp text-center  error  kaspr-form-text-error">
                        {error + " "}
                    </div>
                )}
                <div className="modal-footer border-0">
                    <a
                        href="#"
                        className="btn3 btn-md"
                        onClick={() => setDeleteInvitationPopup(false)}
                    >
                        <FormattedMessage id={"cancel"}/>
                    </a>
                    <a
                        href="#"
                        className="btn1 btn-md ml-20"
                        onClick={handleDeleteInvitation}
                    >
                        <FormattedMessage id={"confirm"}/>
                    </a>
                </div>
            </Modal>
        </Col>
    );
}

function ReceivedJoinRequests({
                                  joinReqs,
                                  organization,
                                  setJoinRequestMember,
                                  handleDeclineJoinRequest,
                              }) {
    return (
        <div
            className="join-invite-msg"
            style={{maxWidth: "100%", margin: "20px 0"}}
        >
            {joinReqs &&
                joinReqs.map((joinReq, i) => (
                    <div className="invite-notification-ui" key={i}>
                        <div className="left-ui">
                            <figure>
                                <img src="/assets/images/ic_profile.png" alt=""/>
                            </figure>
                            <div className="content-ui">
                                <p>
                                    <strong>{joinReq.joiner}</strong>{" "}
                                    <FormattedMessage id={"organization.requestJoining"}/>{" "}
                                    <strong className="capital">" {organization.name} "</strong>
                                </p>
                            </div>
                        </div>

                        <div className="right-ui">
                            <span className="time-ui"/>
                            <a
                                href="#"
                                className="btn-ui"
                                onClick={() => setJoinRequestMember(joinReq)}
                            >
                <span className="icon">
                  <img src="/images/ic-chk-permision.svg" alt=""/>
                </span>
                                <FormattedMessage id={"organization.accept"}/>
                            </a>

                            <span className="time-ui"/>
                            <a
                                href="#"
                                className="btn-ui"
                                onClick={() => handleDeclineJoinRequest(joinReq)}
                            >
                <span className="icon">
                  <img src="/images/ic-chk-permision.svg" alt=""/>
                </span>
                                <FormattedMessage id={"organization.decline"}/>
                            </a>
                        </div>
                    </div>
                ))}
        </div>
    );
}
