import React from "react";
import "./ConnectionModalRight.css";
import ListItem from "../ListItem/ListItem";
import OauthComponent from "../../OauthComponent/OauthComponent";
import ApiKeyComponent from "../../ApiKeyComponent/ApiKeyComponent";
import ZapierComponent from "../../ZapierComponent/ZapierComponent";
import LinkedinComponent from "../../LinkedinComponent/LinkedinComponent";
import { services } from "../../../../constants";
import { integrationsDetailedDescriptions } from "../../../../constants";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

function ConnectionModalRight({ props }) {
  const intl = useIntl();
  const IntegrationData = integrationsDetailedDescriptions[props.integration];
  return (
    <div
      className={
        "connection-modal-right-container" +
        (props.integration === "linkedin" && intl.locale === "es"
          ? " linkedin-container-width"
          : "")
      }
    >
      {services[props.integration] === "oauth" && <OauthComponent {...props} />}
      {services[props.integration] === "apikey" && (
        <ApiKeyComponent {...props} />
      )}
      {services[props.integration] === "zapier" && (
        <ZapierComponent {...props} />
      )}
      {services[props.integration] === "linkedin" && (
        <LinkedinComponent {...props} />
      )}
      {IntegrationData.exportAvailable &&
        IntegrationData.exportAvailable.length > 0 && (
          <div>
            <h1>
              <FormattedMessage id="settings.integration.exportAvailable" />
            </h1>

            <div className="items-list">
              {IntegrationData.exportAvailable.map((item) => (
                <ListItem
                  key={item}
                  prefix={
                    IntegrationData.name === "Zapier"
                      ? ""
                      : "settings.integration.exportFrom"
                  }
                  itemName={item}
                />
              ))}
            </div>
          </div>
        )}
      {IntegrationData.exportType && IntegrationData.exportType.length > 0 && (
        <div>
          <h1>
            <FormattedMessage id="settings.integration.exportType" />
          </h1>
          <div className="items-list">
            {IntegrationData.exportType.map((item) => (
              <ListItem key={item} itemName={item} />
            ))}
          </div>
        </div>
      )}
      {IntegrationData.availableOn && IntegrationData.availableOn.length > 0 && (
        <div>
          <h1>
            <FormattedMessage id="settings.integration.availableOn" />
          </h1>
          <div className="items-list">
            {IntegrationData.availableOn.map((item) => (
              <ListItem key={item} itemName={item} />
            ))}
          </div>
        </div>
      )}
      {IntegrationData.featuresAvailable &&
        IntegrationData.featuresAvailable.length > 0 && (
          <div>
            <h1>
              <FormattedMessage id="settings.integration.featuresAvailable" />
            </h1>
            <div className="items-list">
              {IntegrationData.featuresAvailable.map((item) => (
                <ListItem
                  key={item}
                  prefix={"settings.integration.enrichFrom"}
                  itemName={item}
                />
              ))}
            </div>
          </div>
        )}
    </div>
  );
}

export default ConnectionModalRight;
