import React from "react";
import { oauthVariables } from "../../../constants";
import AuthServ from "../../../../../Auth/AuthServ/AuthServ";
import { FormattedMessage } from "react-intl";
import { desynchronizeLinkedinAccount } from "../../../../../../services/api/linkedin.api";
import { integrationDeleteAccount } from "../../../../../../services/api/integrations.api";
import { Permissions } from "../../../../../../components";
import "./OauthComponent.css";

function OauthComponent(props) {
  const handleDisconnect = async () => {
    let { statusCode } = await integrationDeleteAccount({
      integrationName: props.integration,
    });
    if (statusCode === 200) {
      props.disconnectAccount();
      props.onHide();
    }
  };

  const handleMapping = (e) => {
    props.onHide();
    props.handleMappingModal();
  };

  const AuthConnect = () => {
    return (
      <AuthServ
        clientId={oauthVariables.clientId[props.integration]}
        scopes={oauthVariables.scopes[props.integration]}
        oauthUrl={oauthVariables.oauthUrl[props.integration]}
        type={props.integration}
        responseType={"code"}
        redirectUri={
          window.location.origin + `/socialAuth/${props.integration}/`
        }
        onLogin={props.confirmConnect}
        dim={{ height: 700, width: 800 }}
        inModal={true}
        checkCrmValidity={props.checkCrmValidity}
        onHide={props.onHide}
      />
    );
  };

  return (
    <>
      {props.account && !props.checkMemberPermissions("Sync") ? (
        <div className="buttons-container">
          {props.account ? (
            <div className="buttons">
              <a
                href="#"
                className="btn1 ripple-btn"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#api-integration-modal"
                onClick={handleDisconnect}
              >
                <FormattedMessage id={"settings.disconnect"} />
              </a>
              <a
                href="#"
                className="btn1 ripple-btn"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#api-integration-modal"
                onClick={(e) => handleMapping(e)}
              >
                <FormattedMessage id={"mapping"} />
              </a>
            </div>
          ) : (
            <Permissions.CallToAction
              withoutIcon={true}
              toolTipStyle={{ width: 200 }}
            >
              <AuthConnect />
            </Permissions.CallToAction>
          )}
        </div>
      ) : (
        <div className="buttons-container">
          {props.account ? (
            <div className="buttons">
              <a
                href="#"
                className="btn1 ripple-btn"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#api-integration-modal"
                onClick={handleDisconnect}
              >
                <FormattedMessage id={"settings.disconnect"} />
              </a>
              <a
                href="#"
                className="btn1 ripple-btn"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#api-integration-modal"
                onClick={(e) => handleMapping(e)}
              >
                <FormattedMessage id={"mapping"} />
              </a>
            </div>
          ) : (
            <AuthConnect />
          )}
        </div>
      )}
    </>
  );
}

export default OauthComponent;
