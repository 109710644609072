import React, { useState, useEffect, useContext } from "react";
import { integrationsDescriptions } from "../../../../constants";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Pagination } from "../../../../../../../components";
import { Modal } from "react-bootstrap";
import { SettingsContext, actions } from "../../../../../store/Settings.store";

import "./ZapierModal.css";

import {
  getIntegrationsLists,
  getIntegrationKey,
  updateIntegrationKey,
  updateZap,
  deleteZap,
} from "../../../../../../../services/api/integrations.api";
import { toast } from "react-toastify";

const ZapierModal = ({ props }) => {
  const [integrationKey, setIntegrationKey] = useState("");
  const [zaps, setZaps] = useState([]);
  const [zapNames, setZapNames] = useState([]);
  const [editZapNames, setEditZapNames] = useState([]);
  const [confirmDeleteZaps, setConfirmDeleteZaps] = useState([]);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const { dispatch } = useContext(SettingsContext);

  const perPage = 4;

  const updateZapierKey = async () => {
    let { data, statusCode } = await updateIntegrationKey("zapier");
    if (statusCode === 200) {
      setIntegrationKey(data.apiKey);
    } else setError(data.messageTranslated || data.message);
  };

  const updateZaps = async (index, property, value) => {
    let updatedZaps = Object.assign([], zaps);
    updatedZaps[index][property] = value;
    await updateZap(updatedZaps[index]);
    setZaps(updatedZaps);
    if (property === "name") {
      let updatedEdit = Object.assign([], editZapNames);
      updatedEdit[index] = false;
      setEditZapNames(updatedEdit);
    }
  };

  const handleConfirmDeleteZap = (index) => {
    let updatedZaps = Object.assign([], confirmDeleteZaps);
    updatedZaps[index] = true;
    setConfirmDeleteZaps(updatedZaps);
  };

  const handleDeleteZap = async (index) => {
    deleteZap(zaps[index]).then(({ data }) => {
      if (data && data.success) {
        setError("");
        let updatedZaps = Object.assign([], zaps);
        updatedZaps.splice(index, 1);
        setZaps(updatedZaps);

        updatedZaps = Object.assign([], editZapNames);
        updatedZaps.splice(index, 1);
        setEditZapNames(updatedZaps);

        updatedZaps = Object.assign([], confirmDeleteZaps);
        updatedZaps.splice(index, 1);
        setConfirmDeleteZaps(updatedZaps);
      } else {
        setError(
          props.intl.formatMessage({
            id: "settings.AnErrorHasOccurredWhileDeletingYourZap",
          })
        );
      }
    });
  };

  const handleChangeZapName = (e, index) => {
    let updatedZapNames = Object.assign([], zapNames);
    updatedZapNames[index] = e.target.value;
    setZapNames(updatedZapNames);
  };

  const handleSetEditZapNames = (index) => {
    let updatedEdit = Object.assign([], editZapNames);
    updatedEdit[index] = true;
    setEditZapNames(updatedEdit);
  };

  const copyToClipboard = () => {
    const cp = document.createElement("textarea");
    cp.value = integrationKey;
    document.body.appendChild(cp);
    cp.select();
    document.execCommand("copy");
    document.body.removeChild(cp);
    toast.success("Key copied!");
  };

  useEffect(() => {
    getIntegrationKey("zapier").then(({ data }) => {
      if (data && data.message) {
        if (data.message === "You do not belong to an organization")
          setError(
            props.intl.formatMessage({
              id: "settings.youMustBePartOfAnOrganizationInOrderToGetAnApi",
            })
          );
        else if (data.message === "Permission denied")
          if (data.type === "plan")
            setError(
              props.intl.formatMessage({ id: "permissions.upgradePlan" })
            );
          else if (data.type === "organization")
            setError(props.intl.formatMessage({ id: "permissions.noAccess" }));
        return;
      }
      setIntegrationKey(data.apiKey);
      getIntegrationsLists({ integrationName: "zapier", getAll: true }).then(
        ({ data }) => {
          setZaps(data.lists);
          setZapNames(data.lists.map((list) => list.name));
          setEditZapNames(data.lists.map(() => false));
          setConfirmDeleteZaps(data.lists.map(() => false));
        }
      );
    });
  }, []);

  const handleOnHide = () => {
    dispatch({
      type: actions.SET_INTEGRATION_TYPE,
      payload: "",
    });
    dispatch({
      type: actions.SET_IS_ZAPIER_MODAL_OPEN,
      payload: false,
    });
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleOnHide}
      className="fade connect-modal"
      id="connected-tools zapier-modal"
    >
      <Modal.Header closeButton className="border-0">
        <div className="modal-inner-header">
          <div className="profile-img-block">
            <img src={props.imagesrc} alt="" />
          </div>
          <div className="profile-info">
            <p className="username">{props.integration}</p>
            <span className="designation">{props.category}</span>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="inner-description">
          <label>Description</label>
          <p>
            <FormattedMessage
              id={integrationsDescriptions[props.integration]}
            />
          </p>
          {integrationKey && (
            <div>
              <p>
                <FormattedMessage
                  id={"settings.useThisKeyToConnectKasprThroughZapier"}
                />{" "}
              </p>
              <p>
                <strong> {integrationKey} </strong>
                <i
                  style={{
                    marginLeft: "20px",
                    cursor: "pointer",
                    color: "#797f99",
                  }}
                  onClick={copyToClipboard}
                  className="fas fa-copy"
                >
                  {" "}
                </i>
                <i
                  style={{
                    marginLeft: "20px",
                    cursor: "pointer",
                    color: "#797f99",
                  }}
                  onClick={updateZapierKey}
                  className="fas fa-redo-alt"
                >
                  {" "}
                </i>
              </p>

              <br />
            </div>
          )}

          <p>
            {props.account &&
              (props.integration === "zapier"
                ? props.intl.formatMessage({
                    id: "settings.youAreConnectedTo",
                  }) +
                  " " +
                  props.account
                : props.intl.formatMessage(
                    {
                      id: "settings.youAreConnectedUsingThis",
                    },
                    {
                      integration:
                        props.integration[0].toUpperCase() +
                        props.integration.slice(1),
                      account: props.account,
                    }
                  ))}
          </p>
          <div className="user-role-listing">
            {zaps &&
              zaps.length > 0 &&
              zaps
                .slice(perPage * (page - 1), perPage * page)
                .map((zap, index) => {
                  return (
                    <div className="user-role-block">
                      <div className="left-block">
                        <div className={"edit-worklfow-name"}>
                          {!editZapNames[index] ? (
                            <p className="title">
                              <a href={zap.link} target="_blank">
                                {zap.name}
                              </a>
                              <span
                                className={"edit-btn"}
                                style={{ paddingLeft: "10px" }}
                                onClick={(e) => handleSetEditZapNames(index)}
                              >
                                <img
                                  src="/assets/images/ic_edit_g.png"
                                  alt=""
                                />
                              </span>
                            </p>
                          ) : (
                            <div>
                              <input
                                type="text"
                                value={zapNames[index]}
                                onChange={(e) => handleChangeZapName(e, index)}
                                style={{ width: "80%" }}
                              />
                              <button
                                onClick={(e) =>
                                  updateZaps(index, "name", zapNames[index])
                                }
                              >
                                {" "}
                                Ok
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="right-block">
                        <div className="checkbox-wrap">
                          <label>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                updateZaps(index, "isEnabled", !zap.isEnabled);
                              }}
                              checked={zap.isEnabled}
                              disabled={false}
                            />
                            <span className="checkbox-icon" />
                            <span className="checkbox-text">
                              {" "}
                              <FormattedMessage id={"settings.enabled"} />{" "}
                            </span>
                          </label>
                        </div>
                        <div className="checkbox-wrap">
                          <label>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                updateZaps(
                                  index,
                                  "autoExport",
                                  !zap.autoExport
                                );
                              }}
                              checked={zap.autoExport}
                              disabled={false}
                            />
                            <span className="checkbox-icon" />
                            <span className="checkbox-text">
                              {" "}
                              <FormattedMessage
                                id={"settings.exportAutomatically"}
                              />{" "}
                            </span>
                          </label>
                        </div>
                        <div className="button-ui delete-account">
                          {confirmDeleteZaps &&
                            confirmDeleteZaps.length > 0 &&
                            (confirmDeleteZaps[index] ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="delete-tooltip">
                                    {" "}
                                    <FormattedMessage
                                      id={"settings.areYouSureYouWantToDelete"}
                                    />{" "}
                                    "{zap.name}" ?
                                  </Tooltip>
                                }
                              >
                                <a
                                  href="#"
                                  className="btn1"
                                  style={{
                                    width: "80px",
                                    height: "30px",
                                    padding: "0px 10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleDeleteZap(index)}
                                >
                                  <FormattedMessage id={"confirm"} />
                                </a>
                              </OverlayTrigger>
                            ) : (
                              <div
                                style={{
                                  width: "80px",
                                  height: "30px",
                                  padding: "0px 10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="/assets/images/ic_delete_g.png"
                                  alt=""
                                  style={{ padding: "35%" }}
                                  onClick={() => handleConfirmDeleteZap(index)}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
            <Pagination
              pages={
                Math.floor(zaps && zaps.length / perPage) +
                (zaps && zaps.length % perPage > 0 ? 1 : 0)
              }
              currentPage={page}
              goToPage={setPage}
            />
          </div>
        </div>
        {error && (
          <div className="btn-grp text-center  error  kaspr-form-text-error">
            {error + " "}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="border-0">
        {props.account && (
          <a
            href={props.moreInfoLink}
            target="_blank"
            className="text-btn mr14"
          >
            <FormattedMessage id={"info.moreInfo"} />
            <span className="link-icon">
              <img src="/assets/images/link_icon.svg" alt="" />
            </span>
          </a>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ZapierModal;
