import React, { useState } from "react";
import "./Permissions.css";
import CustomToolTip from "../CustomToolTip/CustomToolTip";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "../index";
function View({ title, description }) {
  return (
    <div id={"view-permission"} className={"global-fade-in "}>
      <div className={"view-permission-message "}>
        <div className={"message-box"}>
          <div>
            <img
              src="/assets/images/logo_round.png"
              className={"champion-img"}
              alt=""
            />
          </div>
          <div className={"message-header"}>
            <h2>
              <FormattedMessage id={title} />
            </h2>

            <p>
              <FormattedMessage id={description} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ToolTip({ text, children, position, containerStyle }) {
  return (
    <OverlayTrigger
      key={"i_info"}
      placement={position || "top"}
      overlay={(props) =>
        Tooltip({
          ...props,
          data: text,
          container_style: containerStyle,
        })
      }
    >
      {children}
    </OverlayTrigger>
  );
}

function CallToAction({
  children,
  isButton,
  withoutIcon,
  isPlan,
  toolTipStyle,
  toolTipPosition,
  containerStyle,
  tooltipContainerStyle,
}) {
  if (withoutIcon) {
    return (
      <span id={"calltoaction-permission"} style={containerStyle}>
        <CustomToolTip
          style={toolTipStyle}
          position={toolTipPosition}
          tooltipContainerStyle={tooltipContainerStyle}
          element={
            isPlan ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  className={"crown"}
                  src="/assets/images/crown.png"
                  alt=""
                />{" "}
                <div style={{ color: "#fff", fontSize: 15, lineHeight: 1.2 }}>
                  <FormattedMessage id={"permissions.upgradePlan"} />
                </div>
              </div>
            ) : (
              <div style={{ color: "#fff", fontSize: 15, lineHeight: 1.2 }}>
                <FormattedMessage id={"permissions.noAccess"} />
              </div>
            )
          }
        >
          <span style={{ opacity: 0.5, pointerEvents: "none" }}>
            {children}
          </span>
        </CustomToolTip>
      </span>
    );
  }
  if (isButton) {
    return (
      <span className={"calltoaction-permission"}>
        <CustomToolTip
          style={toolTipStyle}
          position={toolTipPosition}
          element={
            isPlan ? (
              <p>
                <FormattedMessage id={"permissions.upgradePlan"} />
              </p>
            ) : (
              <p>
                <FormattedMessage id={"permissions.noAccess"} />
              </p>
            )
          }
        >
          <button className={"btn btn1 "} style={{ width: "max-content" }}>
            {isPlan && (
              <img className={"crown"} src="/assets/images/crown.png" alt="" />
            )}
            <span>{children}</span>
          </button>
        </CustomToolTip>
      </span>
    );
  }

  return (
    <span className={"calltoaction-permission"}>
      <CustomToolTip
        style={toolTipStyle}
        position={toolTipPosition}
        element={
          isPlan ? (
            <p>
              <FormattedMessage id={"permissions.upgradePlan"} />
            </p>
          ) : (
            <p>
              <FormattedMessage id={"permissions.noAccess"} />
            </p>
          )
        }
      >
        <span>
          {children}{" "}
          {isPlan && (
            <img className={"crown"} src="/assets/images/crown.png" alt="" />
          )}
        </span>
      </CustomToolTip>
    </span>
  );
}

export { View, CallToAction, ToolTip };
