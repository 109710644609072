import React, { useEffect, useState } from "react";
import "./OptIOutFromEmail.css";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./OptIOutFromEmail.css";
import queryString from "query-string";
import { optOutFromEmail } from "../../services/api/optOut.api";
export default function OptOutFromEmail() {
  const [isSucceeded, setIsSucceeded] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let { key } = queryString.parse(window.location.search);
    if (key) {
      optOutFromEmail(key).then((results) => {
        if (results.statusCode == "200") {
          setIsSucceeded(true);
        } else {
          setIsSucceeded(false);
          setErrorMessage(results.data.message);
        }
      });
    } else {
      setErrorMessage("key not found");
    }
  }, []);

  return (
    <div id={"optOut-from-email"}>
      <Header />
      <div className={"optOut-from-email-container"}>
        {isSucceeded ? (
          <div>
            <h1>Thank you for your request</h1>
            <div className={"content"}>
              We confirm your details have been successfully deleted from our
              database.
            </div>
          </div>
        ) : (
          <div>
            <h1 className={"error"}>Oops ! {errorMessage}</h1>
            <div>
              <img src="assets/images/notfound.svg" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <p></p>
    </footer>
  );
}

function Header() {
  return (
    <header className="signup-header">
      <Container>
        <Row className="align-items-center">
          <Col>
            <div className={"logo text-left"}>
              <a className="" href="/">
                <img
                  src="/assets/images/logo_on_white.png"
                  alt="logo"
                  width="125"
                />
              </a>
            </div>
          </Col>
          <Col>
            <div className="header-right d-flex align-items-center justify-content-end">
              <p className="p16 p-color pr-3">
                Have an account?{" "}
                <NavLink className="text-btn" exact to="/signin">
                  Log in
                </NavLink>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
