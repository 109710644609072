import React from "react";
import { Button } from "react-bootstrap";
import { Animation } from "../index";

export default function Language({
  profile,
  setSelectToggle,
  selectToggle,
  handleSelectLanguage,
  isOnBoardingPage,
}) {
  const IMAGES = {
    fr: { img: <img src="assets/images/france.png" alt="" />, lang: "French" },
    de: { img: <img src="assets/images/germany.png" alt="" />, lang: "German" },
    es: { img: <img src="assets/images/spain.png" alt="" />, lang: "Spanish" },
    it: { img: <img src="assets/images/italy.png" alt="" />, lang: "Italian" },
    en: { img: <img src="assets/images/usa.png" alt="" />, lang: "English" },
  };
  return (
    <div className="language">
      <div className="dropdown">
        {isOnBoardingPage ? (
          <ButtonWithoutText
            setSelectToggle={setSelectToggle}
            profile={profile}
            IMAGES={IMAGES}
          />
        ) : (
          <ButtonWithText
            setSelectToggle={setSelectToggle}
            profile={profile}
            IMAGES={IMAGES}
          />
        )}
        <Animation.SlideDown
          className={
            "dropdown-menu " + (selectToggle == "changeLanguage" ? "show" : "")
          }
          toggle={selectToggle == "changeLanguage"}
          setToggle={setSelectToggle}
          style={{
            left: isOnBoardingPage ? -180 : 0,
            width: 220,
            marginTop: 5,
          }}
        >
          <div style={{ fontSize: 13, padding: "5px 0" }}>
            <div
              onClick={() => handleSelectLanguage("en")}
              className={"language-label line"}
            >
              <img src="assets/images/usa.png" alt="" /> English
            </div>
            <div
              onClick={() => handleSelectLanguage("fr")}
              className={"language-label line"}
            >
              <img src="assets/images/france.png" alt="" /> French
            </div>
            <div
              onClick={() => handleSelectLanguage("de")}
              className={"language-label line"}
            >
              <img src="assets/images/germany.png" alt="" /> German
            </div>
            <div
              onClick={() => handleSelectLanguage("es")}
              className={"language-label line"}
            >
              <img src="assets/images/spain.png" alt="" /> Spanish
            </div>
            {/*   <div
              onClick={() => handleSelectLanguage("it")}
              className={"language-label line"}
            >
              <img src="assets/images/italy.png" alt="" /> Italian
            </div>*/}
          </div>
        </Animation.SlideDown>
      </div>
    </div>
  );
}

const ButtonWithoutText = ({ setSelectToggle, profile, IMAGES }) => {
  return (
    <Button
      className="dropdown-toggle "
      style={{ width: 54, marginLeft: -35, height: 25 }}
      onClick={() => setSelectToggle("changeLanguage")}
    >
      <div className={"language-label"} style={{ padding: 0 }}>
        {IMAGES[profile.language].img}
      </div>
    </Button>
  );
};
const ButtonWithText = ({ setSelectToggle, profile, IMAGES }) => {
  return (
    <Button
      className="dropdown-toggle "
      onClick={() => setSelectToggle("changeLanguage")}
    >
      <div className={"language-label"} style={{ padding: 0 }}>
        {IMAGES[profile.language].img} {IMAGES[profile.language].lang}
      </div>
    </Button>
  );
};
