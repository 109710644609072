import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ intl, plan }) => [
  {
    planId: "0",
    permissions: [
      {
        category: "technology",
        items: [
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.chromeExtensionAvailableOnX",
              },
              {
                value: "Linkedin",
              }
            ),
            toolTip: null,
          },
        ],
      },
      {
        category: "dashboard",
        items: [
          {
            name: intl.formatMessage(
              { id: "billing.plan.createXLeadLists" },
              { value: `${plan.maxLists}` }
            ),
            toolTip: null,
          },
        ],
      },
      {
        category: "enrichment_automation",
        items: [
          {
            name: intl.formatMessage(
              { id: "billing.plan.createXEnrichmentWk" },
              { value: "1" }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.launchXWkPerMonth",
              },
              { value: "3" }
            ),
            toolTip: null,
          },
        ],
      },
      {
        category: "support",
        items: [
          {
            name: intl.formatMessage({ id: "billing.plan.emailAndChat" }),
            toolTip: null,
          },
        ],
      },
      {
        category: "integrations",
        items: [
          {
            name: (
              <>
                <FormattedMessage
                  id="billing.plan.integrationsCrm"
                  values={{
                    span: (chunk) => <span>{chunk}</span>,
                  }}
                />
                : Salesforce, Hubspot, Pipedrive, Lemlist
              </>
            ),
            toolTip: null,
          },
          {
            name: (
              <>
                <FormattedMessage
                  id="billing.plan.integrationsDialers"
                  values={{
                    span: (chunk) => <span>{chunk}</span>,
                  }}
                />
                : Aircall & Ringover
              </>
            ),
            toolTip: null,
          },
        ],
      },
    ],
  },

  {
    planId: "plan_1",
    permissions: [
      {
        category: "technology",
        items: [
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.chromeExtensionAvailableOnX",
              },
              {
                value: "Linkedin Sales Navigator",
              }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
            toolTip: null,
          },
        ],
      },
      {
        category: "dashboard",
        items: [
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.adminStatusXMembers",
              },
              { value: "1" }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage({ id: "billing.plan.creditsRollOver" }),
            toolTip: null,
          },
          {
            name: intl.formatMessage(
              { id: "billing.plan.createXLeadLists" },
              { value: `${plan.maxLists}` }
            ),
            toolTip: null,
          },
        ],
      },
      {
        category: "enrichment_automation",
        items: [
          {
            name: intl.formatMessage(
              { id: "billing.plan.createXEnrichmentWk" },
              { value: "3" }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.launchXWkPerMonth",
              },
              { value: "3" }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.uploadXBulkEnrichmentFiles",
              },
              { value: "3" }
            ),
            toolTip: null,
          },
        ],
      },
      {
        category: "support",
        items: [
          {
            name: intl.formatMessage({ id: "billing.plan.emailAndChat" }),
            toolTip: null,
          },
        ],
      },
    ],
  },

  {
    planId: "plan_2",
    permissions: [
      {
        category: "technology",
        items: [
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.chromeExtensionAvailableOnX",
              },
              {
                value: "Recruiter Lite",
              }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage({ id: "billing.plan.AdvancedApiAccess" }),
            toolTip: null,
          },
        ],
      },
      {
        category: "dashboard",
        items: [
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.adminStatusXMembers",
              },
              { value: "2" }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage({
              id: "billing.plan.customMemberPermissions",
            }),
            toolTip: null,
          },
          {
            name: intl.formatMessage({ id: "billing.plan.unlimitedLeadLists" }),
            toolTip: null,
          },
          {
            name: intl.formatMessage({
              id: "billing.plan.accesToReportAndCreditUsageInsights",
            }),
            toolTip: null,
          },
        ],
      },

      {
        category: "enrichment_automation",
        items: [
          {
            name: intl.formatMessage({
              id: "billing.plan.unlimitedEnrichmentWk",
            }),
            toolTip: null,
          },
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.launchXWkPerMonth",
              },
              {
                value: "10",
              }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.uploadXBulkEnrichmentFiles",
              },
              { value: "10" }
            ),
            toolTip: null,
          },
        ],
      },

      {
        category: "support",
        items: [
          {
            name: intl.formatMessage({ id: "billing.plan.emailAndChat" }),
            toolTip: null,
          },
        ],
      },
    ],
  },
  {
    planId: "plan_3",
    permissions: [
      {
        category: "dashboard",
        items: [
          {
            name: intl.formatMessage(
              {
                id: "billing.plan.adminStatusXMembers",
              },
              { value: "5" }
            ),
            toolTip: null,
          },
          {
            name: intl.formatMessage({ id: "billing.plan.premiumApiAccess" }),
            toolTip: null,
          },
        ],
      },

      {
        category: "enrichment_automation",
        items: [
          {
            name: intl.formatMessage({
              id: "billing.plan.launchUnlimitedWk",
            }),
            toolTip: null,
          },
          {
            name: intl.formatMessage({
              id: "billing.plan.uploadUnlimitedBulkEnrichmentFiles",
            }),
            toolTip: null,
          },
        ],
      },
      {
        category: "support",
        items: [
          {
            name: intl.formatMessage({
              id: "billing.plan.tailoredOnboardingExperience",
            }),
            toolTip: null,
          },
          {
            name: intl.formatMessage({
              id: "billing.plan.dedicatedAccountManager",
            }),
            toolTip: null,
          },
        ],
      },
    ],
  },
  {
    planId: "plan_11",
    permissions: [
      {
        name: intl.formatMessage({ id: "billing.plan.extensionAvailable" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.saveEditExportLeads" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.tasksManagement" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.emailer" }),
        toolTip: intl.formatMessage({ id: "billing.plan.emailerTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.dialer" }),
        toolTip: intl.formatMessage({ id: "billing.plan.dialerTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.integrations" }),
        toolTip: intl.formatMessage({ id: "billing.plan.integrationsTooltip" }),
      },
      {
        name: intl.formatMessage(
          { id: "billing.plan.upToLists" },
          { value: plan.maxLists }
        ),
        toolTip: intl.formatMessage({ id: "billing.plan.upToListsTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.enrichmentWk" }),
        toolTip: intl.formatMessage({ id: "billing.plan.enrichmentWkTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.enrichmentWkLaunches" }),
        toolTip: intl.formatMessage({
          id: "billing.plan.enrichmentWkLaunchesTooltip",
        }),
      },
      {
        name: intl.formatMessage({
          id: "billing.plan.defaultLinkedinSequences",
        }),
        toolTip: intl.formatMessage({
          id: "billing.plan.defaultLinkedinSequencesTooltip",
        }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
        toolTip: "",
      },
    ],
  },
  {
    planId: "plan_12",
    permissions: [
      {
        name: intl.formatMessage({ id: "billing.plan.extensionAvailable" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.saveEditExportLeads" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.tasksManagement" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.listManagement" }),
        toolTip: null,
      },
    ],
  },
  {
    planId: "custom",
    permissions: [
      {
        name: intl.formatMessage({ id: "billing.plan.extensionAvailable" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.saveEditExportLeads" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.tasksManagement" }),
        toolTip: null,
      },
      {
        name: intl.formatMessage({ id: "billing.plan.emailer" }),
        toolTip: intl.formatMessage({ id: "billing.plan.emailerTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.dialer" }),
        toolTip: intl.formatMessage({ id: "billing.plan.dialerTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.integrations" }),
        toolTip: intl.formatMessage({ id: "billing.plan.integrationsTooltip" }),
      },
      {
        name:
          plan.maxLists == -1
            ? intl.formatMessage(
                { id: "billing.plan.unlimitedLists" },
                { value: plan.maxLists }
              )
            : intl.formatMessage(
                { id: "billing.plan.upToLists" },
                { value: plan.maxLists }
              ),
        toolTip: intl.formatMessage({ id: "billing.plan.upToListsTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.enrichmentWk" }),
        toolTip: intl.formatMessage({ id: "billing.plan.enrichmentWkTooltip" }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.enrichmentWkLaunches" }),
        toolTip: intl.formatMessage({
          id: "billing.plan.enrichmentWkLaunchesTooltip",
        }),
      },
      {
        name: intl.formatMessage({
          id: "billing.plan.defaultLinkedinSequences",
        }),
        toolTip: intl.formatMessage({
          id: "billing.plan.defaultLinkedinSequencesTooltip",
        }),
      },
      {
        name: intl.formatMessage({ id: "billing.plan.publicApiAccess" }),
        toolTip: "",
      },
    ],
  },
];
