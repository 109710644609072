import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CreateOrganizationModal } from "../../../../components";
import { useBecomeMember } from "../../Home.hooks";

export default function BecomeMember({ user, createWorkSpace, organization }) {
  const { func, states, setters } = useBecomeMember(organization);
  const { createWorkspaceToggle, organizations, loading, invitations } = states;
  const { setCreateWorkspaceToggle } = setters;
  const {
    handleJoinOrganization,
    handleAcceptInvitation,
    handleDeclineInvitation,
    getOwner,
  } = func;

  return (
    <div className={"onboarding-content workspace"}>
      {states.invitations &&
        invitations.map((invitation, i) => (
          <div className={"invitations-received"} key={i}>
            <div>
              <img
                src={
                  invitation.inviterPicture || "/assets/images/ic_profile.png"
                }
                alt=""
              />
              <FormattedMessage
                id={"home.invitedYouOn"}
                values={{
                  inviter: <strong> {invitation.inviter} </strong>,
                  orgName: (
                    <strong> '{invitation.organizationId.name}' </strong>
                  ),
                }}
              />
            </div>
            <div>
              <button onClick={() => handleAcceptInvitation(invitation)}>
                <FormattedMessage id={"accept"} />
              </button>
              <button onClick={() => handleDeclineInvitation(invitation)}>
                <FormattedMessage id={"decline"} />
              </button>
            </div>
          </div>
        ))}
      {loading ? (
        <div className={"loader"}>
          <img src="/assets/images/loading.gif" alt="" />
        </div>
      ) : organizations.length > 0 ? (
        <>
          <h4>
            <FormattedMessage
              id={"organization.joinAnExistingOrganization"}
              defaultMessage={""}
              values={{ br: <br /> }}
            />
          </h4>
          <div className={"companies-box"}>
            {organizations &&
              organizations.map((org, i) => (
                <div key={i} className="company-box-item">
                  <div className={"company-icon"}>
                    <img
                      src={org.picture || "/assets/images/ic-company.png"}
                      alt=""
                    />
                  </div>
                  <div className={"company-data"}>
                    <p>{org.name}</p>
                    <p>
                      <span>
                        {org.members.length}{" "}
                        <FormattedMessage id={"organization.members"} />
                      </span>{" "}
                      <span>
                        {" "}
                        <FormattedMessage id={"organization.owner"} />:{" "}
                        <span className="capital">
                          {getOwner(org).firstName}
                        </span>{" "}
                        <span className="capital">
                          {getOwner(org).lastName}
                        </span>
                      </span>
                    </p>
                  </div>
                  <div className={"company-request"}>
                    {org.hasSentJoinReaquest ? (
                      <button className={"btn btn1"} style={{ color: "#fff" }}>
                        {" "}
                        <span>✓</span>{" "}
                        <FormattedMessage
                          id={"home.onboarding.requested"}
                          defaultMessage={"Requested"}
                        />{" "}
                      </button>
                    ) : (
                      <button
                        className={"btn btn2"}
                        onClick={() => handleJoinOrganization(org)}
                      >
                        <span>+</span>{" "}
                        <FormattedMessage
                          id={"home.onboarding.request"}
                          defaultMessage={"Request"}
                        />
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className={"-or-"}>
            <span>
              {" "}
              <FormattedMessage id={"organization.or"} defaultMessage={"or"} />
            </span>{" "}
          </div>
          <div className={"create-new-workspace-btns"}>
            <button onClick={() => createWorkSpace({ isSkip: false })}>
              <img
                src="/assets/images/create-workspace-icon.png"
                style={{ marginRight: 10 }}
                alt=""
              />{" "}
              <FormattedMessage id={"home.iWantMine"} />
            </button>
          </div>
        </>
      ) : (
        <div className={"create-new-workspace-btns"}>
          <p>
            <FormattedMessage id={"home.congratsYouAreAFirstFromYourTeam"} /> 🎉
          </p>
          <button onClick={() => createWorkSpace({ isSkip: false })}>
            <img
              src="/assets/images/create-workspace-icon.png"
              style={{ marginRight: 10 }}
              alt=""
            />{" "}
            <FormattedMessage id={"home.createMyWorkspace"} />
          </button>
        </div>
      )}

      {createWorkspaceToggle && (
        <CreateOrganizationModal
          toggle={createWorkspaceToggle}
          setToggle={setCreateWorkspaceToggle}
          profile={user}
        />
      )}
    </div>
  );
}
