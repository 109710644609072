const actions = {
  GET_LABELS: "GET_LABELS",
  IS_MODAL_OPEN: "IS_MODAL_OPEN",
  CURRENT_STEP: "CURRENT_STEP",
  CSV_CONTENT: "CSV_CONTENT",
  SET_HEADERS: "SET_HEADERS",
  SET_LINKEDIN_COLUMN: "SET_LINKEDIN_COLUMN",
  SET_FIRSTNAME_COLUMN: "SET_FIRSTNAME_COLUMN",
  SET_LASTNAME_COLUMN: "SET_LASTNAME_COLUMN",
  SET_LABELS: "SET_LABELS",
  SET_SELECTED_LABEL: "SET_SELECTED_LABEL",
  UPDATE_ENRICHMENT_DATA: "UPDATE_ENRICHMENT_DATA",
  SET_TOTAL_PAGES: "SET_TOTAL_PAGES",
  SET_NO_DATA: "SET_NO_DATA",
};

export default actions;
