import React, { useState } from "react";
import ApiIntegrationModal from "../../ApiIntegrationModal";
import { FormattedMessage } from "react-intl";
import { integrationDeleteAccount } from "../../../../../../services/api/integrations.api";
import { capitalizeFirstLetter } from "../../../../../../services/utils/tools";
import { integrationUpdatedNames } from "../../../constants";
import "./ApiKeyComponent.css";

const ApiKeyComponent = (props) => {
  const [showApiIntegrationModal, setShowApiIntegrationModal] = useState(false);

  const handleClick = (e) => {
    setShowApiIntegrationModal(true);
    props.onHide();
    props.handleShowApiIntegration(e);
  };

  const handleDisconnect = async () => {
    let { statusCode } = await integrationDeleteAccount({
      integrationName: props.integration,
    });
    if (statusCode === 200) {
      props.disconnectAccount();
      props.onHide();
    }
  };

  const handleMapping = () => {
    props.onHide();
    props.handleMappingModal();
  };

  const apiKeyLinks = {
    lemlist: "https://app.lemlist.com/settings/integrations",
    pipedrive: "https://app.pipedrive.com/settings/api",
    sendinblue: "https://account.sendinblue.com/advanced/api",
  };

  return (
    <>
      {props.account ? (
        <div className="buttons-container">
          <div className="buttons">
            <a
              href="#"
              className="btn1 ripple-btn"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#api-integration-modal"
              onClick={handleDisconnect}
            >
              <FormattedMessage id={"settings.disconnect"} />
            </a>
            <a
              href="#"
              className="btn1 ripple-btn"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#api-integration-modal"
              onClick={(e) => handleMapping()}
            >
              <FormattedMessage id={"mapping"} />
            </a>
          </div>
        </div>
      ) : (
        <>
          <button className="btn btn1" onClick={(e) => handleClick(e)}>
            <div className="responsive-text">
              <FormattedMessage
                id="settings.connectTo"
                values={{
                  value: capitalizeFirstLetter(
                    integrationUpdatedNames[props.integration]
                  ),
                }}
              />
            </div>
          </button>
          {showApiIntegrationModal && (
            <ApiIntegrationModal
              integration={props.integration}
              imagesrc={props.imagesrc}
              show={showApiIntegrationModal}
              onHide={props.handleShowApiIntegration}
              handleMappingModal={props.handleMappingModal}
              confirmConnect={(newAccount, err) =>
                props.confirmConnect(props.integration, newAccount, err)
              }
              apiKeyLink={apiKeyLinks[props.integration]}
            />
          )}
        </>
      )}
    </>
  );
};

export default ApiKeyComponent;
