import React, { useState } from "react";
import { deleteOrganization } from "../../../../services/api/organization.api";
import { Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";

function DeleteBlockModal({
  setBlockToRemove,
  handleRemoveBlock,
  profilesCount,
  intl,
}) {
  const [textToDeleteOrg, setText] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async () => {
    if (
      textToDeleteOrg ===
      intl.formatMessage({ id: "workflow.deleteThisAutomation" })
    ) {
      handleRemoveBlock();
    } else {
      setError(intl.formatMessage({ id: "workflow.pleaseEnterTheValidText" }));
    }
  };
  return (
    <Modal
      show={true}
      onHide={() => setBlockToRemove(false)}
      className="fade  delete-organization-wrapper"
      id="delete-organization"
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={"workflow.deleteAutomation"} />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <div className="delete-inner-ui">
          <div className="alert-wrap">
            <span className="ic-alert">
              <img src="/assets/images/ic-alert.svg" alt="" />
            </span>
            <label>
              <FormattedMessage
                id={"workflow.yourAboutToDeleteThisAutomation"}
              />{" "}
            </label>
            <p>
              <FormattedMessage
                id={"workflow.onceAnAutomationIsDeleted"}
                values={{
                  span: (chunk) => <span> {chunk} </span>,
                  br: <br />,
                }}
              />
            </p>
          </div>

          <p>
            <FormattedMessage
              id={"workflow.thisActionCannotBeUndone_"}
              values={{
                span: (chunk) => <span> {chunk} </span>,
                br: <br />,
              }}
            />{" "}
            {profilesCount && (
              <span>
                : {profilesCount} <FormattedMessage id={"workflow.profiles_"} />{" "}
              </span>
            )}
            <br />
            <br />
            <FormattedMessage id={"workflow.ifYouStillWanToContinue"} />
          </p>
          <div className="form-fields">
            <a href="#" className="delete-tag">
              “<FormattedMessage id={"workflow.deleteThisAutomation"} />”
            </a>
            <div className="form-group">
              <FormattedMessage id={"workflow.typeHere"}>
                {(placeHolder) => (
                  <input
                    type="text"
                    name=""
                    placeholder={placeHolder}
                    onChange={(e) => setText(e.target.value)}
                    style={error ? { border: "1px solid red" } : {}}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">
          {error + " "}
        </div>
      )}
      <div className="modal-footer border-0">
        <a href="#" className="btn6" onClick={() => setBlockToRemove(false)}>
          <FormattedMessage id={"workflow.cancelKeepIt"} />
        </a>
        <a href="#" className="btn5 ml-20" onClick={handleSubmit}>
          <FormattedMessage id={"workflow.yesDeleteThisAutomation"} />
        </a>
      </div>
    </Modal>
  );
}

export default injectIntl(DeleteBlockModal);
