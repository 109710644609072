import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  invitePeopleToGetFreeCredit,
  getKasprInvitations,
} from "../../../../services/api/auth.api";
import { acceptInvitation } from "../../../../services/api/organization.api";
import { Pagination } from "../../../../components";
import { getWorkflows } from "../../../../services/api/workflows.api";
import { FormattedMessage } from "react-intl";
import WarningSection from "../../../Billing/common/WarningSection/WarningSection";

export default function InviteFriend({
  handleCloseModal,
  toggle,
  showToastMessage,
  tracking = {},
}) {
  const [inviteFriend, setInviteFriend] = useState(null);
  let [pendingInvitations, setPendingInvitations] = useState([]);
  let [pendingPage, setPendingPage] = useState(1);
  let [acceptedInvitations, setAcceptedInvitations] = useState([]);
  let [acceptedPage, setAcceptedPage] = useState(1);
  const perPage = 4;
  const handleOnClick = async () => {
    if (inviteFriend) {
      const { data, statusCode } = await invitePeopleToGetFreeCredit(
        inviteFriend
      );
      const info = {
        sent: data.sentInvitationsNumber,
        declined: inviteFriend.length - data.sentInvitationsNumber,
      };

      if (statusCode === 200) {
        setAcceptedInvitations(data.acceptedInvitations);
        setPendingInvitations(data.pendingInvitations);
        // toggle(false);
        showToastMessage("success", info);
      } else {
        showToastMessage(
          "An error has occurred while sending invitation: " +
            data.messageTranslated
        );
        // toggle(false);
      }
    }
    handleCloseModal();
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    if (value) {
      const emails = value
        .replace(",", "")
        .replace(" ", "")
        .split(/\n/)
        .filter((email) => !!email);
      setInviteFriend(emails);
    }
  };

  useEffect(() => {
    getKasprInvitations().then(({ data, statusCode }) => {
      if (data && data.acceptedInvitations && data.pendingInvitations) {
        setAcceptedInvitations(data.acceptedInvitations);
        setPendingInvitations(data.pendingInvitations);
      }
    });
  }, []);

  return (
    <Modal onHide={handleCloseModal} show={toggle}>
      <Modal.Header closeButton>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage
              id={"auth.get5FreeCreditsForEveryFriendBold"}
              values={{
                span: (chunk) => <span className="blue-ui"> {chunk} </span>,
                br: <br />,
              }}
            />
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="inner-content send-invite-ui">
          <form>
            <div className="form-group">
              <label>
                <FormattedMessage id={"auth.youHave5Invites"} />
                <small
                  className={"error-warning "}
                  style={{ display: "block", opacity: 0.7 }}
                >
                  <FormattedMessage
                    id={
                      "settings.profile.emailsWhoHaveAnAccountWillNotBeInvited"
                    }
                  />{" "}
                  <i className="fas fa-exclamation-triangle"></i>
                </small>
              </label>

              <FormattedMessage id={"auth.typeYourEmailAddress"}>
                {(placeholder) => (
                  <textarea
                    placeholder={placeholder}
                    onChange={handleOnChange}
                  />
                )}
              </FormattedMessage>
            </div>
            <p className="info-ui">
              <FormattedMessage id={"auth.forEveryFriendWhoJoins"} />
            </p>
          </form>
          <div
            className="modal-inner-header"
            style={{ "justify-content": "center" }}
          >
            {acceptedInvitations.length > 0 && (
              <h6 className="title">
                <span className="blue-ui">
                  <FormattedMessage id={"auth.acceptedInvitations"} />{" "}
                </span>
              </h6>
            )}
            {acceptedInvitations
              .slice(perPage * (acceptedPage - 1), perPage * acceptedPage)
              .map((invitee) => {
                return <div> {invitee}</div>;
              })}
            <div className="container">
              <Pagination
                pages={
                  Math.floor(acceptedInvitations.length / perPage) +
                  (acceptedInvitations.length % perPage > 0 ? 1 : 0)
                }
                currentPage={acceptedPage}
                goToPage={setAcceptedPage}
                top
              />
            </div>
            {pendingInvitations.length > 0 && (
              <h6 className="title">
                <span className="blue-ui">
                  <FormattedMessage id={"auth.pendingInvitations"} />{" "}
                </span>
              </h6>
            )}
            {pendingInvitations
              .slice(perPage * (pendingPage - 1), perPage * pendingPage)
              .map((invitee) => {
                return <div> {invitee}</div>;
              })}
            <div className="container">
              <Pagination
                pages={
                  Math.floor(pendingInvitations.length / perPage) +
                  (pendingInvitations.length % perPage > 0 ? 1 : 0)
                }
                currentPage={pendingPage}
                goToPage={setPendingPage}
                top
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <a
          href="#"
          onClick={handleCloseModal}
          className="btn4 btn-md mr14"
          data-dismiss="modal"
          data-tracking-id={tracking.cancel}
        >
          <FormattedMessage id={"cancel"} />
        </a>
        <a
          href="#"
          className="btn1  btn-md"
          data-dismiss="modal"
          onClick={handleOnClick}
          data-tracking-id={tracking.send}
        >
          <FormattedMessage id={"auth.sendInvitation"} />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
