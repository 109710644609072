import { FormattedMessage, useIntl } from "react-intl";
import React, { useContext, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { ModalMessage, Tooltip } from "../../../../components";
import PopUp from "../../../../components/PopUp/PopUp";
import { createOrUpdateCustomer } from "../../../../services/api/credits.api";
import { BillingContext } from "../../store/billing.store";

export function CheckoutFormWrapper({ children, title, style }) {
  return (
    <div className={"checkout-form-wrapper"} style={style}>
      <div className="checkout-left-ui">
        <h4 className="heading-ui">{title}</h4>
        <div className="checkout-content-wrap white-wrap">{children}</div>
      </div>
    </div>
  );
}
export function Field({
  label,
  placeholder,
  value,
  onChange,
  required,
  isEmailField = false,
}) {
  const [tooltipToggle, setTooltipToggle] = useState(false);
  const intl = useIntl();

  const inputVerification = (e) => {
    if (!isEmailField && e.target.value.length >= 30) {
      setTooltipToggle(true);
    } else {
      setTooltipToggle(false);
    }
    onChange(e);
  };
  return (
    <div className="form-group ">
      <label>
        {label} {required ? <span>*</span> : ""}{" "}
      </label>
      <input
        type="text"
        placeholder={placeholder}
        onChange={inputVerification}
        value={value}
        required={required}
        maxLength={!isEmailField && "30"}
      />
      {tooltipToggle && (
        <OverlayTrigger
          placement="bottom"
          show={true}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: intl.formatMessage({
                id: "billing.thisInputCanNotContainMoreThen30Chars",
              }),
            })
          }
        >
          <div />
        </OverlayTrigger>
      )}
    </div>
  );
}

export function SuccessModal({
  successModal,
  handleRedirectAndRefreshCredits,
  googleTagManagerEvent,
}) {
  const { state } = useContext(BillingContext);
  const intl = useIntl();
  const [value, setValue] = useState("");
  const handleSubmit = async () => {
    if (!value) return;
    const { statusCode } = await createOrUpdateCustomer({
      paymentInfos: { ...state.checkoutForm, upgradeMotivation: value },
    });

    if (statusCode == 200) {
      handleRedirectAndRefreshCredits();
    }
  };
  return (
    <PopUp onClose={handleRedirectAndRefreshCredits} toggle={successModal}>
      <div className={"success_modal_payment"}>
        <p className={"large"}>
          <FormattedMessage id={"billing.weAreProcessingYourPayment"} />
        </p>
        <p>
          <FormattedMessage id={"billing.onceItsConfirmedWellNotifyYou"} />
        </p>
        <p className={"large"}>
          <FormattedMessage
            id={"billing.whatFeatureMotivatedYouToUpgradeToDay"}
          />
        </p>
        <textarea
          autoFocus={true}
          placeholder={intl.formatMessage({
            id: "billing.shareYourAnswerHere",
          })}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={"btns-group"}>
          <button className={"btn btn1"} onClick={handleSubmit}>
            <FormattedMessage id={"billing.send"} />
          </button>
        </div>
      </div>
    </PopUp>
  );
  return (
    <ModalMessage
      toggle={!successModal}
      setToggle={() => {
        handleRedirectAndRefreshCredits(false);
      }}
      title={""}
      textElement={
        <div className={"success_modal_payment"}>
          <p className={"large"}>
            <FormattedMessage id={"billing.weAreProcessingYourPayment"} /> ...
          </p>
          <p>
            <FormattedMessage id={"billing.onceItsConfirmedWellNotifyYou"} />
          </p>
          <p className={"large"}>
            <FormattedMessage
              id={"billing.whatFeatureMotivatedYouToUpgradeToDay"}
            />
          </p>
          <textarea
            autoFocus={true}
            placeholder={intl.formatMessage({
              id: "billing.shareYourAnswerHere",
            })}
          />
        </div>
      }
      action={<FormattedMessage id={"billing.send"} />}
      handleAction={handleRedirectAndRefreshCredits}
      forbidCancel
      googleTagManagerEvent={googleTagManagerEvent}
    />
  );
}
