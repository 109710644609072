import React from "react";

export default function ErrorBar({ info, onHide }) {
  return (
    <div
      className={"custom-error-bar"}
      style={
        info.type === "announcement"
          ? {
              background: "#3b53d1",
              padding: "10px",
              fontSize: 1,
            }
          : {}
      }
    >
      <p style={{ textAlign: "center", width: "100%" }}>
        <span style={{ fontSize: 15 }}> {info.descriptionText} </span>
        {info.link && (
          <a target={"_blank"} href={info.link}>
            {info.buttonText}
          </a>
        )}
      </p>

      <span onClick={() => onHide(false)}>
        <img width={15} src="/assets/images/close.svg" alt="" />
      </span>
    </div>
  );
}
