import ReactDOM from "react-dom";
import "./RightModal.css";
import React, { useEffect, useRef, useState } from "react";

function Modal({ children, toggle, onClose, hideClose }) {
  const ref = useRef(null);
  const [show, setShow] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setShow("show");
    }, 0);
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [toggle]);

  const handleClickOutSide = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handleClose();
    }
  };
  const handleClose = () => {
    setShow("");
    setTimeout(() => {
      onClose();
    }, 100);
  };
  return (
    <div id={"right_modal"} className={show}>
      <div className={"head-right-modal"} ref={ref}>
        {!hideClose && (
          <span onClick={handleClose}>
            <img src="/assets/images/ic-cross.png" alt="" />
          </span>
        )}
        {children}
      </div>
    </div>
  );
}
export default function RightModal(props) {
  return ReactDOM.createPortal(<Modal {...props} />, document.body);
}
