import React from "react";
import { useElements, CardElement } from "@stripe/react-stripe-js";
import { FormattedMessage } from "react-intl";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
export default function StripeField() {
  return (
    <div>
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            hidePostalCode: true,
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
    </div>
  );
}
