import React, { useState } from "react";
import "./EnrichmentOption.css";
import { FormattedMessage } from "react-intl";

function EnrichmentOption({
  enrichmentOptionKey,
  titleId,
  value,
  updateOption,
}) {
  const [mandatory, setMandatory] = useState(false);
  const [isSelected, setIsSelected] = useState(true);

  const handleToggle = () => {
    const newIsSelected = !isSelected;
    if (!newIsSelected) {
      setIsSelected(newIsSelected);
      setMandatory(false);
      updateOption(enrichmentOptionKey, newIsSelected, value, false);
    } else {
      setIsSelected(newIsSelected);
      updateOption(enrichmentOptionKey, newIsSelected, value, mandatory);
    }
  };

  const handleMandatoryChange = () => {
    const newMandatoryState = !mandatory;
    setMandatory(newMandatoryState);
    updateOption(enrichmentOptionKey, isSelected, value, newMandatoryState);
  };

  return (
    <div className={`card-container ${isSelected ? "card-container-bg" : ""}`}>
      <h1>
        <FormattedMessage id={titleId} />
      </h1>
      <div className={"checkbox-container"}>
        <h2>
          <FormattedMessage id={"bulkEnrichment.enrichmentOptions.no"} />
        </h2>
        <div className="switch-btn">
          <input
            type="checkbox"
            className="switch"
            checked={isSelected}
            onChange={handleToggle}
          />
        </div>
        <h2 className={`${isSelected ? "active-text" : ""}`}>
          <FormattedMessage id={"bulkEnrichment.enrichmentOptions.yes"} />
        </h2>
      </div>
      <div className={"linebreak"}></div>
      <div className={"mandatory"}>
        <input
          type="checkbox"
          className="switch"
          onChange={handleMandatoryChange}
          checked={mandatory}
          disabled={!isSelected}
        />
        <h3>
          <FormattedMessage id={"bulkEnrichment.enrichmentOptions.mandatory"} />
          *
        </h3>
      </div>
    </div>
  );
}

export default EnrichmentOption;
