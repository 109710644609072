import React from "react";
import "./FieldActions.css";
export default function FieldActions({
  children,
  onClick,
  field,
  user,
  organization,
}) {
  if (user.email !== field.addedBy.email) {
    return <>{children}</>;
  }

  return (
    <div className={"fieldactions-container"}>
      <span className={"fieldactions-icons"}>
        <img
          src="/assets/images/ic_edit_g.png"
          alt=""
          onClick={() => onClick({ type: "edit", field })}
        />
        <img
          src="/assets/images/icon-delete.svg"
          alt=""
          onClick={() => onClick({ type: "delete", field })}
        />
      </span>
      {children}
    </div>
  );
}
