import React, { useEffect, useRef, useState } from "react";
import { createOrganization } from "../../services/api/organization.api";
import { Modal } from "react-bootstrap";
import InputTags from "../InputTags/InputTags";
import { SelectPicker } from "../index";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { toast, ToastContainer } from "react-toastify";
import InputEmailTags from "../InputEmailTags/InputEmailTags";
import { capitalizeFirstLetter } from "../../services/utils/tools";

function CreateOrganizationModal({ toggle, setToggle, profile }) {
  const intl = useIntl();
  const [organization, setOrganization] = useState({
    name: "",
    isVisible: true,
    domains: [],
    companySize: 50,
    image: "",
  });
  const [imageBase64, setImageBase64] = useState(null);
  const [error, setError] = useState(null);
  const publicCheckboxRef = useRef(null);
  const privateCheckboxRef = useRef(null);
  const nameInputRef = useRef(null);
  useEffect(() => {
    if (profile.email) {
      const domain = profile.email.split("@")[1];
      let domainName = domain;
      domainName = domainName.split(".");
      domainName.pop();
      domainName = domainName.join("");
      setOrganization({
        ...organization,
        domains: [domain],
        name: capitalizeFirstLetter(domainName) + " Workspace",
      });
    }
    if (publicCheckboxRef.current) {
      publicCheckboxRef.current.checked = true;
    }
  }, [profile]);

  const handleOnSubmit = async () => {
    if (!organization.name) {
      nameInputRef.current.reportValidity();
    } else {
      const file = organization.image.file;

      const formData = new FormData();
      formData.append("picture", file);
      formData.append(
        "organization",
        JSON.stringify({ ...organization, isVisible: true })
      );
      const { data, statusCode } = await createOrganization(formData);
      if (statusCode === 200) {
        window.location = "/organization";
      } else {
        toast.error(data.messageTranslated);
      }
    }
  };

  const handleImageUpload = (e) => {
    let file = e.target.files[0],
      reader = new FileReader();
    const acceptedImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    if (!(file && acceptedImageTypes.includes(file.type))) return;
    setOrganization({
      ...organization,
      image: { file },
    });
    reader.onloadend = function () {
      setImageBase64(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const checkValidity = (domain) => {
    let domainRegex = /^[a-zA-Z0-9-]{0,61}\.([a-zA-Z]{2,})+$/;
    return domainRegex.test(domain);
  };
  const handleOnChangeDomains = (tags, txtValue) => {
    checkValidity(txtValue);
    let invalidsNumber = tags.filter((tag) => tag.isValid == false).length;
    let allInvalids = tags.some((tag) => tag.isValid);
    if (invalidsNumber) {
      setError(
        intl.formatMessage(
          { id: "organization.youHaveEnteredInvalidDomain" },
          { value: invalidsNumber }
        )
      );
    } else {
      setError(null);
    }
    if (
      (tags &&
        tags.length &&
        (tags.length > 1 || invalidsNumber !== 1) &&
        allInvalids) ||
      checkValidity(txtValue)
    ) {
      setOrganization({
        ...organization,
        domains: tags.filter((tag) => tag.isValid).map((tag) => tag.value),
      });
    } else {
      setOrganization({
        ...organization,
        domains: [],
      });
    }
    // setOrganization({ ...organization, domains })
  };
  return (
    <Modal
      show={toggle}
      onHide={() => setToggle(false)}
      className="fade cn-organzation-wraper"
      id="create-new-organzation"
    >
      <Modal.Header className="border-0" closeButton>
        <div className="modal-inner-header">
          <div className="profile-info">
            <h5 className="heading mb-8">
              <FormattedMessage id={"organization.createNewOrganization"} />
            </h5>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="add-member-fields new-organization-ui">
          <form>
            <div className="thumnail-upload-ui">
              <figure>
                <img
                  src={
                    imageBase64 || "/assets/images/ic_organization active.svg"
                  }
                  alt=""
                />
              </figure>
              <div className="thumbnail-content">
                <p>
                  <FormattedMessage id={"organization.thumbnail"} />
                </p>
                <div className="upload-btn">
                  <input type="file" name="file" onChange={handleImageUpload} />
                  <label>
                    + <FormattedMessage id={"organization.changeImage"} />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id={"organization.organizationName"} />
              </label>
              <FormattedMessage id={"organization.egApple"}>
                {(placeholder) => (
                  <input
                    ref={nameInputRef}
                    type="text"
                    required
                    placeholder={placeholder}
                    onChange={(e) =>
                      setOrganization({ ...organization, name: e.target.value })
                    }
                    value={organization.name}
                  />
                )}
              </FormattedMessage>
            </div>

            <div className="account-type-listing">
              <div className="choose-type-listing">
                <div className="enter-domain-ui">
                  <div className="form-group">
                    <label>
                      <FormattedMessage id={"organization.public.text"} />{" "}
                    </label>
                    <InputEmailTags
                      onChange={handleOnChangeDomains}
                      profile={profile}
                      domains={organization.domains.map((domain) => ({
                        value: domain,
                        isValid: true,
                      }))}
                      checkValidity={checkValidity}
                    />
                  </div>
                  <div className="suggestion-items">
                    <label className="suggestion-label">
                      <FormattedMessage id={"organization.examples"} />{" "}
                    </label>
                    <span className="sugeestion-tag-ui">kaspr.com</span>
                    <span className="sugeestion-tag-ui">kaspr.fr</span>
                  </div>
                </div>
                {error && (
                  <div className={"invalid-emails-error"}>
                    <img src="/assets/images/ic-alert-dark.svg" alt="" />{" "}
                    {error}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0">
        <a
          href="#"
          className="btn4 mr14 btn48"
          onClick={() => setToggle(false)}
        >
          <FormattedMessage id={"cancel"} />
        </a>
        <a href="#" className="btn1 btn48" onClick={handleOnSubmit}>
          <FormattedMessage id={"organization.createOrganization"} />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
export default CreateOrganizationModal;
