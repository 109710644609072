import { convertToJsonAndHandleErrors, getToken } from "../utils";
import {
  API_URL,
  CLIENT_ID_GMAIL,
  CLIENT_ID_HUBSPOT,
  CLIENT_ID_LIVE,
  CLIENT_ID_SALESFORCE,
  CLIENT_ID_ZOHO,
  DASHBOARD_URL,
  headers,
  TOKEN,
} from "../../constants";

let language = navigator.language.split(/[-_]/)[0];
language = language !== "fr" && language !== "en" ? "en" : language;

export const checkEmail = (options) => {
  return fetch(`${API_URL}/auth/checkEmail`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify({ ...options, language }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const login = (dataForm) => {
  return fetch(`${API_URL}/auth/signIn`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify({ language, ...dataForm }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const signup = (dataForm) => {
  return fetch(`${API_URL}/auth/signUp`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify({ language, ...dataForm }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const validateEmail = (token) => {
  return fetch(`${API_URL}/auth/steps/validateEmail`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ token }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getUser = () => {
  return fetch(`${API_URL}/auth/getUser`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateUser = (profileFields) => {
  const formData = new FormData();
  for (let field in profileFields) {
    if (field === "image") {
      formData.append("picture", profileFields[field]);
    } else if (field === "phone") {
      formData.append("phone", JSON.stringify(profileFields.phone));
    } else if (field === "autoDiscover") {
      formData.append(
        "autoDiscover",
        JSON.stringify(profileFields.autoDiscover)
      );
    } else {
      formData.append(field, profileFields[field]);
    }
  }
  return fetch(`${API_URL}/auth/updateUser`, {
    method: "PUT",
    headers: {
      Accept: { "Content-Type": "multipart/form-data" },
      json: true,
      Authorization: getToken(),
    },
    body: formData,
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateProfileImage = ({ image }) => {
  let body = new FormData();
  body.append("image", image);
  return fetch(`${API_URL}/auth/updateImage`, {
    method: "PUT",
    headers: {
      Accept: { "Content-Type": "multipart/form-data" },
      json: true,
      Authorization: getToken(),
    },
    body,
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const resendCode = () => {
  return fetch(`${API_URL}/auth/resendEmailValidation`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const phoneVerificationRequest = () => {
  return fetch(`${API_URL}/auth/steps/phoneVerificationRequest`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const isPhoneTokenSent = () => {
  return fetch(`${API_URL}/auth/steps/isPhoneTokenSent`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const validatePhone = ({ token }) => {
  return fetch(`${API_URL}/auth/steps/validatePhone`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ token }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const invitePeopleToGetFreeCredit = (invitees) => {
  return fetch(`${API_URL}/auth/invitations`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ ...invitees }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getKasprInvitations = () => {
  return fetch(`${API_URL}/auth/invitations`, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const skipSteps = () => {
  return fetch(`${API_URL}/auth/steps/skipSteps`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const changePhone = (phone) => {
  return fetch(`${API_URL}/auth/steps/changePhone`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ phone }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const changePassword = (newPassword) => {
  return fetch(`${API_URL}/auth/password/changePassword`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },

    body: JSON.stringify(newPassword),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const resetPasswordRequest = ({ email }) => {
  return fetch(`${API_URL}/auth/password/resetPasswordRequest`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify({ email }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const resetPassword = (dataForm) => {
  return fetch(`${API_URL}/auth/password/resetPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify(dataForm),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const verifyResetPasswordToken = (data) => {
  return fetch(`${API_URL}/auth/password/verifyResetPasswordToken`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const validateAccount = (dataForm) => {
  return fetch(`${API_URL}/auth/confirm`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
    },
    body: JSON.stringify(dataForm),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateProfile = (dataForm) => {
  return fetch(`${API_URL}/auth/profile`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(dataForm),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteProfile = (dataForm) => {
  return fetch(`${API_URL}/auth/profile`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(dataForm),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export async function exists({ email, isBrother, reset }) {
  const redirectUri = reset
    ? `${DASHBOARD_URL}/auth/reset-password`
    : `${DASHBOARD_URL}/login`;
  return fetch(`${API_URL}/auth/exists`, {
    method: "POST",
    headers,
    body: JSON.stringify({ email, redirectUri, isBrother, reset }),
    json: true,
  })
    .then(convertToJsonAndHandleErrors)
    .then((response) => response.json());
}

export async function liveConnect(code) {
  return fetch(`${API_URL}/auth/live`, {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN()}`,
    },
    body: JSON.stringify({
      code,
      clientId: CLIENT_ID_LIVE,
      redirectUri: `${DASHBOARD_URL}/socialAuth/live/`,
    }),
    json: true,
  }).then(convertToJsonAndHandleErrors);
}

export async function gmailConnect(code) {
  return fetch(`${API_URL}/auth/google`, {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN()}`,
    },
    body: JSON.stringify({
      code,
      clientId: CLIENT_ID_GMAIL,
      redirectUri: `${DASHBOARD_URL}/socialAuth/gmail/`,
    }),
    json: true,
  }).then(convertToJsonAndHandleErrors);
}

export function validatePluginInstallation() {
  return fetch(`${API_URL}/auth/steps/validatePluginInstallation/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${TOKEN()}`,
      "Content-Type": "application/json",
    },
    json: true,
  })
    .then((res) => res.json())
    .catch(console.error);
}

export const deleteAccount = () => {
  return fetch(`${API_URL}/auth/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deleteInvitation = (invitee) => {
  return fetch(`${API_URL}/auth/invitations`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ invitee }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(async ({ data, statusCode }) => {
      return { data, statusCode };
    });
};
