import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from "react";
import { getALLInvitations } from "../../../../services/api/organization.api";

const actions = {
  upgrade: {
    link: "/billing/plans",
    name: "home.chooseAKasprPlan",
    img: "/assets/images/upgrade-icon.svg",
    style: { borderColor: "transparent", color: "#FF4083" },
  },
  addMember: {
    link: "/workspace?addMembers=true",
    name: "organization.addMember",
    style: { borderColor: "transparent", color: "#3b53d1" },
    img: "/assets/images/add-member-icon.svg",
  },
  addLicence: {
    link: "/billing/info?addLicense=true",
    name: "organization.addMember",
    style: { borderColor: "transparent", color: "#3b53d1" },
    img: "/assets/images/add-member-icon.svg",
  },
  getMorePhoneCredit: {
    link: "/billing/plans/credits/phone",
    name: "navbar.getMorePhoneCredit",
    style: { borderColor: "transparent", color: "#0eb763" },
    img: "/assets/images/phone-credits.svg",
  },
  getMoreCredit: {
    link: "/billing/plans/credits/phone",
    name: "navbar.getMoreCredit",
    style: { borderColor: "transparent", color: "#0eb763" },
    img: "/assets/images/phone-credits.svg",
  },
  goToAnnual: {
    link: "/billing/plans",
    name: "navbar.goToAnnual",
    style: { borderColor: "transparent", color: "#FF4083" },
    img: "/assets/images/upgrade-icon.svg",
  },
  upgradeToBusiness: {
    link: "billing/plans/plan_2/yearly",
    name: "navbar.upgradeToBusiness",
    style: { borderColor: "transparent", color: "#FF4083" },
    img: "/assets/images/upgrade-icon.svg",
  },
  upgradeToOrganization: {
    link: "billing/plans/plan_3/yearly",
    name: "navbar.upgradeToOrganization",
    style: { borderColor: "transparent", color: "#FF4083" },
    img: "/assets/images/upgrade-icon.svg",
  },
};
export default function UpgradeButton({
  hasSubscription,
  isBillingPage,
  organizationCredits,
  remaining,
  organization,
}) {
  const [pendingInvitations, setPendingInvitations] = useState([]);

  useEffect(() => {
    getALLInvitations().then(({ data }) => {
      setPendingInvitations(data.invitations);
    });
  }, []);
  let licencesToBy =
    organization &&
    organization.members &&
    organization.members.length + pendingInvitations.length + 1;
  let action = null;
  if (
    organizationCredits.plan &&
    remaining &&
    remaining.organizationRemaining
  ) {
    const hasEnoughLicences =
      organizationCredits.plan.numberOfSeats >= licencesToBy;
    action = getButtonAction({
      organizationCredits,
      remaining,
      hasEnoughLicences,
    });
  }
  if (!(action && actions[action])) return null;
  if (action == "upgrade" && isBillingPage) return null;

  return (
    <button
      className="btn upgrade"
      onClick={() =>
        (window.location =
          action == "goToAnnual"
            ? "/billing/plans/" + organizationCredits.plan.planId + "/yearly"
            : actions[action].link)
      }
      style={actions[action].style}
    >
      <span className={actions[action].className}>
        <img src={actions[action].img} alt="" style={{ marginRight: 7 }} />{" "}
        <FormattedMessage id={actions[action].name} />
      </span>
    </button>
  );
}
function getButtonAction({
  organizationCredits,
  remaining,
  hasEnoughLicences,
}) {
  const numberOfLicences = organizationCredits.plan.numberOfSeats;
  const isYearly = organizationCredits.plan.numberOfMonths == 12;
  let highCreditConsumption = false;
  let action = null;
  if (
    organizationCredits.plan &&
    remaining &&
    remaining.organizationRemaining
  ) {
    let phoneCredits =
      organizationCredits.plan.phoneCredits.perSeat *
      organizationCredits.plan.numberOfMonths *
      organizationCredits.plan.numberOfSeats;

    highCreditConsumption =
      remaining.organizationRemaining.phoneCredits < phoneCredits / 2;
  }

  switch (organizationCredits.plan.planId) {
    case "0":
      action = "upgrade";
      break;
    case "plan_1":
      if (numberOfLicences == 1) {
        if (!highCreditConsumption) {
          action = hasEnoughLicences ? "addMember" : "addLicence";
        } else {
          action = isYearly ? "getMoreCredit" : "goToAnnual";
        }
      } else {
        if (!highCreditConsumption) {
          action = hasEnoughLicences ? "addMember" : "addLicence";
        } else {
          action = isYearly ? "upgradeToBusiness" : "goToAnnual";
        }
      }
      break;
    case "plan_2":
      if (numberOfLicences == 1) {
        if (!highCreditConsumption) {
          action = hasEnoughLicences ? "addMember" : "addLicence";
        } else {
          action = isYearly ? "getMoreCredit" : "goToAnnual";
        }
      } else {
        if (!highCreditConsumption) {
          action = hasEnoughLicences ? "addMember" : "addLicence";
        } else {
          action = "upgradeToOrganization";
        }
      }
      break;
    case "plan_3":
      if (isYearly) {
        if (!highCreditConsumption) {
          action = hasEnoughLicences ? "addMember" : "addLicence";
        } else {
          action = "getMoreCredit";
        }
      }
      break;
  }
  return action;
}
