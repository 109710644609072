import React, { useContext, useEffect, useState } from "react";
import { formatNotificationsDate } from "../../../../services/utils/formatDate";
import {
  getWaitingList,
  updateWaitingList,
} from "../../../../services/api/leads.api";
import { Animation } from "../../../../components";
import { FormattedMessage } from "react-intl";
import { actions, LeadsContext } from "../../store/leads.store";
import DataNotFound from "../../../../components/DataNotFound/DataNotFound";
import { checkPermission } from "../../../../services/utils";
import { toast, ToastContainer } from "react-toastify";

export default function Notifications(props) {
  const { state, dispatch } = useContext(LeadsContext);
  const memberPermission = checkPermission({
    permissions: props.redux.profile.permissions,
    name: "Leads",
    action: "Edit",
  });

  const planPermission = checkPermission({
    permissions: props.redux.credits.organizationCredits.plan.permissions,
    name: "Leads",
    action: "Edit",
  });

  useEffect(() => {
    getWaitingList({ status: state.waitingList.status }).then(
      ({ data, statusCode }) => {
        if (statusCode === 200) {
          dispatch({
            type: actions.GET_WAITING_LIST_NOTIFICATIONS,
            waitingListPages: data.pages,
            payload: {
              waitingListNotifications: data.preLeads,
              waitingList: {
                ...state.waitingList,
                loading: false,
                data: data.preLeads,
                more: data.pages.page !== data.pages.totalPages,
                after: data.pages.page + 1,
              },
            },
          });
        } else {
          dispatch({
            type: actions.GET_WAITING_LIST_NOTIFICATIONS,
            payload: { waitingListNotifications: "not_allowed" },
          });
        }
      }
    );
  }, []);
  const { after, status } = state.waitingList;
  const load = () => {
    setTimeout(() => {
      getWaitingList({ status, page: after }).then(({ data, statusCode }) => {
        if (statusCode === 200) {
          dispatch({
            type: actions.GET_WAITING_LIST_NOTIFICATIONS,
            payload: {
              waitingListNotifications: data.preLeads,
              waitingListPages: data.pages,
              waitingList: {
                ...state.waitingList,
                loading: false,
                data: [...state.waitingList.data, ...data.preLeads],
                more: data.pages.page !== data.pages.totalPages,
                after: data.pages.page + 1,
              },
            },
          });
        } else {
          dispatch({
            type: actions.GET_WAITING_LIST_NOTIFICATIONS,
            payload: { waitingListNotifications: "not_allowed" },
          });
        }
      });
    }, 300);
  };

  const handleUpdateWaitingList = async (ids, update, i) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      dispatch({
        type: actions.GET_WAITING_LIST_NOTIFICATIONS,
        payload: { waitingListNotifications: "not_allowed" },
      });
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    updateWaitingList({ ids, update }).then(({ statusCode }) => {
      if (statusCode === 200) {
        let preLeads = state.waitingList.data;
        preLeads[i].status = update.status;
        dispatch({
          type: actions.GET_WAITING_LIST_NOTIFICATIONS,
          payload: {
            waitingList: {
              ...state.waitingList,
              data: preLeads,
            },
          },
        });
      } else {
        dispatch({
          type: actions.GET_WAITING_LIST_NOTIFICATIONS,
          payload: { waitingListNotifications: "not_allowed" },
        });
      }
    });
  };

  const handleOnChange = async (e) => {
    let { status } = state.waitingList;
    if (e.target.checked && status.indexOf(e.target.value) === -1)
      status.push(e.target.value);
    if (!e.target.checked && status.indexOf(e.target.value) > -1)
      status = status.filter((stat) => stat !== e.target.value);
    getWaitingList({ status }).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({
          type: actions.GET_WAITING_LIST_NOTIFICATIONS,
          payload: {
            waitingListNotifications: data.preLeads,
            waitingList: {
              ...state.waitingList,
              loading: false,
              data: data.preLeads,
              more: data.pages.page !== data.pages.totalPages,
              after: data.pages.page + 1,
              status,
            },
          },
        });
      } else {
        dispatch({
          type: actions.GET_WAITING_LIST_NOTIFICATIONS,
          payload: { waitingListNotifications: "not_allowed" },
        });
      }
    });
  };

  return (
    <NotificationUi
      load={load}
      handleUpdateWaitingList={handleUpdateWaitingList}
      handleOnChange={handleOnChange}
      state={state}
    />
  );
}

function NotificationUi({
  handleUpdateWaitingList,
  state,
  load,
  handleOnChange,
}) {
  const [toggle, setToggle] = useState(false);
  const { data, loading, more } = state.waitingList;
  const loader = React.useRef(load);
  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          loader.current();
        }
      },
      { threshold: 1 }
    )
  );
  const [element, setElement] = React.useState(null);

  useEffect(() => {
    loader.current = load;
  }, [load]);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className="notification-ui table-wrap"
        style={{ maxWidth: "900px", minHeight: "230px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ padding: "16px", fontSize: 20 }}>
            <FormattedMessage id={"organization.waitingList.newsFeed"} />
          </div>
          <div
            className="dropdown customize-table-view-ui"
            style={{ padding: "10px" }}
          >
            <button
              className="dropdown-toggle bx-btn"
              type="button"
              onClick={() => setToggle(true)}
            >
              <img
                style={{ width: "25px" }}
                src="/assets/images/ic_leads.png"
                alt=""
              />
            </button>
            <Animation.SlideDown
              toggle={toggle}
              setToggle={setToggle}
              className="dropdown-menu show"
              style={{ width: "150px", marginRight: "15px" }}
            >
              <form style={{ margin: "10px 0" }}>
                <ul className="dropdown-content" style={{ padding: "0" }}>
                  <li style={{ padding: "5 10px" }}>
                    <div className="checkbox-wrap">
                      <label>
                        <input
                          type={"checkbox"}
                          value={"hasInfos"}
                          defaultChecked={
                            state.waitingList.status.indexOf("hasInfos") > -1
                          }
                          onChange={handleOnChange}
                        />
                        <span className="checkbox-icon"></span>
                        <span className="checkbox-text capital">
                          <FormattedMessage
                            id={"organization.waitingList.toDiscover"}
                          />
                        </span>
                      </label>
                      <ToastContainer />
                    </div>
                  </li>
                  <li style={{ padding: "5 10px" }}>
                    <div className="checkbox-wrap">
                      <label>
                        <input
                          type={"checkbox"}
                          value={"ignored"}
                          id={"status"}
                          defaultChecked={
                            state.waitingList.status.indexOf("ignored") > -1
                          }
                          onChange={handleOnChange}
                        />

                        <span className="checkbox-icon"></span>
                        <span className="checkbox-text capital">
                          <FormattedMessage
                            id={"organization.waitingList.ignored"}
                          />
                        </span>
                      </label>
                    </div>
                  </li>
                  <li style={{ padding: "5 10px" }}>
                    <div className="checkbox-wrap">
                      <label>
                        <input
                          type={"checkbox"}
                          value={"enriched"}
                          id={"status"}
                          defaultChecked={
                            state.waitingList.status.indexOf("enriched") > -1
                          }
                          onChange={handleOnChange}
                        />
                        <span className="checkbox-icon"></span>
                        <span className="checkbox-text capital">
                          <FormattedMessage
                            id={"organization.waitingList.enriched"}
                          />
                        </span>
                      </label>
                    </div>
                  </li>
                </ul>
              </form>
            </Animation.SlideDown>

            <ToastContainer position={"bottom-right"} />
          </div>
        </div>

        {!data.length ? (
          <div
            className="no-data"
            style={{ boxShadow: "none", borderRadius: 0 }}
          >
            <FormattedMessage id={"dataNotFound"} />
          </div>
        ) : (
          <div style={{ overflowY: "scroll", maxHeight: "300px" }}>
            <ul className="notification-list">
              {data.map((notif, i) => (
                <li key={i}>
                  <div
                    style={{ display: "block" }}
                    className={"notification-msg-container"}
                  >
                    {notif.status === "ignored" ? (
                      <div className={"waitingList-notifications"}>
                        <span className={"image-container "}>
                          <img
                            style={{ padding: "4px" }}
                            src={"/assets/images/ignore.png"}
                            alt=""
                          />
                        </span>
                        <a
                          className={"waitingList-notifications-links"}
                          style={{ marginLeft: "16px" }}
                          onClick={(e) => e.stopPropagation()}
                          href={"http://linkedin.com/in/" + notif.linkedin}
                          target={"_blank"}
                          rel="noopener noreferrer nofollow"
                        >
                          <p
                            className="user-name capital"
                            style={{ marginRight: "5px" }}
                          >
                            {notif.firstName}
                          </p>
                          <p className="user-name capital">{notif.lastName}</p>
                        </a>
                        <h3>
                          <FormattedMessage
                            id={"organization.waitingList.hasBeenIgnored"}
                          />
                        </h3>
                      </div>
                    ) : notif.status === "hasInfos" ? (
                      <div
                        className={"waitingList-notifications"}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>
                          <span className={"image-container "}>
                            <img
                              style={{ padding: "4px" }}
                              src={"/assets/images/Recruiter.svg"}
                              alt=""
                            />
                          </span>
                          <div style={{ marginLeft: "16px" }}>
                            <p
                              className="user-name capital"
                              style={{ marginRight: "5px" }}
                            >
                              {notif.firstName}
                            </p>
                            <p className="user-name capital">
                              {notif.lastName}
                            </p>
                          </div>
                          <h3>
                            <FormattedMessage
                              id={"organization.waitingList.hasNewInformation"}
                            />
                          </h3>
                        </div>
                        <div>
                          <button
                            onClick={() => {
                              handleUpdateWaitingList(
                                [notif._id],
                                { status: "ignored" },
                                i
                              );
                            }}
                            className="delete-account-btn"
                            style={{ width: 100 }}
                          >
                            <FormattedMessage
                              id={"organization.waitingList.ignore"}
                            />
                          </button>
                          <a
                            href={"http://linkedin.com/in/" + notif.linkedin}
                            target={"_blank"}
                            className="discover-btn"
                            rel="noopener noreferrer nofollow"
                          >
                            <span className="icon">
                              <img src="/assets/images/link_icon.svg" alt="" />
                            </span>
                            <FormattedMessage
                              id={"organization.waitingList.discover"}
                            />
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className={"waitingList-notifications"}>
                        <span className={"image-container "}>
                          <img
                            style={{ padding: "4px" }}
                            src={"/assets/images/Marketing.svg"}
                            alt=""
                          />
                        </span>
                        <h3 style={{ marginLeft: "16px" }}>
                          <FormattedMessage
                            id={"organization.waitingList.congratulations"}
                          />
                        </h3>
                        <a
                          className={"waitingList-notifications-links"}
                          onClick={(e) => e.stopPropagation()}
                          href={"http://linkedin.com/in/" + notif.linkedin}
                          target={"_blank"}
                          rel="noopener noreferrer nofollow"
                        >
                          <p
                            className="user-name capital"
                            style={{ marginRight: "5px" }}
                          >
                            {notif.firstName}
                          </p>
                          <p className="user-name capital">{notif.lastName}</p>
                        </a>
                        <h3>
                          <FormattedMessage
                            id={
                              "organization.waitingList.hasAlreadyBeenEnriched"
                            }
                          />
                        </h3>
                      </div>
                    )}
                  </div>
                  <small>{formatNotificationsDate(notif.createdAt)} </small>
                </li>
              ))}

              {loading && <li></li>}

              {!loading && more && (
                <li ref={setElement} style={{ background: "transparent" }}></li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
