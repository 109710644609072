import React from "react";
import actions from "./bulkEnrichment.actions";

const BulkEnrichmentContext = React.createContext();

const bulkEnrichmentInitialState = {
  isModalOpen: false,
  currentStep: 1,
  totalPages: 1,
  csvContent: null,
  csvHeaders: [],
  linkedinColumn: "",
  firstnameColumn: "",
  lastnameColumn: "",
  labels: [{ id: null, name: "Default list" }],
  selectedLabel: [{ id: null, name: "Default list" }],
  enrichmentData: {
    phone: { isSelected: true, value: "phone", mandatory: false },
    workEmail: {
      isSelected: true,
      value: "workEmail",
      mandatory: false,
    },
    directEmail: {
      isSelected: true,
      value: "directEmail",
      mandatory: false,
    },
  },
  noData: false,
};

const bulkEnrichmentReducer = (state, action) => {
  switch (action.type) {
    case actions.IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case actions.CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case actions.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case actions.CSV_CONTENT:
      return {
        ...state,
        csvContent: action.payload,
      };
    case actions.SET_HEADERS:
      return {
        ...state,
        csvHeaders: action.payload,
      };
    case actions.SET_LINKEDIN_COLUMN:
      return {
        ...state,
        linkedinColumn: action.payload,
      };
    case actions.SET_FIRSTNAME_COLUMN:
      return {
        ...state,
        firstnameColumn: action.payload,
      };
    case actions.SET_LASTNAME_COLUMN:
      return {
        ...state,
        lastnameColumn: action.payload,
      };
    case actions.SET_LABELS:
      return {
        ...state,
        labels: [{ id: null, name: "Default list" }, ...action.payload],
      };
    case actions.SET_SELECTED_LABEL:
      return {
        ...state,
        selectedLabel: action.payload,
      };
    case actions.UPDATE_ENRICHMENT_DATA:
      return {
        ...state,
        enrichmentData: {
          ...state.enrichmentData,
          [action.payload.enrichmentOptionKey]: action.payload.data,
        },
      };
    case actions.SET_NO_DATA:
      return {
        ...state,
        noData: action.payload,
      };
    default:
      return state;
  }
};

export {
  BulkEnrichmentContext,
  bulkEnrichmentInitialState,
  bulkEnrichmentReducer,
  actions,
};
