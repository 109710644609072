import React, { useEffect, useState } from "react";
import allIntegrations from "../../../Workflow/common/allIntegrations.json";
import { getAccounts } from "../../../../services/api/integrations.api";

import ApiIntegrationModal from "../../../Settings/Integration/Modals/ApiIntegrationModal";
import ConnectionModal from "../../../Settings/Integration/Modals/ConnectionModal/ConnectionModal";
import { FormattedMessage } from "react-intl";
import { integrationUpdatedNames } from "../../../Settings/Integration/constants";

export default function IntegrationsList() {
  const [integrations, setIntegrations] = useState([]);
  const [integration, setIntegration] = useState([]);
  const [integrationApiToggle, setIntegrationApiToggle] = useState(false);
  const [integrationToggle, setIntegrationToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [crmNotVerified, setCrmNotVerified] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const _integrations = allIntegrations.filter(
        (intg) =>
          intg.active && intg.name !== "linkedin" && intg.name !== "zapier"
      );
      let { data, statusCode } = await getAccounts();
      setLoading(false);

      if (data && data.accounts)
        _integrations.map((intg) => {
          intg.isConnected = data.accounts[intg.name];
          return intg;
        });
      setIntegrations(_integrations);
    };
    loadData();
  }, []);
  const onConnect = (name) => {
    setIntegration(name);
    return setIntegrationToggle(true);
  };

  return (
    <div className={"onboarding-content"} style={{ marginTop: 0 }}>
      {crmNotVerified && (
        <div className={"div-message"}>
          <i
            className="fas fa-times"
            onClick={() => setCrmNotVerified(null)}
          ></i>
          {crmNotVerified && (
            <div>
              <i className="fas fa-exclamation-triangle"></i>
              <FormattedMessage
                id={"home.sorryWeAreUnableToProvideCredits"}
                values={{ value: crmNotVerified }}
              />
            </div>
          )}
        </div>
      )}
      <div className="integration-box">
        {loading ? (
          <div className={"loader"}>
            <img src="/assets/images/loading.gif" alt="" />
          </div>
        ) : (
          integrations.map((integration, i) => (
            <IntegrationItem
              key={i}
              name={integration.name}
              image={integration.name + "-icon.png"}
              onConnect={onConnect}
              isConnected={integration.isConnected}
            />
          ))
        )}
        {integrationToggle && (
          <ConnectionModal
            integration={integration}
            category={"crm"}
            imagesrc={"/assets/images/" + integration + "-icon.png"}
            show={() => null}
            onHide={() => setIntegrationToggle(false)}
            account={""}
            disconnectAccount={() => null}
            /*  confirmDisconnect(selectedIntegration.integration)
      }*/
            handleShowApiIntegration={() => setIntegrationApiToggle(true)}
            handleMappingModal={() => null}
            moreInfoLink={"https://www." + integration + ".com"}
            confirmConnect={(newAccount, err, isCrmVerified) => {
              isCrmVerified = eval(isCrmVerified);
              if (!isCrmVerified && !err) setCrmNotVerified(integration.name);
              window.location.reload();
            }}
            /* confirmConnect(selectedIntegration.integration, newAccount, err)
      }*/
            checkMemberPermissions={() => true}
          />
        )}
        {integrationApiToggle && (
          <ApiIntegrationModal
            integration={integration}
            imagesrc={integration + "-icon.png"}
            show={true}
            onHide={setIntegrationApiToggle}
            handleMappingModal={() => null}
            confirmConnect={(newAccount, err) => window.location.reload()}
            /*    confirmConnect(selectedIntegration.integration, newAccount, err)
          }*/
            apiKeyLink={"selectedIntegration.apiKeyLink"}
          />
        )}
      </div>
    </div>
  );
}

function IntegrationItem({ name, image, isConnected, onConnect }) {
  return (
    <div className="integration-box-item">
      <div className={"integration-icon"}>
        <img src={"/assets/images/" + image} alt="" />
      </div>
      <div className={"integration-data"}>
        <h4>{integrationUpdatedNames[name]}</h4>
        <p>
          <FormattedMessage id={"home.onboarding." + name + ".desc"} />
        </p>
      </div>
      <div className={"integration-request"}>
        {!isConnected ? (
          <button
            className={"btn btn2"}
            style={{ background: "#fff" }}
            onClick={() => onConnect(name)}
          >
            <span>+</span> <FormattedMessage id={"connect"} />
          </button>
        ) : (
          <button className={"btn btn1"}>
            <span>✓</span> <FormattedMessage id={"connected"} />
          </button>
        )}
      </div>
    </div>
  );
}
