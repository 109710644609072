import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import { calculateRemaining } from "../../../services/utils/tools";
import { useBecomeMember } from "../Home.hooks";
import { getPublicOrganization } from "../../../services/api/organization.api";
// b2b emails :#B149FF
// personal emails : #FF206E
// export email credits :#4D58F3
// mobile credits :#0EB763

export function Onboarding({
  selectedStep,
  organization,
  steps,
  sendInvitations,
  deleteInvitations,
  user,
  createWorkSpace,
  remaining,
  personalCredits,
  organizationCredits,
  userInfo,
}) {
  const currentStep = steps.find((stp) => stp.id == selectedStep);
  const [invitationType, setInvitationType] = useState("goldInvitations");
  if (!currentStep) return <div />;
  const { Render } = currentStep;
  return (
    <OnboardingWrapper
      selectedStep={selectedStep}
      remaining={calculateRemaining(remaining, { personalCredits })}
      step={currentStep}
      invitationType={invitationType}
      personalCredits={personalCredits}
      organizationCredits={organizationCredits}
      userInfo={userInfo}
    >
      <Render
        invitations={currentStep.invitations}
        sendInvitations={sendInvitations}
        deleteInvitations={deleteInvitations}
        createWorkSpace={createWorkSpace}
        user={user}
        organization={organization}
        setInvitationType={setInvitationType}
        invitationType={invitationType}
      />
    </OnboardingWrapper>
  );
}

export function OnBoardingSideModal({
  onTabClick,
  onStepClick,
  steps,
  selectedStep,
  createWorkSpace,
  handleSkipSteps,
}) {
  let progression = steps.filter((step) => step.done).length;
  progression = Math.floor((progression * 100) / 6);
  const handleStepClick = (step) => {
    onStepClick(step.id);
    window.history.pushState(null, null, "/onboarding/" + step.urlSegment);
  };
  return (
    <div className={"onBoarding-sideNav"}>
      <div className={"skip-onboarding"} onClick={handleSkipSteps}>
        <img src="/assets/images/arrow-prev.svg" alt="Left arrow icon" />
        <span>
          <FormattedMessage id={"home.onboarding.backToHome"} />
        </span>
      </div>
      {steps.map((step, i) => (
        <OnboardingItem
          key={i}
          onStepClick={() => handleStepClick(step)}
          step={step}
          selected={selectedStep}
        />
      ))}
      <NavLink className={"upgrade"} to="/billing">
        <FormattedMessage id={"upgrade"} />
      </NavLink>
      <div className={"progress-container"}>
        <progress id="file" max="100" value={progression} />
        <p>
          {" "}
          <FormattedMessage
            id={"%completed"}
            values={{ value: progression }}
          />{" "}
        </p>
      </div>
    </div>
  );
}

function OnboardingItem({ onStepClick, selected, step }) {
  return (
    <div
      className={"onBoarding-item " + (step.done ? "validated" : "")}
      style={selected == step.id ? { borderColor: "#333" } : {}}
      onClick={
        step.done && step.id == "becomeMember"
          ? () => (window.location.href = "/workspace")
          : onStepClick
      }
    >
      <div className={"onBoarding-item-icon"}>
        <img src={"/assets/images/" + step.icon} alt="" />{" "}
      </div>
      <div className={"onBoarding-item-content"}>
        <h3>{step.title} </h3>
      </div>
    </div>
  );
}
function OnboardingWrapper({
  children,
  selectedStep,
  step,
  invitationType,
  organizationCredits,
  userInfo,
}) {
  useEffect(() => {
    let wrapper = document.querySelector("#onboarding_tab");
    if (wrapper) wrapper.classList.add("global-fade-in");

    setTimeout(() => {
      if (wrapper) wrapper.classList.remove("global-fade-in");
    }, 1000);
  }, [step.title]);

  return (
    <div id="onboarding_tab" className="onboarding-section">
      <div className={"onboarding-wp"}>
        {organizationCredits &&
          !organizationCredits.subscription &&
          userInfo.denyFreeCredits && (
            <div className={"onboarding-banner"}>
              <i className="fas fa-exclamation-triangle"></i>{" "}
              <FormattedMessage id={"home.sorryConnectWithAnotherAccount"} />
            </div>
          )}
        <h2>{step.title}</h2>
        {step.urlSegment == "invite" ? (
          invitationType == "goldInvitations" ? (
            <p className={"subtitile"}>
              <img src={"/assets/images/" + step.coin} alt="" />
              {step.creditsText}{" "}
            </p>
          ) : (
            <p className={"subtitile"}>
              <img src={"/assets/images/" + step.coin2} alt="" />
              <FormattedMessage id={"earn"} /> {step.creditsText2}{" "}
            </p>
          )
        ) : (
          <p className={"subtitile"}>
            {" "}
            <img src={"/assets/images/" + step.coin} alt="" />
            {step.urlSegment !== "invite" && (
              <FormattedMessage id={"earn"} />
            )}{" "}
            {step.creditsText}{" "}
          </p>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
}
