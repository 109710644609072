import React from "react";
import actions from "./Organization.actions";

const OrganizationContext = React.createContext();

const OrganizationInitialState = {
  toggle: false,
  organization: {},
  dataChart: null,
  dataPie: null,
  chartFilters: {
    monthly: true,
    emails: null,
    startDate: null,
    endDate: null,
    minDate: null,
  },
  activityFilters: {
    member: null,
    startDate: null,
    endDate: null,
    types: [
      "pack",
      "linkedinUser",
      "linkedinAdvanced",
      "free credits",
      "renew",
      "upgrade",
    ],
  },
  activity: null,
  activityPages: null,
  activityPage: null,
  useByMember: null,
  usageByMemberPages: 0,
  remaining: null,
  organizationInvitations: [],
  isAloneInWorkspace: false,
  deleteTeamModal: false,
  moveWorkspace: false,
  isMoving: false,
  selectedInvitation: null,
  isAdmin: null,
  hasOtherOwnerOrAdmin: false,
  selectAdminsToggle: false,
  selectedAdmins: null,
  orgMembers: [],
};

const OrganizationReducer = (state, action) => {
  if (actions[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return state;
  }
};

export {
  OrganizationContext,
  OrganizationInitialState,
  OrganizationReducer,
  actions,
};
