import React from "react";
import { FormattedMessage } from "react-intl";
import "./IntegrationHeader.css";

function IntegrationHeader() {
  return (
    <div className="integartion-header-ui integration-top-container">
      <div className="top-ui">
        <div className="left-ui integrations-header">
          <label className="inner-title">
            <FormattedMessage id={"settings.integration.integrations"} />
          </label>
        </div>
      </div>
      <div className="content-ui">
        <p>
          <FormattedMessage id={"settings.integration.integrateYourFavorite"} />
        </p>
      </div>
      <div className="cta-buttons">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScoB4YOYGDBEPTjjEkGUokdKk8BiOR-42CtZrEG4MLj5y2bAw/viewform"
          target="_blank"
          className="btn1"
        >
          <FormattedMessage id="settings.integration.requestIntegration" />
        </a>
        <a href="https://www.kaspr.io/partner" target="_blank" className="btn9">
          <FormattedMessage id="settings.integration.wantToBuildYourOwnIntegrations" />
        </a>
      </div>
    </div>
  );
}

export default IntegrationHeader;
