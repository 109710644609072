import { FormattedMessage, useIntl } from "react-intl";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { InfoToolTip, PreLoader, Tooltip } from "../../../components";
import { NavLink } from "react-router-dom";
import LatestLeads from "../LatestLeads/LatestLeads";
import CurrentTasks from "../CurrentTasks/CurrentTasks";
import YourWorkflows from "../YourWorkflows/YourWorkflows";
import HomeNotifications from "../HomeNotifications/HomeNotifications";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeContent.css";
import KnowledgeBaseSlick from "../KnowledgeBaseSlick/KnowledgeBaseSlick";
import CreateWorkSpaceSection from "./common/CreateWorkSpaceSection";
import ColleaguesCard from "../../../components/ColleaguesCard/ColleaguesCard";
import MembersListModal from "../common/MembersListModal/MembersListModal";
import { getALLInvitations } from "../../../services/api/organization.api";
import WorkspacesCards from "../../../components/WorkspacesCards/WorkspacesCards";

export default function HomeContent({
  leads,
  tasks,
  workflows,
  notifications,
  organization,
  user,
  subOrganizations,
  credits,
}) {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <PreLoader />;
  }

  if (!organization) {
    return <CreateWorkSpaceSection organization={organization} user={user} />;
  }

  return (
    <div id="dashboard_tab" className="tab-pane  active show global-fade-in ">
      <KnowledgeBaseSlick />
      <Row>
        <LatestLeads leads={leads} />
        <CurrentTasks tasks={tasks} type={"tasks"} />
      </Row>
      <Row>
        <HomeNotifications notifications={notifications} />
        <YourWorkflows workflows={workflows} type={"workflows"} />
      </Row>
      <WorkspacesCards
        subOrganizations={subOrganizations}
        organization={organization}
        credits={credits}
      />
    </div>
  );
}
