import React, { useContext, useEffect, useState, useRef } from "react";
import { actions, LeadsContext } from "../../container/Leads/store/leads.store";
import { leadsFilter } from "../../services/api/leads.api";
import { Tooltip } from "../index";
import { OverlayTrigger } from "react-bootstrap";

function Pagination({
  pages,
  currentPage,
  top,
  goToPage,
  styles,
  lock,
  lineStyles,
}) {
  const [listPages, setListPages] = useState([]);
  const prev_btn_ref = useRef(null);
  const next_btn_ref = useRef(null);
  useEffect(() => {
    const allPages = [];
    for (let i = 1; i <= pages; i++) {
      allPages.push(i);
    }
    setListPages(allPages);
  }, [pages]);

  const handlePrevious = (e) => {
    e.preventDefault();
    const page = currentPage - 1;
    if (page > 0) {
      goToPage(page);
      if (prev_btn_ref.current && next_btn_ref.current) {
        prev_btn_ref.current.classList.add("active");
        next_btn_ref.current.classList.remove("active");
      }
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    const page = currentPage + 1;
    if (page <= pages) {
      goToPage(page);
      if (prev_btn_ref.current && next_btn_ref.current) {
        next_btn_ref.current.classList.add("active");
        prev_btn_ref.current.classList.remove("active");
      }
    }
  };

  /*const handleGoToThePage = async (page) => {
    const leadsData = { ...state.dataForm, page };
    const { data } = await leadsFilter({ data: leadsData });
    dispatch({
      type: actions.LEADS_FILTER,
      payload: { page },
    });
    dispatch({
      type: actions.GET_LEADS,
      payload: data.leads,
      pages: data.pages,
    });
  };*/

  if (listPages.length <= 1) return <div />;
  if (top) {
    return (
      <div className="cstm-pagination pagination_one" style={{ ...styles }}>
        <p style={{ ...lineStyles }}>
          {currentPage} of {pages} pages
        </p>
        <div className="np-btn">
          {currentPage !== 1 && (
            <a
              href="#"
              className="prev_btn"
              ref={prev_btn_ref}
              onClick={handlePrevious}
            >
              {lock ? (
                <img
                  src="assets/images/lock.png"
                  style={{ width: 15, marginRight: 7 }}
                  alt=""
                />
              ) : (
                <img src="/assets/images/ic_arrow_left.png" alt="Prev" />
              )}
            </a>
          )}
          {currentPage !== pages && (
            <a
              href="#"
              className="next_btn"
              ref={next_btn_ref}
              onClick={lock ? () => false : handleNext}
            >
              {lock ? (
                <OverlayTrigger
                  key={"plan-permission"}
                  placement={"top"}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data:
                        "You have to upgrade your plan to be able to use this feature",
                    })
                  }
                >
                  <img
                    src="assets/images/lockBlack.png"
                    style={{ width: 15, marginRight: 7 }}
                    alt=""
                  />
                </OverlayTrigger>
              ) : (
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src="/assets/images/ic_arrow_left.png"
                  alt="Prev"
                />
              )}
            </a>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="cstm-pagination pagination_two">
      <ul>
        <li>
          <a
            href="#"
            className="arrow-btn prev disabled"
            onClick={handlePrevious}
          >
            <img src="/assets/images/ic_arrow_left.png" alt="" />
          </a>
        </li>
        {listPages.map((page, i) => (
          <li key={i}>
            <a
              onClick={(e) => {
                e.preventDefault();
                goToPage(page);
              }}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </a>
          </li>
        ))}

        <li>
          <a href="#" className="arrow-btn next" onClick={handleNext}>
            <img src="/assets/images/ic_arrow_right.png" alt="" />
          </a>
        </li>
      </ul>
    </div>
  );
}
export default Pagination;
