import React, { useContext, useState } from "react";
import { actions, OrganizationContext } from "../../store/Organization.store";
import { Pagination, PreLoader } from "../../../../components";
import { FormattedMessage } from "react-intl";
import { getUsageByMember } from "../../../../services/api/credits.api";
import { CREDIT_COLORS, CREDITS } from "../../../../constants";
import CoinUsage from "../../common/CoinUsage/CoinUsage";

export default function UsageByMember({ isVersion2 }) {
  const { state, dispatch } = useContext(OrganizationContext);
  if (!state.usageByMember) {
    return <div />;
  }
  return (
    <div className="row statistics-inner-block">
      <CardWrapper
        title={<FormattedMessage id={"organization.usageByMember"} />}
        isUsageByMember
        members={state.usageByMember}
      >
        {state.usageByMember.length == 0 ? (
          <NoData />
        ) : (
          // state.usageByMember.map((member, i) => (
          state.usageByMember.map((member, i) => (
            <MemberInfoCard
              key={i}
              member={{ ...member, ...member.memberInfo }}
              leavedOrg={member.leavedOrg}
            />
          ))
        )}
      </CardWrapper>
    </div>
  );
}

function MemberInfoCard({ img, leavedOrg, member }) {
  return (
    <li>
      <div className="left-ui">
        <div className="user-info">
          <figure
            className="profile-ui"
            style={leavedOrg ? { backgroundColor: "#DCDCDF" } : {}}
          >
            <img
              src={member.picture || "/assets/images/ic_profile.png"}
              alt=""
            />
          </figure>
          <div className="profile-detail">
            <p className="capital">{member.firstName}</p>{" "}
            <p className="capital">{member.lastName}</p>
            <span>{member.job || "---"}</span>
          </div>
        </div>
      </div>
      <div className="right-ui coin-usage">
        {CREDITS.map(
          (creditType) =>
            !!member.credits[creditType] && (
              <CoinUsage
                creditUsage={member.credits[creditType]}
                type={creditType}
              />
            )
        )}
      </div>
    </li>
  );
}

function CardWrapper({ title, children }) {
  const [filters, setFilters] = useState({ search: "", page: 1 });
  const { state, dispatch } = useContext(OrganizationContext);

  const handleSearch = async (search) => {
    setFilters({ ...filters, search });
    let { data, statusCode } = await getUsageByMember({ search });
    if (statusCode == 200) {
      dispatch({
        type: actions.GET_USAGE_BY_MEMBER,
        payload: {
          usageByMember: data.creditUsage,
          usageByMemberPages: data.pages,
        },
      });
    }
  };
  const handlePagination = async (page) => {
    setFilters({ ...filters, page });
    let { data, statusCode } = await getUsageByMember({ ...filters, page });
    if (statusCode == 200) {
      dispatch({
        type: actions.GET_USAGE_BY_MEMBER,
        payload: {
          usageByMember: data.creditUsage,
          usageByMemberPages: data.pages,
        },
      });
    }
  };
  return (
    <div className="col-md-11 usage-by-member">
      <div className="info-card card-listing-wrapper">
        <div className="info-card-header ">
          <p>{title}</p>
          <div className="page-header">
            <div>
              <Pagination
                pages={state.usageByMemberPages}
                currentPage={filters.page}
                goToPage={(page) => handlePagination(page)}
                styles={{ margin: 0 }}
                top
              />
            </div>
            <div>
              <a className={"btn btn2"} href="/workspace">
                <FormattedMessage id={"organization.manageMembers"} />
              </a>
            </div>
          </div>
        </div>

        <div className="info-card-content">
          <div className="card-info-listing">
            <ul style={{ overflow: "auto" }}>{children}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const NoData = () => {
  return (
    <div style={{ color: "#999", margin: "40px 0", textAlign: "center" }}>
      <FormattedMessage id={"dataNotFound"} />
    </div>
  );
};
