import React from "react";
import InitialAvatar from "../../InitialsAvatar/InitialsAvatar";

const Member = ({
  id,
  email,
  firstName,
  lastName,
  avatar,
  role,
  job,
  lastActive,
  handleClick,
  isChecked,
  innerRef,
  pendingInvitations,
}) => {
  const alreadyInvited = !!pendingInvitations.find(
    (invitation) => invitation.invitee === email
  );
  return (
    <div
      className="memberItem"
      ref={innerRef}
      style={
        alreadyInvited
          ? { opacity: 0.4, pointerEvents: "none", background: "#f5F5F5" }
          : {}
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          onChange={handleClick}
        />
        <label htmlFor={id} style={{ display: "flex", alignItems: "center" }}>
          {avatar ? (
            <img className="membersAvatar" key={id} src={avatar} alt={id} />
          ) : (
            <InitialAvatar
              firstName={firstName}
              lastName={lastName}
              className="initials"
            />
          )}
          <div className="emailRoleJob">
            <div className="emailAndRole">
              <span>{email}</span>
              <span className={`role ${role}`}>{role} </span>
            </div>
            <div className="job">{job}</div>
          </div>
          {alreadyInvited && (
            <small className={"already-invited"}>Already Invited</small>
          )}
        </label>
      </div>
      <div className="lastActive">{lastActive}</div>
    </div>
  );
};

export default Member;
