import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  actions,
  LeadsContext,
  leadsInitialState,
  leadsReducer,
} from "../store/leads.store";
import { NavLink, useParams } from "react-router-dom";
import LeadsProvider from "../store/leads.provider";
import {
  Animation,
  ContentWrapperUi,
  Header,
  PaginationInput,
  PreLoader,
  SelectPicker,
  SideNav,
} from "../../../components";
import { FormattedMessage, useIntl } from "react-intl";
import { Row } from "react-bootstrap";
import LeadsContentWrapper from "../common/LeadsContentWrapper/LeadsContentWrapper";
import TasksList from "./common/TasksList/TasksList";
import AddTaskModal from "./common/AddTaskModal/AddTaskModal";
import "./Tasks.css";
import {
  createTask,
  getLeadFeatures,
  leadsFilter,
  searchTask,
} from "../../../services/api/leads.api";
import { debounce } from "../../../services/utils/tools";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { getMembers } from "../../../services/api/organization.api";
import { checkPermission } from "../../../services/utils";
import { toast, ToastContainer } from "react-toastify";
import * as Permissions from "../../../components/Permissions/Permissions";
import { usePermissions } from "../../../components/Permissions/permissions.hooks";
function Tasks(props) {
  const [state, dispatch] = useReducer(leadsReducer, leadsInitialState);
  const [filters, setFilters] = useState({ search: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersToggle, setFiltersToggle] = useState(false);
  const [leadToAttach, setLeadToAttach] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const [members, setMembers] = useState(
    props.redux.organization.organization &&
      props.redux.organization.organization.members
  );
  const [planPermission, memberPermission] = usePermissions(
    props.redux.credits &&
      props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan &&
      props.redux.credits.organizationCredits.plan.permissions,
    props.redux.profile.permissions,
    "Leads"
  );
  const intl = useIntl();
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    if (!memberPermission("View")) {
      return setPermissionError("member_permissions");
    }
    if (!planPermission("View")) {
      return setPermissionError("plan_permissions");
    }
    searchTask().then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.GET_TASKS, payload: data });
      }
    });
    leadsFilter({ data: state.dataForm }).then((results) => {
      if (results.statusCode == 200) {
        dispatch({
          type: actions.GET_LEADS,
          payload: results.data.leads,
          pages: results.data.pages,
        });
      }
    });
  }, []);
  const handleFilters = debounce(async (dataFilters) => {
    if (dataFilters.isFilterByPeriod) {
      switch (dataFilters.type) {
        case null:
          dataFilters.afterDue = null;
          dataFilters.beforeDue = null;
          break;
        case "dueToday":
          const before = new Date();
          const after = new Date();
          before.setDate(before.getDate() - 1);
          before.setHours(0, 0, 0, 0);
          after.setDate(after.getDate() + 1);
          after.setHours(0, 0, 0, 0);

          dataFilters.beforeDue = after.toString();
          dataFilters.afterDue = before.toString();
          break;
        case "overdue":
          dataFilters.beforeDue = Date.now();
          dataFilters.afterDue = null;
          break;
        case "upcoming":
          dataFilters.afterDue = Date.now();
          dataFilters.beforeDue = null;
          break;
      }
    }
    if (dataFilters.isFilterByStatus) {
      dataFilters.isCompleted = dataFilters.type;
    }
    const currentFilters = { ...filters, ...dataFilters };
    setFilters(dataFilters);
    setCurrentPage(1);
    const { data, statusCode } = await searchTask(currentFilters);
    if (statusCode == 200) {
      dispatch({ type: actions.GET_TASKS, payload: data });
    }
  }, 200);

  const onMemberSearch = debounce(async (search) => {
    const { data, statusCode } = await getMembers({ search });
    if (statusCode == 200) {
      setMembers(data.members);
    }
  });
  const handlePagination = (page) => {
    setCurrentPage(page);
    searchTask({ ...filters, page: page - 1 }).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.GET_TASKS, payload: data });
      }
    });
  };
  const handleCreateTask = async (dataForm) => {
    if (!memberPermission("Edit")) {
      setLeadToAttach(null);
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission("Edit")) {
      setLeadToAttach(null);
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    const { data, statusCode } = await createTask(dataForm);
    if (statusCode == 200) {
      setLeadToAttach(null);
      const { statusCode: tasksStatus, data: tasksData } = await searchTask();
      if (tasksStatus == 200) {
        dispatch({ type: actions.GET_TASKS, payload: tasksData });
      }
    } else {
      setLeadToAttach(null);
      toast.error(data.message);
    }
    return true;
  };
  if (!planPermission("View")) {
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
        </div>
        <ContentWrapperUi>
          {" "}
          <SideNav navToggle={props.redux.globalReducer.navToggle} />
          <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
            <Permissions.View
              title={"Title"}
              description={"Description"}
              img={"tasks-bg.png"}
            />
          </LeadsContentWrapper>
        </ContentWrapperUi>
      </LeadsProvider>
    );
  }
  if (!memberPermission("View")) {
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
        </div>
        <ContentWrapperUi>
          {" "}
          <SideNav navToggle={props.redux.globalReducer.navToggle} />
          <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
            <Permissions.View
              title={"Title"}
              description={"Description"}
              img={"tasks-bg.png"}
            />
          </LeadsContentWrapper>
        </ContentWrapperUi>
      </LeadsProvider>
    );
  }
  if (!props.redux.organization.organization)
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
        </div>
        <ContentWrapperUi>
          {" "}
          <SideNav navToggle={props.redux.globalReducer.navToggle} />
          <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
            <DataNotFound
              image={"/assets/images/img_empty_no_leads.png"}
              elements={
                <div
                  style={{ marginTop: "0" }}
                  className="join-organization-inner join-organization-page"
                >
                  <h4>
                    <FormattedMessage
                      id={"organization.youHavetoCreateAnOrganization"}
                    />
                  </h4>
                  <p className="desc">
                    <FormattedMessage
                      id={"organization.youHavetoCreateAnOrganization.text"}
                    />
                  </p>
                  <button className={"btn1"}>
                    <NavLink
                      to={"/workspace"}
                      style={{ color: "white" }}
                      data-tracking-id={"leads.createOrganization"}
                    >
                      <FormattedMessage
                        id={"organization.createOrganization"}
                      />
                    </NavLink>
                  </button>
                </div>
              }
            />
          </LeadsContentWrapper>
        </ContentWrapperUi>
      </LeadsProvider>
    );
  if (!state.tasks && !permissionError) return <PreLoader />;
  if (permissionError) {
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            currentPage={<FormattedMessage id={"sidenav.leads"} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />
        </div>
        <ContentWrapperUi>
          {" "}
          <SideNav navToggle={props.redux.globalReducer.navToggle} />
          <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
            <DataNotFound
              image={"/assets/images/img_empty_no_leads.png"}
              elements={
                <div
                  style={{ marginTop: "0" }}
                  className="join-organization-inner join-organization-page"
                >
                  <h4>
                    <FormattedMessage id={"permissions.denied"} />{" "}
                  </h4>
                  {!permissionError === "plan_permissions" ? (
                    <p className="desc">
                      <FormattedMessage id={"permissions.upgradePlan"} />
                    </p>
                  ) : (
                    <p className="desc">
                      <FormattedMessage id={"permissions.noAccess"} />
                    </p>
                  )}
                </div>
              }
            />
          </LeadsContentWrapper>
        </ContentWrapperUi>
      </LeadsProvider>
    );
  }

  return (
    <LeadsProvider store={store}>
      <div className="main-wraper">
        <Header
          currentPage={<FormattedMessage id={"sidenav.tasks"} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          organization={props.redux.organization}
        />
      </div>
      <ContentWrapperUi>
        <SideNav navToggle={props.redux.globalReducer.navToggle} />
        <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
          <div className="table-wrap" style={{ marginBottom: 0 }}>
            <div className="head_block" style={{ flexDirection: "column" }}>
              <div
                className="left_block"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="title-box">
                      <p>
                        {state.tasks.tasksNumber}{" "}
                        <FormattedMessage id={"leads.tasks"} />{" "}
                      </p>
                    </div>
                    <div
                      className="search-ui mr-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="search"
                        name="search"
                        placeholder={intl.formatMessage({
                          id: "leads.tasks.searchTasks",
                        })}
                        onChange={(e) =>
                          handleFilters({ search: e.target.value })
                        }
                        defaultValue={filters.search}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {state.tasks.pages > 0 && (
                    <div
                      style={{
                        display: "flex",
                        marginRight: 30,
                      }}
                    >
                      <label
                        style={{
                          margin: "12px 0px 0px 12px",
                          color: "#595F79",
                        }}
                      >
                        <FormattedMessage id={"page"} /> :
                      </label>
                      <div style={{ marginTop: 5 }}>
                        <PaginationInput
                          pages={state.tasks.pages}
                          currentPage={currentPage}
                          onChange={handlePagination}
                        />
                      </div>
                      <label
                        style={{
                          margin: "12px 12px 0px 0px",
                          color: "#595F79",
                        }}
                      >
                        <FormattedMessage
                          id={"ofPages"}
                          values={{ value: state.tasks.pages }}
                        />{" "}
                      </label>
                    </div>
                  )}
                  <div
                    className="cstm-dropdown  "
                    style={{ width: "max-content" }}
                  >
                    <div className="dropdown" style={{ marginRight: 15 }}>
                      <button
                        className="btn btn-primary dropdown-toggle"
                        type="button"
                        style={{ width: "100%" }}
                        onClick={() => setFiltersToggle(!filtersToggle)}
                      >
                        <FormattedMessage id={"filters"} />
                      </button>
                    </div>
                  </div>
                  {!planPermission("Edit") || !memberPermission("Edit") ? (
                    <Permissions.CallToAction
                      isButton={true}
                      isPlan={!planPermission("Eddit")}
                    >
                      <CreateTask onCreateTask={setLeadToAttach} />
                    </Permissions.CallToAction>
                  ) : (
                    <CreateTask onCreateTask={setLeadToAttach} />
                  )}

                  {!!leadToAttach && (
                    <AddTaskModal
                      lead={leadToAttach}
                      show={!!leadToAttach}
                      setToggle={() => setLeadToAttach(false)}
                      organization={props.redux.organization}
                      onCreate={handleCreateTask}
                    />
                  )}
                </div>
              </div>
              {filtersToggle && (
                <div
                  className="right_block global-fade-in"
                  style={{
                    alignItems: "center",
                    width: "100%",
                    marginTop: 20,
                    display: "flex-end",
                  }}
                >
                  <div className="sorting-block">
                    <label>
                      <FormattedMessage id={"leads.tasks.assignedTo"} />
                    </label>
                    <div className="cstm-select date-sorting">
                      <AssigneeSelect
                        members={members}
                        searchMember={onMemberSearch}
                        labelsStyle={{ width: "100%" }}
                        handleSelectLabel={(member) =>
                          handleFilters({ assigneeId: member._id })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="sorting-block"
                    style={{
                      marginLeft: 10,
                      paddingLeft: 10,
                      borderLeft: "1px solid #ccc",
                    }}
                  >
                    <label>
                      <FormattedMessage id={"leads.tasks.filterByPeriod"} />
                    </label>
                    <div className="cstm-select date-sorting">
                      <SelectPicker
                        doNotCapitalize
                        values={[
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.allTasks",
                            }),
                            id: null,
                          },
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.dueToday",
                            }),
                            id: "dueToday",
                          },
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.overdue",
                            }),
                            id: "overdue",
                          },
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.upcoming",
                            }),
                            id: "upcoming",
                          },
                        ]}
                        onChange={(id) =>
                          handleFilters({ isFilterByPeriod: true, type: id })
                        }
                        //type={"sortBy"}
                        withId={true}
                        labelsStyle={{ width: "max-content" }}
                      />
                    </div>
                  </div>
                  <div
                    className="sorting-block"
                    style={{
                      marginLeft: 10,
                      paddingLeft: 10,
                      borderLeft: "1px solid #ccc",
                    }}
                  >
                    <label>
                      <FormattedMessage id={"leads.tasks.filterByStatus"} />
                    </label>
                    <div className="cstm-select date-sorting">
                      <SelectPicker
                        doNotCapitalize
                        values={[
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.allTasks",
                            }),
                            id: null,
                          },
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.completed",
                            }),
                            id: true,
                          },
                          {
                            name: intl.formatMessage({
                              id: "leads.tasks.notCompleted",
                            }),
                            id: false,
                          },
                        ]}
                        onChange={(id) =>
                          handleFilters({ isFilterByStatus: true, type: id })
                        }
                        //type={"sortBy"}
                        withId={true}
                        labelsStyle={{ width: "max-content" }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {state.tasks.tasks.length > 0 ? (
              <TasksList
                tasks={state.tasks.tasks}
                organization={props.redux.organization}
                memberPermission={memberPermission("Edit")}
                planPermission={planPermission("Edit")}
              />
            ) : (
              <DataNotFound />
            )}
          </div>

          <ToastContainer />
        </LeadsContentWrapper>
      </ContentWrapperUi>
    </LeadsProvider>
  );
}

const AssigneeSelect = ({
  members,
  searchMember,
  labelsStyle,
  handleSelectLabel,
  selectedMember,
  zIndex,
}) => {
  const [selectToggle, setSelectToggle] = useState(false);
  const [memberName, setMemberName] = useState("");
  const intl = useIntl();
  const onMemberSearch = (value) => {
    searchMember(value);
    setMemberName(value);
  };
  return (
    <div className={"custom-member-select"}>
      <div
        className={"custom-select-picker"}
        style={{
          ...(zIndex ? { zIndex } : {}),
        }}
      >
        <div
          className={"dropdown-toggle selected-label"}
          onClick={() => setSelectToggle(true)}
        >
          <input
            type="text"
            placeholder={
              intl.formatMessage({ id: "organization.member" }) + "..."
            }
            onChange={(e) => onMemberSearch(e.target.value)}
            value={memberName}
          />
          {/* {selectedMember.firstName + " " + selectedMember.firstName}*/}
        </div>
        <Animation.SlideDown
          className={"labels"}
          toggle={selectToggle}
          setToggle={setSelectToggle}
          style={labelsStyle}
        >
          <ul>
            <li
              onClick={() => {
                handleSelectLabel({});
                setSelectToggle(false);
                setMemberName("");
              }}
            >
              <span className={"custom_tag_name"}>all</span>
              <FormattedMessage id={"organization.allMembers"} />
              <hr />
            </li>
            {members.length > 0 ? (
              members.map((member, i) => (
                <li
                  className={""}
                  key={i}
                  style={
                    member === selectedMember
                      ? { background: "#3B53D1", color: "#fff" }
                      : {}
                  }
                  onClick={() => {
                    setMemberName(member.firstName + " " + member.lastName);
                    handleSelectLabel(member);
                    setSelectToggle(false);
                  }}
                >
                  {member.leavedOrg ? (
                    <span>
                      <span
                        className={"custom_tag_name"}
                        style={{ background: "#D3D2D2", color: "#4F4D4D" }}
                      >
                        {member.firstName ? member.firstName[0] : " "}
                      </span>
                      {"  "}
                      <span>{member.firstName}</span>
                    </span>
                  ) : (
                    <span>
                      <span className={"custom_tag_name"}>
                        {(member.firstName ? member.firstName[0] : " ") +
                          (member.lastName ? member.lastName[0] : " ")}
                      </span>
                      {"  "}
                      <span className={"capital"}>{member.firstName}</span>{" "}
                      <span className={"capital"}>{member.lastName}</span>
                    </span>
                  )}
                </li>
              ))
            ) : (
              <li style={{ textAlign: "center" }}>
                <FormattedMessage id={"workflow.noResultsFound"} />
              </li>
            )}
          </ul>
        </Animation.SlideDown>
      </div>
    </div>
  );
};
const CreateTask = ({ onCreateTask }) => {
  const [createTaskToggle, setCreateTaskToggle] = useState(false);
  const { state } = useContext(LeadsContext);
  return (
    <div
      className="cstm-dropdown  user_filter"
      style={{ width: "max-content" }}
    >
      <div className="dropdown" style={{ marginRight: 15 }}>
        <div onClick={() => setCreateTaskToggle(true)}>
          <a
            href="#"
            className="btn1 addmember-btn"
            style={{ width: "max-content" }}
          >
            <span className="icon">
              <img src="assets/images/ic-createtask.svg" alt="" />
            </span>
            <FormattedMessage id={"leads.createTask"} />
          </a>
        </div>
        {createTaskToggle && (
          <SelectWithSearch
            toggle={createTaskToggle}
            setToggle={setCreateTaskToggle}
            onChange={(lead) => {
              onCreateTask(lead);
              setCreateTaskToggle(false);
            }}
            value={""}
            members={state.leads}
          />
        )}
      </div>
    </div>
  );
};

function SelectWithSearch({
  setToggle,
  toggle,
  onChange,
  value,
  members,
  error,
}) {
  const { state } = useContext(LeadsContext);
  const [currentMembers, setCurrentMembers] = useState(members);
  const intl = useIntl();
  const handleSearchMember = debounce(async (search) => {
    const { data, statusCode } = await leadsFilter({
      data: { ...state.dataForm, search },
    });
    if (statusCode == 200) {
      setCurrentMembers(data.leads);
    }
  });
  return (
    <Animation.SlideDown
      className={"dropdown-menu show "}
      toggle={toggle}
      setToggle={setToggle}
      style={{ minWidth: "400px", left: -256 }}
    >
      <div style={{ padding: "0 20px" }}>
        <div style={{ marginBottom: 15, fontSize: 20 }}>
          <p>
            <FormattedMessage id={"leads.tasks.attachThisTaskToLead"} />
          </p>
        </div>
        <div className="cstm-dropdown  " style={{ width: "100%" }}>
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({ id: "leads.tasks.searchLEad" })}
            onChange={(e) => handleSearchMember(e.target.value)}
            style={{
              boxShadow: "none",
            }}
          />
        </div>
        <ul>
          {currentMembers && currentMembers.length > 0 ? (
            currentMembers.map((member, i) => (
              <li
                key={i}
                onClick={() => onChange(member)}
                style={{ marginTop: 15, cursor: "pointer", display: "flex" }}
              >
                {" "}
                <span
                  className="name_tag light_red"
                  style={{ textTransform: "uppercase" }}
                >
                  {(member.firstName ? member.firstName[0] : "") +
                    (member.lastName ? member.lastName[0] : "")}
                </span>
                <span>
                  {" "}
                  <span>{member.firstName + " " + member.lastName}</span>
                  <small style={{ display: "block", color: "#999" }}>
                    {" "}
                    {member.currentProEmail || member.currentPersonalEmail}{" "}
                  </small>
                </span>{" "}
                <div style={{}}> </div>
              </li>
            ))
          ) : (
            <li style={{ textAlign: "center", marginTop: 20 }}>
              <a href="#">
                <span className="name_ui">
                  <FormattedMessage id={"workflow.noResultsFound"} />
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </Animation.SlideDown>
  );
}
export default Tasks;
