import React, { useRef, useState } from "react";
import { changePassword } from "../../../../../services/api/auth.api";
import { Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Field } from "../../../../Auth/common/Field/Field";

function NewPasswordModal(props) {
  const [error, setError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const passwordRef = useRef(null);

  const handleOnSubmit = async (e) => {
    setError("");
    e.preventDefault();
    if (!passwordRef.current.checkValidity()) {
      return;
    }
    if (newPassword !== confirmPassword) {
      setError(
        props.intl.formatMessage({ id: "auth.pleaseConfirmYourNewPassword" })
      );
      return;
    }
    let { data, statusCode } = await changePassword({
      newPassword,
      currentPassword,
    });
    if (statusCode === 200) {
      props.toggleShowPasswordModal();
    } else setError(data.messageTranslated || data.message);
  };
  return (
    <Modal
      show={props.showPasswordModal}
      onHide={props.toggleShowPasswordModal}
      className="fade add-member-wrapper  addmember-modal"
    >
      <Modal.Header className="modal-header border-0" closeButton>
        <h5 className="heading mb-8">
          {" "}
          <FormattedMessage id={"info.newPassword"} />
        </h5>
      </Modal.Header>
      <form onSubmit={handleOnSubmit} className="form-inner-content">
        <div className="modal-body p-10">
          <div className="form-blocks">
            <Field
              label={<FormattedMessage id={"info.currentPassword"} />}
              onChange={setCurrentPassword}
              type={"password"}
              hideCheck
              hideCheckIcon
            />
            <Field
              label={<FormattedMessage id={"info.newPassword"} />}
              onChange={setNewPassword}
              type={"password"}
              passwordRef={passwordRef}
            />
            {/*  <Field
              label={"Confirm Password"}
              onChange={setNewPassword}
              type={"password"}
            />*/}
            <div
              className={
                "floating-effect  form-group " +
                (confirmPassword && newPassword === confirmPassword
                  ? "success"
                  : "")
              }
            >
              <input
                type={"password"}
                placeholder={" "}
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                required
              />
              <label>
                <FormattedMessage id={"info.confirmPassword"} />
              </label>
              {passwordRef &&
                passwordRef.current &&
                passwordRef.current.checkValidity() &&
                newPassword === confirmPassword && (
                  <span className="checked-icon">
                    <img src="/assets/images/ic-checked.svg" alt="" />
                  </span>
                )}
            </div>
          </div>
        </div>
      </form>
      {error && (
        <div
          className="text-center  error  kaspr-form-text-error"
          style={{ top: 0, width: "90%" }}
        >
          {error + " "}
        </div>
      )}
      <div className="modal-footer border-0">
        <a
          href="#"
          className="btn1 btn48"
          onClick={handleOnSubmit}
          type={"submit"}
        >
          <FormattedMessage id={"info.changePassword"} />
        </a>
      </div>
    </Modal>
  );
}

export default injectIntl(NewPasswordModal);
