import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../Store/auth.store";
import "./Signup.css";
import { Field, PhoneField } from "../common/Field/Field";
import CheckBox from "../common/CheckBox/CheckBox";
import actions from "../Store/auth.actions";
import { signup } from "../../../services/api/auth.api";
import { NavLink, useParams } from "react-router-dom";
import VerticalSlider from "../common/VerticalSlider/VerticalSlider";
import { saveAsCookie } from "../../../services/utils";
import { BASE_URL, IS_PROD } from "../../../constants";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

import cookie from "react-cookies";
import queryString from "query-string";
import { getSbUtm } from "../../../services/utils/tools";
import { SelectPicker } from "../../../components";

function isValidPhoneNumber() {
  return true;
}

function SignupForm() {
  return (
    <section
      className="signup-wrapper half-white-bg"
      style={{ paddingTop: 80 }}
    >
      <div className="container">
        <div className="row">
          <SignupLeft />
          <SignupRight />
        </div>
      </div>
    </section>
  );
}

function SignupRight({}) {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [inputValidationId, setInputValidation] = useState(null);
  const [loading, setLoading] = useState(null);
  const [utms, setUtms] = useState(null);
  const { email: encodedEmail } = useParams();
  const intl = useIntl();

  useEffect(() => {
    let email = "";
    try {
      email = atob(encodedEmail);
      let url = cookie.load("__gtm_campaign_url", {
        path: "/",
        domain: BASE_URL,
      });
      if (url) {
        url = url.split("?");
        url = url[1] && url[1].split("&");
        let properties = url.map((property) => {
          property = property.split("=");
          let utmProperty = { name: property[0], value: property[1] };
          return utmProperty;
        });
        setUtms(properties);
      }
    } catch (err) {}
    dispatch({ type: actions.SIGNUP, payload: { email } });
  }, []);
  const passwordRef = useRef(null);
  // const [isValidPassword, setPasswordValidation] = useState(false);
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setInputValidation("");
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    let email = document.getElementById("email");
    let firstName = document.getElementById("firstName");
    let lastName = document.getElementById("lastName");
    let phone = document.getElementById("phone");
    let password = document.getElementById("password");
    let terms = document.getElementById("terms");
    if (email.value.length == 0) return setInputValidation(email.id);
    if (firstName.value.length == 0) return setInputValidation(firstName.id);
    if (lastName.value.length == 0) return setInputValidation(lastName.id);
    if (phone.value.length == 0 || !isValidPhoneNumber(phone.value)) {
      return setInputValidation(phone.id);
    }
    if (!passwordRegex.test(password.value))
      return setInputValidation(password.id);
    if (!terms.checked) return setInputValidation(terms.id);
    try {
      setLoading(true);

      let signupForm = state.signupForm;
      let partnerStackCookieValue = cookie.load("ps_partner_key", {
        path: "/",
        domain: BASE_URL,
      });
      let partnerStackCookieXid = cookie.load("ps_xid", {
        path: "/",
        domain: BASE_URL,
      });
      const utm = getSbUtm();
      if (utm) {
        signupForm.utm = utm;
      }

      if (partnerStackCookieValue) {
        signupForm.partner_key = partnerStackCookieValue;
      }
      if (partnerStackCookieXid) {
        signupForm.xid = partnerStackCookieXid;
      }
      const { code, rewardUnlimitedB2bWeek } = queryString.parse(
        window.location.search
      );
      if (code) {
        signupForm.code = code;
      }
      if (rewardUnlimitedB2bWeek) {
        signupForm.rewardUnlimitedB2bWeek = true;
      }
      const { statusCode, data } = await signup(signupForm);
      if (statusCode === 200) {
        if (IS_PROD) {
          window.googleTagManager("pre-registration");
        }
        saveAsCookie(data.jwt);
        window.location = "/emailvalidation";
      } else {
        setError(data.messageTranslated || data.message);
      }
      setLoading(false);
    } catch (err) {}
  };
  return (
    <div className="col-md-6 signup-right">
      <form
        onSubmit={(e) => (loading ? false : handleOnSubmit(e))}
        className="form-inner-content"
      >
        <div className="form-heading-grp text-center">
          <h4 className="h4">
            <FormattedMessage id={"auth.signUptoGetStarted"} />
          </h4>
        </div>
        <div className="form-blocks">
          <Field
            label={intl.formatMessage({ id: "info.emailAddress" })}
            onChange={(email) =>
              dispatch({ type: actions.SIGNUP, payload: { email } })
            }
            value={state.signupForm.email}
            type={"email"}
            id={"email"}
            inputValidationId={inputValidationId}
          />
          <div style={{ display: "flex" }}>
            <Field
              className="text-capitalize"
              label={intl.formatMessage({ id: "info.firstName" })}
              onChange={(firstName) =>
                dispatch({ type: actions.SIGNUP, payload: { firstName } })
              }
              value={state.signupForm.firstName}
              id={"firstName"}
              inputValidationId={inputValidationId}
              setInputValidation={setInputValidation}
            />
            <Field
              className="text-capitalize"
              label={intl.formatMessage({ id: "info.lastName" })}
              onChange={(lastName) =>
                dispatch({ type: actions.SIGNUP, payload: { lastName } })
              }
              value={state.signupForm.lastName}
              id={"lastName"}
              inputValidationId={inputValidationId}
              setInputValidation={setInputValidation}
            />
          </div>
          <PhoneField
            onChange={(countryCode, phoneNumber, isValidPhone) => {
              dispatch({
                type: actions.SIGNUP,
                payload: { phone: { phoneNumber, countryCode } },
              });
            }}
            propsValue={
              state.signupForm.phone.countryCode +
              state.signupForm.phone.phoneNumber
            }
            id={"phone"}
            inputValidationId={inputValidationId}
            setInputValidation={setInputValidation}
          />
          <Field
            label={intl.formatMessage({ id: "info.password" })}
            onChange={(password) => {
              dispatch({ type: actions.SIGNUP, payload: { password } });
            }}
            type={"password"}
            passwordRef={passwordRef}
            id={"password"}
            inputValidationId={inputValidationId}
            setInputValidation={setInputValidation}
          />
          <div className={"floating-effect  form-group "}>
            {/*  <input
              onChange={(e) => {
                dispatch({
                  type: actions.SIGNUP,
                  payload: { primaryPropose: e.target.value },
                });
              }}
              id={"primaryPropose"}
              placeholder={""}
            />*/}
            {/* <div className={"select-purpose"}>
            </div>*/}

            <select
              className={"select-purpose"}
              name={"test"}
              onChange={(e) => {
                dispatch({
                  type: actions.SIGNUP,
                  payload: { primaryPropose: e.target.value },
                });
              }}
            >
              <option value={""}>
                -
                {intl.formatMessage({
                  id: "auth.primaryPurpose",
                })}
                -
              </option>

              <option value={"Acquire new customers"}>
                {intl.formatMessage({
                  id: "auth.acquireNewCustomers",
                })}
              </option>
              <option value={"Improve my CRM data"}>
                {intl.formatMessage({
                  id: "auth.improveMyCrmData",
                })}
              </option>
              <option value={"Increase my team’s lead generation"}>
                {intl.formatMessage({
                  id: "auth.increaseMyTeamsLeadGeneration",
                })}
              </option>
              <option value={"Client research"}>
                {intl.formatMessage({
                  id: "auth.clientResearch",
                })}
              </option>
              <option value={"Discover key decision-maker"}>
                {" "}
                {intl.formatMessage({
                  id: "auth.discoverKeyDecisionMakers",
                })}
              </option>
              <option value={"Other"}>
                {" "}
                {intl.formatMessage({
                  id: "auth.other",
                })}
              </option>
            </select>
          </div>

          <div className="checkbox-listing" style={{ marginBottom: 20 }}>
            <CheckBox
              element={
                <span className="checkbox-text">
                  <FormattedMessage id={"auth.iAgreeTo_"} />{" "}
                  <a target="_blank" href="https://kaspr.io/terms/">
                    <FormattedMessage id={"auth.termsOfService"} />
                  </a>{" "}
                  <FormattedMessage id={"auth.and"} />{" "}
                  <a target="_blank" href="https://kaspr.io/privacy-policy/">
                    <FormattedMessage id={"auth.privacyPolicy"} />
                  </a>
                </span>
              }
              onChange={(acceptedTerms) =>
                dispatch({ type: actions.SIGNUP, payload: { acceptedTerms } })
              }
              id={"terms"}
              inputValidationId={inputValidationId}
              required
            />
            <CheckBox
              element={
                <span className="checkbox-text">
                  <FormattedMessage id={"auth.usefulTipsAndSpecial"} />
                </span>
              }
              onChange={(receiveNewsletter) =>
                dispatch({
                  type: actions.SIGNUP,
                  payload: { receiveNewsletter: !receiveNewsletter },
                })
              }
            />
          </div>
          {error && (
            <div
              className="btn-grp text-center floating-effect kaspr-form-text-error"
              style={{ width: "100%" }}
            >
              {error + " "}
            </div>
          )}
          <div className="btn-grp text-center">
            <button className="btn btn-blue btn-block h-58" type={"submit"}>
              {loading ? (
                <div>
                  <img
                    src="/assets/images/loading.gif"
                    alt=""
                    style={{ width: 30 }}
                  />{" "}
                </div>
              ) : (
                <FormattedMessage id={"auth.createAccount"} />
              )}
            </button>
          </div>
          <div className="other-info text-center">
            <p className="info-btn">
              <FormattedMessage id={"auth.haveAnAccount"} />{" "}
              <NavLink exact to="/signin" className="text-btn">
                <FormattedMessage id={"auth.logIn"} />
              </NavLink>
            </p>
          </div>
        </div>
        {utms &&
          utms.length > 0 &&
          utms.map((utm, i) =>
            utm.name && utm.value ? (
              <input key={i} type="hidden" name={utm.name} value={utm.value} />
            ) : null
          )}
      </form>
    </div>
  );
}

SignupRight = injectIntl(SignupRight);

function SignupLeft() {
  return (
    <div className="col-md-6 signup-left" style={{ paddingTop: 100 }}>
      <div className="desc-block">
        <h4 className="title">
          <FormattedMessage
            id={"auth.allInOne"}
            values={{ span: (chunk) => <span> {chunk} </span> }}
          />
        </h4>
        <p>
          <FormattedMessage id={"auth.kasprIsTool"} />
        </p>
      </div>
      <div className="steps-block">
        <div className="d-flex">
          {/*fade-in-txt one*/}
          <p>
            <FormattedMessage id={"auth.freeCreditsInFreeAccount"} />
          </p>
        </div>
        <div className="d-flex">
          {/*fade-in-txt two*/}
          <p>
            {" "}
            <FormattedMessage id={"auth.industryLeading"} />
          </p>
        </div>
        <div className="d-flex">
          {/*fade-in-txt three*/}
          <p>
            {" "}
            <FormattedMessage id={"auth.nativeIntegrationsToPopularCrms"} />
          </p>
        </div>
      </div>

      <VerticalSlider />
      <div className="side-footer">
        <ul className="footer-list">
          <li>©2023 Kaspr</li>
          <li>·</li>
          <li>
            <p>
              <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                <FormattedMessage id={"auth.privacy"} />
              </a>{" "}
              &amp;{" "}
              <a target="_blank" href="https://kaspr.io/terms/">
                <FormattedMessage id={"auth.terms"} />
              </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SignupForm;
