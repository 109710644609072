import React, { useEffect, useRef, useState } from "react";
import { DASHBOARD_URL } from "../../../../../constants";
import { FormattedMessage, useIntl } from "react-intl";

const kasprPropertiesValuesExamples = {
  firstName: "Caroline",
  lastName: "Smith",
  name: "Caroline Smith",
  email: "caroline.smith@amazon.com",
  currentProEmail: "caroline.smith@amazon.com",
  currentPersonalEmail: "caroline.smith@gmail.com",
  emails: "[caroline.smith@amazon.com,caroline.smith@gmail.com]",
  phone: "+33666666666",
  phones: "[+33666666666,+11666666666]",
  company: "Amazon",
  location: "USA",
  job: "COO",
  description: "Hi, this is Caroline",
  comment: "Note",
  linkedin: "caroline-smith-linkedin-id",
  source: "Kaspr",
  companyInfo_specialities: "[E-commerce, Retail]",
  companyInfo_industries: "[Computer Software]",
  companyInfo_addresses: "[New-York, US]",
  companyInfo_foundedOn: "1994",
  companyInfo_followerCount: "65606",
  companyInfo_industryName: "Computer Software",
  companyInfo_description: "Description",
  companyInfo_type: "Privately Held",
  companyInfo_staffCount: "3540",
  companyInfo_rankForTopCompanies: "2",
  companyInfo_universalName: "Amazon",
  companyInfo_companyPageUrl: "Amazon website url",
  companyInfo_name: "Amazon",
  companyInfo_staffInterval: "1000-10000",
  companyInfo_companyId: "amazon-linkedin-id",
};
export default function ListDrop({
  type,
  title,
  defaultLabel,
  properties,
  leadsLabels,
  onSelectItem,
  value,
  style,
  dropDownStyle,
  automationTypes,
  formInStyle,
  disableSearch,
}) {
  const [toggle, setToggle] = useState(false);
  const intl = useIntl();
  const ref = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setToggle(false);
    }
  };

  const onDropDown = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const handleChooseItem = (item) => {
    onSelectItem(item);
    setTimeout(() => {
      setToggle(false);
    }, 10);
  };

  const Item = () => {
    return (
      <div>
        {properties.map((property, i) => (
          <div
            className="cdi-in"
            onMouseDown={() => handleChooseItem(property)}
          >
            <input type="radio" name="Workflow" style={{ maxWidth: "90%" }} />

            <p>
              {type === "kaspr" && property.indexOf("customField-") === -1 ? (
                <FormattedMessage id={"leads.properties." + property} />
              ) : (
                property.replace("customField-", "")
              )}
            </p>
            <span>
              <span>{kasprPropertiesValuesExamples[property] || ""}</span>
              <b>
                <small
                  className={"wk-badge"}
                  style={
                    type === "kaspr" && property.indexOf("customField-") === -1
                      ? {}
                      : { color: "#e23381", background: "#ffecf5" }
                  }
                >
                  {property.indexOf("customField-") > -1
                    ? "Custom"
                    : type[0].toUpperCase() + type.slice(1)}
                </small>
              </b>
            </span>
          </div>
        ))}{" "}
        {type === "kaspr" && (
          <a
            className={"btn-gray"}
            style={{ width: "120%", marginTop: 5 }}
            onMouseDown={() => {
              onSelectItem("");
              disableSearch();
            }}
          >
            {" "}
            {"Insert Static Value"}{" "}
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="form-in" ref={ref} style={formInStyle || {}}>
      <div className={"custom-dropdown workflow " + (toggle ? "show" : "")}>
        <div
          className="custom-dropdown-in drop-down-style "
          style={dropDownStyle || {}}
        >
          <Item />
        </div>
      </div>
    </div>
  );
}
