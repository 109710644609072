import React from "react";
import { getCurrency } from "../../services/utils/tools";

const CurrencyDisplay = ({ user, organizationCredits, amount }) => {
  const currency = getCurrency(
    user,
    organizationCredits && organizationCredits.customer
  );

  return (
    <>
      {currency !== "€" && currency}
      {amount}
      {currency === "€" && currency}
    </>
  );
};

export default CurrencyDisplay;
