import React, { useState } from "react";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import FinalConfirmationModal from "./FinalConfirmationModal/FinalConfirmationModal";
import SubscriptionCancelledModal from "./SubscriptionCancelledModal/SubscriptionCancelledModal";
import LetUsHelpModal from "./LetUsHelpModal/LetUsHelpModal";
import ChatWithExpertModal from "./ChatWithExpertModal/ChatWithExpertModal";
import { options } from "../../constants";
import { subscribe } from "../../../../services/api/credits.api";

function UnsubscribeModals({ subscription, toggle, setError, error }) {
  const [modalPath, setModalPath] = useState(["feedback-modal"]);
  const [step, setStep] = useState(modalPath[0]);
  const [optionSelected, setOptionSelected] = useState("");

  const handleNextModal = (nextModal) => {
    setModalPath((modalPath) => {
      const newModalPath = [...modalPath, nextModal];
      setStep(newModalPath[newModalPath.length - 1]);
      return newModalPath;
    });
  };

  const handlePreviousModal = () => {
    setModalPath((prevModalPath) => {
      const newModalPath = prevModalPath.slice(0, -1);
      setStep(newModalPath[newModalPath.length - 1]);
      return newModalPath;
    });
  };

  const handleCloseModal = () => {
    toggle(false);
    setError(null);
    setStep(modalPath[0]);
    setModalPath(modalPath[0]);
  };

  const handleUnsubscribe = async (reason) => {
    const { data, statusCode } = await subscribe({
      planId: "0",
      immediate: false,
      reason,
      deleteCoupon: true,
    });
    if (statusCode === 200) {
      setStep("subscription-cancelled-modal");
    } else {
      setError(data.messageTranslated || data.message);
    }
  };

  return (
    <>
      {step === "feedback-modal" && (
        <FeedbackModal
          toggle={toggle}
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          handleNextModal={handleNextModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {step === "final-confirmation-modal" && (
        <FinalConfirmationModal
          subscription={subscription}
          toggle={toggle}
          optionSelected={optionSelected}
          handleUnsubscribe={handleUnsubscribe}
          handlePreviousModal={handlePreviousModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {step === "subscription-cancelled-modal" && (
        <SubscriptionCancelledModal
          toggle={toggle}
          handleCloseModal={handleCloseModal}
        />
      )}
      {step === "lets-us-help-modal" && (
        <LetUsHelpModal
          toggle={toggle}
          setError={setError}
          handlePreviousModal={handlePreviousModal}
          handleNextModal={handleNextModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {step === "chat-with-expert-modal" && (
        <ChatWithExpertModal
          toggle={toggle}
          handlePreviousModal={handlePreviousModal}
          handleNextModal={handleNextModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
}

export default UnsubscribeModals;
