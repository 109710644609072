import React, { useState, useContext, useEffect, useRef } from "react";
import "./Dropdown.css";
import { BulkEnrichmentContext } from "../../store/bulkEnrichment.store";
import actions from "../../store/bulkEnrichment.actions";
import { FormattedMessage } from "react-intl";

function Dropdown({
  options,
  defaultName,
  columnType,
  actionName,
  hasError,
  setHasError,
  mandatory,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { state, dispatch } = useContext(BulkEnrichmentContext);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClick = (header) => {
    selectOption(header);
    if (setHasError) setHasError(false);
  };
  const selectOption = (option) => {
    setSelectedOption(option);
    dispatch({
      type: actions[actionName],
      payload: option,
    });
    setIsOpen(false);
  };

  const selectedValue = state[columnType]
    ? state[columnType]
    : selectedOption || defaultName;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      id="dropdown-ctn"
      className={`dropdown-container ${hasError ? "error" : ""}`}
      ref={dropdownRef}
    >
      <div
        className={`${isOpen ? "button is-open" : "button"}`}
        onClick={toggleDropdown}
      >
        <p className="ellipsis">{selectedValue}</p>
        <img
          height={"auto"}
          src={"/assets/images/ic-arrow-bottom.png"}
          alt={"arrow down"}
        />
      </div>
      {isOpen && (
        <ul>
          {!mandatory && (
            <li key={"default value"} onClick={() => handleClick("")}>
              - <FormattedMessage id="bulkEnrichment.settings.selectColumn" /> -
            </li>
          )}
          {options.map((header, index) => (
            <li
              key={index}
              onClick={() => handleClick(header)}
              className="ellipsis"
              title={header}
            >
              {header}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
