import actions from "../actions";
const initState = null;

export const permissions = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_PERMISSIONS:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actions.PLAN_PERMISSIONS:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actions.MEMBER_PERMISSIONS:
      state = {
        ...state,
        ...action.payload,
      };
      break;
  }
  return state;
};
