import React, { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "../../../../../components";
import { LeadsContext, actions } from "../../../store/leads.store";
import { OverlayTrigger } from "react-bootstrap";
import companyInfoData from "../../../LeadsInfo/Common/CompanyInfos/data";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import "./LeadsItem.css";
import { imageExists } from "../../../../../services/utils/tools";
import { CREDIT_COLORS } from "../../../../../constants";
function LeadsItem({
  lead,
  properties,
  onLeadSelect,
  handleTemplateToggle,
  onDataDiscover,
  isVersion2,
  organization,
  hidePersonalEmails,
}) {
  const { dispatch, state } = useContext(LeadsContext);
  const checkbox_ref = useRef(null);
  const [picture, setPicture] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    const domain =
      lead.companyInfo &&
      lead.companyInfo.domains &&
      lead.companyInfo.domains[0];
    const url = "https://logo.clearbit.com/" + domain;
    imageExists(url).then((result) => {
      if (result) setPicture(url);
      else setPicture("/assets/images/ic_profile.png");
    });
  }, []);
  const toggleTemplate = (e) => {
    if (e) e.stopPropagation();
    handleTemplateToggle(lead);
  };
  const handleOnItemClick = (e, type) => {
    if (type === "view_details") return (window.location = "/lead/" + lead._id);
    else lead.isViewDetails = false;
    if (!lead.companyInfo) lead.companyInfo = companyInfoData;
    if (!checkbox_ref.current.contains(e.target)) {
      dispatch({ type: actions.SELECT_LEAD, payload: lead });
    }
  };
  const findProperty = (leadProperty) => {
    let prop = Object.assign({}, lead);
    if (!prop.tags) {
      prop.tags = [];
    }
    try {
      const splittedProperty = leadProperty.split(".");
      splittedProperty.forEach((p) => {
        prop = prop[p];
      });
      return prop;
    } catch (err) {}
  };
  return (
    <tr>
      <td className={"checkbox-container"}>
        <div className="action-btns" style={{ transform: "translateX(100px)" }}>
          <OverlayTrigger
            placement={"top"}
            key={"quickview"}
            overlay={(props) =>
              Tooltip({
                ...props,
                data: intl.formatMessage({ id: "leads.quickView" }),
                container_style: { padding: "0px 5px" },
              })
            }
            defaultShow={false}
          >
            <button
              className="act-btn view-btn"
              onClick={(e) => handleOnItemClick(e, "quick_View")}
              data-tracking-id={"leads.leadsList-quickView"}
            >
              <span data-toggle="tooltip" className="icon" />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"top"}
            key={"view_details"}
            overlay={(props) =>
              Tooltip({
                ...props,
                data: intl.formatMessage({ id: "leads.viewDetails" }),
                container_style: { padding: "0px 5px" },
              })
            }
            defaultShow={false}
          >
            <button
              className="act-btn list-btn"
              data-tracking-id={"leads.leadsList-viewLeadDetails"}
              onClick={(e) => handleOnItemClick(e, "view_details")}
            >
              <span data-toggle="tooltip" className="icon" />
            </button>
          </OverlayTrigger>
          {/* <OverlayTrigger
            placement={"top"}
            key={"sendemail"}
            overlay={(props) =>
              Tooltip({
                ...props,
                data: intl.formatMessage({ id: "leads.sendEmail" }),
                container_style: { padding: "0px 5px" },
              })
            }
            defaultShow={false}
          >
            <button
              className="act-btn email-btn"
              onClick={(e) => toggleTemplate(e, lead)}
              data-tracking-id={"leads.leadsList-sendEmail"}
            >
              <span className="icon" />
            </button>
          </OverlayTrigger>*/}
        </div>
        <div className="checkbox-wrap" ref={checkbox_ref}>
          <label>
            <input
              type="checkbox"
              defaultChecked={lead.isChecked}
              onChange={(e) => onLeadSelect(e.target.checked, lead)}
            />
            <span className="checkbox-icon"></span>
          </label>
        </div>
      </td>
      {/*<td data-toggle="modal" data-target="#userinfo_modal">
         <figure className="profile-ui" style={{background:"rgba(150,150,150,.2)"}}><img
          src={picture || 'assets/images/ic_profile.png'} alt=""/>
        </figure>
      </td>*/}
      {properties.map((property, i) => {
        if (property.property === "addedBy") {
          return (
            <td key={i}>
              <div>{lead[property.property].email}</div>
            </td>
          );
        }
        if (property.property === "name") {
          return (
            <td key={i}>
              <div className="username view-profile">
                <span className={"company-logo"}>
                  <img src={picture} alt="" />
                </span>
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnItemClick(e, "view_details");
                  }}
                  href={"http://linkedin.com/in/" + lead.linkedin}
                  target={"_blank"}
                  data-tracking-id={"leads.leadsList-likedinProfile"}
                  rel="noopener noreferrer nofollow"
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                >
                  {lead.firstName + " " + lead.lastName}
                </a>

                {lead.linkedin ? (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    href={"http://linkedin.com/in/" + lead.linkedin}
                    target={"_blank"}
                    data-tracking-id={"leads.leadsList-likedinProfile"}
                    rel="noopener noreferrer nofollow"
                    style={{ marginLeft: 10 }}
                  >
                    <img width={20} src="/assets/images/linkedin.png" alt="" />
                  </a>
                ) : (
                  <p>{lead.firstName + " " + lead.lastName}</p>
                )}
                {/*  {lead.job.length > 25 ? (
                  <OverlayTrigger
                    key={"job-" + lead._id}
                    placement={"top"}
                    overlay={(props) => Tooltip({ ...props, data: lead.job })}
                  >
                    <span>{lead.job.substring(0, 25) + "..."}</span>
                  </OverlayTrigger>
                ) : (
                  <span> {lead.job} </span>
                )}*/}
              </div>
            </td>
          );
        }
        if (
          property.property == "currentPersonalEmail" &&
          !findProperty(property.property)
        ) {
          return (
            <td key={i}>
              {!hidePersonalEmails ? (
                <ShowDataButton
                  lead={lead}
                  type={"personalEmailCredits"}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              ) : (
                <span></span>
              )}
            </td>
          );
        }
        if (
          property.property == "currentProEmail" &&
          !findProperty(property.property)
        ) {
          return (
            <td key={i}>
              <ShowDataButton
                lead={lead}
                type={"workEmailCredits"}
                onDataDiscover={onDataDiscover}
                isVersion2={isVersion2}
              />
            </td>
          );
        }
        if (
          typeof findProperty(property.property) === "string" ||
          typeof findProperty(property.property) === "number" ||
          findProperty(property.property) === null
        ) {
          if (
            property.property == "currentProEmail" ||
            property.property == "currentPersonalEmail"
          ) {
            return (
              <td key={i}>
                {findProperty(property.property + "s")[0]}
                {findProperty(property.property + "s").length > 1 && (
                  <OverlayTrigger
                    key={"list-" + lead._id}
                    placement={"top"}
                    overlay={(props) =>
                      Tooltip({
                        ...props,
                        data: findProperty(property.property + "s"),
                      })
                    }
                  >
                    <p className="contact-more tip">
                      {findProperty(property.property + "s").length - 1}
                    </p>
                  </OverlayTrigger>
                )}
              </td>
            );
          }

          return (
            <td key={i}>
              {findProperty(property.property) &&
              findProperty(property.property).length > 25 ? (
                <OverlayTrigger
                  key={"item-" + lead._id}
                  placement={"top"}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data: findProperty(property.property),
                    })
                  }
                >
                  <span>
                    {findProperty(property.property).substring(0, 25) + "..."}
                  </span>
                </OverlayTrigger>
              ) : (
                <span>
                  {findProperty(property.property) === "Default list" ? (
                    <FormattedMessage id={"leads.defaultList"} />
                  ) : (
                    findProperty(property.property)
                  )}{" "}
                </span>
              )}
            </td>
          );
        }
        if (property.property === "tags") {
          return (
            <td key={i}>
              <div style={{ width: "max-content" }}>
                {findProperty(property.property)[0] && (
                  <span
                    style={{
                      background:
                        findProperty(property.property)[0].color + "1A",
                      color: findProperty(property.property)[0].color,
                      borderRadius: 20,
                      margin: "5px 0",
                      padding: 5,
                    }}
                  >
                    {findProperty(property.property)[0].value}{" "}
                  </span>
                )}
                {findProperty(property.property).length > 1 && (
                  <OverlayTrigger
                    key={"tags-" + lead._id}
                    placement={"top"}
                    overlay={(props) =>
                      Tooltip({
                        ...props,
                        data: (
                          <div
                            style={{
                              background: "#fff",
                              borderRadius: 5,
                              padding: 10,
                            }}
                          >
                            {findProperty(property.property)
                              .slice(1)
                              .map((tag, i) => (
                                <div
                                  key={i}
                                  style={{
                                    background: tag.color + "1A",
                                    color: tag.color,
                                    borderRadius: 20,
                                    margin: "5px 0",
                                    padding: "2px 5px",
                                  }}
                                >
                                  {tag.value}
                                </div>
                              ))}
                          </div>
                        ),
                      })
                    }
                  >
                    <p className="contact-more tip">
                      {findProperty(property.property).length - 1}
                    </p>
                  </OverlayTrigger>
                )}
              </div>
            </td>
          );
        }
        if (Array.isArray(findProperty(property.property))) {
          if (
            property.property == "phones" &&
            findProperty(property.property).length == 0
          ) {
            return (
              <td key={i}>
                <ShowDataButton
                  lead={lead}
                  type={"phoneCredits"}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              </td>
            );
          }
          /* if (
            property.property == "currentPersonalEmails" &&
            findProperty(property.property).length == 0
          ) {
            return (
              <td key={i}>
                <ShowDataButton
                  lead={lead}
                  type={"personalEmailCredits"}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              </td>
            );
          }
          if (
            property.property == "currentProEmails" &&
            findProperty(property.property).length == 0
          ) {
            return (
              <td key={i}>
                <ShowDataButton
                  lead={lead}
                  type={"workEmailCredits"}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              </td>
            );
          }*/
          return (
            <td key={i}>
              {findProperty(property.property)[0]}
              {findProperty(property.property).length > 1 && (
                <OverlayTrigger
                  key={"list-" + lead._id}
                  placement={"top"}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data: findProperty(property.property),
                    })
                  }
                >
                  <p className="contact-more tip">
                    {findProperty(property.property).length - 1}
                  </p>
                </OverlayTrigger>
              )}
            </td>
          );
        }
      })}
    </tr>
  );
}
function ShowDataButton({ lead, type, onDataDiscover, isVersion2 }) {
  if (!isVersion2) return <span>--</span>;
  return (
    <button
      className={"btn btn2 show-data"}
      onClick={() =>
        onDataDiscover({ lead, type: CREDIT_COLORS[type].creditMap })
      }
      style={{
        borderColor: CREDIT_COLORS[type].color,
        color: CREDIT_COLORS[type].color,
      }}
    >
      <img src={CREDIT_COLORS[type].icon} alt="" />{" "}
      <FormattedMessage id={"show"} defaultMessage={"Show"} />
    </button>
  );
}
export default LeadsItem;
