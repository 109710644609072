import React from "react";
import { desynchronizeLinkedinAccount } from "../../../../../../services/api/linkedin.api";
import { FormattedMessage } from "react-intl";
import "./LinkedinComponent.css";
import { capitalizeFirstLetter } from "../../../../../../services/utils/tools";
import { integrationUpdatedNames } from "../../../constants";

function LinkedinComponent(props) {
  const handleConnect = () => {
    props.onHide();
    return window.open("https://www.linkedin.com/feed/?synchro=true");
  };

  const handleDisconnect = async () => {
    let { statusCode } = await desynchronizeLinkedinAccount();
    if (statusCode === 200) {
      props.disconnectAccount();
      props.onHide();
    }
  };

  return (
    <div className={"linkedin-btn-right"}>
      <div className="linkedin-cta">
        <a
          href="https://chromewebstore.google.com/detail/kaspr-get-anyones-phone-n/kkfgenjfpmoegefcckjklfjieepogfhg?hl=fr"
          className="btn btn3 "
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="responsive-text dl-extension">
            <FormattedMessage id="settings.integration.downloadTheExtension" />
          </div>
        </a>
      </div>
      {props.account ? (
        <a
          href="#"
          className="btn1 ripple-btn"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#api-integration-modal"
          onClick={handleDisconnect}
        >
          <FormattedMessage id={"settings.disconnect"} />
        </a>
      ) : (
        <button className={"btn1 ripple-btn"} onClick={handleConnect}>
          <div className="responsive-text ">
            <FormattedMessage
              id="settings.connectTo"
              values={{
                value: capitalizeFirstLetter(
                  integrationUpdatedNames[props.integration]
                ),
              }}
            />
          </div>
        </button>
      )}
    </div>
  );
}

export default LinkedinComponent;
