import React, { useContext, useState } from "react";
import DataNotFound from "../../../../../components/DataNotFound/DataNotFound";
import { FormattedDate, useIntl } from "react-intl";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "../../../../../components";
import {
  getLeadFeatures,
  searchTask,
  updateTask,
} from "../../../../../services/api/leads.api";
import { actions, LeadsContext } from "../../../store/leads.store";
import AddTaskModal from "../../../Tasks/common/AddTaskModal/AddTaskModal";
import { FormattedMessage } from "react-intl/lib";
import { toast } from "react-toastify";

export default function Tasks({
  onAddTask,
  tasks,
  profile,
  organization,
  memberPermission,
  planPermission,
}) {
  const { state, dispatch } = useContext(LeadsContext);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const handleTaskComplete = async (task, isCompleted) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    task.isCompleted = isCompleted;
    const { data, statusCode } = await updateTask(task);
    if (statusCode == 200) {
      const {
        data: searchData,
        statusCode: searchStatusCode,
      } = await searchTask({
        isCompleted: taskStatus,
        leadId: profile._id,
      });
      if (searchStatusCode == 200) {
        dispatch({
          type: actions.LEAD_DETAILS,
          payload: { ...state.leadDetails, tasks: searchData.tasks },
        });
      }
    }
  };
  const handleEditTask = async (task) => {
    if (!memberPermission) {
      setTaskToEdit(null);

      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!planPermission) {
      setTaskToEdit(null);

      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    const { data, statusCode } = await updateTask(task);
    if (statusCode == 200) {
      const { data: featuresData } = await getLeadFeatures({
        leadId: profile._id,
      });
      dispatch({ type: actions.LEAD_DETAILS, payload: featuresData });
      setTaskToEdit(null);
    }
    return true;
  };
  const handleFilterByStatus = async (isCompleted) => {
    setTaskStatus(isCompleted);
    const { data, statusCode } = await searchTask({
      isCompleted,
      leadId: profile._id,
    });
    if (statusCode == 200) {
      dispatch({
        type: actions.LEAD_DETAILS,
        payload: { ...state.leadDetails, tasks: data.tasks },
      });
    }
  };
  return (
    <div id="task_tab" className="tab-pane fade">
      <div className="task-tab">
        <button className="new-task-btn" onClick={onAddTask}>
          <FormattedMessage id={"leads.tasks.addNewTask"} />
        </button>

        <div className="newtask-listing mt-4">
          <FilterByStatus onFilter={handleFilterByStatus} />
          <label className="s-title">
            <FormattedMessage id={"leads.tasks.tasksAdded"} />
          </label>
          <div className="task-card-wrapper">
            {tasks.length > 0 ? (
              <div>
                {tasks.map((task, i) => (
                  <TaskCard
                    key={i}
                    task={task}
                    onTaskComplete={handleTaskComplete}
                    onTaskEdit={setTaskToEdit}
                  />
                ))}
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      </div>
      {taskToEdit && (
        <AddTaskModal
          task={taskToEdit}
          show={!!taskToEdit}
          setToggle={setTaskToEdit}
          onCreate={handleEditTask}
          organization={organization}
          lead={profile}
        />
      )}
    </div>
  );
}
function FilterByStatus({ onFilter }) {
  const [checked, setChecked] = useState("all");
  return (
    <div className={"filter-by-status"}>
      <div className={"filter-title"}>
        <FormattedMessage id={"leads.tasks.filterByStatus"} /> :
      </div>
      <div className={"radio-container"}>
        <div
          className="radio-btn"
          id={"filter_status_all"}
          onClick={() => {
            setChecked("all");
            onFilter(null);
          }}
        >
          <input type="radio" name="status" checked={checked == "all"} />
          <FormattedMessage id={"all"} />
          <span
            className="radio-icon"
            style={{ transform: "scale(.8)" }}
          ></span>{" "}
        </div>
        <div
          className="radio-btn"
          onClick={() => {
            setChecked("completed");
            onFilter(true);
          }}
        >
          <input type="radio" name="status" checked={checked == "completed"} />
          <FormattedMessage id={"leads.tasks.completed"} />
          <span
            className="radio-icon"
            style={{ transform: "scale(.8)" }}
          ></span>{" "}
        </div>
        <div
          className="radio-btn"
          onClick={() => {
            setChecked("notCompleted");
            onFilter(false);
          }}
        >
          <input
            type="radio"
            name="status"
            checked={checked == "notCompleted"}
          />
          <FormattedMessage id={"leads.tasks.notCompleted"} />
          <span
            className="radio-icon"
            style={{ transform: "scale(.8)" }}
          ></span>{" "}
        </div>
      </div>
    </div>
  );
}
function TaskCard({ task, onTaskComplete, onTaskEdit }) {
  const intl = useIntl();
  return (
    <div className="task-card">
      <div className="top-bar">
        <div className={"task-legend " + task.type + "-tag"}>
          {<FormattedMessage id={"leads.tasks." + task.type} />}
        </div>
        <div className="mb-blk">
          <button className="" onClick={() => onTaskEdit(task)}>
            <img src="/assets/images/ic_edit_g.png" alt="" width={15} />
          </button>
        </div>
      </div>
      <div className="bottom-bar mt-4">
        <div className="task-overview">
          <div style={{ display: "flex", alignItems: "center" }}>
            <figure className="status-ui" style={{ marginRight: 7 }}>
              {task.isCompleted ? (
                <OverlayTrigger
                  key={"completed-task"}
                  placement={"top"}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data: intl.formatMessage({
                        id: "leads.tasks.markTaskAsNotCompleted",
                      }),
                    })
                  }
                >
                  <span
                    className="completed"
                    onClick={() => onTaskComplete(task, false)}
                  ></span>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  key={"complete-task"}
                  placement={"top"}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data: intl.formatMessage({
                        id: "leads.tasks.markTaskAsCompleted",
                      }),
                    })
                  }
                >
                  <span
                    className="ic-status"
                    onClick={() => onTaskComplete(task, true)}
                  ></span>
                </OverlayTrigger>
              )}
            </figure>
            <h5 className="title-ui">{task.title}</h5>
          </div>
          <p className={"desc"} style={{ marginLeft: 5 }}>
            {task.description}
          </p>
        </div>

        <div className="right-ui">
          <p className="date-ui">
            <FormattedDate
              value={task.due}
              month={"short"}
              day={"numeric"}
              hour={"numeric"}
              minute={"numeric"}
            />{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
