import React, { useContext, useEffect, useRef, useState } from "react";
import {
  createOrganization,
  deleteOrganization,
  deletePersonalEmails,
  getOrganization,
  updateOrganization,
} from "../../../../services/api/organization.api";
import { Col, Modal, Row } from "react-bootstrap";
import InputTags from "../../../../components/InputTags/InputTags";
import { InfoToolTip, SelectPicker } from "../../../../components";
import { actions, OrganizationContext } from "../../store/Organization.store";
import DeleteMember from "../DeleteMember/DeleteMember";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import "./MyTeam.css";
import { getToken } from "../../../../services/utils";
import { getNotifications } from "../../../../services/api/notifications.api";
import CreditsSection from "../../../../components/CreditsSection/CreditsSection";
import { calculateRemaining } from "../../../../services/utils/tools";
function EditTeamModal({
  toggle,
  setToggle,
  profile,
  team,
  intl,
  handleDeleteEmailsFromLeads,
}) {
  const { state, dispatch } = useContext(OrganizationContext);
  const [error, setError] = useState(false);
  const [organization, setOrganization] = useState({
    name: "",
    isVisible: false,
    domains: [],
    companySize: null,
    image: "",
  });

  const [imageBase64, setImageBase64] = useState(null);
  const updateImage = async (e) => {
    let file = e.target.files[0],
      reader = new FileReader();
    const acceptedImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    if (!(file && acceptedImageTypes.includes(file.type))) return;
    setOrganization({
      ...organization,
      image: { file },
    });
    reader.onloadend = function () {
      setImageBase64(reader.result);
    };

    reader.readAsDataURL(file);
  };
  useEffect(() => {
    setOrganization({
      ...organization,
      domains: team.domains,
      isVisible: team.isPublic,
      name: team.name,
      image: team.picture,
      hidePersonalEmail: team.hidePersonalEmail,
    });
    if (team.hidePersonalEmail) {
      hidePersonalEmailCheckboxRef.current.checked = true;
    }
    if (team.isPublic) {
      publicCheckboxRef.current.checked = true;
      privateCheckboxRef.current.checked = false;
    } else {
      privateCheckboxRef.current.checked = true;
      publicCheckboxRef.current.checked = false;
    }
  }, [team]);

  const publicCheckboxRef = useRef(null);
  const privateCheckboxRef = useRef(null);
  const hidePersonalEmailCheckboxRef = useRef(null);
  const nameInputRef = useRef(null);

  const handleOnSubmit = async () => {
    if (!organization.name) {
      nameInputRef.current.reportValidity();
    } else {
      const file = organization.image && organization.image.file;

      const formData = new FormData();
      formData.append("picture", file);
      formData.append("organization", JSON.stringify(organization));
      const { data, statusCode } = await updateOrganization(formData);
      if (statusCode === 200) {
        getOrganization().then(({ data, statusCode }) => {
          dispatch({ type: actions.GET_ORGANIZATION, payload: data });
          if (statusCode !== 200) {
            window.location = "/workspace/createjoin";
          }
        });
        setToggle(false);
      } else {
        setError(data.messageTranslated || data.message);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    if (e.target.name === "public" && e.target.checked) {
      privateCheckboxRef.current.checked = false;
      setOrganization({ ...organization, isVisible: true });
    }

    if (e.target.name === "private" && e.target.checked) {
      publicCheckboxRef.current.checked = false;
      setOrganization({ ...organization, isVisible: false });
    }
    if (e.target.name === "hidePersonalEmail") {
      hidePersonalEmailCheckboxRef.current.checked = !!e.target.checked;
      setOrganization({
        ...organization,
        hidePersonalEmail: !!e.target.checked,
      });
    }
  };
  const handleSelectCompanySize = (companySize) => {
    setOrganization({ ...organization, companySize });
  };
  const companySizeValues = [
    {
      name:
        intl.formatMessage({ id: "organization.from" }) +
        " 1 - 50 " +
        intl.formatMessage({ id: "organization.people" }),
      id: 50,
    },
    {
      name:
        intl.formatMessage({ id: "organization.from" }) +
        " 50 - 100 " +
        intl.formatMessage({ id: "organization.people" }),
      id: 100,
    },
    {
      name:
        intl.formatMessage({ id: "organization.from" }) +
        " 100 - 200 " +
        intl.formatMessage({ id: "organization.people" }),
      id: 200,
    },
    {
      name:
        intl.formatMessage({ id: "organization.from" }) +
        " 200 - 500 " +
        intl.formatMessage({ id: "organization.people" }),
      id: 500,
    },
    {
      name:
        intl.formatMessage({ id: "organization.from" }) +
        " 500 - 1000 " +
        intl.formatMessage({ id: "organization.people" }),
      id: 1000,
    },
    {
      name:
        intl.formatMessage({ id: "organization.moreThan" }) +
        " 1000 " +
        intl.formatMessage({ id: "organization.people" }),
      id: -1,
    },
  ];
  return (
    <Modal
      show={toggle}
      onHide={() => setToggle(false)}
      className="fade cn-organzation-wraper"
      id="create-new-organzation"
    >
      <Modal.Header className="border-0" closeButton>
        <div className="modal-inner-header">
          <div className="profile-info">
            <h5 className="heading mb-8">
              <FormattedMessage id={"organization.editTeam"} />
            </h5>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-24">
        <div className="add-member-fields new-organization-ui">
          <form>
            <div className="thumnail-upload-ui">
              <figure>
                <img
                  src={
                    imageBase64 ||
                    organization.image ||
                    "/assets/images/ic_organization active.svg"
                  }
                  alt=""
                />
              </figure>
              <div className="thumbnail-content">
                <p>
                  <FormattedMessage id={"organization.thumbnail"} />
                </p>
                <div className="upload-btn">
                  <input type="file" name="file" onChange={updateImage} />
                  <label>
                    + <FormattedMessage id={"organization.changeImage"} />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id={"organization.organizationName"} />
              </label>
              <input
                ref={nameInputRef}
                type="text"
                required
                placeholder="eg. Apple"
                onChange={(e) =>
                  setOrganization({ ...organization, name: e.target.value })
                }
                value={organization.name}
              />
            </div>

            <div className="account-type-listing">
              <div className="choose-heading-ui">
                <label>
                  <FormattedMessage id={"organization.chooseType"} />
                </label>
              </div>
              <div className="choose-type-listing">
                <div className="checkbox-wrap mb-10">
                  <label>
                    <input
                      type="checkbox"
                      ref={publicCheckboxRef}
                      name={"public"}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkbox-icon" />
                    <div className="checkbox-content">
                      <h6>
                        <FormattedMessage id={"organization.public"} />
                      </h6>
                      <p>
                        <FormattedMessage id={"organization.public.text"} />
                      </p>
                    </div>
                  </label>
                </div>
                {organization.isVisible && (
                  <div className="enter-domain-ui">
                    <div className="form-group">
                      <InputTags
                        onChange={(domains) =>
                          setOrganization({ ...organization, domains })
                        }
                        profile={profile}
                        domains={organization.domains}
                        placeholder={intl.formatMessage({
                          id: "organization.typeYourDomain",
                        })}
                      />
                    </div>
                    <div className="suggestion-items">
                      <label className="suggestion-label">
                        <FormattedMessage id={"organization.examples"} />
                      </label>
                      <span className="sugeestion-tag-ui">kaspr.com</span>
                      <span className="sugeestion-tag-ui">kaspr.fr</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="choose-type-listing">
                <div className="checkbox-wrap">
                  <label>
                    <input
                      type="checkbox"
                      name={"private"}
                      ref={privateCheckboxRef}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkbox-icon" />
                    <div className="checkbox-content">
                      <h6>
                        <FormattedMessage id={"organization.private"} />
                      </h6>
                      <p>
                        <FormattedMessage id={"organization.private.text"} />
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="account-type-listing">
              <div className="choose-heading-ui">
                <label>
                  <FormattedMessage id={"organization.settings"} />
                </label>
              </div>
              <div className="choose-type-listing">
                <div className="checkbox-wrap mb-10">
                  <label>
                    <input
                      type="checkbox"
                      name={"hidePersonalEmail"}
                      ref={hidePersonalEmailCheckboxRef}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkbox-icon" />
                    <div className="checkbox-content">
                      <h6>
                        <FormattedMessage
                          id={"organization.hidePersonalEmail"}
                        />
                      </h6>
                      <p>
                        <FormattedMessage
                          id={"organization.hidePersonalEmail.text"}
                        />
                      </p>
                    </div>
                  </label>
                </div>
                {hidePersonalEmailCheckboxRef.current &&
                  hidePersonalEmailCheckboxRef.current.checked && (
                    <button
                      className={"btn btn1"}
                      onClick={handleDeleteEmailsFromLeads}
                    >
                      <FormattedMessage
                        id={"organization.deletePersonalEmails"}
                      />
                    </button>
                  )}
              </div>
            </div>

            <div className="form-wrap">
              <div className="form-group">
                <label>
                  <FormattedMessage id={"organization.companySize"} />
                </label>
                <SelectPicker
                  values={companySizeValues}
                  id={team.companySize}
                  onChange={handleSelectCompanySize}
                  labelsStyle={{ width: "100%" }}
                  zIndex={100}
                  isCreateOrg
                  withId
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">
          {error + " "}
        </div>
      )}
      <Modal.Footer className="modal-footer border-0">
        <a
          href="#"
          className="btn4 mr14 btn48"
          onClick={() => setToggle(false)}
        >
          <FormattedMessage id={"cancel"} />
        </a>
        <a href="#" className="btn1 btn48" onClick={handleOnSubmit}>
          <FormattedMessage id={"organization.editTeam"} />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
EditTeamModal = injectIntl(EditTeamModal);

function DeleteLeadsEmails({ setDeleteTeamModal }) {
  const [textToDeleteOrg, setText] = useState(false);
  const [error, setError] = useState(false);
  const intl = useIntl();
  const handleSubmit = async () => {
    if (
      textToDeleteOrg ===
      intl.formatMessage({ id: "organization.deletePersonalEmails" })
    ) {
      const { data, statusCode } = await deletePersonalEmails();
      if (statusCode === 200) {
        window.location = "/workspace";
      } else {
        setError(data.messageTranslated || data.message);
      }
    } else {
      setError(
        intl.formatMessage({ id: "organization.pleaseEnterTheValidPhrase" })
      );
    }
  };
  return (
    <Modal
      show={true}
      onHide={() => setDeleteTeamModal(false)}
      className="fade  delete-organization-wrapper"
      id="delete-organization"
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={"organization.deletePersonalEmails"} />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <div className="delete-inner-ui">
          <div className="alert-wrap">
            <span className="ic-alert">
              <img src="/assets/images/ic-alert.svg" alt="" />
            </span>
            <label>
              {" "}
              <FormattedMessage
                id={"organization.yourAboutToDeletePersonnalEmails"}
              />{" "}
            </label>
            <p>
              <FormattedMessage
                id={"organization.onceEmailsDeleted"}
                values={{
                  span: (chunk) => <span>{chunk}</span>,
                  br: <br />,
                }}
              />
            </p>
          </div>

          <p>
            <FormattedMessage
              id={"organization.beforeThisAction"}
              values={{
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }}
            />
          </p>
          <div className="form-fields">
            <a href="#" className="delete-tag">
              “<FormattedMessage id={"organization.deletePersonalEmails"} />”
            </a>
            <div className="form-group">
              <FormattedMessage id={"organization.typeHere"}>
                {(placeHolder) => (
                  <input
                    type="text"
                    name=""
                    placeholder={placeHolder}
                    onChange={(e) => setText(e.target.value)}
                    style={
                      error
                        ? { border: "1px solid red", color: "#981830" }
                        : { color: "#981830" }
                    }
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">
          {error + " "}
        </div>
      )}
      <div className="modal-footer border-0">
        <a href="#" className="btn6" onClick={() => setDeleteTeamModal(false)}>
          <FormattedMessage id={"cancel"} />
        </a>
        <a href="#" className="btn5 ml-20" onClick={handleSubmit}>
          <FormattedMessage id={"delete"} />
        </a>
      </div>
    </Modal>
  );
}
function DeleteOrganizationModal({ setDeleteTeamModal, intl }) {
  const [textToDeleteOrg, setText] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async () => {
    if (
      textToDeleteOrg ===
      intl.formatMessage({ id: "organization.deleteThisOrganization" })
    ) {
      const { data, statusCode } = await deleteOrganization();
      if (statusCode === 200) {
        window.location = "/workspace/createjoin";
      } else {
        setError(data.messageTranslated || data.message);
      }
    } else {
      setError(
        intl.formatMessage({ id: "organization.pleaseEnterTheValidPhrase" })
      );
    }
  };
  return (
    <Modal
      show={true}
      onHide={() => setDeleteTeamModal(false)}
      className="fade  delete-organization-wrapper"
      id="delete-organization"
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={"organization.deleteOrganization"} />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <div className="delete-inner-ui">
          <div className="alert-wrap">
            <span className="ic-alert">
              <img src="/assets/images/ic-alert.svg" alt="" />
            </span>
            <label>
              {" "}
              <FormattedMessage
                id={"organization.yourAboutToDeleteThisOrg"}
              />{" "}
            </label>
            <p>
              <FormattedMessage
                id={"organization.onceOrgIsDeleted"}
                values={{
                  span: (chunk) => <span>{chunk}</span>,
                  br: <br />,
                }}
              />
            </p>
          </div>

          <p>
            <FormattedMessage
              id={"organization.thisActionCannotBeUndone"}
              values={{
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }}
            />
          </p>
          <div className="form-fields">
            <a href="#" className="delete-tag">
              “<FormattedMessage id={"organization.deleteThisOrganization"} />”
            </a>
            <div className="form-group">
              <FormattedMessage id={"organization.typeHere"}>
                {(placeHolder) => (
                  <input
                    type="text"
                    name=""
                    placeholder={placeHolder}
                    onChange={(e) => setText(e.target.value)}
                    style={
                      error
                        ? { border: "1px solid red", color: "#981830" }
                        : { color: "#981830" }
                    }
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">
          {error + " "}
        </div>
      )}
      <div className="modal-footer border-0">
        <a href="#" className="btn6" onClick={() => setDeleteTeamModal(false)}>
          <FormattedMessage id={"organization.cancelKeepIt"} />
        </a>
        <a href="#" className="btn5 ml-20" onClick={handleSubmit}>
          <FormattedMessage id={"organization.yesDeleteThisOrganization"} />
        </a>
      </div>
    </Modal>
  );
}
DeleteOrganizationModal = injectIntl(DeleteOrganizationModal);

export default function MyTeam({
  organization,
  isAdmin,
  member,
  organizationCredits,
  invitationsNumber,
  remainingCredits,
  personalCredits,
}) {
  const [deleteTeamToggle, setDeleteTeamModal] = useState(false);
  const [deleteMemberPopUp, setDeleteMemberPopup] = useState(false);
  const [editTeamToggle, setEditTeamToggle] = useState(false);
  const [deleteEmailsFromLeadsModal, setDeleteEmailsFromLeadsModal] = useState(
    false
  );
  const intl = useIntl();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (isAdmin && organization && queryParams.get("action") === "EDIT_ORG") {
      const settingsTab = document.querySelector("a#settings");
      if (settingsTab) settingsTab.click();
    }
    //  setEditTeamToggle(true);
  }, []);
  const handleDeleteEmailsFromLeads = () => {
    setEditTeamToggle(false);
    setDeleteEmailsFromLeadsModal(true);
  };
  const handleEditTeam = () => {
    const settingsTab = document.querySelector("a#settings");
    if (settingsTab) settingsTab.click();
  };
  return (
    <div className="content-box-ui my-team-block" style={{ marginBottom: 0 }}>
      <div className="content-header">
        <div className="title-ui text-left">
          <span className="award-icon">
            <figure style={{ overflow: "hidden" }}>
              <img
                width={40}
                src={
                  organization.picture ||
                  "/assets/images/ic_organization active.svg"
                }
              />
            </figure>
          </span>
          <p className="capital">{organization.name}</p>
        </div>
        {isAdmin ? ( //admin: edit +delete org
          <div className="button-ui delete-account">
            <a
              href="/settings/workspace"
              // onClick={() => setEditTeamToggle(true)}
              style={{ marginRight: 15, width: 16 }}
              //    onClick={handleEditTeam}
            >
              <span className="ic-btn">
                <img src="/assets/images/ic_edit_g.png" alt="" />
              </span>
              {/* <FormattedMessage id={"organization.editTeam"} />*/}
            </a>
            <a
              href="/settings/workspace?action=delete"
              style={{ marginRight: 0, width: 16 }}
              //    onClick={() => setDeleteTeamModal(true)}
            >
              <span className="ic-btn">
                <img src="/assets/images/ic_delete_g.png" alt="" />
              </span>
              {/*
              <FormattedMessage id={"delete"} />
*/}
            </a>
          </div>
        ) : (
          //member: leave org
          <div className="button-ui delete-account">
            <a
              href="#"
              onClick={() => setDeleteMemberPopup(true)}
              style={{ marginRight: 15 }}
            >
              <span className="ic-btn" style={{ marginRight: 5 }}>
                <img src="/assets/images/ic_logout.svg" alt="" />
              </span>
              <FormattedMessage id={"organization.leaveTeam"} />
            </a>
          </div>
        )}
      </div>
      {deleteTeamToggle && (
        <DeleteOrganizationModal setDeleteTeamModal={setDeleteTeamModal} />
      )}
      {/* {editTeamToggle && (
        <EditTeamModal
          toggle={editTeamToggle}
          setToggle={setEditTeamToggle}
          profile={{}}
          team={organization}
          handleDeleteEmailsFromLeads={handleDeleteEmailsFromLeads}
        />
      )}*/}
      {deleteMemberPopUp && (
        <DeleteMember
          isLeaveOrganization={!isAdmin}
          toggle={deleteMemberPopUp}
          setToggle={setDeleteMemberPopup}
          member={member}
        />
      )}
      {deleteEmailsFromLeadsModal && (
        <DeleteLeadsEmails
          setDeleteTeamModal={() => {
            setDeleteEmailsFromLeadsModal(false);
            setEditTeamToggle(true);
          }}
          setConfirm={() => setEditTeamToggle(true)}
        />
      )}
      {organizationCredits.plan.planId !== "0" && (
        <div className={"total-member-infos"}>
          <hr />
          <span className={"n-licences"}>
            {organizationCredits.plan.numberOfSeats} Licences:
          </span>
          <span className={"n-members"}>
            {organization.totalMembers}{" "}
            <FormattedMessage id={"organization.member"} />
            {organization.totalMembers !== 1 && "s"}{" "}
          </span>
          {invitationsNumber > 0 && (
            <span className={"n-invitations"}>
              {invitationsNumber} Invitation{invitationsNumber !== 1 && "s"}
            </span>
          )}
        </div>
      )}
      <div className={"features-infos"}>
        <Row>
          <Col sm={12}>
            <div className="features-listing">
              <div className="line-heading">
                <label className="text-capitalize">
                  <FormattedMessage id={"tabs.billinginfo"} />
                </label>
              </div>
              <div className={"workspace-billing-info"}>
                <section>
                  <div>
                    <FormattedMessage id={"billing.plan"} />
                  </div>
                  <p> {organizationCredits.plan.name}</p>
                </section>

                <section>
                  <div>
                    <FormattedMessage id={"billing.subscription"} />
                  </div>
                  <p>
                    {" "}
                    {organizationCredits.plan.numberOfMonths == 1 ? (
                      <FormattedMessage id={"billing.monthly"} />
                    ) : organizationCredits.plan.numberOfMonths == 12 ? (
                      <FormattedMessage id={"billing.yearly"} />
                    ) : (
                      <>
                        {organizationCredits.plan.numberOfMonths}{" "}
                        <FormattedMessage id={"billing.months"} />
                      </>
                    )}
                  </p>
                </section>
                <section>
                  <div>
                    <FormattedMessage id={"billing.numberOfSeats"} />
                  </div>
                  <p> {organizationCredits.plan.numberOfSeats}</p>
                </section>
              </div>
              {organizationCredits &&
                organizationCredits.plan &&
                organizationCredits.plan.version == 2 && (
                  <CreditsSection
                    organizationCredits={organizationCredits}
                    remaining={calculateRemaining(
                      remainingCredits,
                      {
                        personalCredits,
                      },
                      "organizationRemaining"
                    )}
                  />
                )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
