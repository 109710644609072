import React from "react";
import "./ListItem.css";
import { FormattedMessage } from "react-intl";

function ListItem({ prefix, itemName }) {
  return (
    <div className="list-item">
      <img
        src="/assets/images/white-on-green-tick.svg"
        alt={"green tick icon"}
        width={20}
        height={20}
      />
      {prefix ? (
        <p>
          <FormattedMessage id={prefix} /> <FormattedMessage id={itemName} />
        </p>
      ) : (
        <p>
          <FormattedMessage id={itemName} />
        </p>
      )}
    </div>
  );
}

export default ListItem;
