import { convertToJsonAndHandleErrors, getToken } from "../utils";
import { PUBLIC_API_URL } from "../../constants";

export const generateKey = () => {
  return fetch(`${PUBLIC_API_URL}/keys/generateKey`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getKey = () => {
  return fetch(`${PUBLIC_API_URL}/keys/getKey`, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteKey = () => {
  return fetch(`${PUBLIC_API_URL}/keys/revokeKey`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const setHidePersonalEmailRequest = (hidePersonalEmail) => {
  return fetch(`${PUBLIC_API_URL}/keys/setHidePersonalEmail`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ hidePersonalEmail }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
