import React from "react";
import { FormattedMessage } from "react-intl";
import { planIncludes } from "../PlanCard/utils";
import CustomToolTip from "../../../../components/CustomToolTip/CustomToolTip";
import "./PlanPermissions.css";

const PlanPermissions = ({ staticPlan, currentPlan }) => {
  const PermissionCategory = ({ category, items, titleId, tooltip }) => (
    <React.Fragment key={category}>
      <div className="credits-section-title">
        <h3>
          <FormattedMessage id={titleId} />
        </h3>
        {tooltip && (
          <CustomToolTip
            style={{ maxWidth: "20vw" }}
            position={tooltip.position}
            element={tooltip.element}
          >
            <img src="/assets/images/icon-info.svg" alt="info" />
          </CustomToolTip>
        )}
      </div>
      <ul className="permissions">
        {items.map((item, index) => (
          <li key={index}>
            <span className="permission-name">{item.name}</span>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );

  const getTitleMessageId = (planId) =>
    planIncludes[planId]?.id || planIncludes[planId];

  const renderPermissionCategories = (permissions) => {
    const categories = [
      { key: "technology", titleId: "billing.plan.technology" },
      { key: "dashboard", titleId: "organization.filters.dashboard" },
      {
        key: "enrichment_automation",
        titleId: "billing.plan.enrichmentAutomation",
        tooltip: {
          position: currentPlan.planId === "plan_3" ? "left" : "",
          element: (
            <FormattedMessage
              id="billing.plan.enrichmentAutomationTooltip"
              values={{ strong: (chunks) => <strong>{chunks}</strong> }}
            />
          ),
        },
      },
      { key: "support", titleId: "billing.permissions.Support" },
      { key: "integrations", titleId: "billing.permissions.Integration" },
    ];

    return categories.map(({ key, titleId, tooltip }) =>
      permissions.some((permission) => permission.category === key) ? (
        <PermissionCategory
          key={key}
          category={key}
          items={
            permissions.find((permission) => permission.category === key).items
          }
          titleId={titleId}
          tooltip={tooltip}
        />
      ) : null
    );
  };

  return (
    <div id="plan-permissions">
      <h2>
        <FormattedMessage
          id={getTitleMessageId(currentPlan.planId)}
          values={{ value: planIncludes[currentPlan.planId]?.value }}
        />
      </h2>
      <div className="plan-permissions-content">
        {staticPlan &&
          staticPlan.permissions &&
          renderPermissionCategories(staticPlan.permissions)}
      </div>
    </div>
  );
};

export default PlanPermissions;
