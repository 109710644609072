import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { SettingsContext, actions } from "../../../../store/Settings.store";
import { capitalizeFirstLetter } from "../../../../../../services/utils/tools";
import { integrationUpdatedNames } from "../../../constants";

const ZapierComponent = (props) => {
  const { dispatch } = useContext(SettingsContext);

  const handleClick = () => {
    dispatch({
      type: actions.SET_IS_ZAPIER_MODAL_OPEN,
      payload: true,
    });
  };

  return (
    <button className={"btn1 ripple-btn"} onClick={handleClick}>
      <FormattedMessage
        id="settings.connectTo"
        values={{
          value: capitalizeFirstLetter(
            integrationUpdatedNames[props.integration]
          ),
        }}
      />
    </button>
  );
};

export default ZapierComponent;
