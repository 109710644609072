import React, { useEffect, useRef, useState } from "react";
import "../InputTags/InputTags.css";
export default function InputEmailTags({
  onChange,
  profile,
  domains,
  placeholder,
  checkValidity,
}) {
  const inputRef = useRef(null);
  const tagsRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    observer.observe(inputRef.current);
    if (tagsRef.current && inputRef.current) {
      inputRef.current.style.paddingLeft =
        tagsRef.current.offsetWidth + 5 + "px";
    }
  }, [tags, isIntersecting]);

  useEffect(() => {
    setTags(domains);
  }, [profile]);

  const handleKeyUp = (e) => {
    const targetValue = e.target.value.trim();
    onChange([...tags], e.target.value);
    //13 : enter
    //32 : space
    if ((e.keyCode === 32 || e.keyCode === 13) && targetValue) {
      setTags([
        ...tags,
        { value: targetValue, isValid: checkValidity(targetValue) },
      ]);
      inputRef.current.value = "";
      onChange([
        ...tags,
        { value: targetValue, isValid: checkValidity(targetValue) },
      ]);
    }
    //8 : delete
    if (e.keyCode === 8 && !e.target.value) {
      let newTags = [...tags];
      newTags.pop();
      if (tags.length > 0) {
        setTags([...newTags]);
        onChange([...newTags]);
      } else {
        setTags([]);
        onChange([]);
      }
    }
  };
  const handleBlur = (e) => {
    if (e.target.value) {
      const targetValue = e.target.value.trim();
      setTags([
        ...tags,
        { value: targetValue, isValid: checkValidity(targetValue) },
      ]);
      inputRef.current.value = "";
      onChange([
        ...tags,
        { value: targetValue, isValid: checkValidity(targetValue) },
      ]);
    } else {
      if (tags.length) return;
      setTags([]);
      onChange([]);
    }
  };
  const handleClose = (email) => {
    let newTags = [...tags];
    newTags = newTags.filter((tag) => tag.value !== email);
    setTags(newTags);
    onChange(newTags);
  };
  return (
    <div className={"input-tags"}>
      <input
        ref={inputRef}
        type="text"
        name=""
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />
      <div className={"input-all-tags"} ref={tagsRef}>
        {tags.length >= 4 && (
          <span className={"input-tag"}>
            {"+" + (tags.length - tags.slice(3, 7).length)}
          </span>
        )}
        {tags.length < 4
          ? tags.map((tag, i) => (
              <span
                key={i}
                className={"input-tag"}
                style={!tag.isValid ? { background: "red", color: "#fff" } : {}}
              >
                {tag.value}
                <span
                  className={"close-tag"}
                  onClick={() => handleClose(tag.value)}
                >
                  <img src="/assets/images/close.svg" alt="" />
                </span>
              </span>
            ))
          : tags
              .map((tag, i) => (
                <span
                  key={i}
                  className={"input-tag"}
                  style={
                    !tag.isValid ? { background: "red", color: "#fff" } : {}
                  }
                >
                  {tag.value}
                  <span
                    className={"close-tag"}
                    onClick={() => handleClose(tag.value)}
                  >
                    <img src="/assets/images/close.svg" alt="" />
                  </span>
                </span>
              ))
              .slice(3, 7)}
      </div>
    </div>
  );
}
