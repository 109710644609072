const actions = {
  TOGGLE: "TOGGLE",
  GET_PLANS: "GET_PLANS",
  GET_SUBSCRIPTION_INFOS: "GET_SUBSCRIPTION_INFOS",
  ON_CHECKOUT_FORM: "ON_CHECKOUT_FORM",
  ON_CREDIT_PACKS: "ON_CREDIT_PACKS",
  GET_PROFILE: "GET_PROFILE",
  GET_PAYMENT_HISTORY: "GET_PAYMENT_HISTORY",
  GET_ORGANIZATION: "GET_ORGANIZATION",
  SET_COUNTRY_STATES: "SET_COUNTRY_STATES",
  SUBSCRIPTION_INFOS_ERROR: "SUBSCRIPTION_INFOS_ERROR",
};

export default actions;
