import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./LeadsDetails.css";
import ProfileSection from "../ProfileSideModal/Components/ProfileSection";
import useProfile from "../Hooks/Profile.hooks";
import HeadButtons from "../ProfileSideModal/Components/HeadButtons";
import ContactFields from "../ProfileSideModal/Components/ContactFields";
import useProfileInfo from "../Hooks/ProfileInfo.hooks";
import CompanyInfos from "../Common/CompanyInfos/CompanyInfos";
import IntegrationsPopUp from "../../../Workflow/common/IntegrationsPopUp/IntegrationsPopUp";
import { toast, ToastContainer } from "react-toastify";
import { actions, LeadsContext } from "../../store/leads.store";
import TagsBlock from "../ProfileSideModal/Components/TagsBlock";
import { FormattedMessage, useIntl } from "react-intl";
import RightFeatures from "./RightFeatures/RightFeatures";
import { ModalMessage } from "../../../../components";
import SendEmail from "../../common/SendEmail/SendEmail";
import SelectEmailTemplate from "../../common/SelectEmailTemplate/SelectEmailTemplate";
import AddNoteSection from "../Common/AddNoteSection/AddNoteSection";
import companyInfoData from "../../LeadsInfo/Common/CompanyInfos/data";
import * as Permissions from "../../../../components/Permissions/Permissions";
import {
  addLeadNote,
  createTask,
  getLeadFeatures,
} from "../../../../services/api/leads.api";
import AddTaskModal from "../../Tasks/common/AddTaskModal/AddTaskModal";
import AircallModal from "../../common/phoneIntegrations/Aircall/AircallModal";
import RingoverModal from "../../common/phoneIntegrations/Ringover/RingoverModal";

export default function LeadsDetails(props) {
  const { checkPlanPermissions, checkMemberPermissions } = props;
  const { state, dispatch } = useContext(LeadsContext);
  const intl = useIntl();
  const [usedState, setters, handlers] = useProfile({
    props,
    account: props.account,
  });
  const [usedInfoState, infoSetters, infoHandlers] = useProfileInfo({
    profile: props.profile,
    planPermission: checkPlanPermissions("Edit"),
    memberPermission: checkMemberPermissions("Edit"),
  });
  const [addNoteMessage, setAddNoteMessage] = useState(null);

  const fieldsChanges = () => {
    const profileKeys = Object.keys(props.profile);
    const companyKeys =
      props.profile.companyInfo && Object.keys(props.profile.companyInfo);
    let fieldsChanged = [];
    profileKeys.forEach((key) => {
      if (props.profile[key] !== usedInfoState.leadToModify[key]) {
        if (key == "companyInfo" && companyKeys) {
          companyKeys.forEach((companyKey) => {
            if (
              props.profile.companyInfo[companyKey] !==
              usedInfoState.leadToModify.companyInfo[companyKey]
            ) {
              fieldsChanged.push({
                property: "companyInfo." + companyKey,
                previousValue: props.profile.companyInfo[companyKey],
                newValue: usedInfoState.leadToModify.companyInfo[companyKey],
              });
            }
          });
        } else {
          if (key !== "isViewNotes") {
            fieldsChanged.push({
              property: key,
              previousValue: props.profile[key],
              newValue: usedInfoState.leadToModify[key],
            });
          }
        }
      }
    });

    return fieldsChanged;
  };

  const onLeadExport = (type) => {
    handlers.handleExportLeads(type, props.profile);
  };
  const handleTemplateChange = (value, type) => {
    if (type === "template") setters.setTemplate(value);
    else setters.setSubject(value);
  };

  const handleTemplateToggle = () => {
    setters.setEmailToggle(!usedState.emailToggle);
    setters.setTemplateToggle(!usedState.templateToggle);
  };
  const handleAddNote = async (noteValue) => {
    if (!checkMemberPermissions("Edit")) {
      infoSetters.setCreateNoteToggle(false);
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!checkPlanPermissions("Edit")) {
      infoSetters.setCreateNoteToggle(false);
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    const { statusCode, data } = await addLeadNote({
      text: noteValue,
      leadId: props.profile._id,
      clientDate: new Date(),
    });
    if (statusCode == 200) {
      infoSetters.setCreateNoteToggle(false);
      setAddNoteMessage("success");
      const { data } = await getLeadFeatures({ leadId: props.profile._id });
      dispatch({ type: actions.LEAD_DETAILS, payload: data });
      props.profile.isViewNotes = true;
      dispatch({ type: actions.SELECT_LEAD, payload: props.profile });
    } else {
      setAddNoteMessage("error");
      toast.error(data.message);
    }

    infoSetters.setCreateNoteToggle(false);
  };

  const handleCreateTask = async (dataForm) => {
    if (!checkMemberPermissions("Edit")) {
      infoSetters.setAddTaskToggle(false);
      return toast.error(<FormattedMessage id={"permissions.noAccess"} />);
    }
    if (!checkPlanPermissions("Edit")) {
      infoSetters.setAddTaskToggle(false);
      return toast.error(<FormattedMessage id={"permissions.upgradePlan"} />);
    }
    const { data, statusCode } = await createTask(dataForm);
    if (statusCode == 200) {
      infoSetters.setAddTaskToggle(false);
      const { data: featuresData } = await getLeadFeatures({
        leadId: props.profile._id,
      });
      dispatch({ type: actions.LEAD_DETAILS, payload: featuresData });
      toast.success(
        intl.formatMessage({ id: "leads.tasks.taskSuccessfullyCreated" })
      );
    } else {
      infoSetters.setAddTaskToggle(false);
      toast.error(data.message);
    }
    let allButton = document.querySelector("#filter_status_all");
    if (allButton) allButton.click();
    return true;
  };
  const resetLeadFeatures = async () => {
    let { data } = await getLeadFeatures({
      leadId: usedState.exportLeadToIntegration._id,
    });
    dispatch({
      type: actions.LEAD_DETAILS,
      payload: data,
    });
  };
  return (
    <>
      <div className={"profile-side-modal global-fade-in"}>
        <div className="content-wrapper-ui " style={{ paddingTop: 20 }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 content-wrap">
                <div className="content-block ">
                  <div className="inner-content-wrap white-card leads-detail-wrapper table-wrap">
                    <div className="header-blk head_block  ">
                      {/*PROFILE SECTION*/}
                      <ProfileSection profile={props.profile} />
                      {/*ACTION BTN LISTING*/}
                      <HeadButtons
                        setShow={infoSetters.setShow}
                        toggleTemplate={(e, lead) => {
                          if (e) e.stopPropagation();
                          handlers.handleEmailToggle(lead);
                        }}
                        profile={props.profile}
                        setExportToggle={infoSetters.setExportToggle}
                        exportToggle={usedInfoState.exportToggle}
                        onLeadExport={(type) => {
                          onLeadExport(type);
                          infoSetters.setLeadToModify(props.profile);
                        }}
                        onLeadDelete={() => {
                          if (!checkMemberPermissions("Edit")) {
                            return toast.error(
                              <FormattedMessage id={"permissions.noAccess"} />
                            );
                          }
                          if (!checkPlanPermissions("Edit")) {
                            return toast.error(
                              <FormattedMessage
                                id={"permissions.upgradePlan"}
                              />
                            );
                          }
                          infoSetters.setLeadToModify(props.profile);
                          infoSetters.setDeleteLeadToggle(
                            !usedInfoState.deleteLeadToggle
                          );
                        }}
                        setDeleteLeadToggle={infoSetters.setDeleteLeadToggle}
                        deleteLeadToggle={usedInfoState.deleteLeadToggle}
                        setCreateNoteToggle={() =>
                          infoSetters.setCreateNoteToggle(
                            !usedInfoState.createNoteToggle
                          )
                        }
                        isViewDetails={true}
                        addTaskToggle={usedInfoState.addTaskToggle}
                        setAddTaskToggle={infoSetters.setAddTaskToggle}
                        phoneToggle={usedInfoState.phoneToggle}
                        setPhoneToggle={infoSetters.setPhoneToggle}
                        setAircallToggle={infoSetters.setAircallToggle}
                        setRingoverToggle={infoSetters.setRingoverToggle}
                        planExportPermission={checkPlanPermissions("Export")}
                        memberExportPermission={checkMemberPermissions(
                          "Export"
                        )}
                        planEditPermission={checkPlanPermissions("Edit")}
                        memberEditPermission={checkMemberPermissions("Edit")}
                        organizationPlan={
                          props.redux.credits &&
                          props.redux.credits.organizationCredits &&
                          props.redux.credits.organizationCredits.plan
                        }
                      />
                    </div>
                    <div
                      className="body-content body-content-container "
                      style={{ overflow: "unset" }}
                    >
                      <div className="row">
                        <div className="col-lg-5">
                          <TagsBlock
                            selectToggle={usedInfoState.selectToggle}
                            setSelectToggle={infoSetters.setSelectToggle}
                            profilePermissions={props.redux.profile.permissions}
                            organizationPlanPermissions={
                              props.redux.credits &&
                              props.redux.credits.organizationCredits &&
                              props.redux.credits.organizationCredits.plan
                                .permissions
                            }
                            organizationPlan={
                              props.redux.credits &&
                              props.redux.credits.organizationCredits &&
                              props.redux.credits.organizationCredits.plan
                            }
                            /* planEditPermission={checkPlanPermissions("Edit")}
                            memberEditPermission={checkMemberPermissions(
                              "Edit"
                            )}*/
                            profile={props.profile}
                          />

                          <div className={"contact-fields-container"}>
                            <div className="workflow-detail-header">
                              <div className="cstm-tabs-ui cs-tabs-slider subscription-tabs">
                                <ul className="nav nav-tabs">
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#contact_info"
                                      className="active"
                                      style={{ minWidth: "49%" }}
                                    >
                                      Contact
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#company_info"
                                      style={{ minWidth: "49%" }}
                                    >
                                      <FormattedMessage
                                        id={"leads.properties.company"}
                                      />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className={"contact-infos-container"}>
                              <div
                                id={"contact_info"}
                                className={"tab-pane fade active show "}
                              >
                                <hr />
                                <ContactFields
                                  profile={props.profile}
                                  setEditLeadToggle={
                                    infoSetters.setEditLeadToggle
                                  }
                                  editLeadToggle={usedInfoState.editLeadToggle}
                                  moreEmails={usedInfoState.moreEmails}
                                  showMoreEmails={infoSetters.showMoreEmails}
                                  morePhones={usedInfoState.morePhones}
                                  showMorePhones={infoSetters.showMorePhones}
                                  onFieldsChange={infoHandlers.onFieldsChange}
                                  leadToModify={usedInfoState.leadToModify}
                                />
                              </div>
                              <div
                                id={"company_info"}
                                className={"tab-pane fade active "}
                              >
                                <CompanyInfos
                                  companyInfo={
                                    usedInfoState.leadToModify.companyInfo ||
                                    companyInfoData
                                  }
                                  onChange={(companyFields) =>
                                    infoHandlers.onFieldsChange({
                                      value: companyFields,
                                      property: "companyInfo",
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <RightFeatures
                          profile={props.profile}
                          user={props.user}
                          organization={props.organization}
                          addNoteMessage={addNoteMessage}
                          memberPermission={checkMemberPermissions("Edit")}
                          planPermission={checkPlanPermissions("Edit")}
                          onAddTask={() => infoSetters.setAddTaskToggle(true)}
                        />
                      </div>
                    </div>
                    {fieldsChanges().length > 0 &&
                      usedInfoState.saveAndCancelToggle && (
                        <SaveAndCancel
                          onFieldsSubmit={infoHandlers.onFieldsSubmit}
                          onCancel={() => {
                            infoSetters.setLeadToModify(props.profile);
                            infoSetters.setSaveAndCancelToggle(false);
                          }}
                          fieldsChanged={fieldsChanges()}
                          checkPlanPermissions={checkPlanPermissions}
                          checkMemberPermissions={checkMemberPermissions}
                        />
                      )}
                    {usedInfoState.createNoteToggle && (
                      <ModalMessage
                        toggle={true}
                        action={"confirm"}
                        setToggle={() => infoSetters.setCreateNoteToggle(false)}
                        forbidAction
                        forbidCancel
                        textElement={
                          <AddNoteSection
                            addNote={(value) => {
                              handleAddNote(value);
                            }}
                            onCancel={() =>
                              infoSetters.setCreateNoteToggle(false)
                            }
                            goToNotes={() => {
                              props.profile.isViewNotes = true;
                              dispatch({
                                type: actions.SELECT_LEAD,
                                payload: props.profile,
                              });
                              infoSetters.setCreateNoteToggle(false);
                            }}
                            containerStyle={{ marginTop: -25 }}
                          />
                        }
                      />
                    )}
                    {usedState.exportLeadToIntegration && (
                      <IntegrationsPopUp
                        exportRemaining={
                          props.redux.credits.remaining &&
                          props.redux.credits.remaining.organizationCredits &&
                          props.redux.credits.remaining.organizationCredits
                            .exportCredits
                        }
                        hasPermission={
                          props.redux.organizationCredit &&
                          !!props.redux.organizationCredit.plan.permissions.find(
                            (permission) =>
                              permission.name == "Integration" &&
                              permission.actions.includes("Export")
                          )
                        }
                        toggle={usedState.exportLeadToIntegration}
                        setToggle={setters.setExportLeadToIntegration}
                        filters={state.dataForm}
                        leads={[usedState.exportLeadToIntegration]}
                        showToastMessage={(response, type) => {
                          resetLeadFeatures();
                          type === "success"
                            ? toast.success(response)
                            : type === "info"
                            ? toast.info(response)
                            : toast.error(response);
                        }}
                        leadFromDetailsPageId={
                          usedState.exportLeadToIntegration &&
                          usedState.exportLeadToIntegration._id
                        }
                        organizationCredits={
                          props.redux.credits.organizationCredits
                        }
                        isVersion2={props.isVersion2}
                      />
                    )}

                    {usedInfoState.deleteLeadToggle && (
                      <ModalMessage
                        textElement={
                          <p>
                            {" "}
                            <FormattedMessage
                              id={"settings.areYouSureYouWantToDelete"}
                            />{" "}
                            {props.profile.firstName +
                              " " +
                              props.profile.lastName}{" "}
                            ?
                          </p>
                        }
                        action={"confirm"}
                        toggle={true}
                        handleAction={() => {
                          handlers.handleDeleteLead(props.profile);
                          dispatch({
                            type: actions.SELECT_LEAD,
                            payload: null,
                          });
                          window.location = "/leads";
                        }}
                        setToggle={() => infoSetters.setDeleteLeadToggle(false)}
                      />
                    )}
                    <SendEmail
                      setToggle={(e) => handlers.handleEmailToggle(e)}
                      toggle={usedState.emailToggle}
                      lead={props.profile}
                      handleTemplateToggle={(e) => handleTemplateToggle(e)}
                      handleTemplateChange={handleTemplateChange}
                      template={{
                        template: usedState.template,
                        subject: usedState.subject,
                      }}
                    />
                    <SelectEmailTemplate
                      setToggle={(e) => handleTemplateToggle(e)}
                      toggle={usedState.templateToggle}
                      handleTemplateChange={handleTemplateChange}
                      template={{
                        template: usedState.template,
                        subject: usedState.subject,
                      }}
                    />
                    <ModalMessage
                      toggle={usedState.permissionToggle}
                      setToggle={() =>
                        setters.setPermissionToggle(!usedState.permissionToggle)
                      }
                      title={intl.formatMessage({ id: "permissions.denied" })}
                      textElement={
                        props.account ? (
                          <>
                            <FormattedMessage id={"permissions.upgradePlan"} />{" "}
                            <br />
                            <a
                              target="_blank"
                              href=""
                              onClick={() => (window.location = "/billing")}
                            >
                              <FormattedMessage
                                id={"settings.clickHereToCheckOurDeals"}
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <FormattedMessage
                              id={"settings.youNeedConnectToGmail"}
                            />{" "}
                            <br />
                            <a
                              target="_blank"
                              href=""
                              onClick={() =>
                                (window.location = "/settings?integration=true")
                              }
                            >
                              <FormattedMessage
                                id={"settings.clickHereToConnect"}
                              />
                            </a>
                          </>
                        )
                      }
                      action={"Ok"}
                      handleAction={() =>
                        setters.setPermissionToggle(!usedState.permissionToggle)
                      }
                      forbidCancel={true}
                    />
                    {usedInfoState.addTaskToggle && (
                      <AddTaskModal
                        setToggle={infoSetters.setAddTaskToggle}
                        show={usedInfoState.addTaskToggle}
                        onCreate={handleCreateTask}
                        organization={props.organization}
                        lead={props.profile}
                      />
                    )}
                    {usedInfoState.aircallToggle && (
                      <AircallModal
                        handleToggle={infoSetters.setAircallToggle}
                        toggle={usedInfoState.aircallToggle}
                        phones={props.profile && props.profile.phones}
                        leadId={props.profile && props.profile._id}
                      />
                    )}
                    {usedInfoState.ringoverToggle && (
                      <RingoverModal
                        handleToggle={infoSetters.setRingoverToggle}
                        toggle={usedInfoState.ringoverToggle}
                        phones={props.profile && props.profile.phones}
                        leadId={props.profile && props.profile._id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id={"intersection"} style={{ marginBottom: 100, height: 1 }} />
    </>
  );
}
function SaveAndCancel({
  onFieldsSubmit,
  onCancel,
  fieldsChanged,
  checkPlanPermissions,
  checkMemberPermissions,
}) {
  const [slideUp, setSlideUp] = useState("");
  const [position, setPosition] = useState("");
  const [width, setWidth] = useState("");
  const sectionRef = useRef(null);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPosition("absolute");
        } else {
          setPosition("fixed");
        }
      },
      { root: null, threshold: 0, rootMargin: "1px" }
    )
  );
  useEffect(() => {
    setTimeout(() => {
      setSlideUp(" slide-up");
    }, 0);
    const intersection = document.querySelector("#intersection");
    if (observer.current) {
      observer.current.observe(intersection);
    }
    const container = document.querySelector(".body-content-container");
    container.style.paddingBottom = "60px";
    setWidth(container.offsetWidth);
    window.addEventListener(
      "resize",
      function (event) {
        setWidth(container.offsetWidth);
      },
      true
    );
    return () => {
      container.style.paddingBottom = "inherit";
      container.style.background = "inherit";
    };
  }, []);

  const handleCancel = () => {
    onCancel();
  };
  return (
    <div
      className={"btns-leadsdetails-group " + slideUp}
      ref={sectionRef}
      style={{ position, width }}
    >
      {checkPlanPermissions("Edit") ? (
        <button className={"btn btn1 "} onClick={onFieldsSubmit}>
          <FormattedMessage id={"save"} />
        </button>
      ) : (
        <Permissions.CallToAction isButton={true} isPlan={true}>
          <FormattedMessage id={"save"} />
        </Permissions.CallToAction>
      )}

      <button className={"btn btn2"} onClick={handleCancel}>
        <FormattedMessage id={"cancel"} />
      </button>
      <div className={"fields-changed-container"}>
        <p>
          <FormattedMessage id={"leads.fieldsChanged"} /> :
        </p>
        {fieldsChanged.map((field) => (
          <span className={"fields-changed"}>
            <span>
              <FormattedMessage id={"leads.properties." + field.property} />{" "}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
}
