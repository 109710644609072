import React, { useEffect, useState } from "react";
import {
  createOrganization,
  getNewInvitations,
  getOrganization,
  getPublicOrganization,
  joinOrganization,
} from "../../../services/api/organization.api";
import ReceivedInvitations from "./ReceivedInvitations/ReceivedInvitations";
import { toast, ToastContainer } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { capitalizeFirstLetter } from "../../../services/utils/tools";

export default function CreateJoinOrganization({ profile }) {
  const [toggle, setToggle] = useState(false);
  const [invitations, setInvitations] = useState(false);
  useEffect(() => {
    getNewInvitations().then(({ data }) => {
      setInvitations(data.invitations);
    });
  }, []);

  const onCreateWorkspace = async () => {
    let domainName = profile.email.split("@")[1];
    domainName = domainName.split(".");
    domainName.pop();
    domainName = domainName.join("");
    const organization = {
      name: capitalizeFirstLetter(domainName) + " Workspace",
      domains: [profile.email.split("@")[1]],
    };
    const formData = new FormData();

    formData.append("picture", null);
    formData.append("organization", JSON.stringify(organization));
    const { data, statusCode } = await createOrganization(formData);
    if (statusCode === 200) {
      getOrganization().then(({ data: orgData, statusCode }) => {
        if (statusCode == 200) {
          window.location = "/organization";
        }
      });
    } else {
      toast.error(data.messageTranslated || data.message);
    }
  };

  return (
    <div className="col-md-10 content-wrap">
      <div className="join-organization-inner join-organization-page">
        <div style={{ marginBottom: 30 }}>
          {invitations && <ReceivedInvitations invitations={invitations} />}
        </div>
        <h4>
          <FormattedMessage id={"organization.createOrJoinOrganization"} />
        </h4>
        <p className="desc">
          <FormattedMessage
            id={"organization.createOrganization.description"}
          />
        </p>

        <div className="create-organization-btn">
          <a onClick={onCreateWorkspace}>
            <span className="ic-user">
              <img src="/assets/images/ic-create.png" alt="" />
            </span>
            <h5>
              <FormattedMessage id={"organization.createNewOrganization"} />
            </h5>
            <p>
              <FormattedMessage id={"organization.createOrganizationForTeam"} />
            </p>
          </a>
        </div>
        <ExistingOrganizations
          showToastMessage={(response) => {
            toast.error(response);
          }}
        />
      </div>
      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </div>
  );
}

function ExistingOrganizations({ showToastMessage }) {
  const [organizations, setOrganizations] = useState([]);
  const [invitations, setInvitations] = useState([]);
  useEffect(() => {
    getPublicOrganization().then(({ data }) => {
      setOrganizations(data.organizations);
    });
  }, []);

  const handleJoinOrganization = async (organization) => {
    let { statusCode, data } = await joinOrganization({
      organizationId: organization._id,
      clientDate: new Date(Date.now()),
    });
    if (statusCode !== 200) {
      showToastMessage(
        "An error has occurred while join organization request's sending: " +
          data.messageTranslated
      );
    }
    getPublicOrganization().then(({ data }) => {
      setOrganizations(data.organizations);
    });
  };
  const getOwner = (org) => {
    const owner = org.members.find((member) => member.type === "owner");
    const admin = org.members.find((member) => member.type === "admin");
    return owner
      ? { firstName: owner.firstName, lastName: owner.lastName }
      : admin
      ? { firstName: admin.firstName, lastName: admin.lastName }
      : { firstName: null, lastName: null };
  };
  if (organizations && organizations.length === 0) {
    return <div />;
  }
  return (
    <>
      <div className="divider-ui">
        <span>
          <FormattedMessage id={"organization.owner"} />
        </span>
      </div>
      <div className="existing-organization-wrapper">
        <h6>
          <FormattedMessage
            id={"organization.joinAnExistingOrganization"}
            values={{ br: <br /> }}
          />
        </h6>
        <p>
          <FormattedMessage id={"organization.weVeFound_"} />{" "}
          {organizations && organizations.length}{" "}
          <FormattedMessage id={"organization.organizationsWithYourEmail_"} />
        </p>
        <div className="existing-listing">
          <ul>
            {organizations &&
              organizations.map((org, i) => (
                <li key={i}>
                  <div className="left-ui">
                    <figure>
                      <img
                        src={
                          org.picture ||
                          "/assets/images/ic_organization active.svg"
                        }
                        alt=""
                      />
                    </figure>
                    <div className="content-ui">
                      <label>{org.name}</label>
                      {getOwner(org).firstName && (
                        <p>
                          <FormattedMessage id={"organization.owner"} />:{" "}
                          <span className="capital">
                            {getOwner(org).firstName}
                          </span>{" "}
                          <span className="capital">
                            {getOwner(org).lastName}
                          </span>
                        </p>
                      )}
                      <p>
                        {org.members.length}{" "}
                        <FormattedMessage id={"organization.members"} />
                      </p>
                    </div>
                  </div>
                  {org.hasSentJoinReaquest ? (
                    <div className="right-ui">
                      <button className="btn3">
                        <FormattedMessage
                          id={"organization.joinRequestAlreadySent"}
                        />
                      </button>
                    </div>
                  ) : (
                    <div className="right-ui">
                      <a
                        href="#"
                        className="btn3"
                        onClick={() => handleJoinOrganization(org)}
                      >
                        + <FormattedMessage id={"organization.join"} />
                      </a>
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
