import { checkPermission } from "../../services/utils";

export function usePermissions(
  planPermissions,
  memberPermissions,
  permissionName
) {
  let checkPlanPermission = () => true;
  let checkMemberPermission = () => true;
  if (planPermissions) {
    checkPlanPermission = (action) =>
      checkPermission({
        permissions: planPermissions,
        name: permissionName,
        action,
      });
  }

  if (memberPermissions) {
    checkMemberPermission = (action) =>
      checkPermission({
        permissions: memberPermissions,
        name: permissionName,
        action,
      });
  }

  return [checkPlanPermission, checkMemberPermission];
}
